import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { State } from '../../rootReducer';
import { getProp, required, __ } from '../../utilities';
import {
  activeAppspaceProfileSelector,
  codelistSelector,
} from '../App/selectors';
import { SportObject } from './definitions';

export const FORM_NAME = 'SPORT_OBJECT_FORM';

interface CustomFormData {
  name: string;
}

interface OwnProps {
  id: string;
  data: SportObject | null;
  loading: boolean;
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const sport = getProp(activeAppspaceProfileSelector(state) || {}, ['sport']);
  const initialValues = {
    sport,
    ...(props.data || {}),
  };
  return {
    initialValues,
    countryCodelist: codelistSelector('country')(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = InjectedFormProps<CustomFormData> & IMapStateToProps & OwnProps;

class SportObjectForm extends React.Component<Props> {
  render() {
    const { handleSubmit, countryCodelist } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Názov športového zariadenia')}
              name="name"
              type="text"
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Vlastník')}
              name="owner"
              type="text"
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
          <Col xs={12} s={6} m={4}>
            <Field
              label={__('Ulica')}
              name="street"
              type="text"
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
          <Col xs={12} s={6} m={2}>
            <Field
              label={__('Číslo')}
              name="number"
              type="text"
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
          <Col xs={12} s={4}>
            <Field
              label={__('PSČ')}
              name="zip"
              type="text"
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
          <Col xs={12} s={4}>
            <Field
              label={__('Obec')}
              name="city"
              type="text"
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
          <Col xs={12} s={4}>
            <Field
              label={__('Štát')}
              name="country"
              type="theselectsimple"
              options={countryCodelist}
              component={FormField}
              validate={[required]}
              required
            />
          </Col>
        </Row>
      </Form>
    );
  }
}
const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
  enableReinitialize: true,
})(SportObjectForm);

export default connect(mapStateToProps)(connected);
