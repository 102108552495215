import * as React from 'react';
import TheSelect from '@sportnet/ui/TheSelect';
import { __, debounce } from '@sportnet/ui/utilities';
import sportnetApi from 'src/sportnetApi';
import { Athlete } from './definitions';
import { rem, rgba } from 'polished';

interface IOwnProps {
  appSpace: string;
  value?: any;
  onChange: (user?: { _id: string; name: string } | null) => void;
  error?: string;
  disabled?: boolean;
}

const UserSelectionInput: React.FC<IOwnProps> = ({
  appSpace,
  value,
  onChange,
  error,
  disabled,
}) => {
  const [formattedValue, setFormattedValue] = React.useState<
    | {
        value: string | number | null;
        label: string;
      }
    | undefined
  >(value);

  const [users, setUsers] = React.useState<{
    [key: string]: Array<Athlete>;
  }>({});
  const [isFetchingUsers, setIsFetchingUsers] = React.useState(false);

  const [q, setQ] = React.useState('');

  const fetchAthletes = React.useMemo(() => {
    return debounce(async (val: string) => {
      if (val.length > 3) {
        setUsers((prevState) => ({ ...prevState, [val]: [] }));
        setIsFetchingUsers(true);
        try {
          const res = await sportnetApi.organizationPPOUsers(appSpace, {
            q: val,
          });
          const athletes = res.users?.map((u) => {
            return {
              additionalData: {},
              sportnetUser: {
                _id: u._id || '',
                name: u.name + ' ' + u.surname,
              },
            };
          });
          setUsers((prevState) => ({
            ...prevState,
            [val]: athletes || [],
          }));
        } catch (e: any) {
          console.error(e);
          alert(__('Nepodarilo sa získať zoznam športovcov'));
        } finally {
          setIsFetchingUsers(false);
        }
      }
    }, 300);
  }, [appSpace]);

  React.useEffect(() => {
    fetchAthletes(q);
  }, [q, fetchAthletes]);

  React.useEffect(() => {
    if (
      JSON.stringify(value) ===
      JSON.stringify({ value: undefined, label: undefined })
    ) {
      setFormattedValue(undefined);
    } else {
      setFormattedValue(value);
    }
  }, [value]);

  return (
    <TheSelect
      filterOptions={(options, inputValue) => {
        return options;
      }}
      disabled={disabled}
      error={error}
      loading={isFetchingUsers}
      options={(users[value || q] || []).map((u) => ({
        label: u.sportnetUser.name || '',
        value: u.sportnetUser._id,
      }))}
      renderLabel={(i) => {
        const athlete = users[value || q].find(
          (u) => u.sportnetUser._id === i.value,
        );
        if (athlete) {
          return (
            <div style={{ margin: `${rem(4)} 0` }}>
              <div>{athlete.sportnetUser.name}</div>
              <small style={{ color: rgba(0, 0, 0, 0.5) }}>
                {athlete.sportnetUser._id}
              </small>
            </div>
          );
        }
        return i.label;
      }}
      placeholder={__(
        'Vyberte typ príslušnosti alebo začnite vyhľadávať podľa mena',
      )}
      // inputValue={'test'}
      value={formattedValue || null}
      onChange={(i: any) => {
        if (!!i) {
          setFormattedValue({ value: i.value, label: i.label });
          onChange({ _id: i?.value, name: i?.label });
        } else {
          setFormattedValue(undefined);
          onChange(undefined);
        }
      }}
      onChangeInput={setQ}
    />
  );
};

export default UserSelectionInput;
