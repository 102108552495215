import Button from '@sportnet/ui/Button';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import sportnetApi from '../../sportnetApi';
import { __ } from '../../utilities';
import { Team } from '../Teams/definitions';
import { getCompetitionPartById } from './actions';
import ClubManagerForm from './clubManagerForm';

const CLUB_MANAGER_FORM_NAME = 'CLUB_MANAGER_FORM';

interface OwnProps {
  clubManagers: Array<{ clubId: string; sportnetId: string }>;
  teams: Team[];
  appSpace: string;
  competitionId: string;
  partId: string;
}

const mapStateToProps = (state: State) => ({
  isSubmittingClubManagerForm: isSubmitting(CLUB_MANAGER_FORM_NAME)(state),
});

type Props = ReturnType<typeof mapStateToProps> &
  OwnProps & { dispatch: ThunkDispatch<any, any, AnyAction> };

const CompetitionPartClubManagers: React.FC<Props> = ({
  clubManagers,
  teams,
  isSubmittingClubManagerForm,
  appSpace,
  competitionId,
  partId,
  dispatch,
}) => {
  const [clubManagersIsFetching, setClubManagersIsFetching] =
    React.useState(false);
  const [clubManagersNames, setClubManagersNames] = React.useState<{
    [key: string]: string;
  }>({});
  const [clubNames, setClubNames] = React.useState<{ [key: string]: string }>(
    {},
  );
  const [isDeletingClubManager, setIsDeletingClubManager] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [modalOpened, setModalOpened] = React.useState(false);

  const getClubManagers = React.useCallback(async () => {
    try {
      setClubManagersIsFetching(true);
      const userPromises = clubManagers.map((i) =>
        sportnetApi.getPublicUserProfile(i.sportnetId),
      );
      const userProfiles = await Promise.all(userPromises);
      setClubManagersNames(
        userProfiles.reduce(
          (acc: { [key: string]: string }, i) => ({
            ...acc,
            [i._id || '']: `${i.name} ${i.surname}`,
          }),
          {},
        ),
      );
      const clubPromises = clubManagers.map((i) =>
        sportnetApi.organizationPPOProfile(i.clubId),
      );
      const clubProfiles = await Promise.all(clubPromises);
      setClubNames(
        clubProfiles.reduce(
          (acc: { [key: string]: string }, i) => ({
            ...acc,
            [i._id || '']: i.name || '',
          }),
          {},
        ),
      );
    } catch (e: any) {
      //
    } finally {
      setClubManagersIsFetching(false);
    }
  }, [clubManagers]);

  React.useEffect(() => {
    getClubManagers();
  }, [getClubManagers]);

  const deleteClubManager = async (clubManager: {
    clubId: string;
    sportnetId: string;
  }) => {
    if (
      window.confirm(__('Skutočne si želáte odstrániť klubového manažéra?'))
    ) {
      try {
        setIsDeletingClubManager({
          ...isDeletingClubManager,
          [`${clubManager.sportnetId}|${clubManager.clubId}`]: true,
        });
        await api.adminRemoveClubManager(
          appSpace,
          competitionId,
          partId,
          clubManager.clubId,
          clubManager.sportnetId,
        );
        await dispatch(
          getCompetitionPartById.action({
            appspace: appSpace,
            competitionId,
            competitionPartId: partId,
          }),
        );
      } catch (e: any) {
        alert(__('Nepodarilo sa odstrániť klubového manažéra.'));
      } finally {
        setIsDeletingClubManager({
          ...isDeletingClubManager,
          [`${clubManager.sportnetId}|${clubManager.clubId}`]: false,
        });
      }
    }
  };

  const submitClubManagerForm = async (values: {
    sportnetId: string;
    clubId: { value: string };
  }) => {
    await api.adminAssignClubManager(
      appSpace,
      competitionId,
      partId,
      values.clubId.value,
      {},
      { sportnetId: values.sportnetId },
    );
    await dispatch(
      getCompetitionPartById.action({
        appspace: appSpace,
        competitionId,
        competitionPartId: partId,
      }),
    );
    setModalOpened(false);
  };

  const toggleModal = () => setModalOpened(!modalOpened);

  if (clubManagersIsFetching) {
    return (
      <LoaderWrapper>
        <Loader size="xs" />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Modal isOpen={modalOpened} handleClose={toggleModal}>
        <ModalContent>
          <ClubManagerForm
            onSubmit={submitClubManagerForm}
            form={CLUB_MANAGER_FORM_NAME}
            teams={teams}
          />
        </ModalContent>
        <ModalActions>
          <div />
          <div>
            <Button onClick={toggleModal}>{__('Zavrieť')}</Button>
            &nbsp;
            <Button
              primary
              loading={isSubmittingClubManagerForm}
              onClick={() => dispatch(submit(CLUB_MANAGER_FORM_NAME))}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      <Button onClick={toggleModal} primary>
        {__('Pridať klubového manažéra')}
      </Button>
      <br />
      <br />
      <Table>
        <Thead>
          <Tr>
            <Th>{__('Klub')}</Th>
            <Th>{__('Meno')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {clubManagers.map((i) => (
            <Tr key={i.sportnetId}>
              <Td>{clubNames[i.clubId] || i.clubId}</Td>
              <Td>
                {clubManagersNames[i.sportnetId] || i.sportnetId}
                <br />
                <i>{i.sportnetId}</i>
              </Td>
              <Td>
                <Button
                  onClick={() => deleteClubManager(i)}
                  loading={isDeletingClubManager[`${i.sportnetId}|${i.clubId}`]}
                  danger
                >
                  {__('Odstrániť')}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default connect(mapStateToProps)(CompetitionPartClubManagers);
