import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required, __ } from '../../utilities';

interface OwnProps {
  teams: Array<{ _id: string; name: string }>;
  loading: boolean;
}

const TeamSelectionForm: React.FC<OwnProps> = ({ teams, loading }) => {
  return (
    <>
      <Field
        name="teamId"
        component={FormField}
        label={__('Družstvo')}
        loading={loading}
        type="theselect"
        options={teams.map((t) => ({ label: t.name, value: t._id }))}
        required
        validate={[required]}
      />
    </>
  );
};

export default compose<any>(reduxForm({ enableReinitialize: true }))(
  TeamSelectionForm,
);
