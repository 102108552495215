import { createSelector } from 'reselect';
import { State } from '../../../rootReducer';
import { getProp } from '../../../utilities';

const membersDomainSelector = (state: State) => {
  return state.entities.membersByTeamId;
};
export const membersByTeamIdSelector = (teamId: string) =>
  createSelector(membersDomainSelector, (substate) => {
    const items = getProp(substate, [teamId], {});
    return Object.keys(items).reduce((acc: any[], nextId: any) => {
      return [...acc, items[nextId]];
    }, []);
  });

export const membersByIdSelector = (teamId: string, membersId: string) =>
  createSelector(membersDomainSelector, (substate) => {
    const items = getProp(substate, [teamId], {});
    return items[membersId] || null;
  });
