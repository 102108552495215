import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import { __ } from '@sportnet/ui/utilities';
import { change, reduxForm } from 'redux-form';
import UserSelectionInput from './userSelectionInput';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import React from 'react';
import { FOOTBALL_POSITIONS } from '../TeamSignup/Forms/Futbal/form';
import { compose } from 'redux';
import { getProp } from 'src/utilities';

const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

const ErrorText = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  margin-top: ${rem(3)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

type Props = {
  dispatch: any;
  form: any;
  appspace: string;
  editing: boolean;
  onSubmit: any;
  initialValues: any;
  player: any;
  setPlayer: any;
  captain: any;
  setCaptain: any;
  position: any;
  setPosition: any;
  nr: any;
  setNr: any;
  errors: any;
};

const AddPlayerForm: React.FC<Props> = ({
  dispatch,
  form,
  appspace,
  editing,
  player,
  setPlayer,
  captain,
  setCaptain,
  position,
  setPosition,
  nr,
  setNr,
  errors,
}) => {
  return (
    <Row>
      {!!!editing && (
        <Col xs={12}>
          <FormGroup>
            <Label>{__('Výber hráča (min. 4 znaky)')}</Label>
            <RelativeWrapper>
              <UserSelectionInput
                error={getProp(errors, ['player'])}
                value={player}
                appSpace={appspace}
                onChange={(i) => {
                  dispatch(change(form, 'sportnetUser', i));
                  setPlayer({ value: i?._id, label: i?.name });
                }}
              />
              {!!getProp(errors, ['player']) && (
                <ErrorText title={getProp(errors, ['player'])}>
                  {getProp(errors, ['player'])}
                </ErrorText>
              )}
            </RelativeWrapper>
          </FormGroup>
        </Col>
      )}
      <Col xs={12}>
        <FormField
          type="checkbox"
          label={__('Kapitán')}
          checked={captain || false}
          onChange={(e: any) => {
            dispatch(change(form, 'additionalData.captain', e.target.checked));
            setCaptain(e.target.checked || false);
          }}
        ></FormField>
      </Col>
      <Col xs={12}>
        <FormField
          type="select"
          onChange={(e: any) => {
            dispatch(
              change(form, 'additionalData.position', e.target.value || ''),
            );
            setPosition(e.target.value || '');
          }}
          value={position || 'goalkeeper'}
          label={__('Pozícia')}
          required={true}
        >
          <option hidden />
          {FOOTBALL_POSITIONS.map((position) => (
            <option key={position.value} value={position.value}>
              {position.label}
            </option>
          ))}
        </FormField>
      </Col>
      <Col xs={12}>
        <FormField
          type="number"
          label={__('Číslo dresu')}
          required={true}
          value={nr || '1'}
          onChange={(e: any) => {
            dispatch(change(form, 'additionalData.nr', e.target.value || ''));
            setNr(e.target.value || '');
          }}
        />
      </Col>
    </Row>
  );
};
export default compose(
  reduxForm<any, any>({
    enableReinitialize: true,
  }),
)(AddPlayerForm) as any;
