import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import FormField from '@sportnet/ui/FormField';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  change,
  formValueSelector,
  isSubmitting,
  reset,
  submit,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import Box from '../../components/Box';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { getProp, __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { profileSportSelector } from '../App/selectors';
import { getCompetitionPartById } from '../Competitions/actions';
import { Competition, CompetitionPart } from '../Competitions/definitions';
import { SportGround } from '../SportObjects/definitions';
import { Team } from '../Teams/definitions';
import { getMatchById, postMatch, updateMatch } from './actions';
import ContumationForm, { CONTUMATION_FORM_NAME } from './contumationForm';
import Form, { FORM_NAME, MatchNomination } from './form';
import { format } from 'date-fns';
import { Match } from './definitions';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state: State, props: RouteProps) => {
  return {
    profileSportValues: profileSportSelector(state),
    matchIsClosed: selector(state, 'closed') || false,
    matchHasScore: (selector(state, 'score') || []).length > 0,
    contumation: selector(state, 'contumation') || {
      isContumated: false,
      keepScore: false,
      advantage: '',
    },
    formIsSubmitting: isSubmitting(FORM_NAME)(state),
    attendants: selector(state, 'teams') || [],
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type RouteProps = RouteComponentProps<{
  id: string;
  appspace: string;
  competitionPartId: string;
  competitionId: string;
}>;
type Props = RouteProps & OwnProps & IMapStateToProps;

const MatchDetail: React.FC<Props> = ({
  dispatch,
  matchIsClosed: closed,
  formIsSubmitting,
  location: { search },
  match: { params },
  attendants,
  history,
  matchHasScore,
  contumation,
  profileSportValues,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [matchIsDeleting, setMatchIsDeleting] = React.useState(false);
  const [contumationModalVisible, setContumationModalVisible] =
    React.useState(false);

  const [sportGrounds, setSportGrounds] = React.useState<SportGround[]>([]);
  const [teams, setTeams] = React.useState<Team[]>([]);

  const [match, setMatch] = React.useState<Match | null>(null);
  const [competition, setCompetition] = React.useState<Competition | null>(
    null,
  );
  const [competitionPart, setCompetitionPart] =
    React.useState<CompetitionPart | null>(null);

  const setBreadcrumbsHelper = React.useCallback(
    (
      competitionId: string | null = null,
      competitionPartId: string | null = null,
      seasonId: string | null = null,
      roundId: string | null = null,
    ) => {
      let matchesUrl = `/admin/${params.appspace}/matches`;
      if (seasonId) {
        matchesUrl = `/admin/${
          params.appspace
        }/matches?seasonId=${seasonId}&competitionId=${
          competitionId || params.competitionId
        }&competitionPartId=${competitionPartId || params.competitionPartId}`;
        if (roundId) {
          matchesUrl = `${matchesUrl}&roundId=${roundId}`;
        }
      }
      dispatch(
        setBreadcrumbs([
          <Link key={0} to={matchesUrl}>
            {__('Zoznam stretnutí')}
          </Link>,
          params.id === 'new' ? __('Nové stretnutie') : __('Detail stretnutia'),
        ]),
      );
    },
    [dispatch, params],
  );

  const setMatchData = React.useCallback(async () => {
    const {
      entities: { matches },
    } = await dispatch(
      getMatchById.action({ appspace: params.appspace, matchId: params.id }),
    );
    const item = await api.adminGetCompetition(
      params.appspace,
      getProp(matches[params.id], ['competition', '_id'], ''),
    );
    const teams = await api.adminGetMatchSuitableTeams(
      params.appspace,
      params.id,
    );

    setTeams(teams.teams);
    setCompetition(item);
    setMatch({
      ...matches[params.id],
      sportGround: getProp(matches[params.id], ['sportGround', '_id']),
    });
    setBreadcrumbsHelper(
      getProp(matches[params.id], ['competitionId']),
      getProp(matches[params.id], ['partId']),
      matches[params.id].season._id,
      matches[params.id].round?._id,
    );
  }, [dispatch, setBreadcrumbsHelper, params]);

  const setInitialData = React.useCallback(async () => {
    setIsFetching(true);
    const { items } = await api.adminGetAllSportGrounds(params.appspace);
    setSportGrounds(items);
    if (params.competitionPartId) {
      const {
        entities: { competitionPartsByCompetitionId },
      } = await dispatch(
        getCompetitionPartById.action({
          appspace: params.appspace,
          competitionId: params.competitionId,
          competitionPartId: params.competitionPartId,
        }),
      );
      setCompetitionPart(
        competitionPartsByCompetitionId[params.competitionId][
          params.competitionPartId
        ],
      );
    }
    if (params.id !== 'new') {
      const match = await api.adminGetMatchById(params.appspace, params.id);

      setBreadcrumbsHelper(
        getProp(match, ['competition', '_id']),
        getProp(match, ['part', '_id']),
        match.season._id,
        match.round?._id,
      );
      if (!params.competitionPartId) {
        const {
          entities: { competitionPartsByCompetitionId },
        } = await dispatch(
          getCompetitionPartById.action({
            appspace: params.appspace,
            competitionId: getProp(match, ['competition', '_id']),
            competitionPartId: getProp(match, ['competitionPart', '_id']),
          }),
        );
        setCompetitionPart(
          competitionPartsByCompetitionId[
            getProp(match, ['competition', '_id'])
          ][getProp(match, ['competitionPart', '_id'])],
        );
      }
      await setMatchData();
    } else {
      const item = await api.adminGetCompetition(
        params.appspace,
        params.competitionId,
      );
      setCompetition(item);
    }
    try {
      if (params.id !== 'new') {
        const teams = await api.adminGetMatchSuitableTeams(
          params.appspace,
          params.id,
        );
        setTeams(teams.teams);
      }
    } catch (e: any) {
      alert(__('Nepodarilo sa získať zoznam družstiev alebo športovísk'));
    } finally {
      setIsFetching(false);
    }
  }, [params, dispatch, setBreadcrumbsHelper, setMatchData]);

  React.useEffect(() => {
    if (params.id !== 'new') {
      setInitialData();
    } else {
      dispatch(reset(FORM_NAME));
      setIsFetching(false);
      setSportGrounds([]);
      setTeams([]);
      setMatch(null);
      setCompetition(null);
      setInitialData();
    }
  }, [params.id, dispatch, setInitialData]);

  const handleSubmit = async (data: any) => {
    let competitionId = params.competitionId;
    let partId = params.competitionPartId;
    if (!competitionId || !partId) {
      const match = await api.adminGetMatchById(params.appspace, params.id);
      competitionId = getProp(match, ['competition', '_id'], '');
      partId = getProp(match, ['competitionPart', '_id'], '');
    }
    const submitData: {
      scoreComputationDisabled?: boolean;
      closed?: boolean;
      // smarttags: any[];
      teams?: any;
      perex?: string;
      sportGround?: SportGround;
      competitionId: string;
      partId: string;
      drawId?: number;
      startDate?: string;
      protocol?: any;
      rules?: any;
      nominations?: MatchNomination[];
      roundId?: string;
      score?: number[];
      scoreByPhases?: Array<number[]>;
      penaltiesScore?: number[];
      timer?: {
        [key: string]: {
          start: {
            date: string;
            seconds: string;
          };
          end?: {
            date: string;
            seconds: string;
          };
        };
      };
      livestream?: string;
      ticketsUrl?: string;
      detailUrl?: string;
      television_broadcasters?: Array<{ _id: string; label: string }>;
      internet_broadcasters?: Array<{ _id: string; label: string }>;
    } = {
      competitionId,
      partId,
      // smarttags: data.smarttags || [],
    };

    if (params.id !== 'new') {
      submitData.score = data.score && data.score.length ? data.score : [];
      submitData.scoreByPhases =
        data.scoreByPhases && data.scoreByPhases.length
          ? (data.scoreByPhases || []).filter((i: number[]) => i)
          : [];
      submitData.penaltiesScore =
        data.penaltiesScore && data.penaltiesScore.length
          ? data.penaltiesScore
          : [];
    }

    submitData.teams = data.teams;

    if (sportGrounds && data.sportGround) {
      submitData.sportGround = sportGrounds.find(
        (i) => i._id === data.sportGround.value,
      );
    }
    if (data.roundId) {
      submitData.roundId = data.roundId;
    }
    submitData.television_broadcasters = (
      data.television_broadcasters || []
    ).map((i: any) => ({ label: i.label, _id: i.value || i._id }));
    submitData.internet_broadcasters = (data.internet_broadcasters || []).map(
      (i: any) => ({ label: i.label, _id: i.value || i._id }),
    );
    submitData.livestream = data.livestream;
    submitData.ticketsUrl = data.ticketsUrl;
    submitData.detailUrl = data.detailUrl;
    submitData.perex = data.perex;
    if (data.nominations) {
      submitData.nominations = data.nominations;
    }
    if (data.drawId) {
      submitData.drawId = Number(data.drawId);
    }
    if (Object.keys(data.protocol || {}).length) {
      submitData.protocol = data.protocol;
      if (submitData.protocol && submitData.protocol.audience) {
        submitData.protocol.audience = Number(submitData.protocol.audience);
      } else {
        submitData.protocol.audience = undefined;
      }
    }
    if (typeof data.toClose !== 'undefined') {
      submitData.closed = data.toClose;
    }
    if (typeof data.scoreComputationDisabled !== 'undefined') {
      submitData.scoreComputationDisabled = data.scoreComputationDisabled;
    }
    if (data.timer) {
      submitData.timer = data.timer;
    }
    submitData.startDate = format(
      new Date(data.startDate),
      'yyyy-MM-dd HH:mm:ss',
    );

    if (params.id === 'new') {
      const res = await dispatch(
        postMatch.action({ appspace: params.appspace, match: submitData }),
      );
      history.push(
        `/admin/${params.appspace}/competitions/${competitionId}/parts/${partId}/matches/${res._id}`,
      );
    } else {
      await dispatch(
        updateMatch.action({
          appspace: params.appspace,
          matchId: params.id,
          match: submitData,
        }),
      );
      const {
        entities: { matches },
      } = await dispatch(
        getMatchById.action({ appspace: params.appspace, matchId: params.id }),
      );
      setMatch({
        ...matches[params.id],
        sportGround: getProp(matches[params.id], ['sportGround', '_id']),
      });
    }
  };

  const getNomination = () => {
    return attendants
      .map((i: Team) => {
        const team = teams.find((t) => t._id === i._id);
        if (team) {
          const nomination = getProp(match || {}, ['nominations'], []).find(
            (n: { teamId: string }) => n.teamId === team._id,
          );
          return {
            team,
            nomination,
          };
        }
        return null;
      })
      .filter((a: any) => a);
  };

  const isMatchNominated = () => {
    const nominations = getNomination();
    let matchNominated = nominations.length > 0;

    nominations.forEach((nomination: any) => {
      if (!getProp(nomination, ['nomination', 'closed'], false)) {
        matchNominated = false;
      }
    });
    return matchNominated;
  };

  const toggleContumationModal = () => {
    setContumationModalVisible((prevState) => !prevState);
  };

  const submitContumationForm = async () => {
    await dispatch(submit(CONTUMATION_FORM_NAME));
    toggleContumationModal();
    await setInitialData();
  };

  const deleteMatch = async () => {
    if (window.confirm(__('Skutočne si želáte zmazať stretnutie?'))) {
      try {
        setMatchIsDeleting(true);
        await api.adminDeleteMatch(params.appspace, params.id);
        history.push(
          `/admin/${params.appspace}/matches?competitionId=${params.competitionId}&competitionPartId=${params.competitionPartId}`,
        );
      } catch (e: any) {
        console.error(e);
        alert(__('Stretnutie sa nepodarilo odstrániť'));
      } finally {
        setMatchIsDeleting(false);
      }
    }
  };

  const partObject = getProp(competition || {}, ['parts'], []).find(
    (part: CompetitionPart) => part._id === params.competitionPartId,
  );

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          {params.id === 'new' && (
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${params.appspace}/competitions/${params.competitionId}`}
            />
          )}
          {!!match && (
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${params.appspace}/matches?competitionId=${
                match.competition._id
              }&competitionPartId=${match.competitionPart._id}&seasonId=${
                match.season._id
              }${!!match.round ? `&roundId=${match.round._id}` : ``}`}
            />
          )}
          <HeaderBar.Header>{__('Detail stretnutia')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          {!isFetching && !closed && (
            <ContextBarItem>
              <Button danger onClick={deleteMatch} loading={matchIsDeleting}>
                {__('Zmazať')}
              </Button>
            </ContextBarItem>
          )}
          <ContextBarSpacer />
          <ContextBarItem>
            {!isFetching &&
              params.id !== 'new' &&
              match &&
              competitionPart?.type === 'collective' && (
                <Button onClick={toggleContumationModal} danger>
                  {__('Kontumácia stretnutia')}
                </Button>
              )}
            &nbsp;
            {!isFetching && !closed ? (
              <React.Fragment>
                <Button
                  primary
                  onClick={async () => {
                    await dispatch(change(FORM_NAME, 'toClose', false));
                    dispatch(submit(FORM_NAME));
                  }}
                  loading={formIsSubmitting}
                >
                  {__('Uložiť')}
                </Button>
                &nbsp;
                {params.id !== 'new' &&
                  (isMatchNominated() ||
                    matchHasScore ||
                    (match && match.rules?.sport_sector === 'other')) && (
                    <Button
                      success
                      onClick={async () => {
                        await dispatch(change(FORM_NAME, 'toClose', true));
                        dispatch(submit(FORM_NAME));
                      }}
                      loading={formIsSubmitting}
                    >
                      {__('Uložiť a uzavrieť')}
                    </Button>
                  )}
              </React.Fragment>
            ) : (
              !isFetching && (
                <>
                  <Button
                    primary
                    onClick={async () => {
                      await dispatch(change(FORM_NAME, 'toClose', false));
                      dispatch(submit(FORM_NAME));
                    }}
                    loading={formIsSubmitting}
                  >
                    {__('Otvoriť pre úpravy')}
                  </Button>
                </>
              )
            )}
          </ContextBarItem>
        </ContextBar>
      }
    >
      {/* <AppContext title={__('Detail stretnutia')} /> */}
      <Segment>
        {!!competition && params.id !== 'new' && (
          <>
            {contumation && contumation.isContumated && (
              <Message danger title={__('Kontumované stretnutie')}>
                {__(
                  'Stretnutie bolo kontumované. Ďalšie informácie o kontumácii stretnutia nájdete po kliknutí na tlačidlo "Kontumácia stretnutia" v spodnom paneli.',
                )}
              </Message>
            )}
            {match &&
              match.closed &&
              (!contumation || !contumation.isContumated) && (
                <Message success title={__('Uzatvorený výsledok')}>
                  {__(
                    'Výsledok bol uzatvorený. Pre úpravu ho môžete znovuotvoriť kliknutím na tlačidlo "Otvoriť pre úpravy" v spodnom paneli',
                  )}
                </Message>
              )}
          </>
        )}
        {!!competition && (
          <Box title={__('Súťaž')}>
            <Row>
              <Col xs={12} m={4}>
                <FormField
                  readOnly
                  label={__('Názov súťaže')}
                  value={competition.name}
                />
              </Col>
              <Col xs={12} m={4}>
                <FormField
                  readOnly
                  label={__('Sezóna')}
                  value={competition.season.name}
                />
              </Col>
              {!!partObject && (
                <Col xs={12} m={4}>
                  <FormField
                    readOnly
                    label={__('Časť súťaže')}
                    value={partObject.name}
                  />
                </Col>
              )}
            </Row>
          </Box>
        )}
        {!!competitionPart && (
          <Form
            id={params.id}
            appspace={params.appspace}
            profileSportValues={profileSportValues}
            sportGrounds={sportGrounds}
            competition={competition}
            teams={teams}
            onSubmit={handleSubmit}
            data={match}
            competitionPart={competitionPart}
            closed={closed}
            isMatchNominated={isMatchNominated()}
            nominations={getNomination()}
            reloadMatch={() => {
              setInitialData();
            }}
          />
        )}
      </Segment>
      <Modal
        isOpen={contumationModalVisible}
        handleClose={toggleContumationModal}
      >
        <ModalContent>
          <Header size="s" withSeparator>
            {__('Kontumácia stretnutia')}
          </Header>
          <ContumationForm
            matchId={params.id}
            appSpace={params.appspace}
            initialValues={contumation}
            nominations={getNomination()}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button onClick={toggleContumationModal}>{__('Zavrieť')}</Button>
            <Button onClick={submitContumationForm} primary>
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </ScrollLayout>
  );
};

export default connect(mapStateToProps)(MatchDetail);
