import { IBaseObjectName, IResultsTableItem } from "./resultsTableSorters";

/**
 * Zoradi vysledky podla bodov
 * 
 * @param results 
 * @param baseObjectName 
 * @returns 
 */
const getResultsByPoints = (
  results: IResultsTableItem[],
  baseObjectName: IBaseObjectName,
) => {
  const getResultsByPoints = (
    results
  ).reduce((acc, result) => {
    return {
      ...acc, [Number(result[baseObjectName]?.points || 0)]: [
        ...acc[result[baseObjectName]?.points || 0] || [],
        result
      ]
    }
  }, {});
  return getResultsByPoints;
}

export default getResultsByPoints;