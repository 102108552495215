import 'moment/locale/sk';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import configureStore from './configureStore';
import App from './containers/App';
import { globalStyles as GlobalStyles } from './theme/globalStyles';
import { theme } from './theme/theme';

const store = configureStore();

const client = new QueryClient();
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Router>
          <Switch>
            <QueryClientProvider client={client}>
              <App />
            </QueryClientProvider>
          </Switch>
        </Router>
      </>
    </ThemeProvider>
  </Provider>,
);
