import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../../../utilities';

interface IOwnProps {
  readOnly?: boolean;
  points: Array<{ _id: string; label: string }>;
}

const SwimmingCompetitionPartRulesForm: React.FC<IOwnProps> = ({
  readOnly,
  points,
}) => {
  if (points.length === 0) {
    return null;
  }
  return (
    <Segment
      header={
        <SegmentHeader size="xs" withSeparator>
          {__('Zobrazované bodovacie parametre v medzikolách')}
        </SegmentHeader>
      }
    >
      <Row>
        <Col>
          <Field
            name="settings.roundPoints"
            component={FormField}
            type="theselect"
            multiple
            disabled={readOnly}
            parse={(items: Array<{ label: string; value: string }>) => {
              return items.map((i) => i.value);
            }}
            format={(i: string[]) => {
              if (!i) {
                return [];
              }
              return points.reduce((acc: any[], p) => {
                if (i.includes(p._id)) {
                  return [...acc, { label: p.label, value: p._id }];
                }
                return acc;
              }, []);
            }}
            options={points.map((p) => ({ label: p.label, value: p._id }))}
          />
        </Col>
      </Row>
    </Segment>
  );
};

export default SwimmingCompetitionPartRulesForm;
