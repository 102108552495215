import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { State } from '../../rootReducer';

const matchesDomainSelector = (state: State) => state.entities.matches;

export const currentListMatchesSelector = (listName: string) =>
  createSelector(
    getListResults(listName),
    matchesDomainSelector,
    (results, objects) => {
      if (results) {
        const res = results.map((id: string) => {
          return objects[id];
        });
        return res;
      }
      return [];
    },
  );
