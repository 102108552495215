import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FormProps, reduxForm } from 'redux-form';
import { State } from '../../../../rootReducer';
import Box from '../../../../components/Box';
import { __ } from '../../../../utilities';

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {};

type Props = FormProps<{ closed: boolean }, {}> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const BasicInfo: React.FC<Props> = () => {
  return (
    <Box title={__('Základné informácie')}>
      <Row>
        <Col xs={12} s={6}>
          <Field
            name="startDate"
            component={FormField}
            format={null}
            label={__('Termín konania')}
            type="dateTime"
            disabled
          />
        </Col>
        <Col xs={12} s={6}>
          <Field
            name="sportGround"
            disabled
            component={FormField}
            label={__('Miesto konania')}
            format={(i?: { _id: string; name: string }) => {
              if (i) {
                return i.name;
              }
              return '';
            }}
          />
        </Col>
      </Row>
    </Box>
  );
};

export default compose(
  reduxForm({}),
  connect(mapStateToProps, mapDispatchToProps),
)(BasicInfo) as any;
