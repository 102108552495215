import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import { debounce } from '@sportnet/ui/utilities';
import * as React from 'react';
import { compose } from 'redux';
import {
  change,
  Field,
  formValueSelector,
  getFormSubmitErrors,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import sportnetApi from '../../sportnetApi';
import { getProp, required, __ } from '../../utilities';
import api from '../../api';
import { matchManagerRoleName, matchManagerRoles } from './definitions';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import styled from 'styled-components';
import { rem } from 'polished';
import CrewSelectionInput from './crewSelectionInput';
import { connect } from 'react-redux';
import { State } from '../../rootReducer';
import { CustomThunkDispatch } from '../../configureStore';

const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

const ErrorText = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  margin-top: ${rem(3)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

interface IOwnProps {
  sportSector: string;
  appSpace: string;
  ownerPPO?: string;
}

const mapStateToProps = (state: State, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    user: selector(state, 'user'),
    errors: getFormSubmitErrors(props.form)(state),
  };
};

const MatchManagerForm: React.FC<
  InjectedFormProps<{}, IOwnProps> &
    IOwnProps & { dispatch: CustomThunkDispatch } & ReturnType<
      typeof mapStateToProps
    >
> = ({ sportSector, appSpace, ownerPPO, form, dispatch, user, errors }) => {
  const [delegates, setDelegates] = React.useState<
    Array<{ value: string; label: string }>
  >([]);

  const [isFetchingRelatedOrganizations, setIsFetchingRelatedOrganizations] =
    React.useState(false);
  const [relatedOrganizations, setRelatedOrganizations] = React.useState<
    Array<{
      _id?: string;
      name?: string;
    }>
  >([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState(
    ownerPPO || appSpace,
  );

  const getInitialData = React.useCallback(async () => {
    const { items } = await api.getSettingBySportSector(
      'sport_sector_delegates',
      sportSector,
    );
    setDelegates(
      items.map((i) => ({
        label: i.label,
        value: i._id,
        competenceTypes: getProp(i, ['payload', 'acceptedCompetenceTypes'], []),
      })),
    );
  }, [sportSector]);

  React.useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const fetchRelatedOrganizations = debounce(async (value: string) => {
    setRelatedOrganizations([]);
    if (value.length >= 3) {
      setIsFetchingRelatedOrganizations(true);
      try {
        const { items: relatedItems } =
          await sportnetApi.organizationPPOListRelatedPPOs(appSpace, {
            q: value,
          });
        setRelatedOrganizations(relatedItems || []);
      } catch (e: any) {
        console.error(e);
        alert(__('Nepodarilo sa získať zoznam organizácií.'));
      } finally {
        setIsFetchingRelatedOrganizations(false);
      }
    }
  }, 300);

  return (
    <>
      <FormField
        name="ppo"
        label={__('Organizácia')}
        placeholder={__(
          `Predvolene vlastník súťaže a organizátor (${ownerPPO}, ${appSpace})`,
        )}
        type="theselectsimple"
        options={relatedOrganizations.map((i) => ({
          label: i.name,
          value: i._id,
        }))}
        loading={isFetchingRelatedOrganizations}
        onChangeInput={(i: string) => {
          fetchRelatedOrganizations(i);
        }}
        value={selectedOrganization}
        onChange={(i: string) => {
          setSelectedOrganization(i);
        }}
      />
      <FormGroup>
        <Label>{__('Výber osoby (min. 4 znaky)')}</Label>
        <RelativeWrapper>
          <CrewSelectionInput
            error={getProp(errors, ['user'])}
            value={user}
            appSpace={appSpace}
            selectedOrganization={selectedOrganization || ownerPPO}
            types={delegates}
            onChange={(i, tags) => {
              dispatch(change(form, 'user', i));
              if (getProp(tags || {}, ['type'])) {
                dispatch(change(form, 'type', tags!.type));
              }
            }}
          />
          {!!getProp(errors, ['user']) && (
            <ErrorText title={getProp(errors, ['user'])}>
              {getProp(errors, ['user'])}
            </ErrorText>
          )}
        </RelativeWrapper>
      </FormGroup>
      <Field
        name="type"
        label={__('Typ delegovanej osoby')}
        component={FormFieldRedux}
        type="theselect"
        options={delegates}
        required
        validate={[required]}
      />
      <Field
        name="roles"
        label={__('Rola správcu')}
        component={FormFieldRedux}
        type="theselect"
        options={matchManagerRoles.map((role) => {
          return {
            value: role,
            label: matchManagerRoleName(role),
          };
        })}
        multiple
      />
    </>
  );
};

export default compose<any>(
  connect(mapStateToProps),
  reduxForm({}),
)(MatchManagerForm);
