import { reducerWithInitialState } from '../../../node_modules/typescript-fsa-reducers/dist';
import * as actions from './actions';
import { SportObject } from './definitions';

export type SportObjectsState = {
  readonly [key: string]: SportObject;
};

const INITIAL_STATE = {};

export const sportObjectsReducer = reducerWithInitialState(INITIAL_STATE)
  .case(actions.getSportObjectById.async.started, (state, parameters) => ({
    ...state,
    [parameters.id]: {
      isFetching: true,
    },
  }))
  .case(actions.getSportObjectById.async.failed, (state, parameters) => ({
    ...state,
    [parameters.params.id]: {
      isFetching: false,
    },
  }))
  .case(actions.getSportObjectById.async.done, (state, parameters) => ({
    ...state,
    [parameters.params.id]: {
      isFetching: false,
    },
  }))
  .case(actions.putSportObject.async.done, (state, parameters) => ({
    ...state,
    [parameters.params.sportObjectId]: {
      isFetching: false,
    },
  }));
