import Loader from '@sportnet/ui/Loader';
import { withTheme } from 'styled-components';

interface OwnProps {
  theme: any;
}

const BoxLoader = (props: OwnProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader size="xl" theme={props.theme} />
    </div>
  );
};

export default withTheme(BoxLoader);
