import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { commit, initialize, isCommiting } from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { getSportObjects } from './actions';
import { LIST_NAME, SportObject } from './definitions';
import { sportObjectsSelector } from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterParameters = RouteComponentProps<{ appspace: string }>;

const mapStateToProps = (state: State, props: RouterParameters) => ({
  sportObjects: sportObjectsSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouterParameters & OwnProps & IMapStateToProps;

class SportObjectsList extends React.PureComponent<Props> {
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(setBreadcrumbs([__('Zoznam športových zariadení')]));
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    try {
      await dispatch(
        commit.action({
          listName: LIST_NAME,
          load: async () => {
            const {
              entities: { sportObjects },
            } = await dispatch(getSportObjects.action({ appspace }));
            const items = Object.keys(sportObjects).map((id) => {
              return sportObjects[id];
            });
            return {
              total: items.length,
              results: items.map((object: SportObject) => {
                return object._id;
              }),
              nextOffset: null,
            };
          },
        }),
      );
    } catch (e: any) {
      console.error(e);
    }
  }
  render() {
    const {
      sportObjects,
      isFetching,
      match: {
        params: { appspace },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>
              {__('Zoznam športových zariadení')}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Link to={`/admin/${appspace}/sport-objects/new`}>
                <Button primary>{__('Pridať športové zariadenie')}</Button>
              </Link>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <>
          {/* <AppContext title={__('Zoznam športových zariadení')} /> */}
          {!isFetching && sportObjects.length === 0 ? (
            <NotFound
              icon="search"
              title={__('Pre váš klub sa nenašli žiadne športové zariadenia')}
            />
          ) : (
            <Segment>
              <Segment raised>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__('Názov športového zariadenia')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sportObjects.map((object: SportObject) => {
                      return (
                        <Tr
                          onClick={() => {
                            this.props.history.push(
                              `/admin/${appspace}/sport-objects/${object._id}`,
                            );
                          }}
                          key={object._id}
                        >
                          <Td>{object.name}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Segment>
            </Segment>
          )}
        </>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(SportObjectsList));
