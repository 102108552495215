import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../../api';
import { Squad, SquadDetail } from './definitions';
import { TeamState } from '../reducer';

const create = actionCreatorFactory('TEAMS');
const createAsync = asyncFactory<TeamState>(create);

interface NormalizedSquadSchema {
  entities: {
    squadsByTeamId: {
      [key: string]: {
        [key: string]: Squad;
      };
    };
  };
}

const normalizeSquads = (teamId: string, data: Squad[]) => {
  return {
    entities: {
      squadsByTeamId: {
        [teamId]: data.reduce((acc, item: Squad) => {
          return { ...acc, [item._id]: item };
        }, {}),
      },
    },
  };
};

export const getSquads = createAsync<
  {
    appspace: string;
    teamId: string;
  },
  NormalizedSquadSchema
>('GET_TEAM_SQUADS', async (parameters) => {
  const { teamId, appspace } = parameters;
  const response = (await Api.adminGetTeamSquads(
    appspace,
    teamId,
  )) as unknown as {
    items: Squad[];
  };
  return normalizeSquads(teamId, response.items || []);
});

export const getSquadById = createAsync<
  {
    appspace: string;
    teamId: string;
    squads: Squad[];
    squadId: string;
  },
  NormalizedSquadSchema
>('GET_TEAM_SQUAD_BY_ID', async (parameters) => {
  const { teamId, squadId, appspace, squads } = parameters;
  const response = (await Api.adminGetTeamSquad(
    appspace,
    teamId,
    squadId,
  )) as SquadDetail;
  squads[squads.findIndex((s) => s._id === response._id)] = response;
  return normalizeSquads(teamId, squads);
});

export const postSquad = createAsync<
  { appspace: string; teamId: string; squad: Omit<SquadDetail, '_id'> },
  SquadDetail
>('POST_SQUAD', async (parameters) => {
  const response = await Api.adminCreateTeamSquads(
    parameters.appspace,
    parameters.teamId,
    {},
    parameters.squad as any,
  );
  return response as any;
});

export const deleteSquad = createAsync<
  { appspace: string; teamId: string; squadId: string },
  SquadDetail
>('DELETE_SQUAD', async (parameters) => {
  const response = await Api.adminDeleteTeamSquad(
    parameters.appspace,
    parameters.teamId,
    parameters.squadId,
  );
  return response as any;
});

export const putSquad = createAsync<
  {
    appspace: string;
    teamId: string;
    squadId: string;
    squad: Omit<SquadDetail, '_id'>;
  },
  SquadDetail
>('PUT_SQUAD', async (parameters: any) => {
  const data = { ...parameters.squad };
  const response = await Api.adminUpdateTeamSquad(
    parameters.appspace,
    parameters.teamId,
    parameters.squadId,
    {},
    data,
  );
  return response as any;
});
