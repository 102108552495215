import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../api';
import { Team } from './definitions';
import { TeamState } from './reducer';

const create = actionCreatorFactory('TEAMS');
const createAsync = asyncFactory<TeamState>(create);

interface NormalizedTeamsSchema {
  entities: {
    teams: {
      [key: string]: Team;
    };
  };
  total?: number;
  nextOffset?: number | null;
}

const normalizeTeams = (data: Team[]) => {
  return {
    entities: {
      teams: data.reduce((acc, item: Team) => {
        return { ...acc, [item._id]: item };
      }, {}),
    },
  };
};

export const getTeams = createAsync<
  {
    appspace: string;
    limit: number;
    offset?: number;
    seasonIds?: string[];
  },
  NormalizedTeamsSchema
>('GET_TEAMS', async (parameters) => {
  const { appspace, limit, offset, seasonIds } = parameters;
  const params: {
    limit: number;
    offset?: number;
    seasonIds?: string[];
  } = {
    limit,
    offset,
  };
  if (seasonIds) {
    params.seasonIds = seasonIds;
  }
  const response = await Api.adminGetTeams(appspace, params);
  return {
    ...normalizeTeams(response.teams),
    total: response.total,
    nextOffset: response.nextOffset,
  };
});

export const getTeamById = createAsync<
  { appspace: string; id: string },
  NormalizedTeamsSchema
>('GET_TEAM_BY_ID', async (parameters) => {
  const response = await Api.adminGetTeam(parameters.appspace, parameters.id);
  return normalizeTeams([response]);
});

export const postTeam = createAsync<{ appspace: string; team: Team }, Team>(
  'POST_TEAM',
  async (parameters) => {
    const response = await Api.adminCreateTeam(
      parameters.appspace,
      {},
      parameters.team,
    );
    return response;
  },
);

export const putTeam = createAsync<
  {
    appspace: string;
    teamId: string;
    team: {
      name?: string;
      displayName?: string;
      filter?: {
        dateFrom?: string;
        dateTo?: string;
      };
    };
  },
  Team
>('PUT_TEAM', async (parameters) => {
  const data = { ...parameters.team };
  const response = await Api.adminUpdateTeam(
    parameters.appspace,
    parameters.teamId,
    {},
    data,
  );
  return response;
});
