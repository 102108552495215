import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import { State } from '../../rootReducer';
import { required, __ } from '../../utilities';
import { AnyAction } from 'typescript-fsa';
import { activeAppspaceSelector } from '../App/selectors';
import { getCompetitionPartById } from './actions';
import { debounce } from '@sportnet/ui/utilities';

export const PARTICIPANT_FORM_NAME = 'PARTICIPANT_FORM';

const mapStateToProps = (state: State) => ({
  appSpace: activeAppspaceSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  appspace: string;
  competitionId: string;
  competitionPartId?: string;
}
type Props = InjectedFormProps<{ teamId: string }> &
  IMapStateToProps &
  OwnProps & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  };

const ParticipantForm: React.FC<Props> = ({
  appspace,
  competitionId,
  competitionPartId,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [suitableTeamsByQ, setSuitableTeamsByQ] = React.useState<{
    [key: string]: Array<{ _id: string; name: string }>;
  }>({});
  const [q, setQ] = React.useState('');

  const getSuitableTeams = React.useMemo(() => {
    return debounce(async (q: string) => {
      if (q.length > 3) {
        setIsFetching(true);
        try {
          const res = await api.adminGetCompetitionPartSuitableTeams(
            appspace,
            competitionId,
            competitionPartId || '',
            { limit: 1000, offset: 0, q },
          );
          setSuitableTeamsByQ((prev) => ({
            ...prev,
            [q]: (res.items || []).map((i) => ({ _id: i._id, name: i.name })),
          }));
        } catch (e) {
          console.error(e);
          alert(__('Nepodarilo sa načítať zoznam družstiev'));
        } finally {
          setIsFetching(false);
        }
      }
    }, 300);
  }, [appspace, competitionId, competitionPartId]);

  React.useEffect(() => {
    getSuitableTeams(q);
  }, [q, getSuitableTeams]);

  return (
    <Field
      name="teamId"
      component={FormField}
      type="theselectsimple"
      label={__('Výber družstva (min. 4 znaky)')}
      required
      validate={[required]}
      loading={isFetching}
      onChangeInput={setQ}
      placeholder={__('Výber družstva (min. 4 znaky)')}
      options={(suitableTeamsByQ[q] || []).map((team) => ({
        label: team.name,
        value: team._id,
      }))}
    />
  );
};

export default reduxForm<{ teamId: string }, any>({
  form: PARTICIPANT_FORM_NAME,
  destroyOnUnmount: true,
  onSubmit: async (values: any, dispatch: any, props: Props) => {
    if (props.competitionPartId) {
      try {
        await api.adminSignupCompetitionPartTeam(
          props.appspace,
          props.competitionId,
          props.competitionPartId,
          undefined,
          {
            teamId: values.teamId,
          },
        );
        props.dispatch(
          getCompetitionPartById.action({
            appspace: props.appspace,
            competitionId: props.competitionId,
            competitionPartId: props.competitionPartId,
          }),
        );
      } catch (e: any) {
        if (e.details.name === 'TEAM_ALREADY_SIGNED_UP') {
          alert(__('Družstvo je už do časti súťaže prihlásené.'));
        } else {
          console.error(e.details);
          alert(__('Vyskytla sa neočakávaná chyba.'));
        }
      }
    }
  },
})(connect(mapStateToProps)(ParticipantForm));
