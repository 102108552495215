import * as React from 'react';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { rem } from 'polished';
import TheSelect from '@sportnet/ui/TheSelect';
import api from '../../../api';
import sportnetApi from '../../../sportnetApi';
import { __ } from '../../../utilities';
import { debounce } from '@sportnet/ui/utilities';
import { formatUserName } from 'sportnet-utilities';

interface IOwnProps {
  appSpace: string;
  competitionId: string;
  teamId: string;
  partId: string;
  value?: any;
  onChange: (user?: { sportnetId: string; displayName: string } | null) => void;
  error?: string;
  disabled?: boolean;
}

const UserSelectionInput: React.FC<IOwnProps> = ({
  appSpace,
  competitionId,
  partId,
  teamId,
  value,
  onChange,
  error,
  disabled,
}) => {
  const [types, setTypes] = React.useState<
    Array<{ label: string; value: string }>
  >([]);
  const [isFetchingTypes, setIsFetchingTypes] = React.useState(false);

  const [selectedType, setSelectedType] = React.useState<IOption | null>(null);
  const [users, setUsers] = React.useState<{
    [key: string]: Array<{
      sportnetId: string;
      displayName: string;
      familyName?: string;
      birthyear?: string;
    }>;
  }>({});
  const [isFetchingUsers, setIsFetchingUsers] = React.useState(false);

  const [q, setQ] = React.useState('');

  React.useEffect(() => {
    if (
      typeof value === 'string' &&
      value.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)
    ) {
      setIsFetchingUsers(true);
      sportnetApi
        .getPublicUserProfile(value)
        .then((res) => {
          setUsers((prevState) => ({
            ...prevState,
            [value]: [
              { sportnetId: res._id || '', displayName: formatUserName(res) },
            ],
          }));
        })
        .catch((e) => {
          alert(__('Nepodarilo sa nájsť používateľa podľa Sportnet ID.'));
        })
        .finally(() => {
          setIsFetchingUsers(false);
        });
    }
  }, [value]);

  React.useEffect(() => {
    setIsFetchingTypes(true);
    sportnetApi
      .getCodelist('sport-org-competence-type')
      .then((res) => {
        setTypes(
          (res.codelist || []).map((i) => ({
            label: i.label || '',
            value: i.value,
          })),
        );
      })
      .catch((e) => {
        alert(__('Nepodarilo sa získať zoznam skupín'));
      })
      .finally(() => {
        setIsFetchingTypes(false);
      });
  }, [appSpace]);

  const fetchAthletes = React.useMemo(() => {
    return debounce(async (val: string) => {
      if (val.length > 3) {
        setUsers((prevState) => ({ ...prevState, [val]: [] }));
        setIsFetchingUsers(true);
        try {
          const res = await api.clubManagerGetOrganizationAthletes(
            appSpace,
            competitionId,
            partId,
            {
              q: val,
              teamId,
              ...(selectedType
                ? { competenceType: String(selectedType.value) }
                : {}),
            },
          );
          setUsers((prevState) => ({
            ...prevState,
            [val]: res.athletes || [],
          }));
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športovcov'));
        } finally {
          setIsFetchingUsers(false);
        }
      }
    }, 300);
  }, [appSpace, competitionId, partId, teamId, selectedType]);

  React.useEffect(() => {
    fetchAthletes(q);
  }, [q, fetchAthletes]);

  const setType = (i: IOption) => {
    setUsers({});
    setSelectedType(i);
    onChange(null);
  };

  let formattedValue = value;
  if (formattedValue && typeof formattedValue === 'string') {
    const item = (users[value || q] || []).find(
      (u) => u.sportnetId === formattedValue,
    );
    if (item) {
      formattedValue = {
        label: item.displayName,
        value: item.sportnetId,
      };
    }
  }

  return (
    <TheSelect
      filterOptions={(options, inputValue) => {
        return options;
      }}
      disabled={disabled}
      error={error}
      loading={isFetchingTypes || isFetchingUsers}
      options={
        (users[value || q] || []).length && (selectedType || q || value)
          ? (users[value || q] || []).map((u) => ({
              label: u.displayName,
              value: u.sportnetId,
              user: true,
            }))
          : types.map((i) => ({
              label: i.label,
              value: i.value,
              group: true,
            }))
      }
      tags={selectedType ? [selectedType] : []}
      onChangeTags={() => {
        onChange(null);
        setSelectedType(null);
      }}
      onBlur={(ref) => {
        if (ref && ref.current && selectedType && !value) {
          setTimeout(() => {
            ref!.current!.focus();
          });
        }
      }}
      renderLabel={(i) => {
        if (i.group) return i.label;
        const athlete = users[value || q].find((u) => u.sportnetId === i.value);
        if (athlete) {
          return (
            <div>
              <div>{athlete.displayName}</div>
              {athlete.familyName && (
                <div style={{ fontSize: rem(10) }}>
                  {__('rod.')} {athlete.familyName}
                </div>
              )}
              {athlete.birthyear && (
                <div style={{ fontSize: rem(10) }}>
                  {__('nar.')} {athlete.birthyear}
                </div>
              )}
              {athlete.sportnetId && (
                <div style={{ fontSize: rem(10) }}>
                  {__('Sportnet Id:')} {athlete.sportnetId}
                </div>
              )}
            </div>
          );
        }
        return i.label;
      }}
      placeholder={__(
        'Vyberte typ príslušnosti alebo začnite vyhľadávať podľa mena',
      )}
      value={formattedValue}
      onChange={(i: any) => {
        !i || i.user ? onChange(i) : setType(i);
      }}
      onChangeInput={setQ}
    />
  );
};

export default UserSelectionInput;
