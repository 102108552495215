import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required, __ } from '../../../../src/utilities';

type OwnProps = {
  parts: Array<{
    _id: string;
    competitionName: string;
    partName: string;
    seasonName: string;
  }>;
};

const SquadCopyForm: React.FC<OwnProps> = ({ parts }) => {
  return (
    <>
      <Field
        name="partId"
        component={FormField}
        label={__('Časť súťaže')}
        type="theselectsimple"
        options={parts?.map((p) => ({
          label: `${p.competitionName} - ${p.partName} (${p.seasonName})`,
          value: p._id,
        }))}
        required
        validate={[required]}
      />
    </>
  );
};

export default compose<any>(reduxForm({}))(SquadCopyForm);
