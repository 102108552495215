import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Label from '@sportnet/ui/Label/Label';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import {
  change,
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  isSubmitting,
  reduxForm,
  submit,
  SubmissionError,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api, { MatchManagerRole } from '../../api';
import BoxLoader from '../../components/BoxLoader';
import styled from 'styled-components';
import Box from '../../components/Box';
import { State } from '../../rootReducer';
import {
  getProp,
  isInteger,
  isScore,
  required,
  transformQueryParameters,
  __,
} from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';
import {
  Competition,
  CompetitionPart,
  Round,
} from '../Competitions/definitions';
import UserCreationForm from '../Competitions/Squads/userCreationForm';
import { NominationItem } from '../Nominations';
import BeachFootballNominationsList from '../Nominations/BeachFootball/list';
import CyclingNominationList from '../Nominations/Cycling';
import FormulaOneNominationList from '../Nominations/FormulaOne';
import FutbalNominationsList from '../Nominations/Futbal/list';
import FutsalNominationsList from '../Nominations/Futsal/list';
import MinifootballNominationsList from '../Nominations/Minifootball/list';
import SwimmingNominationList from '../Nominations/Swimming';
import WaterPoloNominationsList from '../Nominations/WaterPolo/list';
import BeachFootballProtocol from '../Protocols/BeachFootball';
import CyclingProtocol from '../Protocols/Cycling';
import FormulaOneProtocol from '../Protocols/FormulaOne';
import FutbalProtocol from '../Protocols/Futbal';
import FutsalProtocol from '../Protocols/Futsal';
import MinifootballProtocol from '../Protocols/Minifootball';
import SwimmingProtocol from '../Protocols/Swimming';
import WaterPoloProtocol from '../Protocols/WaterPolo';
import { SportGround } from '../SportObjects/definitions';
import { Team } from '../Teams/definitions';
import { Match, matchManagerRoleName } from './definitions';
import MatchManagerForm from './matchManagerForm';
import NewRoundForm, { NEW_ROUND_FORM_NAME } from './Round';
import sportnetApi from '../../sportnetApi';
import { endOfDay, format, startOfDay } from 'date-fns';

export const FORM_NAME = 'MATCH_FORM';
const USER_CREATION_FORM_NAME = 'USER_CREATION_FORM';
const MATCH_MANAGER_FORM_NAME = 'MATCH_MANAGER_FORM';

export const BoxNote = styled.strong`
  font-size: ${rem(13)};
  color: #9e9e9e;
`;

const Role = styled.span`
  display: inline-block;
  margin: 3px;
  padding: 5px;
  border-radius: 5px;
  background: #f1f6ff;
  color: #5283e7;
  font-size: 0.75rem;
`;

interface CustomFormData {
  name: string;
}

export interface MatchNomination {
  teamId: string;
  nomination: NominationItem[];
  closed?: boolean;
}

interface OwnProps {
  id: string;
  data: Match | null;
  appspace: string;
  sportGrounds: SportGround[];
  competition: Competition | null;
  teams: Team[];
  profileSportValues: {
    sport: any;
    sport_sector: any;
  } | null;
  competitionPart: CompetitionPart;
  closed: boolean;
  isMatchNominated: boolean;
  nominations: MatchNomination[];
  reloadMatch: () => void;
}

type RouteProps = RouteComponentProps<{
  appspace: string;
  competitionId: string;
  competitionPartId: string;
  id: string;
}>;

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  const data = {
    rules: selector(state, 'rules') || {},
    roundId: selector(state, 'roundId'),
    round: selector(state, 'round'),
    attendants: selector(state, 'teams') || [],
    nominations: selector(state, 'nominations') || [],
    isSubmittingNewRound: isSubmitting(NEW_ROUND_FORM_NAME)(state),
    activeAppSpace: activeAppspaceSelector(state),
  };
  const rules = getProp(props, ['data', 'rules']);

  return {
    ...data,
    initialValues: {
      ...props.data,
      rules:
        rules && Object.keys(rules).length > 0
          ? {
              ...rules,
            }
          : {},
    },
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const MatchForm: React.FC<
  InjectedFormProps<CustomFormData> &
    IMapStateToProps &
    OwnProps & {
      dispatch: ThunkDispatch<any, any, AnyAction>;
    } & RouteProps
> = ({
  data,
  match: {
    params: { id, competitionId, competitionPartId, ...restParams },
  },
  closed,
  nominations,
  rules: { sport_sector },
  attendants,
  teams,
  competitionPart,
  competition,
  sportGrounds,
  reloadMatch,
  dispatch,
  round,
  roundId,
  activeAppSpace,
  isSubmittingNewRound,
  handleSubmit,
  location: { search },
  ...restProps
}) => {
  const [selectRound, setSelectRound] = React.useState(false);
  const [createRound, setCreateRound] = React.useState(false);

  const [matchManagerModalOpened, setMatchManagerModalOpened] =
    React.useState(false);
  const [isRemovingMatchManager, setIsRemovingMatchManager] =
    React.useState('');
  const [isSubmittingMatchManager, setIsSubmittingMatchManager] =
    React.useState(false);

  const [userCreationModalVisible, setUserCreationModalVisible] =
    React.useState(false);
  const [userCreationSubmitting, setUserCreationSubmitting] =
    React.useState(false);

  const [createdRounds, setCreatedRounds] = React.useState<
    Array<{ _id: string }>
  >([]);

  const [isFetchingCodelists, setIsFetchingCodelists] = React.useState(false);
  const [codelists, setCodelists] = React.useState<{ [key: string]: any[] }>({
    televisionBroadcasters: [],
    internetBroadcasters: [],
    delegates: [],
  });

  React.useEffect(() => {
    const queryParameters = transformQueryParameters(search);

    if (queryParameters.roundId) {
      dispatch(change(FORM_NAME, 'roundId', queryParameters.roundId));
    }
    if (queryParameters.drawId) {
      dispatch(change(FORM_NAME, 'drawId', queryParameters.drawId));
    }
  }, [search, dispatch]);

  const fetchDelegatesCodelist = React.useCallback(async () => {
    try {
      const res = await sportnetApi.getCodelist('sport-expert-competence-type');
      setCodelists((prevState) => ({
        ...(prevState || {}),
        delegates: res.codelist || [],
      }));
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchBroadcasters = React.useCallback(async () => {
    if (data && data.rules) {
      setIsFetchingCodelists(true);
      try {
        const televisionBroadcasters = await api.getSettingBySportSector(
          'sport_sector_television_broadcasters',
          data.rules.sport_sector || '',
        );
        const internetBroadcasters = await api.getSettingBySportSector(
          'sport_sector_internet_broadcasters',
          data.rules.sport_sector || '',
        );
        setCodelists((prevState) => ({
          ...(prevState || {}),
          televisionBroadcasters: televisionBroadcasters.items,
          internetBroadcasters: internetBroadcasters.items,
        }));
      } catch (e: any) {
        //
      } finally {
        setIsFetchingCodelists(false);
      }
    }
  }, [data]);

  React.useEffect(() => {
    fetchBroadcasters();
    fetchDelegatesCodelist();
  }, [fetchBroadcasters, fetchDelegatesCodelist]);

  const submitUserCreationForm = async (data: {
    name: string;
    surname: string;
    citizenship?: string;
    competenceType?: string;
    dateFrom?: string;
  }) => {
    try {
      setUserCreationSubmitting(true);
      await api.externalRegistrationDelegate(
        restParams.appspace,
        id,
        undefined,
        data,
      );
      toggleUserCreationModal();
      window.location.reload();
    } catch (e: any) {
      alert(__('Nepodarilo sa vytvoriť nového používateľa'));
    } finally {
      setUserCreationSubmitting(false);
    }
  };

  const renderProtocol = () => {
    if (!sport_sector) {
      return <BoxLoader />;
    }

    const props = {
      data,
      teams: data ? data.teams || [] : [],
      nominations,
      closed,
    };
    switch (sport_sector) {
      case 'water-polo':
        return <WaterPoloProtocol {...props} />;
      case 'futbal':
        return <FutbalProtocol {...props} />;
      case 'futsal':
        return <FutsalProtocol {...props} />;
      case 'beachfutbal':
        return <BeachFootballProtocol {...props} />;
      case 'minifootball':
        return <MinifootballProtocol {...props} />;
      case 'swimming':
        return <SwimmingProtocol {...props} />;
      case 'cestna-cyklistika':
        return <CyclingProtocol {...props} />;
      case 'formula-1':
        return <FormulaOneProtocol {...props} />;
      default:
        return (
          <BoxNote>
            {__(
              'Pre dané športové odvetvie momentálne neexistuje zápis o stretnutí.',
            )}
          </BoxNote>
        );
    }
  };

  const renderNominations = () => {
    if (!competitionPart) {
      return <BoxLoader />;
    }
    const { sport_sector } = (competitionPart.rules || {}) as {
      sport_sector: string;
    };
    const props = {
      appSpace: restProps.appspace,
      competitionId,
      partId: competitionPartId,
      matchId: id,
      nominations,
      teams,
      attendants,
      closed,
      submitMatch: () => {
        dispatch(submit(FORM_NAME));
      },
    };

    switch (sport_sector) {
      case 'water-polo':
        return <WaterPoloNominationsList {...props} />;
      case 'futbal':
        return <FutbalNominationsList {...props} />;
      case 'futsal':
        return <FutsalNominationsList {...props} />;
      case 'beachfutbal':
        return <BeachFootballNominationsList {...props} />;
      case 'minifootball':
        return <MinifootballNominationsList {...props} />;
      case 'swimming':
        return <SwimmingNominationList {...props} />;
      case 'cestna-cyklistika':
        return <CyclingNominationList {...props} />;
      case 'formula-1':
        return <FormulaOneNominationList {...props} />;
      default:
        return (
          <BoxNote>
            {__(
              'Pre dané športové odvetvie momentálne neexistuje možnosť nominácie.',
            )}
          </BoxNote>
        );
    }
  };

  const submitRound = async (values: any) => {
    const newRound = await api.adminCreateCompetitionPartRound(
      restParams.appspace,
      competitionId,
      competitionPartId,
      undefined,
      {
        name: values.name,
        dateFrom: format(
          startOfDay(new Date(values.dateFrom)),
          'yyyy-MM-dd HH:mm:ss',
        ),
        dateTo: format(
          endOfDay(new Date(values.dateTo)),
          'yyyy-MM-dd HH:mm:ss',
        ),
      },
    );
    await dispatch(change(FORM_NAME, 'roundId', newRound._id));
    setCreateRound(false);
    setCreatedRounds([...createdRounds, newRound]);
  };

  const renderSportGroundCustomLabel = (i: {
    label: string;
    value: string;
  }) => {
    const sportGround = sportGrounds.find((s) => s._id === i.value);
    return (
      <div style={{ padding: `${rem(5)} 0` }}>
        <div>{i.label}</div>
        <div>
          {sportGround &&
            `${sportGround.street} ${sportGround.number} ${sportGround.city}`}
        </div>
      </div>
    );
  };

  const toggleMatchManagerModal = () => {
    setMatchManagerModalOpened((prevState) => !prevState);
  };

  const submitMatchManager = async (values: {
    user: { value: string; label: string; citizenship?: string };
    type: { value: string; label: string };
    roles?: Array<{ value: MatchManagerRole }>;
  }) => {
    if (!values.user) {
      throw new SubmissionError({ user: __('Pole je povinné') });
    }
    setIsSubmittingMatchManager(true);
    try {
      await api.adminAddMatchManager(restParams.appspace, id, undefined, {
        user: {
          _id: values.user.value,
          name: values.user.label,
          citizenship: values.user.citizenship,
        },
        type: values.type,
        ...(values.roles && values.roles.length
          ? { roles: values.roles.map(({ value }) => value) }
          : {}),
      });
      reloadMatch();
    } catch (e: any) {
      console.error(e);
      if (e.details && e.details.name === 'MATCH_NOT_FOUND') {
        alert(__('Manažér už bol priradený'));
      } else {
        alert(__('Manažéra sa nepodarilo priradiť'));
      }
    } finally {
      setIsSubmittingMatchManager(false);
    }
  };

  const removeMatchManager = async (userId: string) => {
    setIsRemovingMatchManager(userId);
    try {
      await api.adminRemoveMatchManager(restParams.appspace, id, userId, {});
      reloadMatch();
    } catch (e: any) {
      alert(__('Manažéra sa nepodarilo odstrániť.'));
    } finally {
      setIsRemovingMatchManager('');
    }
  };

  const toggleUserCreationModal = () =>
    setUserCreationModalVisible((prevState) => !prevState);

  let selectedRound = round;
  if (roundId) {
    selectedRound = [...(competitionPart.rounds || []), ...createdRounds].find(
      (i) => i._id === roundId,
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Box title={__('Základné informácie')}>
        <Row>
          <Col xs={12} s={3}>
            <Field
              name="startDate"
              validate={[required]}
              component={FormField}
              format={null}
              required
              label={__('Termín konania')}
              type="dateTime"
              disabled={closed}
            />
          </Col>
          <Col xs={12} s={3}>
            <Field
              name="sportGround"
              disabled={closed}
              component={FormField}
              type="theselect"
              label={__('Miesto konania')}
              options={sportGrounds.map((i) => ({
                value: i._id,
                label: `${i.sportObjectName} (${i.name})`,
              }))}
              renderLabel={renderSportGroundCustomLabel}
              format={(i: string | { _id: string; name: string }) => {
                if (typeof i === 'string') {
                  const option = sportGrounds.find(
                    (s) => s && String(s._id) === i,
                  );
                  if (option) {
                    return {
                      value: option._id || '',
                      label: `${option.sportObjectName} (${option.name})`,
                    };
                  }
                } else if (i) {
                  return i;
                }
                return '';
              }}
            />
          </Col>
          {competitionPart && competitionPart.format === 'draw' && (
            <Col xs={12} s={3}>
              <Field
                name="drawId"
                validate={[isInteger]}
                component={FormField}
                required
                label={__('Číslo do pavúka')}
                disabled={closed}
              />
            </Col>
          )}
          <Col xs={12} s={3}>
            <FormGroup>
              <Label>{__('Kolo')}</Label>
              <Button
                type="button"
                disabled={closed}
                primary
                onClick={() => {
                  setSelectRound(true);
                }}
              >
                {selectedRound ? selectedRound.name : __('Zaradiť do kola')}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        {restProps.appspace && (
          <Row>
            <Col xs={12}>
              <Field
                name="perex"
                component={FormField}
                label={__('Perex')}
                disabled={closed}
              />
            </Col>
            <Col xs={12} m={4}>
              <Field
                name="livestream"
                component={FormField}
                label={__('Embed')}
                disabled={closed}
              />
            </Col>
            <Col xs={12} m={4}>
              <Field
                name="ticketsUrl"
                component={FormField}
                label={__('URL pre predaj lístkov')}
                disabled={closed}
              />
            </Col>
            <Col xs={12} m={4}>
              <Field
                name="detailUrl"
                component={FormField}
                label={__('URL pre detail zápasu')}
                info={__('Napríklad link na článok o zápase')}
                disabled={closed}
              />
            </Col>
            {id !== 'new' && (
              <>
                <Col xs={12} m={6}>
                  <Field
                    name="television_broadcasters"
                    component={FormField}
                    label={__('TV prenos')}
                    disabled={closed}
                    type="theselect"
                    multiple
                    options={getProp(
                      codelists,
                      ['televisionBroadcasters'],
                      [],
                    ).map((i: any) => ({
                      label: i.label,
                      value: i._id,
                    }))}
                    loading={isFetchingCodelists}
                    format={(i: any) => {
                      if (Array.isArray(i) && i.length > 0) {
                        return i.map((item) => ({
                          label: item.label,
                          value: item._id,
                        }));
                      }
                      return [];
                    }}
                    parse={(i: any) => {
                      if (Array.isArray(i) && i.length > 0) {
                        const ids = i.map((item) => item.value);
                        const items = getProp(
                          codelists,
                          ['televisionBroadcasters'],
                          [],
                        ).reduce((acc: any, p: any) => {
                          if (ids.includes(p._id)) {
                            return [...acc, p];
                          }
                          return acc;
                        }, []);
                        return items;
                      }
                      return [];
                    }}
                  />
                </Col>
                <Col xs={12} m={6}>
                  <Field
                    name="internet_broadcasters"
                    component={FormField}
                    label={__('Internetový prenos')}
                    disabled={closed}
                    type="theselect"
                    multiple
                    options={getProp(
                      codelists,
                      ['internetBroadcasters'],
                      [],
                    ).map((i: any) => ({
                      label: i.label,
                      value: i._id,
                    }))}
                    loading={isFetchingCodelists}
                    format={(i: any) => {
                      if (Array.isArray(i) && i.length > 0) {
                        return i.map((item) => ({
                          label: item.label,
                          value: item._id,
                        }));
                      }
                      return [];
                    }}
                    parse={(i: any) => {
                      if (Array.isArray(i) && i.length > 0) {
                        const ids = i.map((item) => item.value);
                        const items = getProp(
                          codelists,
                          ['internetBroadcasters'],
                          [],
                        ).reduce((acc: any, p: any) => {
                          if (ids.includes(p._id)) {
                            return [...acc, p];
                          }
                          return acc;
                        }, []);
                        return items;
                      }
                      return [];
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
        <Modal
          isOpen={selectRound}
          handleClose={() => {
            setSelectRound(false);
          }}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Zaradenie stretnutia do kola')}
            </Header>
            <Row>
              <Col xs={12}>
                <Field
                  name="roundId"
                  component={FormField}
                  label={__('Kolo')}
                  type="select"
                  disabled={closed}
                >
                  <option hidden />
                  {[
                    ...getProp(competitionPart, ['rounds'], []),
                    ...createdRounds,
                  ].map((e: Round) => (
                    <option key={e._id} value={e._id}>
                      {e.name}
                    </option>
                  ))}
                </Field>
              </Col>
            </Row>
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button
                basic
                primary
                type="button"
                onClick={() => {
                  setCreateRound(true);
                }}
              >
                {__('Vytvoriť nové kolo')}
              </Button>
              &nbsp;
              <Button
                secondary
                type="button"
                onClick={() => {
                  setSelectRound(false);
                }}
              >
                {__('Zavrieť')}
              </Button>
              &nbsp;
              <Button
                primary
                type="button"
                onClick={() => {
                  setSelectRound(false);
                }}
              >
                {__('Vybrať kolo')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal
          isOpen={createRound}
          handleClose={() => {
            setCreateRound(false);
          }}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Nové kolo')}
            </Header>
            <NewRoundForm onSubmit={submitRound} />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button
                secondary
                type="button"
                onClick={() => {
                  setCreateRound(false);
                }}
              >
                {__('Zavrieť')}
              </Button>
              &nbsp;
              <Button
                primary
                loading={isSubmittingNewRound}
                type="button"
                onClick={() => {
                  dispatch(submit(NEW_ROUND_FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
      </Box>
      {id !== 'new' && (
        <React.Fragment>
          <Box title={__('Delegované osoby')}>
            {!closed && (
              <>
                <Button type="button" primary onClick={toggleMatchManagerModal}>
                  {__('Pridať osobu')}
                </Button>
                &nbsp;
                {activeAppSpace && activeAppSpace.app_space === 'uefa' && (
                  <Button
                    type="button"
                    primary
                    onClick={toggleUserCreationModal}
                  >
                    {__('Vytvoriť osobu')}
                  </Button>
                )}
              </>
            )}
            <Modal
              isOpen={userCreationModalVisible}
              handleClose={toggleUserCreationModal}
            >
              <ModalContent>
                <UserCreationForm
                  onSubmit={submitUserCreationForm}
                  form={USER_CREATION_FORM_NAME}
                  competenceTypes={codelists.delegates}
                />
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button onClick={toggleUserCreationModal}>
                    {__('Zavrieť')}
                  </Button>
                  &nbsp;
                  <Button
                    primary
                    onClick={() => dispatch(submit(USER_CREATION_FORM_NAME))}
                    loading={userCreationSubmitting}
                  >
                    {__('Uložiť')}
                  </Button>
                </div>
              </ModalActions>
            </Modal>
            <Modal
              isOpen={matchManagerModalOpened}
              handleClose={toggleMatchManagerModal}
            >
              <ModalContent>
                {matchManagerModalOpened && !!sport_sector && (
                  <MatchManagerForm
                    form={MATCH_MANAGER_FORM_NAME}
                    sportSector={sport_sector}
                    appSpace={restProps.appspace}
                    ownerPPO={competition?.ownerPPO}
                    onSubmit={submitMatchManager}
                  />
                )}
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button onClick={toggleMatchManagerModal}>
                    {__('Zavrieť')}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(submit(MATCH_MANAGER_FORM_NAME));
                    }}
                    primary
                    loading={isSubmittingMatchManager}
                  >
                    {__('Uložiť')}
                  </Button>
                </div>
              </ModalActions>
            </Modal>
            <div style={{ height: rem(15) }} />
            <BasicTable
              rows={(data ? data.managers || [] : []).map((i) => ({
                ...i,
                rowKey: `${i.user._id}-${i.type.value}`,
              }))}
              columns={[
                { header: __('Meno a priezisko') },
                { header: __('Typ') },
                { header: __('Rola správcu') },
                { header: '' },
              ]}
              rowKey="rowKey"
              renderRow={(i) => [
                i.user.name,
                i.type.label,
                (i.roles || []).map((role: MatchManagerRole) => (
                  <Role key={role}>{matchManagerRoleName(role)}</Role>
                )),
                !closed && (
                  <Button
                    key={i.user._id}
                    danger
                    loading={isRemovingMatchManager}
                    onClick={() => {
                      removeMatchManager(i.user._id);
                    }}
                  >
                    {__('Odstrániť')}
                  </Button>
                ),
              ]}
            />
          </Box>
          {!!sport_sector &&
            (sport_sector === 'futbal' || sport_sector === 'futsal') && (
              <>
                <Box title={__('Základné informácie')}>
                  <Row>
                    <Col xs={12} s={4}>
                      <Field
                        label={__('Číslo stretnutia')}
                        name="protocol.matchNr"
                        component={FormField}
                        type="input"
                        disabled={closed}
                      />
                    </Col>
                    <Col xs={12} s={4}>
                      <Field
                        label={__('Počet divákov')}
                        name="protocol.audience"
                        validate={[isInteger]}
                        component={FormField}
                        type="input"
                        disabled={closed}
                      />
                    </Col>
                  </Row>
                </Box>
                <Box title={__('Skóre')}>
                  <Row>
                    <Col xs={12}>
                      <Field
                        label={__(
                          'Pevne zadané skóre, ignorovať priebeh zápasu.',
                        )}
                        name="scoreComputationDisabled"
                        type="checkbox"
                        component={FormField}
                        disabled={closed}
                      />
                    </Col>
                    <Col xs={12} s={3}>
                      <Field
                        label={__('Skóre')}
                        name="score"
                        component={FormField}
                        type="input"
                        disabled={closed}
                        format={(i: any) => {
                          if (i && Array.isArray(i) && i.length === 2) {
                            return `${i[0]}:${i[1]}`;
                          }
                          return i;
                        }}
                        parse={(i: any) => {
                          if (i && typeof i === 'string') {
                            const split = i.split(':');
                            if (
                              split.length === 2 &&
                              split[0] !== '' &&
                              split[1] !== ''
                            ) {
                              return [Number(split[0]), Number(split[1])];
                            }
                          }
                          return i;
                        }}
                        validate={[isScore]}
                      />
                    </Col>
                    <Col xs={12} s={3}>
                      <Field
                        label={__('Skóre v 1. polčase')}
                        name="scoreByPhases.0"
                        component={FormField}
                        type="input"
                        disabled={closed}
                        format={(i: any) => {
                          if (i && Array.isArray(i) && i.length === 2) {
                            return `${i[0]}:${i[1]}`;
                          }
                          return i;
                        }}
                        parse={(i: any) => {
                          if (i && typeof i === 'string') {
                            const split = i.split(':');
                            if (
                              split.length === 2 &&
                              split[0] !== '' &&
                              split[1] !== ''
                            ) {
                              return [Number(split[0]), Number(split[1])];
                            }
                          }
                          return i;
                        }}
                        validate={[isScore]}
                      />
                    </Col>
                    <Col xs={12} s={3}>
                      <Field
                        label={__('Skóre v 2. polčase')}
                        name="scoreByPhases.1"
                        component={FormField}
                        type="input"
                        disabled={closed}
                        format={(i: any) => {
                          if (i && Array.isArray(i) && i.length === 2) {
                            return `${i[0]}:${i[1]}`;
                          }
                          return i;
                        }}
                        parse={(i: any) => {
                          if (i && typeof i === 'string') {
                            const split = i.split(':');
                            if (
                              split.length === 2 &&
                              split[0] !== '' &&
                              split[1] !== ''
                            ) {
                              return [Number(split[0]), Number(split[1])];
                            }
                          }
                          return i;
                        }}
                        validate={[isScore]}
                      />
                    </Col>
                    <Col xs={12} s={3}>
                      <Field
                        label={__('Skóre v 1. predĺžení')}
                        name="scoreByPhases.2"
                        component={FormField}
                        type="input"
                        disabled={closed}
                        format={(i: any) => {
                          if (i && Array.isArray(i) && i.length === 2) {
                            return `${i[0]}:${i[1]}`;
                          }
                          return i;
                        }}
                        parse={(i: any) => {
                          if (i && typeof i === 'string') {
                            const split = i.split(':');
                            if (
                              split.length === 2 &&
                              split[0] !== '' &&
                              split[1] !== ''
                            ) {
                              return [Number(split[0]), Number(split[1])];
                            }
                          }
                          return i;
                        }}
                        validate={[isScore]}
                      />
                    </Col>
                    <Col xs={12} s={3}>
                      <Field
                        label={__('Skóre v 2. predĺžení')}
                        name="scoreByPhases.3"
                        component={FormField}
                        type="input"
                        disabled={closed}
                        format={(i: any) => {
                          if (i && Array.isArray(i) && i.length === 2) {
                            return `${i[0]}:${i[1]}`;
                          }
                          return i;
                        }}
                        parse={(i: any) => {
                          if (i && typeof i === 'string') {
                            const split = i.split(':');
                            if (
                              split.length === 2 &&
                              split[0] !== '' &&
                              split[1] !== ''
                            ) {
                              return [Number(split[0]), Number(split[1])];
                            }
                          }
                          return i;
                        }}
                        validate={[isScore]}
                      />
                    </Col>
                    <Col xs={12} s={3}>
                      <Field
                        label={__('Výsledok penáltového rozstrelu')}
                        name="penaltiesScore"
                        component={FormField}
                        type="input"
                        disabled={closed}
                        format={(i: any) => {
                          if (i && Array.isArray(i) && i.length === 2) {
                            return `${i[0]}:${i[1]}`;
                          }
                          return i;
                        }}
                        parse={(i: any) => {
                          if (i && typeof i === 'string') {
                            const split = i.split(':');
                            if (
                              split.length === 2 &&
                              split[0] !== '' &&
                              split[1] !== ''
                            ) {
                              return [Number(split[0]), Number(split[1])];
                            }
                          }
                          return i;
                        }}
                        validate={[isScore]}
                      />
                    </Col>
                  </Row>
                </Box>
              </>
            )}
          <Box title={__('Účastníci')}>{renderNominations()}</Box>
          <Box title={__('Zápis')}>{renderProtocol()}</Box>
        </React.Fragment>
      )}
    </Form>
  );
};

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
  enableReinitialize: true,
})(MatchForm);

export default connect(mapStateToProps)(withRouter(connected));
