import Button from '@sportnet/ui/Button';
import CmsConnector from '@sportnet/cms-connector';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { activeAppspaceSelector } from '../../containers/App/selectors';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';

const mapStateToProps = (state: State) => ({
  appspace: activeAppspaceSelector(state),
});

const CMSCompetitionContent: React.FC<
  ReturnType<typeof mapStateToProps> & { id: string }
> = ({ appspace, id }) => {
  const [cmsConnectorOpened, setCmsConnectorOpened] = React.useState(false);

  const accessToken =
    Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';

  const cmsConnector = (
    <CmsConnector
      opened={cmsConnectorOpened}
      appSpace={appspace ? appspace.app_space : ''}
      appId={'sutaze'}
      contentDivider={`competitionId|${id}`}
      accessToken={accessToken}
      onClose={() => {
        setCmsConnectorOpened(false);
      }}
    />
  );

  return (
    <>
      <Button
        primary
        onClick={() => {
          setCmsConnectorOpened(true);
        }}
      >
        {__('Spravovať obsah')}
      </Button>
      {cmsConnectorOpened && cmsConnector}
    </>
  );
};

export default compose(connect(mapStateToProps))(CMSCompetitionContent);
