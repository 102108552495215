import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../api';
import { SportObject } from './definitions';
import { SportObjectsState } from './reducer';

const create = actionCreatorFactory('AGE_CATEGORIES');
const createAsync = asyncFactory<SportObjectsState>(create);

interface NormalizedSchema {
  entities: {
    sportObjects: {
      [key: string]: SportObject;
    };
  };
}

const normalize = (data: SportObject[]) => {
  return {
    entities: {
      sportObjects: data.reduce((acc, item: SportObject) => {
        return { ...acc, [item._id || '']: item };
      }, {}),
    },
  };
};

export const getSportObjects = createAsync<
  { appspace: string },
  NormalizedSchema
>('GET_SPORT_OBJECTS', async (parameters) => {
  const response = await Api.adminGetSportObjects(parameters.appspace);
  return normalize(response.sportobjects);
});

export const getSportObjectById = createAsync<
  { appspace: string; id: string },
  NormalizedSchema
>('GET_SPORT_OBJECT_BY_ID', async (parameters) => {
  const response = await Api.adminGetSportObject(
    parameters.appspace,
    parameters.id,
  );
  return normalize([response]);
});

export const postSportObject = createAsync<
  { appspace: string; sportObject: any },
  SportObject
>('POST_SPORT_OBJECT', async (parameters) => {
  const response = await Api.adminCreateSportObject(
    parameters.appspace,
    {},
    parameters.sportObject,
  );
  return response;
});

export const putSportObject = createAsync<
  { appspace: string; sportObjectId: string; sportObject: any },
  SportObject
>('PUT_SPORT_OBJECT', async (parameters) => {
  const response = await Api.adminUpdateSportObject(
    parameters.appspace,
    parameters.sportObjectId,
    {},
    parameters.sportObject,
  );
  return response;
});
