import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../../api';
import { Season, SeasonUpdate } from './definitions';
import { SeasonsState } from './reducer';

const create = actionCreatorFactory('SEASONS');
const createAsync = asyncFactory<SeasonsState>(create);

interface NormalizedSchema {
  entities: {
    seasons: {
      [key: string]: Season;
    };
  };
}

const normalize = (data: Season[]) => {
  return {
    entities: {
      seasons: data.reduce((acc, item: Season) => {
        return { ...acc, [item._id || '']: item };
      }, {}),
    },
  };
};

export const getSeasons = createAsync<{ appspace: string }, NormalizedSchema>(
  'GET_SEASONS',
  async (parameters) => {
    const response = await Api.adminGetSeasons(parameters.appspace);
    return normalize(response.seasons);
  },
);

export const getSeasonById = createAsync<
  { appspace: string; id: string },
  NormalizedSchema
>('GET_SEASON_BY_ID', async (parameters) => {
  const response = await Api.adminGetSeason(parameters.appspace, parameters.id);
  return normalize([response]);
});

export const postSeason = createAsync<
  { appspace: string; season: Season },
  Season
>('POST_SEASON', async (parameters) => {
  const response = await Api.adminCreateSeason(
    parameters.appspace,
    {},
    parameters.season,
  );
  return response;
});

export const putSeason = createAsync<
  { appspace: string; seasonId: string; season: SeasonUpdate },
  Season
>('PUT_SEASON', async (parameters) => {
  const response = await Api.adminUpdateSeason(
    parameters.appspace,
    parameters.seasonId,
    {},
    parameters.season,
  );
  return response;
});

export const getSuitableSeasons = createAsync<
  { appspace: string },
  NormalizedSchema
>('GET_SUITABLE_SEASONS', async (parameters) => {
  const response = await Api.adminGetSuitableSeasons(parameters.appspace);
  return normalize(response.seasons);
});
