import Button from '@sportnet/ui/Button';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { submit } from 'redux-form';
import { commit, setParams } from '@sportnet/redux-list';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import { getCompetitionById, getCompetitionPartById } from '../Competitions/actions';
import { competitionByIdSelector, competitionPartByIdSelector } from '../Competitions/selectors';
import { getMatches } from '../Matches/actions';
import NewRoundForm, { NEW_ROUND_FORM_NAME } from '../Matches/Round';
import { currentListMatchesSelector } from '../Matches/selectors';
import { endOfDay, format, startOfDay } from 'date-fns';
import WidgetCompetitionDraw from './competitionDraw';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { Link } from 'react-router-dom';

const LIST_NAME = 'MATCHES_LIST';

const mapDispatchToProps = {
  getCompetition: getCompetitionById.action,
  getCompetitionPart: getCompetitionPartById.action,
  fetchMatches: getMatches.action,
  commitAction: commit.action,
  setListParams: setParams,
  submitRoundCreation: () => submit(NEW_ROUND_FORM_NAME),
};

type RouteProps = RouteComponentProps<{
  appspace: string;
  id: string;
  competitionPartId: string;
}>;

const mapStateToProps = (state: State, props: RouteProps) => ({
  competition: competitionByIdSelector(props.match.params.id)(state),
  competitionPart: competitionPartByIdSelector(
    props.match.params.id,
    props.match.params.competitionPartId,
  )(state),
  matches: currentListMatchesSelector(LIST_NAME)(state),
});

type IProps = RouteProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const Draw: React.FC<IProps> = ({
  getCompetitionPart,
  getCompetition,
  fetchMatches,
  commitAction,
  setListParams,
  match: {
    params: { appspace, id: competitionId, competitionPartId },
  },
  competitionPart,
  competition,
  matches,
  submitRoundCreation,
}) => {
  const [roundCreationModalOpened, setRoundCreationModalOpened] =
    React.useState(false);
  const [isSubmittingRoundCreation, setIsSubmittingRoundCreation] =
    React.useState(false);

  React.useEffect(() => {
    getCompetition({ appspace, id: competitionId });
    getCompetitionPart({
      appspace,
      competitionId,
      competitionPartId,
    });
    setListParams({
      listName: LIST_NAME,
      parameters: { appspace, competitionId, competitionPartId },
    });
    commitAction({
      listName: LIST_NAME,
      load: async () => {
        const res: any = await fetchMatches({
          appspace,
          limit: 1000,
          offset: 0,
          competitionIds: [competitionId],
          partIds: [competitionPartId],
        });
        return {
          results: Object.keys(res.entities.matches).map((i) => i),
          total: res.total || 0,
          nextOffset: res.nextOffset || null,
        };
      },
    });
  }, [
    appspace,
    competitionId,
    competitionPartId,
    getCompetitionPart,
    getCompetition,
    commitAction,
    fetchMatches,
    setListParams,
  ]);

  const toggleRoundCreationModal = () =>
    setRoundCreationModalOpened(!roundCreationModalOpened);

  const onSubmitRoundCreation = async (values: any) => {
    setIsSubmittingRoundCreation(true);
    try {
      await api.adminCreateCompetitionPartRound(
        appspace,
        competitionId,
        competitionPartId,
        undefined,
        {
          name: values.name,
          dateFrom: format(
            startOfDay(new Date(values.dateFrom)),
            'yyyy-MM-dd HH:mm:ss',
          ),
          dateTo: format(
            endOfDay(new Date(values.dateTo)),
            'yyyy-MM-dd HH:mm:ss',
          ),
        },
      );
      getCompetitionPart({
        appspace,
        competitionId,
        competitionPartId,
      });
      setRoundCreationModalOpened(false);
    } catch (e: any) {
      alert(__('Nepodarilo sa vytvoriť nové kolo'));
    } finally {
      setIsSubmittingRoundCreation(false);
    }
  };

  if (!competitionPart) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <ScrollLayout topFixed={
      <HeaderBar>
        <HeaderBar.Action
          icon="back"
          as={Link}
          to={`/admin/${appspace}/competitions/${competitionId}`}
        />
        <HeaderBar.Header>
          {`${competition?.name} | ${competitionPart.name}`}
        </HeaderBar.Header>
      </HeaderBar>
    }>
      <Segment>
        <Segment raised>
          <div style={{ textAlign: 'right' }}>
            <Button primary onClick={toggleRoundCreationModal}>
              {__('Pridať kolo')}
            </Button>
          </div>
          <Modal
            isOpen={roundCreationModalOpened}
            handleClose={toggleRoundCreationModal}
          >
            <ModalContent>
              <NewRoundForm onSubmit={onSubmitRoundCreation} />
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={toggleRoundCreationModal}>
                  {__('Zavrieť')}
                </Button>
                <Button
                  onClick={submitRoundCreation}
                  primary
                  loading={isSubmittingRoundCreation}
                >
                  {__('Uložiť')}
                </Button>
              </div>
            </ModalActions>
          </Modal>
        </Segment>
        <Segment raised>
          <WidgetCompetitionDraw appSpace={appspace} competitionId={competitionId} partId={competitionPartId} data={{ data: { competitionPart, matches } }} />
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Draw as any,
);
