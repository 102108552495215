import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import { Team } from '../Teams/definitions';

export const CONTUMATION_FORM_NAME = 'CONTUMATION_FORM';

interface IFormData {
  isContumated: boolean;
  keepScore: boolean;
  advantage: string;
}

interface OwnProps {
  nominations: Array<{
    team: Team;
  }>;
  appSpace: string;
  matchId: string;
}

const selector = formValueSelector(CONTUMATION_FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => ({
  keepScore: selector(state, 'keepScore'),
  isContumated: selector(state, 'isContumated'),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = InjectedFormProps<IFormData, OwnProps> &
  OwnProps &
  IMapStateToProps & { dispatch: ThunkDispatch<{}, any, any> };

class ContumationForm extends React.PureComponent<Props> {
  render() {
    const { keepScore, isContumated } = this.props;
    return (
      <>
        <Field
          component={FormField}
          name="isContumated"
          type="checkbox"
          label={__('Kontumované')}
        />
        {isContumated && (
          <>
            <Field
              component={FormField}
              name="keepScore"
              type="checkbox"
              label={__('S ponechaním výsledku')}
            />
            {!keepScore && (
              <Field
                component={FormField}
                name="advantage"
                type="select"
                label={__('Zvýhodnené družstvo')}
              >
                <option value="">{__('Bez zvýhodnenia')}</option>
                {this.props.nominations.map((nomination) => (
                  <option value={nomination.team._id}>
                    {nomination.team.name}
                  </option>
                ))}
              </Field>
            )}
          </>
        )}
      </>
    );
  }
}

export default reduxForm<IFormData, OwnProps>({
  form: CONTUMATION_FORM_NAME,
  enableReinitialize: true,
  onSubmit: async (values: any, dispatch: any, props: any) => {
    await api.adminUpdateMatchContumation(
      props.appSpace,
      props.matchId,
      undefined,
      values,
    );
  },
})(connect(mapStateToProps)(ContumationForm));
