import FormField from '@sportnet/ui/FormField';
import Column, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { compose } from 'redux';
import { change, reduxForm } from 'redux-form';
import { __, required } from 'src/utilities';

const MembersForm: React.FC<{
  dispatch: any;
  form: string;
  onSubmit: any;
  title: string;
  setTitle: any;
  validFrom: Date;
  setValidFrom: any;
}> = ({ dispatch, form, title, setTitle, validFrom, setValidFrom }) => {
  return (
    <Row>
      <Column xs={12} s={6}>
        <FormField
          label={__('Názov osôb v tíme')}
          required
          value={title}
          validate={[required]}
          onChange={(e: any) => {
            dispatch(change(form, 'title', e.target.value || ''));
            setTitle(e.target.value || '');
          }}
        />
      </Column>
      <Column xs={12} s={6}>
        <FormField
          label={__('Platí od')}
          type="date"
          required
          value={validFrom}
          validate={[required]}
          onChange={(e: any) => {
            dispatch(change(form, 'validFrom', e || new Date()));
            setValidFrom(e || new Date());
          }}
        />
      </Column>
    </Row>
  );
};

export default compose<any>(reduxForm({}))(MembersForm);
