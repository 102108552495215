import { Theme } from '@sportnet/ui/Themes/styled-components';
import isAfter from 'date-fns/isAfter';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from '@sportnet/utilities';
import styled, { css, withTheme } from 'styled-components';
import { Team } from '../Teams/definitions';
import {
  IProtocol,
  ISportSectorEvent,
  ISportSectorPhase,
} from '../Protocols/timeline';
import { Match } from '../Matches/definitions';

export const Icon = (props: {
  theme: any;
  eventType: string;
  subType?: string;
  title: string;
  color?: string;
  size?: number;
  sport: string;
}) => {
  switch (props.eventType) {
    case 'phase':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 16}
          height={props.size || 16}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.inactiveColor}
            d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          />
        </svg>
      );
    case 'goal': {
      if (props.sport === 'ice-hockey') {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={props.size || 16}
            height={props.size || 16}
          >
            <title>{props.title}</title>
            <path
              fill="#333"
              d="M12,3.5C5.972168,3.5,1.25,5.8061523,1.25,8.75v6C1.25,17.6943359,5.972168,20,12,20
	c6.0283203,0,10.75-2.3056641,10.75-5.25v-6C22.75,5.8061523,18.0283203,3.5,12,3.5z M12,5c5.4511719,0,9.25,1.9760742,9.25,3.75
	S17.4511719,12.5,12,12.5s-9.25-1.9760742-9.25-3.75S6.5488281,5,12,5z M12,18.5c-5.4511719,0-9.25-1.9765625-9.25-3.75v-3.298584
	C4.6055908,12.9871216,8.0120239,14,12,14c3.9882812,0,7.3944702-1.0128784,9.25-2.5485229V14.75
	C21.25,16.5234375,17.4511719,18.5,12,18.5z"
            />
          </svg>
        );
      }

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width={props.size || 16}
          height={props.size || 16}
        >
          <title>{props.title}</title>
          <path
            fill={
              props.subType && props.subType === 'dropped'
                ? props.theme.color.danger
                : props.theme.app.textColor || '#333'
            }
            d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 2.5c.7 0 1.4.1 2.2.2L16 4.2l-2.2-1.5c.7-.2 1.5-.2 2.2-.2zm-5.2 1l4.4 3.2.8.6.7-.5 4.4-3.2c2 .8 3.7 2.1 5.1 3.7l-1.7 5.3-.3.8.7.5 4.5 3.3c-.2 2.2-.8 4.2-1.9 6h-6.4l-.3.8-1.7 5.3c-1 .2-2 .3-3.1.3s-2.2-.1-3.2-.4l-1.7-5.3-.3-.9H4.4c-1.1-1.8-1.7-3.8-1.9-6L7 13.8l.7-.5-.3-.8-1.7-5.3c1.4-1.6 3.1-2.9 5.1-3.7zM16 8.7l-.7.5-5.6 4.1-.7.6.3.8 2.2 6.6.3.8H20.5l.3-.8 2.2-6.6.3-.8-.7-.5L17 9.3l-1-.6zm12 1c.7 1.3 1.1 2.7 1.3 4.2l-2.2-1.6.9-2.6zM4 9.8l.8 2.5-2.2 1.6c.3-1.4.7-2.8 1.4-4.1zm12 2l4.2 3-1.6 4.9h-5.2l-1.6-4.9 4.2-3zm6.9 13.7h2.8c-1 1.1-2.3 2-3.6 2.7l.8-2.7zm-16.6 0H9l.8 2.6c-1.2-.7-2.4-1.6-3.5-2.6z"
          />
        </svg>
      );
    }
    case 'failed_goal':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 16}
          height={props.size || 16}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.color.warning}
            d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
          />
        </svg>
      );
    case 'yellow_card':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="card"
          width={props.size || 16}
          height={props.size || 16}
        >
          <title>{props.title}</title>
          <path
            fill="#ffc600"
            d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
          />
        </svg>
      );
    case 'red_card':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="card"
          width={props.size || 16}
          height={props.size || 16}
        >
          <title>{props.title}</title>
          <path
            fill="#FF0C00"
            d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
          />
        </svg>
      );
    case 'second_yellow_card':
      return (
        <svg
          version="1.1"
          viewBox="0 0 512 512"
          width={props.size || 16}
          height={props.size || 16}
        >
          <title>{props.title}</title>
          <g>
            <path
              fill="#FF0C00"
              d="M367.6,512c37.6,0,67.8-30.5,67.8-68.2V68.4c0-16.3-5.7-31.5-15.2-43.3L92.3,487.5   c12.5,15.1,31.1,24.5,52.2,24.5H367.6z"
            />
            <path
              fill="#FFC700"
              d="M76.7,68.4v375.4c0,16.6,5.9,31.8,15.6,43.7L420.1,25.2C407.7,9.9,388.9,0,367.6,0H144.4   C106.8,0,76.7,30.8,76.7,68.4z"
            />
          </g>
        </svg>
      );
    case 'substitution':
      return (
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width={props.size || 16}
          height={props.size || 16}
        >
          <title>{props.title}</title>
          <path
            fill="#FF0C00"
            d="M303.1,133.3L151,379.2L0,132.8h303.4L303.1,133.3z"
          />
          <path
            fill="#63C509"
            d="M208.9,378.7l152.1-246l151,246.5H208.6L208.9,378.7z"
          />
        </svg>
      );
    case 'exclusion':
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width={props.size || 16}
          height={props.size || 16}
        >
          <g>
            <g>
              <g>
                <path
                  d="M395.675,233.392L395.675,233.392c-6.651-4.334-14.775-5.789-22.517-4.033c-7.736,1.71-14.479,6.418-18.75,13.092
				l-21.483,33.652V113.233c-0.02-16.487-13.38-29.847-29.867-29.867c-4.436,0.021-8.811,1.039-12.8,2.979v-5.154
				c0-16.495-13.372-29.867-29.867-29.867c-16.495,0-29.867,13.372-29.867,29.867v5.2c-3.985-1.955-8.361-2.984-12.8-3.008
				c-16.521,0.055-29.88,13.471-29.867,29.992v5.179c-3.984-1.958-8.361-2.988-12.8-3.012c-7.918,0.016-15.511,3.15-21.133,8.725
				c-5.618,5.598-8.763,13.211-8.733,21.141v221.525c0.046,42.396,34.404,76.754,76.8,76.8h32.667
				c26.225,0.065,50.652-13.316,64.717-35.45l85.433-133.708C413.653,260.68,409.565,242.245,395.675,233.392z M390.417,265.4
				L390.417,265.4l-85.426,133.701c-10.943,17.21-29.939,27.614-50.333,27.566h-32.667c-32.973-0.04-59.693-26.76-59.733-59.733
				V145.408c-0.018-3.398,1.323-6.663,3.725-9.067c3.692-3.673,9.238-4.749,14.036-2.724c4.798,2.025,7.895,6.749,7.839,11.957
				v85.809c0,4.713,3.821,8.533,8.533,8.533c4.713,0,8.533-3.82,8.533-8.533V113.375c-0.044-4.602,2.385-8.875,6.364-11.189
				c3.979-2.314,8.893-2.314,12.872,0c3.979,2.314,6.409,6.586,6.364,11.189v118.008c0,4.713,3.82,8.533,8.533,8.533
				s8.533-3.82,8.533-8.533V81.192c-0.044-4.602,2.385-8.875,6.364-11.189c3.979-2.314,8.893-2.314,12.872,0
				c3.979,2.314,6.409,6.586,6.364,11.189v150.183c0,4.713,3.82,8.533,8.533,8.533s8.533-3.821,8.533-8.533V113.408
				c-0.004-3.457,1.362-6.774,3.8-9.225c2.368-2.417,5.616-3.771,9-3.75c7.066,0.008,12.792,5.734,12.8,12.8v192.091
				c0.043,0.296,0.102,0.589,0.177,0.879c0.055,0.576,0.169,1.144,0.342,1.696c0.5,1.588,1.474,2.986,2.79,4.006
				c0.234,0.188,0.375,0.446,0.633,0.61c0.2,0.127,0.431,0.137,0.637,0.246c1.022,0.499,2.121,0.822,3.251,0.954
				c0.244,0.021,0.454,0.142,0.704,0.142c0.303-0.043,0.603-0.104,0.898-0.181c0.561-0.055,1.115-0.166,1.653-0.333
				c1.019-0.331,1.968-0.847,2.799-1.522c0.456-0.38,0.871-0.809,1.235-1.278c0.185-0.231,0.442-0.371,0.606-0.627l37.183-58.242
				c3.803-5.947,11.699-7.7,17.661-3.92S394.174,259.423,390.417,265.4z"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'timeout':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 16}
          height={props.size || 16}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path
            fill="#333"
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
          <path fill="#333" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
        </svg>
      );
    default:
      return null;
  }
};

const Wrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.app.textColor};
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    height: calc(100% - ${rem(70)});
    width: ${rem(1)};
    background: ${({ theme }) => theme.separatorColor};
    position: absolute;
    top: calc(0% + ${rem(35)});
    left: calc(50% - ${rem(1)});
  }
`;
const EventItem = styled.div<{ align: string; last?: boolean }>`
  display: flex;
  align-items: center;
  ${({ last }) => {
    if (last) {
      return css`
        ${Minute} {
          &:after {
            height: 0;
          }
        }
      `;
    }
    return css``;
  }}
  ${({ align }) => {
    if (align === 'left') {
      return css`
        flex-direction: row;
        justify-content: flex-end;
      `;
    }
    return css``;
  }}
`;
const EventItemPart = styled.div`
  width: calc(50% - ${rem(21)});
`;
const Spacer = styled(EventItemPart)`
  display: block;
`;
const Minute = styled.div<{ phase?: boolean }>`
  text-align: center;
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  border-width: 0;
  background: ${({ theme }) => theme.app.bgColor};
  font-weight: bold;
  color: ${({ theme }) => theme.app.textColor};
  border-radius: 100%;
  height: ${rem(24)};
  display: flex;
  align-items: center;
  position: relative;
  width: ${rem(42)};
  font-size: ${rem(10)};
  z-index: 1;
  justify-content: center;
  ${({ phase }) => {
    if (phase) {
      return css`
        border-width: 0;
        visibility: hidden;
      `;
    }
    return css``;
  }}
`;
const MinuteMask = styled.div``;
const EventItemInfo = styled(EventItemPart)<{ align: string }>`
  ${({ align }) => {
    if (align === 'right') {
      return css`
        margin-left: -${rem(12)};
      `;
    }
    return css`
      margin-right: -${rem(12)};
    `;
  }}
`;
const EventIcon = styled.div<{ align: string }>`
  display: flex;
  align-items: center;
  ${({ align }) => {
    if (align === 'left') {
      return css`
        justify-content: flex-start;
        flex-direction: row-reverse;
        ${Player} {
          align-items: flex-start;
          text-align: right;
        }
      `;
    }
    return css``;
  }}
`;
const Player = styled.div`
  padding: 0 ${rem(6)};
  text-align: left;
  width: 100%;
`;
const PlayerName = styled.div<{ phase?: boolean }>`
  display: block;
  font-size: ${rem(11)};
  font-weight: ${({ phase }) => (phase ? 'normal' : 'bold')};
  color: inherit;
  text-decoration: none;
  :any-link:focus,
  :any-link:hover,
  :any-link:active {
    text-decoration: underline;
  }
` as any;
const EventType = styled.div<{ mobile?: boolean }>`
  font-size: ${rem(11)};
  color: ${({ mobile, theme }) => (mobile ? 'inherit' : theme.inactiveColor)};
  display: block;
`;
const IconWrapper = styled.div`
  min-width: ${rem(30)};
`;

interface IOwnProps {
  teams: Team[];
  protocol?: IProtocol;
  timer?: {
    [key: string]: {
      start: {
        date: string;
        seconds?: number;
      };
      end?: {
        date: string;
        seconds?: number;
      };
    };
  };
  eventTypes: ISportSectorEvent[];
  phases: ISportSectorPhase[];
  settings: Match['settings'];
  sport: string;
}

type Props = IOwnProps & Theme;

const TimeLine: React.FC<Props> = ({
  sport,
  eventTypes,
  teams,
  theme,
  settings,
  protocol,
  timer,
  phases,
}) => {
  const renderEventDetail = (event: any, currentTeam: Team, align: string) => {
    const phase = eventTypes.find((e) => e._id === event.type);
    return (
      <EventItemInfo align={align}>
        <EventIcon align={align}>
          <IconWrapper>
            <Icon
              title={phase ? phase.label : ''}
              eventType={event.eventType}
              subType={event.type}
              theme={theme}
              sport={sport}
            />
          </IconWrapper>
          <Player>
            {!!event.player && (
              <div>
                <PlayerName>{event.player.name}</PlayerName>
              </div>
            )}
            {event.eventType === 'timeout' && <PlayerName>Timeout</PlayerName>}
            {!!event.replacement && (
              <EventType className="event-description">
                {event.replacement.name}
              </EventType>
            )}
          </Player>
        </EventIcon>
      </EventItemInfo>
    );
  };

  const getTeam = (teamSide: string) =>
    teams.find(
      (i) => getProp(i, ['additionalProperties', 'homeaway'], '') === teamSide,
    );

  const getTeamsIds = () => {
    const homeTeam = getTeam('home');
    const homeTeamId = homeTeam ? homeTeam._id : teams[0]?._id || '';
    const awayTeam = getTeam('away');
    const awayTeamId = awayTeam ? awayTeam._id : teams[1]?._id || '';

    return { homeTeamId, awayTeamId };
  };

  const getEventTime = (event: any) => {
    let minutes = `${Math.ceil(event.eventTime / 60)}`;
    if (settings.displaySeconds) {
      minutes = `${Math.floor(event.eventTime / 60)}`;
      const seconds = String(event.eventTime % 60).padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
    if (settings.overlapTime) {
      const phase = settings.phases[event.phase];
      if (phase.endTime && phase.endTime < event.eventTime) {
        const remaind = Math.ceil((event.eventTime - phase.endTime) / 60);
        return <div>{`${phase.endTime / 60}+${remaind}'`}</div>;
      }
      return `${minutes}'`;
    }
    return `${minutes}'`;
  };

  const renderEventItem = (eventTeams: Team[], event: any) => {
    const currentTeam = eventTeams.find((i) => i._id === event.team)!;
    const { homeTeamId } = getTeamsIds();

    return (
      <EventItem align={event.team === homeTeamId ? 'left' : 'right'}>
        {event.team === homeTeamId ? (
          renderEventDetail(event, currentTeam, 'left')
        ) : (
          <Spacer />
        )}
        <Minute>
          <MinuteMask />
          <div style={{ position: 'relative' }}>{getEventTime(event)}</div>
        </Minute>
        {event.team === homeTeamId ? (
          <Spacer />
        ) : (
          renderEventDetail(event, currentTeam, 'right')
        )}
      </EventItem>
    );
  };

  const playablePhases = phases.filter((a) => a.playPhase);
  const PHASES = playablePhases.reduce((acc: any, phase: any) => {
    if (timer && timer[phase._id]) {
      return [
        ...acc,
        {
          ...phase,
          startDate: getProp(timer[phase._id], ['start', 'date']),
          endDate: getProp(timer[phase._id] || {}, ['end', 'date']),
          startTime:
            getProp(timer[phase._id], ['start', 'seconds']) ||
            settings.phases[phase._id].startTime,
          endTime:
            getProp(timer[phase._id] || {}, ['end', 'seconds']) ||
            settings.phases[phase._id].endTime,
        },
      ];
    }
    return acc;
  }, []);

  const events = (protocol || { events: [] }).events
    .filter((event) => event.eventTime)
    .map((event) => {
      const timeParts = event.eventTime.split(':');
      const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
      return {
        ...event,
        eventTime: seconds,
      };
    });

  if (settings.reverseTime) {
    events.sort((a, b) => b.eventTime - a.eventTime);
  } else {
    events.sort((a, b) => a.eventTime - b.eventTime);
  }

  const eventsByPhase = events.reduce(
    (acc, event) => {
      return { ...acc, [event.phase]: [...(acc[event.phase] || []), event] };
    },
    PHASES.reduce((acc: any, phase: any) => {
      if (phase && phase.startDate) {
        return { ...acc, [phase._id]: [] };
      }
      return acc;
    }, {}),
  );

  const sortedEventsByPhases = Object.keys(eventsByPhase).reduce((acc, e) => {
    const phase = PHASES.find((p: any) => p._id === e);
    if (phase && e !== 'shootout') {
      acc.push({
        phaseId: e,
        startTime: phase.startTime || phase.playTimeOrder,
        events: eventsByPhase[e],
        phaseData: phase,
      });
    }
    return acc;
  }, [] as Array<{ phaseId: string; startTime: number; phaseData: any; events: Array<typeof eventsByPhase> }>);

  if (settings.reverseTime) {
    sortedEventsByPhases.sort((a, b) => b.startTime - a.startTime);
  } else {
    sortedEventsByPhases.sort((a, b) => a.startTime - b.startTime);
  }

  return (
    <Wrapper>
      <Events>
        {sortedEventsByPhases.map((p) => {
          if (
            p.phaseData &&
            isAfter(new Date(), new Date(p.phaseData.startDate))
          ) {
            return (
              <React.Fragment key={p.phaseId}>
                <EventItem align="right">
                  <Spacer />
                  <Minute phase>
                    {Math.floor(p.phaseData.startTime / 60)}'
                  </Minute>
                  <EventItemInfo align="right">
                    <EventIcon align="right">
                      <IconWrapper>
                        <Icon
                          title={p.phaseData.label}
                          eventType="phase"
                          theme={theme}
                          sport={sport}
                        />
                      </IconWrapper>
                      <Player>
                        <PlayerName
                          phase
                        >{`Začiatok - ${p.phaseData.label}`}</PlayerName>
                      </Player>
                    </EventIcon>
                  </EventItemInfo>
                </EventItem>
                {p.events.map((event: any, idx: number) => (
                  <div key={`event_${idx}`}>
                    {renderEventItem(teams, event)}
                  </div>
                ))}
                {!!p.phaseData.endDate && (
                  <EventItem align="right">
                    <Spacer />
                    <Minute phase>
                      {Math.floor(
                        (p.phaseData.startTime + p.phaseData.playTime) / 60,
                      )}
                      '
                    </Minute>
                    {isAfter(new Date(), new Date(p.phaseData.endDate)) && (
                      <EventItemInfo align="right">
                        <EventIcon align="right">
                          <IconWrapper>
                            <Icon
                              title={p.phaseData.label}
                              eventType="phase"
                              theme={theme}
                              sport={sport}
                            />
                          </IconWrapper>
                          <Player>
                            <PlayerName
                              phase
                            >{`Koniec - ${p.phaseData.label}`}</PlayerName>
                          </Player>
                        </EventIcon>
                      </EventItemInfo>
                    )}
                  </EventItem>
                )}
              </React.Fragment>
            );
          }
          return null;
        })}
      </Events>
    </Wrapper>
  );
};

export default withTheme(TimeLine);
