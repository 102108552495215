
import commonSorters from "./commonSorters";
import { IBaseObjectName, IResultsTableItem, IResultsTableItemMatch } from "./resultsTableSorters";

const sortResults = (
    results: IResultsTableItem[],
    baseObjectName: IBaseObjectName,
    miniTable?: boolean,
) => {
    const [currentSorter, ...sorters] = commonSorters;
    const teamIds = results.map(result => result.team._id);
    const modifiedResults = results.map((result, idx) => {
        let sortPoints = result[baseObjectName]?.points || 0;
        let sortGoalsGiven = result[baseObjectName]?.goals?.given || 0;
        let sortGoalsReceived = result[baseObjectName]?.goals?.received || 0;

        if (miniTable) {
            sortPoints = 0;
            sortGoalsGiven = 0;
            sortGoalsReceived = 0;
            let matchesStatsBaseObjectName: IBaseObjectName | '' = '';

            if (baseObjectName === 'stats') {
                matchesStatsBaseObjectName = 'matches' as IBaseObjectName;
            } else if (baseObjectName === 'homeStats') {
                matchesStatsBaseObjectName = 'awayMatches' as IBaseObjectName;
            } else if (baseObjectName === 'awayStats') {
                matchesStatsBaseObjectName = 'homeMatches' as IBaseObjectName;
            }

            if (matchesStatsBaseObjectName) {
                ((result[matchesStatsBaseObjectName] || []) as IResultsTableItemMatch[]).filter(match => teamIds.includes(match.team._id)).forEach(match => {
                    const [homeTeamScore, awayTeamScore] = match.score.split(":").map(Number);
                    if (match.result === 'W') {
                        sortPoints += 3;
                    } else if (match.result === 'D') {
                        sortPoints += 1;
                    } else if (match.result === 'L') {
                        // no points awarded for loss
                    }
                    sortGoalsGiven = match.homeaway === 'home' ? homeTeamScore : awayTeamScore;
                    sortGoalsReceived = match.homeaway === 'home' ? awayTeamScore : homeTeamScore;
                })
            }
        }

        return { ...result, [baseObjectName]: { ...result[baseObjectName], sortPoints, sortGoalsGiven, sortGoalsReceived } };
    })

    return modifiedResults.sort((a, b) =>
        currentSorter(a, b, sorters, baseObjectName),
    );
};

export default sortResults;