import Button from '@sportnet/ui/Button';
import Modal, { ModalContent, ModalActions } from '@sportnet/ui/Modal';
import { __ } from '@sportnet/ui/utilities';
import { format } from 'date-fns';
import { reset, submit } from 'redux-form';
import Box from 'src/components/Box';
import { Members, MembersDetail } from './definitions';
import MembersForm from './form';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import BasicTable from '@sportnet/ui/BasicTable';
import Icon from '@sportnet/ui/Icon';
import { FOOTBALL_POSITIONS } from '../../Competitions/Squads/Forms/Cycling';
import { FUTSAL_POSITIONS } from '../../Competitions/Squads/Forms/Futsal/form';
import React from 'react';
import Header from '@sportnet/ui/Header';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { State } from '../../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { membersByIdSelector } from './selectors';
import {
  deleteMembers,
  getMembersById,
  postMembers,
  putMembers,
} from './actions';
import PlayerForm from '../PlayerForm';
import { Athlete, CrewMember, Team } from '../definitions';
import Message from '@sportnet/ui/Message';
import CrewMemberForm from '../crewMemberForm';
import api from 'src/api';

const RowWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: ${rem(8)};
`;

type PersonInfo = {
  sportnetUser: {
    _id: string;
    name: string;
  };
  additionalData: any;
} | null;

type Props = {
  appspace: string;
  members: Members;
  keyValue: string;
  membersList: Members[];
  setMembers: any;
  team: Team;
  onCreateMembersCopy: (members: MembersDetail) => Promise<void>;
};

const TeamMembersDetail: React.FC<Props> = ({
  appspace,
  members,
  keyValue,
  membersList,
  setMembers,
  team,
  onCreateMembersCopy,
}) => {
  const FORM_NAME = React.useMemo(() => 'MEMBERS_FORM_' + keyValue, [keyValue]);
  const PLAYER_FORM = React.useMemo(
    () => 'PLAYER_FORM_' + keyValue,
    [keyValue],
  );
  const CREW_MEMBER_FORM = React.useMemo(
    () => 'CREW_MEMBER_FORM_' + keyValue,
    [keyValue],
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const membersDetailSelector = useSelector((state: State) =>
    membersByIdSelector(team._id, members._id)(state),
  ) as MembersDetail;
  const [membersDetail, setMembersDetail] = React.useState<MembersDetail>();
  const [formErrors, setFormErrors] = React.useState<any>();
  const [isRemovingPlayer, setIsRemovingPlayer] = React.useState<string | null>(
    null,
  );
  const [isSubmittingPlayer, setIsSubmittingPlayer] = React.useState(false);

  const [activePlayer, setActivePlayer] = React.useState<PersonInfo>(null);
  const [activeCrewMember, setActiveCrewMember] =
    React.useState<PersonInfo>(null);
  const [team_sport_sector] = React.useState(team.sport_sector);

  const [isCrewMemberModalOpened, setIsCrewMemberModalOpened] =
    React.useState(false);
  const [isPlayerModalOpened, setIsPlayerModalOpened] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const [isSubmittingMembers, setIsSubmittingMembers] = React.useState(false);
  const [isRemovingMembers, setIsRemovingMembers] = React.useState(false);
  const [isSubmittingCrewMember, setIsSubmittingCrewMember] =
    React.useState(false);
  const [isFetchingMembersDetail, setIsFetchingMembersDetail] =
    React.useState(false);

  const [hasChanged, setHasChanged] = React.useState(false);

  const [validFrom, setValidFrom] = React.useState(
    new Date(members.validFrom || new Date()),
  );
  const [title, setTitle] = React.useState(members.title || '');

  const [captain, setCaptain] = React.useState(false);
  const [playerPosition, setPlayerPosition] = React.useState<string>('');
  const [nr, setNr] = React.useState('');
  const [player, setPlayer] = React.useState<{
    value: string;
    label: string;
  }>();

  const [crewMember, setCrewMember] = React.useState<{
    value: string;
    label: string;
  }>();
  const [crewMemberPosition, setCrewMemberPosition] = React.useState('');
  const [crewCodelists, setCrewCodelists] = React.useState<
    {
      _id: string;
      label: string;
    }[]
  >([]);

  React.useEffect(() => {
    setFormErrors({});
  }, [player]);

  React.useEffect(() => {
    setCaptain(activePlayer?.additionalData?.captain || false);
    setPlayerPosition(activePlayer?.additionalData?.position || '');
    setNr(activePlayer?.additionalData?.nr || '');
    setPlayer(
      activePlayer?.sportnetUser
        ? {
            value: activePlayer?.sportnetUser?._id,
            label: activePlayer?.sportnetUser?.name,
          }
        : undefined,
    );
  }, [activePlayer]);

  const getCrewCodelists = React.useCallback(async () => {
    await api
      .getSettingBySportSector('sport_sector_crew', team_sport_sector)
      .then((res) => {
        setCrewCodelists(res.items);
      });
  }, [team_sport_sector]);

  React.useEffect(() => {
    getCrewCodelists();
  }, [getCrewCodelists]);

  const getMembersDetail = React.useCallback(async () => {
    if (!!members._id) {
      try {
        setIsFetchingMembersDetail(true);
        await dispatch(
          getMembersById.action({
            appspace,
            teamId: team._id,
            members: membersList,
            membersId: members._id,
          }),
        ).finally(() => {
          setIsFetchingMembersDetail(false);
        });
      } catch (e: any) {
        console.error(e);
      }
    }
  }, [appspace, dispatch, members._id, team._id, membersList]);

  React.useEffect(() => {
    if (!!!isCollapsed) {
      setTitle(members.title);
      setValidFrom(new Date(members.validFrom));
      getMembersDetail();
    }
  }, [getMembersDetail, isCollapsed, members.title, members.validFrom]);

  React.useEffect(() => {
    if (!!!members?._id) {
      setMembersDetail(members as MembersDetail);
    } else {
      setMembersDetail(membersDetailSelector);
    }
  }, [membersDetailSelector, members]);

  const submitCrewMemberForm = async (values: Athlete) => {
    setIsSubmittingCrewMember(true);
    if (!!membersDetail) {
      if (activeCrewMember) {
        values.sportnetUser = activeCrewMember.sportnetUser;
        const index = membersDetail.crew.findIndex(
          (a: Athlete) => a.sportnetUser._id === values.sportnetUser._id,
        );
        setHasChanged(
          JSON.stringify(membersDetail.crew[index]) !== JSON.stringify(values),
        );
        membersDetail.crew[index] = values;
      } else {
        setHasChanged(true);
        membersDetail.crew.push(values);
      }
      setIsSubmittingCrewMember(false);
      setIsCrewMemberModalOpened(false);
    }
  };

  const onSubmitMembers = async () => {
    if (!!membersDetail) {
      setIsSubmittingMembers(true);
      membersDetail.title = title;
      membersDetail.validFrom = validFrom.toISOString();

      const { _id, ...members } = membersDetail;

      if (!!membersDetail?._id) {
        await dispatch(
          putMembers.action({
            appspace,
            teamId: team._id,
            membersId: _id,
            members,
          }),
        )
          .then((res) => {
            setHasChanged(false);
            const index = membersList.findIndex((s) => s._id === _id);
            const updatedMembersList = [...membersList];
            updatedMembersList[index] = res;
            setMembers(updatedMembersList);
            dispatch(reset(FORM_NAME));
          })
          .catch((err) => {
            console.error(err);
            window.alert('Osoby v tíme sa nepodarilo uložiť');
          })
          .finally(() => {
            setIsSubmittingMembers(false);
          });
      } else {
        await dispatch(
          postMembers.action({
            appspace,
            teamId: team._id,
            members: members,
          }),
        )
          .then((res) => {
            setMembersDetail(res);
            membersList[0] = res;
            setMembers(membersList);
            setHasChanged(false);
            dispatch(reset(FORM_NAME));
          })
          .catch((err) => {
            console.error(err);
            window.alert('Osoby v tíme sa nepodarilo vytvoriť');
          })
          .finally(() => {
            setIsSubmittingMembers(false);
          });
      }
    }
  };

  const onDeleteMembers = async () => {
    if (
      !!membersDetail?._id &&
      window.confirm('Naozaj si prajete odstrániť tieto osoby v tíme?')
    ) {
      setIsRemovingMembers(true);

      await dispatch(
        deleteMembers.action({
          appspace,
          teamId: team._id,
          membersId: membersDetail._id,
        }),
      )
        .catch((err) => {
          console.error(err);
          window.alert('Osoby v tíme sa nepodarilo odstrániť');
        })
        .finally(() => {
          setIsRemovingMembers(false);
          const index = membersList.findIndex(
            (s) => s._id === membersDetail._id,
          );
          if (index >= 0) {
            membersList.splice(index, 1);
          }
        });
    }
  };

  const renderPlayersTable = () => {
    const commonProps = {
      rows: membersDetail?.athletes || [],
      onClickRow: (i: any) => {
        setActivePlayer(i);
        setIsPlayerModalOpened(true);
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string, disabled?: boolean) => (
      <Button
        disabled={disabled}
        loading={isRemovingPlayer === sportnetId}
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();

          if (
            window.confirm(
              __('Skutočne si želáte odstrániť hráča z osôb v tíme?'),
            )
          ) {
            setIsRemovingPlayer(sportnetId);
            const index = (membersDetail?.athletes as Athlete[]).findIndex(
              (a) => a.sportnetUser._id === sportnetId,
            );
            const reducedAthletes = [...(membersDetail?.athletes || [])];

            if (index >= 0) {
              reducedAthletes.splice(index, 1);
              setMembersDetail({
                ...(membersDetail as any),
                athletes: reducedAthletes,
              });
              setHasChanged(true);
            }
            setIsRemovingPlayer(null);
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );
    const getFootballPositions = (positionId: string) => {
      const p = FOOTBALL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    const getFutsalPositions = (positionId: string) => {
      const p = FUTSAL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    switch (team_sport_sector) {
      case 'water-polo':
      case 'swimming':
      case 'cestna-cyklistika':
      case 'formula-1':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      case 'futbal':
        return (
          <BasicTable
            rows={membersDetail?.athletes || []}
            onClickRow={(i: any) => {
              setActivePlayer(i);
              setIsPlayerModalOpened(true);
            }}
            rowKey={'_id'}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => {
              return [
                member.sportnetUser?.name,
                !!member.additionalData.captain && <Icon name="check" />,
                getFootballPositions(member.additionalData.position),
                member.additionalData.nr,
                RemoveButton(
                  member.sportnetUser?._id,
                  member.removable === false,
                ),
              ];
            }}
          />
        );
      case 'futsal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFutsalPositions(member.additionalData.position),
              member.additionalData.nr,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      case 'beachfutbal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              {
                header: __('Meno na drese'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFootballPositions(member.additionalData.position),
              member.additionalData.nr,
              member.additionalData.shirtname,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      case 'minifootball':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      default:
        return null;
    }
  };

  const renderCrewTable = () => {
    const commonProps = {
      rows: Array.isArray(membersDetail?.crew || [])
        ? ((membersDetail?.crew || []) as any).map((crewMember: any) => {
            return {
              ...crewMember,
            };
          })
        : Object.keys(membersDetail?.crew || {}).map((position) => {
            return {
              ...membersDetail?.crew[position],
              position,
            };
          }),
      onClickRow: (i: any) => {
        setActiveCrewMember(i);
        setCrewMemberPosition(i.position);
        setIsCrewMemberModalOpened(true);
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string) => (
      <Button
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          if (
            window.confirm(
              __(
                'Skutočne si želáte odstrániť člena realizačného tímu z osôb v tíme?',
              ),
            )
          ) {
            const index = (membersDetail?.crew as CrewMember[]).findIndex(
              (a) => a.sportnetUser._id === sportnetId,
            );
            const reducedCrew = [...(membersDetail?.crew || [])];

            if (index >= 0) {
              reducedCrew.splice(index, 1);
              setMembersDetail({
                ...(membersDetail as any),
                crew: reducedCrew,
              });
              setHasChanged(true);
            }
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );

    return (
      <BasicTable
        {...commonProps}
        columns={[
          {
            header: __('Meno a priezvisko'),
          },
          {
            header: __('Pozícia'),
          },
          { header: '', width: 100 },
        ]}
        renderRow={(member) => [
          member.sportnetUser?.name,
          crewCodelists.find((c) => c._id === member.position)?.label,
          RemoveButton(member.sportnetUser?._id),
        ]}
      />
    );
  };

  const submitPlayerForm = async (values: Athlete) => {
    values.additionalData = values.additionalData || {};
    values.additionalData.position =
      values.additionalData.position || 'goalkeeper';
    values.additionalData.nr = values.additionalData.nr || '1';

    if (!values?.sportnetUser?._id) {
      setFormErrors({ player: __('Toto pole je povinné') });
      return;
    }

    setFormErrors({});
    setIsSubmittingPlayer(true);

    if (!!membersDetail) {
      if (activePlayer && !!membersDetail) {
        values.sportnetUser = activePlayer.sportnetUser;
        const index = membersDetail.athletes?.findIndex(
          (a: Athlete) => a.sportnetUser._id === values.sportnetUser._id,
        );
        setHasChanged(
          JSON.stringify(membersDetail.athletes[index]) !==
            JSON.stringify(values),
        );
        membersDetail.athletes[index] = values;
      } else {
        setHasChanged(true);
        membersDetail.athletes.push(values);
      }
      setIsSubmittingPlayer(false);
      setIsPlayerModalOpened(false);
    }
  };

  return (
    <>
      <Box
        title={`Osoby v tíme: ${members.title}`}
        subtitle={`${format(new Date(members.validFrom), 'dd.MM.yyyy')}`}
        collapsible={true}
        collapsed={!!members._id ? isCollapsed : false}
        toggleCollapse={!!members._id ? setIsCollapsed : undefined}
      >
        {!!membersDetail?._id &&
          (!!hasChanged ||
            title !== members.title ||
            validFrom.toISOString() !== members.validFrom) && (
            <Message warning>
              {__('Zmeny sa zaznamenajú až po stlačení tlačidla')}
              <b>{__(' Uložiť osoby v tíme')}</b>
            </Message>
          )}
        {!!!membersDetail?._id && (
          <Message warning>
            {__('Osoby v tíme bude vytvorená až po stlačení tlačidla')}
            <b>{__(' Vytvoriť osoby v tíme')}</b>
          </Message>
        )}
        <MembersForm
          dispatch={dispatch}
          form={FORM_NAME}
          initialValues={{
            validFrom: new Date(members.validFrom),
            title: members.title,
          }}
          onSubmit={onSubmitMembers}
          title={title}
          setTitle={setTitle}
          validFrom={validFrom}
          setValidFrom={setValidFrom}
        />
        <Box title="Hráči">
          <Button
            onClick={() => {
              setActivePlayer(null);
              setPlayer(undefined);
              setNr('');
              setPlayerPosition('');
              setIsPlayerModalOpened(true);
            }}
            primary
          >
            {__('Pridať hráča')}
          </Button>
          <br />
          <br />
          {renderPlayersTable()}
        </Box>
        <br />
        <Box title="Realizačný tím">
          <Button
            onClick={() => {
              setActiveCrewMember(null);
              setCrewMember(undefined);
              setCrewMemberPosition('');
              setIsCrewMemberModalOpened(true);
            }}
            primary
          >
            {__('Pridať osobu')}
          </Button>
          <br />
          <br />
          {renderCrewTable()}
        </Box>
        <br />
        <RowWrapper>
          {!!membersDetail?._id && (
            <>
              <Button
                danger
                onClick={onDeleteMembers}
                loading={isRemovingMembers}
              >
                {__('Zmazať')}
              </Button>
              <Button
                basic
                primary
                onClick={() => {
                  if (!!membersDetail) {
                    onCreateMembersCopy(membersDetail);
                  }
                }}
              >
                {__('Vytvoriť kópiu')}
              </Button>
              {(!!hasChanged ||
                title !== members.title ||
                validFrom.toISOString() !== members.validFrom) && (
                <Button
                  basic
                  danger
                  loading={isFetchingMembersDetail}
                  onClick={() => {
                    setTitle(members.title);
                    setValidFrom(new Date(members.validFrom));
                    getMembersDetail().then(() => {
                      setMembersDetail(membersDetailSelector);
                    });
                    dispatch(reset(FORM_NAME));
                    setHasChanged(false);
                  }}
                >
                  {__('Zahodiť zmeny')}
                </Button>
              )}
            </>
          )}
          {!!!membersDetail?._id && (
            <Button
              basic
              danger
              onClick={() => {
                const reducedMembersList = [...membersList];
                reducedMembersList.shift();
                setMembers(reducedMembersList);
                setIsCollapsed(true);
              }}
            >
              {__('Zahodiť')}
            </Button>
          )}
          <Button
            primary
            onClick={() => {
              dispatch(submit(FORM_NAME));
            }}
            disabled={
              !!membersDetail?._id
                ? !(
                    !!hasChanged ||
                    title !== members.title ||
                    validFrom.toISOString() !== members.validFrom
                  )
                : false
            }
            loading={isSubmittingMembers}
          >
            {__(
              !!membersDetail?._id
                ? 'Uložiť osoby v tíme'
                : 'Vytvoriť osoby v tíme',
            )}
          </Button>
        </RowWrapper>
      </Box>
      <Modal
        handleClose={() => setIsCrewMemberModalOpened(false)}
        isOpen={isCrewMemberModalOpened}
      >
        <ModalContent>
          <Header size="s" withSeparator>
            {!!activeCrewMember
              ? __('Úprava člena realizačného tímu v družstve')
              : __('Pridanie člena realizačného tímu do družstva')}
          </Header>
          <CrewMemberForm
            form={CREW_MEMBER_FORM}
            onSubmit={submitCrewMemberForm}
            codelists={crewCodelists}
            initialValues={activeCrewMember || {}}
            editing={!!activeCrewMember}
            dispatch={dispatch}
            appspace={appspace}
            team={team}
            crewMember={crewMember}
            setCrewMember={setCrewMember}
            position={crewMemberPosition}
            setPosition={setCrewMemberPosition}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                setActiveCrewMember(null);
                setIsCrewMemberModalOpened(false);
              }}
            >
              {__('Zavrieť')}
            </Button>
            &nbsp;
            <Button
              primary
              loading={isSubmittingCrewMember}
              onClick={() => dispatch(submit(CREW_MEMBER_FORM))}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      <Modal
        isOpen={isPlayerModalOpened}
        handleClose={() => setIsPlayerModalOpened(false)}
      >
        <ModalContent>
          <Header size="s" withSeparator>
            {!!activePlayer
              ? __('Úprava hráča v družstve')
              : __('Pridanie hráča do družstva')}
          </Header>
          <PlayerForm
            form={PLAYER_FORM}
            onSubmit={submitPlayerForm}
            errors={formErrors}
            initialValues={activePlayer || {}}
            editing={!!activePlayer}
            dispatch={dispatch}
            appspace={appspace}
            team={team}
            player={player}
            setPlayer={setPlayer}
            captain={captain}
            setCaptain={setCaptain}
            position={playerPosition}
            setPosition={setPlayerPosition}
            nr={nr}
            setNr={setNr}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                setActivePlayer(null);
                setIsPlayerModalOpened(false);
                setFormErrors({});
              }}
            >
              {__('Zavrieť')}
            </Button>
            <Button
              onClick={() => {
                dispatch(submit(PLAYER_FORM));
              }}
              primary
              loading={isSubmittingPlayer}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

export default TeamMembersDetail;
