import MatchPreview from '@sportnet/content/view/widgets/MatchOverview';
import Segment from '@sportnet/ui/Segment';
import Tabber from '@sportnet/ui/Tabber';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import {
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { authUserSelector } from '../../App/selectors';
import styled from 'styled-components';
import Box from '../../../components/Box';
import BoxLoader from '../../../components/BoxLoader';
import { State } from '../../../rootReducer';
import { getProp, __ } from '../../../utilities';
import { Competition, CompetitionPart } from '../../Competitions/definitions';
import { NominationItem } from '../../Nominations';
import BeachFootballNominationsList from '../../Nominations/BeachFootball/list';
import FutbalNominationsList from '../../Nominations/Futbal/list';
import FutsalNominationsList from '../../Nominations/Futsal/list';
import MinifootballNominationsList from '../../Nominations/Minifootball/list';
import WaterPoloNominationsList from '../../Nominations/WaterPolo/list';
import BeachFootballProtocol from '../../Protocols/BeachFootball';
import CyclingProtocol from '../../Protocols/Cycling';
import FormulaOneProtocol from '../../Protocols/FormulaOne';
import FutbalProtocol from '../../Protocols/Futbal';
import FutsalProtocol from '../../Protocols/Futsal';
import MinifootballProtocol from '../../Protocols/Minifootball';
import SwimmingProtocol from '../../Protocols/Swimming';
import WaterPoloProtocol from '../../Protocols/WaterPolo';
import { Team } from '../../Teams/definitions';
import { Match } from '../definitions';
import BasicInfo from './BasicInfo';
import VARBoard from './VAR';

export const FORM_NAME = 'MATCH_FORM';

const Tabs = [
  { label: __('Základné informácie'), value: '' },
  { label: __('VAR'), value: 'VAR' },
  { label: __('Nominácie'), value: 'NOMINATIONS' },
  { label: __('Zápis o stretnutí'), value: 'PROTOCOL' },
];

export const BoxNote = styled.strong`
  font-size: ${rem(13)};
  color: #9e9e9e;
`;

interface CustomFormData {
  name: string;
}

export interface MatchNomination {
  teamId: string;
  nomination: NominationItem[];
  closed?: boolean;
}

interface OwnProps {
  id: string;
  data: Match | null;
  competition: Competition | null;
  teams: Team[];
  competitionPart: CompetitionPart;
  closed: boolean;
  isMatchNominated: boolean;
}

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  const data = {
    rules: selector(state, 'rules') || {},
    attendants: selector(state, 'teams') || [],
    nominations: selector(state, 'nominations') || [],
  };
  const rules = getProp(props, ['data', 'rules']);

  return {
    ...data,
    authUser: authUserSelector(state),
    initialValues: {
      ...props.data,
      rules:
        rules && Object.keys(rules).length > 0
          ? {
              ...rules,
            }
          : {},
    },
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

class MatchForm extends React.PureComponent<
  InjectedFormProps<CustomFormData> &
    IMapStateToProps &
    OwnProps & {
      dispatch: ThunkDispatch<any, any, AnyAction>;
    } & RouteComponentProps<{
      competitionId: string;
      partId: string;
      matchId: string;
    }>
> {
  setActiveTab = (tabName: string) => {
    const tab = Tabs.find((tab) => tab.label === tabName);
    this.props.history.push(
      `/match-manager/competitions/${this.props.match.params.competitionId}/parts/${this.props.match.params.partId}/matches/${this.props.match.params.matchId}#${tab?.value}`,
    );
  };

  renderProtocol = () => {
    const {
      data,
      closed,
      nominations,
      rules: { sport_sector },
      competitionPart,
    } = this.props;
    if (!sport_sector) {
      return <BoxLoader />;
    }

    const props = {
      data,
      teams: data ? data.teams || [] : [],
      nominations,
      closed,
      competitionPart,
    };

    switch (sport_sector) {
      case 'water-polo':
        return <WaterPoloProtocol {...props} />;
      case 'futbal':
        return <FutbalProtocol {...props} />;
      case 'futsal':
        return <FutsalProtocol {...props} />;
      case 'beachfutbal':
        return <BeachFootballProtocol {...props} />;
      case 'minifootball':
        return <MinifootballProtocol {...props} />;
      case 'swimming':
        return <SwimmingProtocol {...props} />;
      case 'cestna-cyklistika':
        return <CyclingProtocol {...props} />;
      case 'formula-1':
        return <FormulaOneProtocol {...props} />;
      default:
        return (
          <BoxNote>
            {__(
              'Pre dané športové odvetvie momentálne neexistuje zápis o stretnutí.',
            )}
          </BoxNote>
        );
    }
  };

  renderNominations = () => {
    const {
      attendants,
      teams,
      closed,
      nominations,
      match: {
        params: { matchId, competitionId, partId },
      },
      data,
      competitionPart,
    } = this.props;

    if (!competitionPart) {
      return <BoxLoader />;
    }
    const { sport_sector } = (competitionPart.rules || {}) as {
      sport_sector: string;
    };
    const props = {
      competitionId,
      partId,
      matchId,
      nominations,
      teams,
      attendants,
      closed,
      appSpace: data && data.appSpace,
      submitMatch: () => {
        this.props.dispatch(submit(FORM_NAME));
      },
    };

    switch (sport_sector) {
      case 'water-polo':
        return <WaterPoloNominationsList {...props} />;
      case 'futbal':
        return <FutbalNominationsList {...props} />;
      case 'futsal':
        return <FutsalNominationsList {...props} />;
      case 'beachfutbal':
        return <BeachFootballNominationsList {...props} />;
      case 'minifootball':
        return <MinifootballNominationsList {...props} />;
      default:
        return (
          <BoxNote>
            {__(
              'Pre dané športové odvetvie momentálne neexistuje možnosť nominácie.',
            )}
          </BoxNote>
        );
    }
  };

  render() {
    const {
      match: {
        params: { competitionId, partId, matchId },
      },
      isMatchNominated,
      authUser,
    } = this.props;

    const manager = (this.props.data?.managers || []).find(
      (i) => i.user._id === authUser?._id,
    );
    const availableTabs = Tabs.filter(
      (i) => !i.value || (manager?.roles || []).includes(i.value as any),
    );

    const hash = this.props.location.hash.substring(1);
    const activeTab = availableTabs.find((i) => i.value === hash);

    return (
      <>
        <Tabber
          onClickItem={this.setActiveTab}
          items={availableTabs.map((tab) => tab.label)}
          active={activeTab?.label}
        />
        <Segment>
          <Segment raised>
            <MatchPreview
              data={{
                data: {
                  match: (this.props.data || {}) as any,
                  sportSectorPhases: [],
                },
              }}
            />
          </Segment>
          {!activeTab?.value && (
            <BasicInfo
              form="MATCH_BASIC_INFO"
              initialValues={this.props.data || {}}
            />
          )}
          {activeTab?.value === 'VAR' && (
            <Box title={__('VAR')}>
              <VARBoard
                competitionId={competitionId}
                partId={partId}
                matchId={matchId}
              />
            </Box>
          )}
          {activeTab?.value === 'NOMINATIONS' && (
            <Box title={__('Účastníci')}>{this.renderNominations()}</Box>
          )}
          {activeTab?.value === 'PROTOCOL' && (
            <Form onSubmit={this.props.handleSubmit}>
              {matchId !== 'new' && (
                <React.Fragment>
                  {isMatchNominated && (
                    <Box title={__('Zápis')}>{this.renderProtocol()}</Box>
                  )}
                </React.Fragment>
              )}
            </Form>
          )}
        </Segment>
      </>
    );
  }
}

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
  enableReinitialize: true,
})(MatchForm);

export default connect(mapStateToProps)(withRouter(connected));
