import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AnyAction } from '../../../node_modules/redux';
import { ThunkDispatch } from '../../../node_modules/redux-thunk';
import Authorization from '../../authorization';
import ClubManagerAuthorization from '../../clubManagerAuthorization';
import MatchManagerAuthorization from '../../matchManagerAuthorization';
import { State } from '../../rootReducer';
import { clearError } from './actions';
import { errorSelector } from './selectors';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

const mapStateToProps = (state: State) => ({
  error: errorSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
}> &
  OwnProps &
  IMapStateToProps;

class App extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.error && this.props.error !== prevProps.error) {
      alert(this.props.error);
      this.props.dispatch(clearError());
    }
  }
  render() {
    return (
      <>
        <Route exact path="/" component={Authorization} />
        <Route path="/club-manager" component={ClubManagerAuthorization} />
        <Route path="/match-manager" component={MatchManagerAuthorization} />
        <Route path="/admin/:appspace/:path" component={Authorization} />
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(App));
