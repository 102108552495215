import Button from '@sportnet/ui/Button';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { change } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import TableForm from '../../../components/TableForm';
import { FORM_NAME, MatchNomination } from '../../Matches/form';
import { Team } from '../../Teams/definitions';
import { getProp, __ } from '../../../utilities';

interface OwnProps {
  attendants: Team[];
  appSpace: string | null;
  nominations: MatchNomination[];
  teams: Team[];
  closed: boolean;
  submitMatch: () => void;
}

class FutbalNominationsList extends React.PureComponent<
  OwnProps & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  } & RouteComponentProps<{
    appspace: string;
    id?: string;
    matchId?: string;
    competitionId?: string;
    partId?: string;
  }>
> {
  getHomeAwayLabel = (value: string) => {
    switch (value) {
      case 'home':
        return __('Domáci');
      case 'away':
        return __('Hostia');
      default:
        return '';
    }
  };
  render() {
    const { attendants, nominations, teams, closed } = this.props;

    const {
      match: {
        params: { appspace, id, matchId, competitionId, partId },
      },
    } = this.props;
    return (
      <>
        {(attendants || []).length === 2 && !closed && (
          <>
            <Button type="button" primary onClick={async () => {
              let homeTeamIdx = attendants.findIndex(i => i.additionalProperties?.homeaway === 'home');
              if (homeTeamIdx === -1) {
                homeTeamIdx = 0;
              }
              let awayTeamIdx = attendants.findIndex(i => i.additionalProperties?.homeaway === 'away');
              if (awayTeamIdx === -1) {
                awayTeamIdx = 0;
              }

              attendants[homeTeamIdx].additionalProperties = { ...attendants[homeTeamIdx].additionalProperties || {}, homeaway: 'away' };
              attendants[awayTeamIdx].additionalProperties = { ...attendants[awayTeamIdx].additionalProperties || {}, homeaway: 'home' };

              await this.props.dispatch(change(FORM_NAME, 'teams', attendants));
              await this.props.submitMatch();
            }}>{__("Prehodiť domácich a hostí")}</Button>
            <br /><br /></>
        )}
        <TableForm
          disabled={closed}
          buttonLabel={__('Pridať účastníka')}
          headers={[
            { label: __('Družstvo') },
            { label: __('Domáci/hostia') },
            { label: __('Stav nominácie') },
            { label: __(''), width: 105 },
          ]}
          items={attendants}
          maxItems={2}
          list={attendants.map((a: any) => {
            const nomination = nominations.find((n) => n.teamId === a._id);
            let status = '';

            if (getProp(nomination || {}, ['athletes'], []).length > 0) {
              if (getProp(nomination || {}, ['closed'], false)) {
                status = __('Uzavretá');
              } else {
                status = __('Vyplnená');
              }
            } else {
              status = __('Nevyplnená');
            }
            return {
              name: getProp(a || {}, ['name']),
              homeaway: this.getHomeAwayLabel(
                getProp(a || {}, ['additionalProperties', 'homeaway']),
              ),
              status,
              link: (
                <Button
                  primary
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (appspace) {
                      this.props.history.push(
                        `/admin/${appspace}/matches/${id}/nomination/${getProp(
                          a || {},
                          ['_id'],
                        )}`,
                      );
                    } else {
                      this.props.history.push(
                        `/match-manager/competitions/${competitionId}/parts/${partId}/matches/${matchId}/nomination/${getProp(
                          a || {},
                          ['_id'],
                        )}`,
                      );
                    }
                  }}
                >
                  {__('Nominácia')}
                </Button>
              ),
            };
          })}
          fields={[
            {
              type: 'select',
              name: 'team',
              label: __('Družstvo'),
              required: true,
              items: teams.map((t) => {
                return {
                  value: t._id,
                  label: t.name || '',
                };
              }),
            },
            {
              type: 'select',
              label: __('Domáci/hostia'),
              name: 'additionalProperties.homeaway',
              required: true,
              items: [
                {
                  value: 'home',
                  label: __('Domáci'),
                },
                {
                  value: 'away',
                  label: __('Hostia'),
                },
              ],
            },
          ]}
          onChange={async (e) => {
            const newTeams = e.reduce((acc, t) => {
              if (typeof t.team === 'string') {
                const teamData = {
                  ...teams.find((i) => i._id === t.team),
                  additionalProperties: t.additionalProperties,
                };
                const nonUniqueTeam = acc.find((i: any) => {
                  return (
                    i._id === getProp(teamData || {}, ['_id']) ||
                    i.additionalProperties.homeaway ===
                    getProp(teamData || {}, [
                      'additionalProperties',
                      'homeaway',
                    ])
                  );
                });
                if (nonUniqueTeam) {
                  return acc;
                }
                return [
                  ...acc,
                  {
                    ...t,
                    ...teamData,
                  },
                ];
              }
              const nonUnique = acc.find(
                (i: any) =>
                  i._id === getProp(t, ['_id']) ||
                  i.additionalProperties.homeaway ===
                  getProp(t || {}, ['additionalProperties', 'homeaway']),
              );
              if (nonUnique) {
                return acc;
              }
              return [...acc, t];
            }, []);
            await this.props.dispatch(change(FORM_NAME, 'teams', newTeams));
            await this.props.submitMatch();
          }}
        />
      </>
    );
  }
}

export default connect()(withRouter(FutbalNominationsList));
