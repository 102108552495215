import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import sportnetApi from '../../sportnetApi';
import { getProp, setProp, __ } from '../../utilities';

// interfaces
export interface Situation {
  name: string;
  label: string;
  type: string;
  fields: Field[];
}
interface Field {
  type: string; // 'input' | 'select' | 'checkbox';
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  validate?: Array<(value: any) => string | null>;
  items?: Array<
    | {
        value: string;
        label: string;
      }
    | string
  >;
  onChange?: (value: any) => void;
  situations?: Situation[];
  players?: Array<{
    label: string;
    items: Array<{
      value: string;
      label: string;
    }>;
  }>;
}

interface Props {
  headers: Array<{
    label: string;
    width?: number;
  }>;
  appspace?: string;
  items: any;
  maxItems?: number;
  disabled?: boolean;
  list: Array<{
    [key: string]: string | number | JSX.Element;
  }>;
  fields: Field[];
  onChange: (items: any[]) => void;
  isOpen?: boolean;
  activeSituation?: {
    situation: Situation;
    data?: any;
  };
  onClose?: () => void;
  buttonLabel?: string;
  buttons?: JSX.Element[];
  timer?: {
    seconds: number;
    phase: string;
    toggleDate: string;
    ongoing: boolean;
  };
}

interface State {
  visible: boolean;
  userId: string;
  data: {};
  invalidFields: Array<{
    name: string;
    error: string;
  }>;
  activeSituation: Situation | null;
}

const Timer = styled.div`
  width: 100%;
`;
const Controls = styled.div`
  padding: ${rem(10)} 0;
`;

const SituationButton = styled.button`
  background: #5283e7;
  color: white;
  font-weight: bold;
  margin: ${rem(3)};
  border: 0;
  padding: ${rem(5)} ${rem(10)};
  border-radius: ${rem(5)};
  cursor: pointer;
  :hover {
    background: #3c73e4;
  }
`;

class TableForm extends React.PureComponent<Props, State> {
  // private form: HTMLDivElement | null;
  private timer: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      userId: '',
      data: {},
      invalidFields: [],
      activeSituation: null,
    };
    // this.form = null;
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.setState({
        visible: true,
      });
    }
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        e.preventDefault();
        this.setState({
          visible: false,
        });
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    });
    if (this.props.activeSituation) {
      this.setState(
        {
          activeSituation: this.props.activeSituation.situation,
          data: {
            ...this.state.data,
            ...(this.props.activeSituation.data || {}),
          },
        },
        () => {
          if (this.props.timer && this.props.timer.phase) {
            this.setState(
              {
                data: {
                  ...this.state.data,
                  phase: this.props.timer.phase,
                },
              },
              () => {
                this.setTime();
              },
            );
          } else {
            this.setTime();
          }
        },
      );
    } else if (this.props.timer && this.props.timer.phase) {
      this.setState(
        {
          data: {
            ...this.state.data,
            phase: this.props.timer.phase,
          },
        },
        () => {
          this.setTime();
        },
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.isOpen !== nextProps.isOpen &&
      this.state.visible !== nextProps.isOpen
    ) {
      this.setState(
        {
          visible: !!nextProps.isOpen,
        },
        () => {
          this.setTime(nextProps);
        },
      );
    }

    if (
      nextProps.timer &&
      nextProps.timer.phase &&
      getProp(this.state.data, ['phase']) !== nextProps.timer.phase
    ) {
      this.setState({
        data: {
          ...this.state.data,
          phase: nextProps.timer.phase,
        },
      });
    }
    if (nextProps.activeSituation !== this.props.activeSituation) {
      const data = nextProps.activeSituation
        ? nextProps.activeSituation.data || {}
        : {};
      this.setState({
        activeSituation: nextProps.activeSituation
          ? nextProps.activeSituation.situation
          : null,
        data: {
          ...this.state.data,
          ...data,
        },
      });
    }
  }

  setTime = (props = this.props) => {
    if (props.timer) {
      const currentTime = addSeconds(
        new Date(props.timer.toggleDate),
        props.timer.seconds,
      );
      const diff = differenceInSeconds(
        currentTime,
        new Date(props.timer.toggleDate),
      );
      const minutes = String(Math.floor(diff / 60)).padStart(2, '0');
      const seconds = String(diff % 60).padStart(2, '0');
      this.setState({
        data: {
          ...this.state.data,
          eventTime: `${minutes}:${seconds}`,
        },
      });
    }
  };

  getUser = async () => {
    if (this.props.appspace) {
      const user = await sportnetApi.appgrantGetProfileForUser(
        String(process.env.REACT_APP_APP_ID),
        this.props.appspace,
        this.state.userId,
      );
      this.setState(
        {
          data: {
            ...this.state.data,
            sportnetId: user._id,
            displayName: `${user.name} ${user.surname}`,
          },
        },
        () => {
          this.validate(
            {
              name: 'sportnetId',
              type: 'input',
              label: '',
            },
            getProp(this.state, ['data', 'sportnetId']),
          );
          this.validate(
            {
              name: 'displayName',
              type: 'input',
              label: '',
            },
            getProp(this.state, ['data', 'displayName']),
          );
        },
      );
    }
  };
  validate = (field: Field, value: string | boolean) => {
    const invalidField = this.state.invalidFields.find(
      (item) => item.name === field.name,
    );

    if (value && invalidField) {
      let validationFailed: boolean | string | null = false;
      if (!field.validate) {
        validationFailed = true;
      }
      (field.validate || []).forEach((validation) => {
        if (validation(value)) {
          validationFailed = true;
        }
      });
      if (validationFailed) {
        this.setState({
          invalidFields: this.state.invalidFields.filter((item) => {
            return item.name !== field.name;
          }),
        });
      }
    } else if (field.required && !value) {
      this.setState({
        invalidFields: [
          ...this.state.invalidFields,
          {
            name: field.name,
            error: __('Pole je povinné'),
          },
        ],
      });
    } else if (field.validate) {
      let validationPassed = true;
      (field.validate || []).forEach((validation) => {
        if (validation(value)) {
          validationPassed = false;
        }
      });
      if (!validationPassed) {
        this.setState({
          invalidFields: [
            ...this.state.invalidFields,
            {
              name: field.name,
              error: __('Pole je povinné'),
            },
          ],
        });
      }
    }
  };
  renderField = (field: Field) => {
    let formItem;
    switch (field.type) {
      case 'select':
        formItem = this.renderSelectField(field);
        break;
      case 'input':
        formItem = this.renderInputField(field);
        break;
      case 'checkbox':
        formItem = this.renderCheckboxField(field);
        break;
      default:
        break;
    }
    return <React.Fragment key={field.name}>{formItem}</React.Fragment>;
  };
  renderEventsField = (field: Field) => {
    if (!getProp(this.state.data, ['eventTime'])) {
      this.setState({
        data: {
          ...this.state.data,
          eventTime: '00:00',
        },
      });
    }
    const buttonProps: {
      primary?: boolean;
      danger?: boolean;
    } = {};
    if (this.timer) {
      buttonProps.danger = true;
    } else {
      buttonProps.primary = true;
    }
    return (
      <React.Fragment>
        <Timer>
          <FormGroup>
            <Label>{field.label}</Label>
            <Input
              onClick={() => {
                clearInterval(this.timer);
                delete this.timer;
                this.forceUpdate();
              }}
              onChange={(e: React.FormEvent) => {
                const value = (e.target as HTMLSelectElement).value;
                this.setState({
                  data: {
                    ...this.state.data,
                    eventTime: value,
                  },
                });
              }}
              value={getProp(this.state.data, ['eventTime'], '00:00')}
            />
            <Controls>
              <Button
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  if (!this.timer) {
                    this.registerTimer();
                  } else {
                    clearInterval(this.timer);
                    delete this.timer;
                  }
                  this.forceUpdate();
                }}
                {...buttonProps}
              >
                {this.timer
                  ? __('Zastaviť časomieru')
                  : __('Spustiť časomieru')}
              </Button>
            </Controls>
          </FormGroup>
        </Timer>
        {(field.situations || []).map((situation) => {
          return (
            <SituationButton
              key={situation.label}
              onClick={(e: React.FormEvent) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.timer) {
                  clearInterval(this.timer);
                  delete this.timer;
                }
                this.setState({
                  activeSituation: situation,
                  data: {
                    ...this.state.data,
                    eventType: situation.type,
                  },
                });
              }}
            >
              {situation.label}
            </SituationButton>
          );
        })}
      </React.Fragment>
    );
  };
  formatSecondsToString = (seconds: number) => {
    const m = String(Math.floor(seconds / 60)).padStart(2, '0');
    const s = String(seconds % 60).padStart(2, '0');
    return `${m}:${s}`;
  };
  registerTimer = () => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      const currentState = getProp(this.state.data, ['eventTime'], '00:00');
      const arr = currentState.split(':');
      let seconds = Number(arr[0]) * 60 + Number(arr[1]) + 1;
      if (Number.isNaN(seconds)) {
        seconds = 0;
      }

      const formatted = this.formatSecondsToString(seconds);
      this.setState({
        data: {
          ...this.state.data,
          eventTime: formatted,
        },
      });
    }, 1000);
  };

  resetFields = (fields: string[]) => {
    const newState = {
      data: { ...this.state.data },
    };
    fields.forEach((fieldName) => {
      newState.data[fieldName] = '';
    });
  };

  renderCheckboxField = (field: Field) => {
    const invalidField = this.state.invalidFields.find(
      (item) => item.name === field.name,
    );
    return (
      <Col xs={12}>
        <FormField
          label={field.label}
          type="checkbox"
          placeholder={field.placeholder}
          checked={getProp(this.state, ['data', ...field.name.split('.')], '')}
          error={invalidField && invalidField.error}
          onChange={(e: React.FormEvent) => {
            const value = (e.target as HTMLInputElement).checked;
            if (field.onChange) {
              field.onChange(value);
            }
            this.validate(field, value);
            const newState = setProp(
              this.state,
              ['data', ...field.name.split('.')],
              value,
            );
            this.setState(newState);
          }}
        />
      </Col>
    );
  };

  renderInputField = (field: Field) => {
    const invalidField = this.state.invalidFields.find(
      (item) => item.name === field.name,
    );
    return (
      <Col xs={12}>
        <FormField
          label={field.label}
          placeholder={field.placeholder}
          value={getProp(this.state, ['data', ...field.name.split('.')], '')}
          error={invalidField && invalidField.error}
          onChange={(e: React.FormEvent) => {
            const value = (e.target as HTMLSelectElement).value;
            if (field.onChange) {
              field.onChange(value);
            }
            this.validate(field, value);
            const newState = setProp(
              this.state,
              ['data', ...field.name.split('.')],
              value,
            );
            this.setState(newState);
          }}
        />
      </Col>
    );
  };
  renderSelectField = (field: Field) => {
    const invalidField = this.state.invalidFields.find(
      (item) => item.name === field.name,
    );
    return (
      <Col xs={12}>
        <FormField
          type="select"
          label={field.label}
          value={getProp(this.state, ['data', ...field.name.split('.')], '')}
          error={invalidField && invalidField.error}
          onChange={(e: React.FormEvent) => {
            const value = (e.target as HTMLSelectElement).value;
            if (field.onChange) {
              field.onChange(value);
            }
            this.validate(field, value);
            const newState = setProp(
              this.state,
              ['data', ...field.name.split('.')],
              value,
            );
            this.setState(newState);
          }}
        >
          <option hidden />
          {field.items!.map((item) => {
            if (typeof item === 'string') {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            }
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </FormField>
      </Col>
    );
  };

  validateField = (field: any) => {
    const value = getProp(this.state, ['data', ...field.name.split('.')]);
    let validationFailed: boolean | string | null = false;
    (field.validate || []).forEach(
      (validation: (value: string) => string | null) => {
        if (validation(value)) {
          validationFailed = validation(value);
        }
      },
    );
    if ((field.required && !value) || (field.validate && validationFailed)) {
      let error = __('Pole je povinné');
      if (validationFailed) {
        const message = validationFailed;
        if (message) {
          error = message;
        }
      }
      return {
        name: field.name,
        error,
      };
    }
    return null;
  };

  submitForm = () => {
    const invalidFields = [];
    let fieldsToValidate = this.props.fields;
    if (this.state.activeSituation) {
      fieldsToValidate = this.state.activeSituation.fields;
    }

    for (const field of fieldsToValidate) {
      const isInvalid = this.validateField(field);
      if (isInvalid) {
        invalidFields.push(isInvalid);
      }
    }
    if (this.state.activeSituation) {
      const eventTime = getProp(this.state.data, ['eventTime'], '');
      if (
        !eventTime ||
        (eventTime &&
          !eventTime.match(/^[0-9][0-9]:[0-5][0-9]$/g) &&
          !eventTime.match(/^[0-9][0-9][0-9]:[0-5][0-9]$/g))
      ) {
        invalidFields.push({
          name: 'eventTime',
          error: __('Zadajte čas v tvare 00:00'),
        });
      }
    }
    if (invalidFields.length > 0) {
      this.setState({
        invalidFields,
      });
    } else {
      this.props.onChange([...this.props.items, this.state.data]);
      const newState: State = {
        data: {},
        activeSituation: null,
        userId: '',
        visible: false,
        invalidFields: [],
      };
      if (this.state.activeSituation) {
        newState.data = {
          eventTime: getProp(this.state, ['data', 'eventTime']),
        };
      }
      this.setState(newState);
    }
  };

  render() {
    const isEventTableForm = this.props.fields.find(
      (field) => field.type === 'events',
    );
    let fields = this.props.fields;
    if (this.state.activeSituation) {
      const situation = getProp(this.props.fields[0], ['situations'], []).find(
        (s: any) => s.type === getProp(this.state, ['activeSituation', 'type']),
      );
      fields = situation.fields;
    }
    const eventTimeInvalidField = this.state.invalidFields.find(
      (item) => item.name === 'eventTime',
    );

    return (
      <div
      // ref={(e: HTMLDivElement) => {
      //   this.form = e;
      // }}
      >
        {((!this.props.maxItems && this.props.maxItems !== 0) ||
          this.props.maxItems > this.props.items.length) &&
          !this.props.disabled && (
            <React.Fragment>
              {this.props.buttonLabel && (
                <Button
                  onClick={(e: React.FormEvent) => {
                    e.preventDefault();
                    this.setState({
                      visible: true,
                    });
                  }}
                  primary
                >
                  {this.props.buttonLabel}
                </Button>
              )}
              {this.props.buttons &&
                this.props.buttons.map((button, btnidx) => (
                  <React.Fragment key={`${btnidx}`}>
                    &nbsp;
                    {button}
                  </React.Fragment>
                ))}
              <br />
              <br />
            </React.Fragment>
          )}
        <Table>
          <Thead>
            <Tr>
              {this.props.headers.map((header) => {
                return (
                  <Th
                    {...(header.width ? { width: header.width } : {})}
                    key={header.label}
                  >
                    {header.label}
                  </Th>
                );
              })}
              {!this.props.disabled && <Th />}
            </Tr>
          </Thead>
          <Tbody>
            {this.props.list.map((item, index) => {
              return (
                <Tr key={index}>
                  {Object.keys(item).map((key, i) => {
                    return <Td key={i}>{item[key]}</Td>;
                  })}
                  {!this.props.disabled && (
                    <Td>
                      <Button
                        danger
                        onClick={(e: React.FormEvent) => {
                          e.preventDefault();
                          const newItems = this.props.items.slice();
                          delete newItems[index];
                          this.props.onChange(newItems.filter((n: any) => n));
                        }}
                      >
                        {__('Odstrániť')}
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <div style={{ position: 'relative', zIndex: 10 }}>
          <Sidebar
            header={getProp(this.state, ['activeSituation', 'name'])}
            visible={this.state.visible}
            onClose={() => {
              if (this.props.onClose) {
                this.props.onClose();
              }
              if (this.state.activeSituation) {
                this.setState({
                  activeSituation: null,
                  data: {
                    ...this.state.data,
                    eventTime: getProp(
                      this.state,
                      ['data', 'eventTime'],
                      '00:00',
                    ),
                  },
                  visible: false,
                });
              }
              this.setState({
                visible: false,
              });
            }}
          >
            <form onSubmit={this.submitForm}>
              <Segment>
                <Row>
                  {this.state.activeSituation && (
                    <Row>
                      <Col xs={12}>
                        <FormField
                          label={__('Čas')}
                          onChange={(e: React.FormEvent) => {
                            const value = (e.target as HTMLSelectElement).value;
                            if (
                              !value ||
                              (value &&
                                !value.match(/^[0-9][0-9]:[0-5][0-9]$/g) &&
                                !value.match(/^[0-9][0-9][0-9]:[0-5][0-9]$/g))
                            ) {
                              this.setState({
                                invalidFields: [
                                  ...this.state.invalidFields,
                                  {
                                    name: 'eventTime',
                                    error: __('Zadajte čas v tvare 00:00'),
                                  },
                                ],
                              });
                            } else {
                              const invalidFields =
                                this.state.invalidFields.reduce(
                                  (acc: any[], next: any) => {
                                    if (next.name !== 'eventTime') {
                                      return [...acc, next];
                                    }
                                    return acc;
                                  },
                                  [],
                                );
                              this.setState({
                                invalidFields,
                              });
                            }
                            this.setState({
                              data: {
                                ...this.state.data,
                                eventTime: value,
                              },
                            });
                          }}
                          error={
                            eventTimeInvalidField && eventTimeInvalidField.error
                          }
                          value={getProp(this.state.data, ['eventTime'], '')}
                        />
                      </Col>
                    </Row>
                  )}
                  {fields.map((field: Field) => this.renderField(field))}
                  {(!isEventTableForm ||
                    (!!isEventTableForm && this.state.activeSituation)) && (
                    <Col xs={12}>
                      <Button
                        primary
                        onClick={(e: React.FormEvent) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.submitForm();
                        }}
                      >
                        {__('Pridať do zoznamu')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Segment>
            </form>
          </Sidebar>
        </div>
      </div>
    );
  }
}

export default TableForm;
