import * as React from 'react';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { rem } from 'polished';
import TheSelect from '@sportnet/ui/TheSelect';
import api from '../../api';
import sportnetApi from '../../sportnetApi';
import { __ } from '../../utilities';
import { debounce } from '@sportnet/ui/utilities';
import { formatUserName } from 'sportnet-utilities';

interface IOwnProps {
  appSpace: string;
  teamId: string;
  competitionId: string;
  partId: string;
  types: Array<{ label: string; _id: string }>;
  value?: any;
  onChange: (
    value?: { sportnetId: string; displayName: string } | null,
    tags?: { [key: string]: IOption | null },
  ) => void;
  error?: string;
  disabled?: boolean;
  isClubManager?: boolean;
}

const CrewSelectionInput: React.FC<IOwnProps> = ({
  types,
  value,
  onChange,
  error,
  disabled,
  appSpace,
  teamId,
  competitionId,
  partId,
  isClubManager,
}) => {
  const [selectedType, setSelectedType] = React.useState<IOption | null>(null);
  const [users, setUsers] = React.useState<{
    [key: string]: Array<{
      sportnetId: string;
      displayName: string;
      familyName?: string;
      birthyear?: string;
    }>;
  }>({});
  const [isFetchingUsers, setIsFetchingUsers] = React.useState(false);

  const [q, setQ] = React.useState('');

  React.useEffect(() => {
    if (
      typeof value === 'string' &&
      value.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)
    ) {
      setIsFetchingUsers(true);
      sportnetApi
        .getPublicUserProfile(value)
        .then((res) => {
          setUsers((prevState) => ({
            ...prevState,
            [value]: [
              { sportnetId: res._id || '', displayName: formatUserName(res) },
            ],
          }));
        })
        .catch((e) => {
          console.error(e);
          alert(__('Nepodarilo sa nájsť používateľa podľa Sportnet ID.'));
        })
        .finally(() => {
          setIsFetchingUsers(false);
        });
    }
  }, [value]);

  const fetchUsers = React.useMemo(() => {
    return debounce(async (val: string) => {
      if (val.length > 3) {
        setUsers((prevState) => ({ ...prevState, [val]: [] }));
        setIsFetchingUsers(true);
        let competenceTypes = [];
        if (selectedType) {
          const type = types.find((i) => i._id === selectedType.value);
          competenceTypes = (type as any).competenceTypes || [];
        }
        try {
          let res: any = { sportExperts: [] };
          if (isClubManager) {
            res = await api.clubManagerGetOrganizationSportExperts(
              competitionId,
              partId,
              teamId,
              {
                q: val,
                ...(competenceTypes.length ? { competenceTypes } : {}),
              },
            );
          } else {
            res = await api.adminGetOrganizationSportExperts(appSpace, {
              teamId,
              q: val,
              ...(competenceTypes.length ? { competenceTypes } : {}),
            });
          }
          setUsers((prevState) => ({
            ...prevState,
            [val]: res.sportExperts || [],
          }));
        } catch (e: any) {
          console.error(e);
          alert(__('Nepodarilo sa získať zoznam športových odborníkov'));
        } finally {
          setIsFetchingUsers(false);
        }
      }
    }, 300);
  }, [
    appSpace,
    types,
    teamId,
    competitionId,
    partId,
    isClubManager,
    selectedType,
  ]);

  React.useEffect(() => {
    fetchUsers(q);
  }, [q, fetchUsers]);

  const setType = (i: IOption) => {
    setUsers({});
    setSelectedType(i);
    onChange(null);
  };

  let formattedValue = value;
  if (formattedValue && typeof formattedValue === 'string') {
    const item = (users[value || q] || []).find(
      (u) => u.sportnetId === formattedValue,
    );
    if (item) {
      formattedValue = {
        label: item.displayName,
        value: item.sportnetId,
      };
    }
  }

  return (
    <TheSelect
      filterOptions={(options, inputValue) => {
        return options;
      }}
      disabled={disabled}
      error={error}
      loading={isFetchingUsers}
      options={
        (users[value || q] || []).length && (selectedType || q || value)
          ? (users[value || q] || []).map((u) => ({
              label: u.displayName,
              value: u.sportnetId,
              user: true,
            }))
          : types.map((i) => ({
              label: i.label,
              value: i._id,
              group: true,
            }))
      }
      tags={selectedType ? [selectedType] : []}
      onChangeTags={() => {
        onChange(null);
        setSelectedType(null);
      }}
      onBlur={(ref) => {
        if (ref && ref.current && selectedType && !value) {
          setTimeout(() => {
            ref!.current!.focus();
          });
        }
      }}
      renderLabel={(i) => {
        if (i.group) return i.label;
        const user = users[value || q].find((u) => u.sportnetId === i.value);
        if (user) {
          return (
            <div>
              <div>{user.displayName}</div>
              {user.familyName && (
                <div style={{ fontSize: rem(10) }}>
                  {__('rod.')} {user.familyName}
                </div>
              )}
              {user.birthyear && (
                <div style={{ fontSize: rem(10) }}>
                  {__('nar.')} {user.birthyear}
                </div>
              )}
              {user.sportnetId && (
                <div style={{ fontSize: rem(10) }}>
                  {__('Sportnet Id:')} {user.sportnetId}
                </div>
              )}
            </div>
          );
        }
        return i.label;
      }}
      placeholder={__(
        'Vyberte typ príslušnosti alebo začnite vyhľadávať podľa mena',
      )}
      value={formattedValue}
      onChange={(i: any) => {
        !i || i.user ? onChange(i, { type: selectedType }) : setType(i);
      }}
      onChangeInput={setQ}
    />
  );
};

export default CrewSelectionInput;
