import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { change, isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import Form from './form';

export const FORM_NAME = 'NOMINATION_FORM';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

export interface NominationItem {
  sportnetUser?: {
    _id?: string;
    name?: string;
  };
  additionalData?: any;
  teamId?: string;
  closed?: boolean;
}

export interface User {
  sportnetId: string;
  displayName: string;
}

const mapStateToProps = (state: State) => ({
  formIsSubmitting: isSubmitting(FORM_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouteComponentProps<{
  matchId: string;
  competitionId: string;
  partId: string;
  teamId: string;
}> &
  OwnProps &
  IMapStateToProps;

class Nomination extends React.PureComponent<
  Props,
  {
    experts: User[];
    nomination: any;
    isFetching: boolean;
    settings: {};
    competitionId: null | string;
    partId: null | string;
    rules?: null | {
      sport_sector?: string;
    };
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      experts: [],
      settings: {},
      nomination: null,
      isFetching: false,
      competitionId: null,
      partId: null,
      rules: null,
    };
  }
  async componentDidMount() {
    const {
      match: {
        params: { competitionId, partId, matchId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isFetching: true,
      });
      const { competition, competitionPart, rules } =
        await api.matchManagerGetMatchById(competitionId, partId, matchId);
      const { settings } = await api.matchManagerGetCompetitionPartById(
        competition._id,
        competitionPart._id,
      );
      const nomination = await api.matchManagerGetMatchNominations(
        competitionId,
        partId,
        matchId,
        teamId,
      );

      const experts = await api.matchManagerGetOrganizationSportExperts(
        competitionId,
        partId,
        teamId,
      );
      this.setState({
        experts: experts.sportExperts || [],
        nomination,
        competitionId: competition._id,
        partId: competitionPart._id,
        isFetching: false,
        settings: settings || {},
        rules,
      });
    } catch (e: any) {
      this.setState({
        isFetching: false,
      });
      alert(__('Nepodarilo sa získať zoznam športovcov'));
    }
  }
  submit = async (formData: any) => {
    const {
      match: {
        params: { competitionId, partId, matchId, teamId },
      },
    } = this.props;
    try {
      await api.matchManagerPutMatchNominations(
        competitionId,
        partId,
        matchId,
        teamId,
        {},
        { ...formData, teamId, crew: formData.crew || {} },
      );
    } catch (e: any) {
      alert(__('Nepodarilo sa uložiť nomináciu'));
      console.error(e);
    } finally {
      this.props.history.push(
        `/match-manager/competitions/${competitionId}/parts/${partId}/matches/${matchId}`,
      );
    }
  };
  render() {
    const {
      experts,
      nomination,
      isFetching,
      settings,
      competitionId,
      partId,
      rules,
    } = this.state;
    const { formIsSubmitting } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/match-manager/competitions/${competitionId}/parts/${partId}/matches/${this.props.match.params.matchId}#NOMINATIONS`}
            />
            <HeaderBar.Header>{__('Nominácia do stretnutia')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          nomination && !nomination.closed ? (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  primary
                  loading={formIsSubmitting}
                  onClick={async () => {
                    await this.props.dispatch(
                      change(FORM_NAME, 'closed', false),
                    );
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                >
                  {__('Uložiť')}
                </Button>
                &nbsp;
                <Button
                  success
                  loading={formIsSubmitting}
                  onClick={async () => {
                    await this.props.dispatch(
                      change(FORM_NAME, 'closed', true),
                    );
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                >
                  {__('Uzavrieť')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          ) : (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  primary
                  loading={formIsSubmitting}
                  onClick={async () => {
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                >
                  {__('Uložiť')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        {/* <AppContext
          title={__('Nominácia do stretnutia')}
        /> */}
        <Segment>
          {nomination && (
            <Form
              handleSubmit={this.submit}
              experts={experts}
              data={nomination}
              settings={settings}
              competitionId={competitionId}
              partId={partId}
              rules={rules}
              isMatchManager
            />
          )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Nomination));
