import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { commit, initialize, isCommiting } from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { State } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { setBreadcrumbs } from '../../App/actions';
import { getAgeCategories } from './actions';
import { AgeCategory, LIST_NAME } from './definitions';
import { ageCategoriesCurrentListSelector } from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterParameters = RouteComponentProps<{ appspace: string }>;

const mapStateToProps = (state: State, props: RouterParameters) => ({
  items: ageCategoriesCurrentListSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouterParameters & OwnProps & IMapStateToProps;

class AgeCategoriesList extends React.PureComponent<Props> {
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    dispatch(setBreadcrumbs([__('Číselník vekových kategórií')]));
    try {
      await dispatch(
        commit.action({
          listName: LIST_NAME,
          load: async () => {
            const {
              entities: { ageCategories },
            } = await dispatch(getAgeCategories.action({ appspace }));
            const items = Object.keys(ageCategories).map((key) => {
              return ageCategories[key];
            });
            return {
              total: items.length,
              results: items.map((ageCategory) => {
                return ageCategory._id;
              }),
              nextOffset: null,
            };
          },
        }),
      );
    } catch (e: any) {
      console.error(e);
    }
  }
  render() {
    const {
      items,
      isFetching,
      match: {
        params: { appspace },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>
              {__('Zoznam vekových kategórií')}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Link to={`/admin/${appspace}/codelists/age-categories/new`}>
                <Button primary>{__('Pridať vekovú kategóriu')}</Button>
              </Link>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <>
          {!isFetching && items.length === 0 ? (
            <NotFound
              icon="search"
              title={__('Momentálne neexistuje žiadna veková kategória')}
            />
          ) : (
            <Segment>
              <Segment raised>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__('Označenie')}</Th>
                      {/* <Th>{__('Aktuálna')}</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.map((item: AgeCategory) => {
                      return (
                        <Tr
                          onClick={() => {
                            this.props.history.push(
                              `/admin/${appspace}/codelists/age-categories/${item._id}`,
                            );
                          }}
                          key={item._id}
                        >
                          <Td>{item.name}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Segment>
            </Segment>
          )}
        </>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(AgeCategoriesList));
