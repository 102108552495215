import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import {
  activeAppspaceSelector,
  codelistSelector,
} from '../../../../App/selectors';
import { State } from '../../../../../rootReducer';
import { required, __ } from '../../../../../utilities';

const mapStateToProps = (state: State) => {
  return {
    sportsGroundTypeCodelist: codelistSelector('sports-ground-type')(state),
    appSpace: activeAppspaceSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

class SwimmingSportGroundsForm extends React.PureComponent<IMapStateToProps> {
  render() {
    const { sportsGroundTypeCodelist, appSpace } = this.props;
    const accessToken =
      Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';
    return (
      <Row>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Označenie športoviska')}
            name="name"
            component={FormField}
            validate={[required]}
            required
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Typ športoviska')}
            name="sportGroundType"
            type="theselectsimple"
            options={sportsGroundTypeCodelist}
            component={FormField}
            validate={[required]}
            required
          />
        </Col>
        <Col xs={12}>
          <Field
            label={__('Fotka')}
            name="image"
            type="image"
            auth={{
              appId: process.env.REACT_APP_APP_ID,
              appSpace: appSpace && appSpace.app_space,
              token: accessToken,
            }}
            component={FormField}
          />
        </Col>
        <Col xs={12}>
          <Field
            label={__('Krátky popis')}
            name="perex"
            type="textarea"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Celková kapacita')}
            name="additionalData.totalCapacity"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Kapacita na sedenie')}
            name="additionalData.seatingCapacity"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Kapacita sektora hostí')}
            name="additionalData.guestCapacity"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Počet VIP sedadiel')}
            name="additionalData.vipSeatingCapacity"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Dĺžka (m)')}
            name="additionalData.length"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Šírka (m)')}
            name="additionalData.width"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Výška')}
            name="additionalData.height"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Iné rozmery')}
            name="additionalData.otherDimensions"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Osvetlenie (lux)')}
            name="additionalData.lighting"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Dátum poslednej revízie osvetlenia')}
            name="additionalData.lightingAuditDate"
            type="date"
            component={FormField}
            format={null}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Dátum vykonania revizie el. zariadení')}
            name="additionalData.electricalDevicesAuditDate"
            type="date"
            component={FormField}
            format={null}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Dátum vydania bezpečnostného certifikátu')}
            name="additionalData.securityCertificateDate"
            type="date"
            component={FormField}
            format={null}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Manažer')}
            name="additionalData.manager"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Kontakt na manažéra')}
            name="additionalData.managerContact"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Zemepisná šírka')}
            name="additionalData.latitude"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Zemepisná dĺžka')}
            name="additionalData.longitude"
            type="text"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6} l={4} xl={3}>
          <Field
            label={__('Typ trate')}
            name="additionalData.type"
            type="text"
            component={FormField}
          />
        </Col>
      </Row>
    );
  }
}

export default connect(mapStateToProps)(SwimmingSportGroundsForm);
