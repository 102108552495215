import { IAppPublic } from '@sportnet/ui/Authorization/types';
import { AnyAction } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { getProp, setProp } from '../../utilities';
import { Season } from '../Codelists/Seasons/definitions';
import { Competition, CompetitionPart } from '../Competitions/definitions';
import { Match } from '../Matches/definitions';
import { IRecord } from '../Records/definitions';
import { SportObject } from '../SportObjects/definitions';
import { Team } from '../Teams/definitions';
import { AgeCategory } from './../Codelists/AgeCategories/definitions';
import * as actions from './actions';
import { ApplicationInfo, Appspace, User } from './types';
import { Squad } from '../Teams/Squads/definitions';
import { Members } from '../Teams/Members/definitions';

export type ApplicationState = {
  readonly applicationInfo: ApplicationInfo | null;
  readonly appspaces: Appspace[];
  readonly authUser: User | null;
  readonly activeAppspace: string | null;
  readonly breadcrumbs: Array<JSX.Element | string>;
  readonly codelists: {
    [codelistId: string]: Array<{
      label: string;
      value: string;
    }>;
  };
  apps: IAppPublic[];
};

const INITIAL_STATE: ApplicationState = {
  applicationInfo: null,
  appspaces: [],
  authUser: null,
  activeAppspace: null,
  breadcrumbs: [],
  codelists: {},
  apps: [],
};

export const applicationReducer = reducerWithInitialState(INITIAL_STATE)
  .case(actions.setAppspaces, (state, appspaces) => ({
    ...state,
    appspaces,
  }))
  .case(actions.setActiveAppspace, (state, activeAppspace) => ({
    ...state,
    activeAppspace,
  }))
  .case(
    actions.setApps,
    (state, apps): ApplicationState => ({
      ...state,
      apps,
    }),
  )
  .case(actions.setAuthUser, (state, authorizationResponse) => ({
    ...state,
    authUser: authorizationResponse.user,
  }))
  .case(actions.setApplicationInfo, (state, applicationInfo) => ({
    ...state,
    applicationInfo,
  }))
  .case(actions.removeActiveAppspace, (state) => ({
    ...state,
    activeAppspace: null,
  }))
  .case(actions.setBreadcrumbs, (state, breadcrumbs) => ({
    ...state,
    breadcrumbs,
  }))
  .case(actions.getCodelist.async.done, (state, response) => ({
    ...state,
    codelists: {
      ...state.codelists,
      [response.result.codelist]: response.result.items,
    },
  }))
  .case(actions.getCodelistSectors.async.done, (state, response) => ({
    ...setProp(
      state,
      ['codelists', 'sectors', response.params.codelistItem],
      response.result.items,
    ),
  }))
  .case(actions.getCodelistSectorDisciplines.async.done, (state, response) => ({
    ...setProp(
      state,
      ['codelists', 'disciplines', response.params.sectorItem],
      response.result.items,
    ),
  }));

export const errorReducer = (state = { message: null }, action: AnyAction) => {
  // if (action.payload && action.payload.error) {
  //   return {
  //     ...state,
  //     message:
  //       getProp(action.payload.error, ['details', 'description']) ||
  //       action.payload.error.message,
  //   };
  // }
  // if (action.type === 'APPLICATION/CLEAR_ERROR') {
  //   return {
  //     ...state,
  //     message: null,
  //   };
  // }
  return state;
};

export const entitiesReducer = (
  state: {
    competitions: { [key: string]: Competition };
    seasons: { [key: string]: Season };
    ageCategories: { [key: string]: AgeCategory };
    teams: { [key: string]: Team };
    squadsByTeamId: { [key: string]: Squad };
    membersByTeamId: { [key: string]: Members };
    sportObjects: { [key: string]: SportObject };
    competitionPartsByCompetitionId: { [key: string]: CompetitionPart };
    matches: { [key: string]: Match };
    records: { [key: string]: IRecord };
  } = {
    competitions: {},
    seasons: {},
    ageCategories: {},
    teams: {},
    squadsByTeamId: {},
    membersByTeamId: {},
    sportObjects: {},
    competitionPartsByCompetitionId: {},
    matches: {},
    records: {},
  },
  action: AnyAction,
) => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return {
      ...state,
      ...Object.keys(action.payload.result.entities).reduce((acc, entity) => {
        return {
          ...acc,
          [entity]: {
            ...getProp(state, [entity], {}),
            ...action.payload.result.entities[entity],
          },
        };
      }, {}),
    };
  }
  return state;
};
