import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Checkbox from '@sportnet/ui/Checkbox';
import FormField from '@sportnet/ui/FormField';
import Input from '@sportnet/ui/Input';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import { rem } from 'polished';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import {
  arrayPush,
  change,
  formValueSelector,
  reduxForm,
  submit,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import UserCreationForm from '../../Competitions/Squads/userCreationForm';
import Box from '../../../components/Box';
// import TableForm from '../../../components/TableForm';
import { State } from '../../../rootReducer';
import { getProp, setProp, __ } from '../../../utilities';
import { User } from '../index';
import CrewSelectionInput from '../crewSelectionInput';
import styled from 'styled-components';
import sportnetApi from '../../../sportnetApi';

const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

export const FORM_NAME = 'NOMINATION_FORM';
const USER_CREATION_FORM_NAME = 'USER_CREATION_FORM';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  sportSector: string;
  athletes: User[];
  experts: User[];
  competitionId: string;
  partId: string;
  handleSubmit: any;
  closed: boolean;
  isClubManager: boolean;
  isMatchManager: boolean;
  settings: {
    players: {
      field: {
        min: number;
        max: number;
      };
      substitutes: {
        max: number;
      };
    };
  };
}

const mapStateToProps = (state: State) => {
  const selector = formValueSelector(FORM_NAME);
  return {
    reduxAthletes: selector(state, 'athletes'),
    crew: selector(state, 'crew') || [],
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
  teamId: string;
  id: string;
  competitionId: string;
  partId: string;
}> &
  OwnProps &
  IMapStateToProps;

class WaterPoloNomination extends React.PureComponent<Props> {
  state = {
    selectedExpert: null,
    selectedExpertPosition: null,
    expertNominationModalOpened: false,
    isEditingExpert: false,
    isFetchingAthletes: false,
    experts: [],
    playerNominationModalOpened: false,
    crewCodelist: [],
    expertCodelist: [],
    userCreationModalVisible: false,
    isSubmittingUserCreation: false,
  };

  toggleExpertNominationModal = () => {
    this.setState({
      expertNominationModalOpened: !this.state.expertNominationModalOpened,
      selectedExpert: null,
      selectedExpertPosition: null,
      isEditingExpert: false,
    });
  };

  submitExpertSelectionForm = async () => {
    const selectedExpert: any = this.state.selectedExpert;
    const selectedPosition: any = this.state.selectedExpertPosition;
    if (selectedExpert && selectedPosition) {
      if (this.state.isEditingExpert) {
        const modifiedCrew = this.props.crew.filter(
          (i: any) => i.sportnetUser._id !== selectedExpert!.value,
        );
        this.props.dispatch(change(FORM_NAME, 'crew', modifiedCrew));
      }
      this.props.dispatch(
        arrayPush(FORM_NAME, 'crew', {
          sportnetUser: {
            citizenship: selectedExpert!.citizenship || null,
            name: selectedExpert!.label,
            _id: selectedExpert!.value,
          },
          position: selectedPosition!.value,
        }),
      );
      this.toggleExpertNominationModal();
    }
  };

  componentDidMount() {
    this.loadCrewCodelist();
  }

  renderExpertCustomLabel = (i: { value: string }) => {
    const expert = (this.state.experts || []).find(
      (a: any) => a.sportnetId === i.value,
    ) as any;

    if (expert) {
      return (
        <div>
          <div>{expert.displayName}</div>
          {expert.birthyear && (
            <div style={{ fontSize: rem(10) }}>
              {__('nar.')} {expert.birthyear}
            </div>
          )}
        </div>
      );
    }
    return i;
  };

  loadCrewCodelist = async () => {
    const { items } = await api.getSettingBySportSector(
      'sport_sector_crew',
      this.props.sportSector,
    );
    const { codelist: expertCodelist } = await sportnetApi.getCodelist(
      'sport-expert-competence-type',
    );
    this.setState({
      crewCodelist: items,
      expertCodelist,
    });
  };

  getRulesDescription = () => {
    const { settings } = this.props;
    return `Počet hráčov v základnej zostave nesmie byť nižší ako ${settings.players.field.min} a zároveň vyšší ako ${settings.players.field.max}. Počet náhradníkov nesmie byť vyšší než ${settings.players.substitutes.max}`;
  };

  loadTeamAthletes = async () => {
    const {
      dispatch,
      match: {
        params: { appspace, teamId },
      },
      competitionId,
      partId,
      isClubManager,
      isMatchManager,
    } = this.props;
    this.setState({
      isFetchingAthletes: true,
    });
    let team = null;
    if (isClubManager) {
      team = await api.clubManagerGetCompetitionPartTeamSquad(
        competitionId,
        partId,
        teamId,
      );
    } else if (isMatchManager) {
      //
    } else {
      team = await api.adminGetCompetitionPartTeamSquad(
        appspace,
        competitionId,
        partId,
        teamId,
      );
    }
    if (team) {
      await dispatch(
        change(
          FORM_NAME,
          'athletes',
          getProp(team, ['squad', 'athletes'], []).reduce(
            (acc: any[], a: any) => {
              const playerData = this.props.reduxAthletes[a.sportnetUser._id];
              return {
                ...acc,
                [a.sportnetUser._id]: {
                  ...a,
                  additionalData: playerData
                    ? {
                        ...(a.additionalData || {}),
                        ...(playerData.additionalData || {}),
                      }
                    : a.additionalData || {},
                },
              };
            },
            {},
          ),
        ),
      );
      await dispatch(
        change(FORM_NAME, 'crew', getProp(team, ['squad', 'crew'], [])),
      );
    }
    this.setState({
      isFetchingAthletes: false,
    });
  };

  toggleUserCreationForm = () =>
    this.setState({
      userCreationModalVisible: !this.state.userCreationModalVisible,
    });

  submitUserCreationForm = async (data: {
    name: string;
    surname: string;
    competenceType?: string;
    dateFrom?: string;
  }) => {
    const {
      match: {
        params: { id, appspace, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isSubmittingUserCreation: true,
      });
      await api.externalRegistrationIntoNomination(
        appspace,
        id,
        teamId,
        undefined,
        data,
      );
      this.toggleUserCreationForm();
      window.location.reload();
    } catch (e: any) {
      alert(__('Nepodarilo sa vytvoriť nového používateľa'));
    } finally {
      this.setState({
        isSubmittingUserCreation: false,
      });
    }
  };

  render() {
    const {
      reduxAthletes = {},
      settings,
      closed,
      isMatchManager,
      match: {
        params: { appspace, teamId, competitionId, partId },
      },
    } = this.props;

    let maxSubstitutesCountReached = false;
    const substituesCount = Object.keys(reduxAthletes || {}).reduce(
      (acc, k) => {
        if (
          getProp(reduxAthletes[k], ['additionalData', 'substitute'], false)
        ) {
          return (acc += 1);
        }
        return acc;
      },
      0,
    );
    if (
      substituesCount >= getProp(settings, ['players', 'substitutes', 'max'], 0)
    ) {
      maxSubstitutesCountReached = true;
    }

    return (
      <React.Fragment>
        <Message primary title={__('Pravidlá nominácie')}>
          {this.getRulesDescription()}
        </Message>
        <Box title={__('Zoznam hráčov')}>
          {!closed && !isMatchManager && (
            <>
              <Button
                key="loadFromTeam"
                type="button"
                primary
                loading={this.state.isFetchingAthletes}
                onClick={(e: any) => {
                  e.preventDefault();
                  this.loadTeamAthletes();
                }}
              >
                {__('Načítať zo súpisky')}
              </Button>
              <br />
              <br />
            </>
          )}
          <BasicTable
            columns={[
              {
                header: __('Náhradník'),
                width: 100,
              },
              {
                header: __('Číslo čiapky'),
              },
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: '',
              },
            ]}
            rowKey="userId"
            rows={Object.keys(reduxAthletes || {}).map((k) => ({
              ...reduxAthletes[k],
              userId: reduxAthletes[k].sportnetUser._id,
            }))}
            renderRow={(athlete) => {
              let checkbox = (
                <Checkbox
                  key={`substitute_checkbox_${athlete.sportnetUser._id}`}
                  disabled={
                    isMatchManager ||
                    closed ||
                    (!getProp(
                      reduxAthletes[athlete.sportnetUser._id],
                      ['additionalData', 'substitute'],
                      false,
                    ) &&
                      maxSubstitutesCountReached)
                  }
                  checked={getProp(
                    reduxAthletes[athlete.sportnetUser._id],
                    ['additionalData', 'substitute'],
                    false,
                  )}
                  value={reduxAthletes[athlete.sportnetUser._id]}
                  onChange={() => {
                    const value = !getProp(
                      reduxAthletes[athlete.sportnetUser._id],
                      ['additionalData', 'substitute'],
                      false,
                    );
                    const modifiedAthletes = { ...reduxAthletes };
                    modifiedAthletes[athlete.sportnetUser._id] = setProp(
                      modifiedAthletes[athlete.sportnetUser._id],
                      ['additionalData', 'substitute'],
                      value,
                    );
                    this.props.dispatch(
                      change(FORM_NAME, 'athletes', modifiedAthletes),
                    );
                  }}
                />
              );
              if (
                !getProp(
                  reduxAthletes[athlete.sportnetUser._id],
                  ['additionalData', 'substitute'],
                  false,
                ) &&
                maxSubstitutesCountReached
              ) {
                checkbox = <div />;
              }
              return [
                checkbox,
                <Input
                  disabled={isMatchManager || closed}
                  type="number"
                  onChange={(e: any) => {
                    this.props.dispatch(
                      change(
                        FORM_NAME,
                        `athletes.${athlete.sportnetUser._id}.additionalData.nr`,
                        e.target.value,
                      ),
                    );
                  }}
                  key={`athlete.additionalData.nr.${athlete.sportnetUser._id}`}
                  value={getProp(athlete, ['additionalData', 'nr'])}
                />,
                athlete.sportnetUser.name,
                !closed && !isMatchManager ? (
                  <Button
                    key={`remove_athlete.${athlete.sportnetUser._id}`}
                    danger
                    onClick={() => {
                      const modifiedAthletes = { ...reduxAthletes };
                      delete modifiedAthletes[athlete.sportnetUser._id];
                      this.props.dispatch(
                        change(FORM_NAME, 'athletes', modifiedAthletes),
                      );
                    }}
                  >
                    {__('Odstrániť')}
                  </Button>
                ) : (
                  <div />
                ),
              ];
            }}
          />
        </Box>
        <Box title={__('Realizačný tým')}>
          <Modal
            handleClose={this.toggleUserCreationForm}
            isOpen={this.state.userCreationModalVisible}
          >
            <ModalContent>
              <UserCreationForm
                form={USER_CREATION_FORM_NAME}
                onSubmit={this.submitUserCreationForm}
                competenceTypes={this.state.expertCodelist}
              />
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={this.toggleUserCreationForm}>
                  {__('Zavrieť')}
                </Button>
                &nbsp;
                <Button
                  primary
                  loading={this.state.isSubmittingUserCreation}
                  onClick={() =>
                    this.props.dispatch(submit(USER_CREATION_FORM_NAME))
                  }
                >
                  {__('Uložiť')}
                </Button>
              </div>
            </ModalActions>
          </Modal>
          <Modal
            handleClose={this.toggleExpertNominationModal}
            isOpen={this.state.expertNominationModalOpened}
          >
            <ModalContent>
              <FormGroup>
                <Label>{__('Výber osoby (min. 4 znaky)')}</Label>
                <RelativeWrapper>
                  <CrewSelectionInput
                    disabled={this.state.isEditingExpert}
                    value={this.state.selectedExpert}
                    appSpace={appspace}
                    teamId={teamId}
                    competitionId={competitionId}
                    partId={partId}
                    types={this.state.crewCodelist}
                    onChange={(i, tags) => {
                      this.setState({
                        selectedExpert: i,
                        ...(getProp(tags || {}, ['type'])
                          ? { selectedExpertPosition: tags!.type }
                          : {}),
                      });
                    }}
                  />
                </RelativeWrapper>
              </FormGroup>
              <FormField
                type="theselect"
                label={__('Pozícia v realizačnom tíme')}
                value={this.state.selectedExpertPosition}
                onChange={(e: any) => {
                  this.setState({
                    selectedExpertPosition: e,
                  });
                }}
                options={(
                  this.state.crewCodelist as Array<{
                    _id: string;
                    label: string;
                  }>
                ).map((i) => ({
                  label: i.label,
                  value: i._id,
                }))}
              />
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={this.toggleExpertNominationModal}>
                  {__('Zavrieť')}
                </Button>
                &nbsp;
                <Button primary onClick={this.submitExpertSelectionForm}>
                  {__('Uložiť')}
                </Button>
                {this.state.isEditingExpert && (
                  <>
                    &nbsp;
                    <Button
                      danger
                      onClick={() => {
                        const selectedExpert: any = this.state.selectedExpert;
                        const modifiedCrew = this.props.crew.filter(
                          (i: any) =>
                            i.sportnetUser._id !== selectedExpert.value,
                        );
                        this.props.dispatch(
                          change(FORM_NAME, 'crew', modifiedCrew),
                        );
                        this.toggleExpertNominationModal();
                      }}
                    >
                      {__('Odstrániť')}
                    </Button>
                  </>
                )}
              </div>
            </ModalActions>
          </Modal>
          {!isMatchManager && !closed && (
            <>
              <Button primary onClick={this.toggleExpertNominationModal}>
                {__('Vybrať osobu')}
              </Button>
              <br />
              <br />
            </>
          )}
          <BasicTable
            renderRow={(i) => {
              const position: any = this.state.crewCodelist.find(
                (c: any) => c._id === i.position,
              );
              return [
                position ? position.label : i.position,
                i.sportnetUser.name,
              ];
            }}
            {...(!isMatchManager &&
              !closed && {
                onClickRow: (i: any) => {
                  const position: any = this.state.crewCodelist.find(
                    (c: any) => c._id === i.position,
                  );
                  this.setState({
                    selectedExpert: {
                      label: i.sportnetUser.name,
                      value: i.sportnetUser._id,
                    },
                    selectedExpertPosition: position || null,
                    expertNominationModalOpened: true,
                    isEditingExpert: true,
                  });
                },
              })}
            rowKey="rowKey"
            rows={(this.props.crew || []).map((i: any) => ({
              ...i,
              rowKey: `${i.sportnetUser._id}|${i.position}`,
            }))}
            columns={[
              { header: __('Pozícia') },
              { header: __('Meno a priezvisko') },
            ]}
          />
        </Box>
      </React.Fragment>
    );
  }
}

export default reduxForm<OwnProps, any>({
  onSubmit: (values: any, dispatch: any, props: Props) => {
    const { settings } = props;

    // kontrola poctu hracov

    const fieldPlayers = Object.keys(values.athletes).filter(
      (k) => !values.athletes[k].additionalData.substitute,
    );
    if (
      values.closed &&
      (fieldPlayers.length > settings.players.field.max ||
        fieldPlayers.length < settings.players.field.min)
    ) {
      alert(
        `Minimálny počet nominovaných hráčov základnej zostavy je ${settings.players.field.min} a maximálny počet je ${settings.players.field.max}`,
      );
      return;
    }
    props.handleSubmit({
      ...values,
      athletes: Object.keys(values.athletes).reduce((acc: any[], k: any) => {
        return [...acc, values.athletes[k]];
      }, []),
    });
  },
})(connect(mapStateToProps)(withRouter(WaterPoloNomination)));
