import { IAppPublic } from '@sportnet/ui/Authorization/types';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import sportnetApi from '../../sportnetApi';
import { ApplicationState } from './reducer';
import { ApplicationInfo, Appspace, AuthorizationResponse } from './types';

const create = actionCreatorFactory('APPLICATION');
const createAsync = asyncFactory<ApplicationState>(create);

export const setAppspaces = create<Appspace[]>('SET_APPSPACES');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const setApps = create<IAppPublic[]>('SET_APPS');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const setAuthUser = create<AuthorizationResponse>('SET_AUTH_USER');

export const setApplicationInfo = create<ApplicationInfo>(
  'SET_APPLICATION_INFO',
);

export const setBreadcrumbs =
  create<Array<JSX.Element | string>>('SET_BREADCRUMBS');

export const clearError = create('CLEAR_ERROR');

export const getCodelist = createAsync<
  {
    codelist: string;
  },
  any
>('GET_CODELIST', async (parameters) => {
  const { codelist } = parameters;
  const response = await sportnetApi.getCodelist(codelist);
  return {
    codelist,
    items: response.codelist,
  };
});

export const getCodelistSectors = createAsync<
  {
    codelist: string;
    codelistItem: string;
  },
  any
>('GET_CODELIST_SECTORS', async (parameters) => {
  const { codelist, codelistItem } = parameters;
  const response = await sportnetApi.getCodelistSector(codelist, codelistItem);
  return {
    codelist,
    codelistItem,
    items: response.codelist,
  };
});

export const getCodelistSectorDisciplines = createAsync<
  {
    codelist: string;
    codelistItem: string;
    sectorItem: string;
  },
  any
>('GET_CODELIST_SECTOR_DISCIPLINES', async (parameters) => {
  const { codelist, codelistItem, sectorItem } = parameters;
  const response = await sportnetApi.getCodelistSectorDiscipline(
    codelist,
    codelistItem,
    sectorItem,
  );
  return {
    codelist,
    codelistItem,
    sectorItem,
    items: response.codelist,
  };
});
