import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import api from '../../api';
import {
  Player,
  RaceResult,
  Result,
  TeamRaceResult,
} from '../Matches/definitions';
import {
  Competition,
  CompetitionCreate,
  CompetitionPart,
  CompetitionUpdateData,
} from './definitions';
import { CompetitionState } from './reducer';

const create = actionCreatorFactory('COMPETITIONS');
const createAsync = asyncFactory<CompetitionState>(create);

interface NormalizedSchema {
  entities: {
    competitions: {
      [key: string]: Competition;
    };
  };
  total?: number;
  nextOffset?: number | null;
}

// interface NormalizedSchemaSetting {
//   entities: {
//     settings: {
//       [key: string]: {
//         [key: string]: Array<{ _id: string; label: string }>
//       };
//     };
//   };
// }

interface NormalizedSchemaParts {
  entities: {
    competitionPartsByCompetitionId: {
      [key: string]: {
        [key: string]: CompetitionPart;
      };
    };
  };
}

const normalize = (data: Competition[]) => {
  return {
    entities: {
      competitions: data.reduce((acc, item: Competition) => {
        return { ...acc, [item._id]: item };
      }, {}),
    },
  };
};

const normalizeParts = (competitionId: string, data: CompetitionPart[]) => {
  return {
    entities: {
      competitionPartsByCompetitionId: {
        [competitionId]: data.reduce((acc, item: CompetitionPart) => {
          return { ...acc, [item._id]: item };
        }, {}),
      },
    },
  };
};

export const getCompetitions = createAsync<
  {
    appspace: string;
    limit: number;
    offset?: number;
    seasonIds?: any;
    q?: string;
  },
  NormalizedSchema
>('GET_COMPETITIONS', async (parameters) => {
  const { limit, offset, seasonIds, appspace, q } = parameters;
  const params: {
    limit: number;
    offset?: number;
    seasonIds?: string[];
    q?: string;
  } = {
    limit,
    offset: offset || 0,
  };
  if (seasonIds) {
    params.seasonIds = seasonIds;
  }
  if (q) {
    params.q = q;
  }
  const response = await api.adminGetCompetitionsByCompetitionGroup(
    appspace,
    params,
  );
  return {
    ...normalize(response.competitions),
    total: response.total,
    nextOffset: response.nextOffset,
  };
});

export const getSettingBySportSector = createAsync<
  { settingName: string; sportSector: string },
  any
>('GET_SETTING_BY_SPORT_SECTOR', async (parameters, dispatch) => {
  const { settingName, sportSector } = parameters;
  const setting = await api.getSettingBySportSector(settingName, sportSector);
  return setting.items;
});

export const getCompetitionById = createAsync<
  { appspace: string; id: string },
  NormalizedSchema
>('GET_COMPETITION_BY_ID', async (parameters, dispatch) => {
  const { appspace, id } = parameters;
  const competition = await api.adminGetCompetition(appspace, id);
  return normalize(competition ? [competition] : []);
});

export const postCompetition = createAsync<
  { appspace: string; competition: CompetitionCreate },
  Competition
>('POST_COMPETITION', async (parameters) => {
  const { appspace, competition } = parameters;
  const response = await api.adminCreateCompetition(appspace, {}, competition);
  return response;
});

export const putCompetition = createAsync<
  {
    appspace: string;
    competitionId: string;
    competition: CompetitionUpdateData;
  },
  Competition
>('PUT_COMPETITION', async (parameters, dispatch) => {
  const { appspace, competition, competitionId } = parameters;
  const response = await api.adminUpdateCompetition(
    appspace,
    competitionId,
    {},
    competition,
  );
  dispatch(getCompetitionById.action({ appspace, id: competitionId }));
  return response;
});

export const getCompetitionParts = createAsync<
  {
    appspace: string;
    competitionId: string;
  },
  NormalizedSchemaParts
>('GET_COMPETITION_PARTS', async (parameters) => {
  const { competitionId, appspace } = parameters;
  const response = await api.adminGetCompetitionParts(appspace, competitionId);
  return normalizeParts(competitionId, response.parts || []);
});

export const getCompetitionPartById = createAsync<
  {
    appspace: string;
    competitionId: string;
    competitionPartId: string;
  },
  NormalizedSchemaParts
>('GET_COMPETITION_PART_BY_ID', async (parameters) => {
  const { competitionId, competitionPartId, appspace } = parameters;
  const response = await api.adminGetCompetitionPartById(
    appspace,
    competitionId,
    competitionPartId,
  );
  return normalizeParts(competitionId, [response]);
});

export const postCompetitionPart = createAsync<
  { appspace: string; competitionId: string; competitionPart: CompetitionPart },
  CompetitionPart
>('POST_COMPETITION_PART', async (parameters) => {
  const { appspace, competitionId, competitionPart } = parameters;
  const response = await api.adminCreateCompetitionPart(
    appspace,
    competitionId,
    {},
    competitionPart,
  );
  return response;
});

export const putCompetitionPart = createAsync<
  {
    appspace: string;
    competitionId: string;
    competitionPart: {
      _id: string;
      name: string;
      settings: {};
      rules: {
        sport_sector: string;
      };
      dateFrom: string;
      dateTo: string;
      resultsTableObject: {
        players: Player[];
        results: {
          [key: string]: Result | RaceResult;
        };
        teamResults: {
          [key: string]: TeamRaceResult;
        };
      };
    };
  },
  CompetitionPart
>('PUT_COMPETITION_PART', async (parameters) => {
  const { appspace, competitionId, competitionPart } = parameters;
  const data = {
    name: competitionPart.name,
    rules: competitionPart.rules,
    dateFrom: competitionPart.dateFrom,
    dateTo: competitionPart.dateTo,
    settings: competitionPart.settings,
    resultsTable: {
      players: competitionPart.resultsTableObject.players,
      teamResults: Object.keys(
        competitionPart.resultsTableObject.teamResults || {},
      ).reduce((acc: TeamRaceResult[], k) => {
        const result: any = competitionPart.resultsTableObject.teamResults[k];
        if (result.totalPoints) {
          const totalPoints: any[] = [];
          Object.keys(result.totalPoints).forEach((key) => {
            totalPoints.push({
              name: key,
              value:
                typeof result.totalPoints[key] !== 'undefined' &&
                typeof result.totalPoints[key].value !== 'undefined'
                  ? result.totalPoints[key].value
                  : result.totalPoints[key],
            });
          });
          result.totalPoints = totalPoints;
        }
        return [...acc, result];
      }, []),
      results: Object.keys(competitionPart.resultsTableObject.results).reduce(
        (acc: Array<Result | RaceResult>, k) => {
          const result: any = competitionPart.resultsTableObject.results[k];
          if (result.totalPoints) {
            const totalPoints: any[] = [];
            Object.keys(result.totalPoints).forEach((key) => {
              totalPoints.push({
                name: key,
                value:
                  typeof result.totalPoints[key] !== 'undefined' &&
                  typeof result.totalPoints[key].value !== 'undefined'
                    ? result.totalPoints[key].value
                    : result.totalPoints[key],
              });
            });
            result.totalPoints = totalPoints;
          }
          return [...acc, result];
        },
        [],
      ),
    },
  };
  const response = await api.adminUpdateCompetitionPart(
    appspace,
    competitionId,
    competitionPart._id,
    {},
    data,
  );
  return response;
});

export const deleteCompetitionPart = createAsync<
  { appspace: string; competitionId: string; competitionPartId: string },
  void
>('DELETE_COMPETITION_PART', async (parameters, dispatch) => {
  const { appspace, competitionId, competitionPartId } = parameters;
  await api.adminDeleteCompetitionPart(
    appspace,
    competitionId,
    competitionPartId,
  );
  await dispatch(getCompetitionParts.action({ appspace, competitionId }));
});
