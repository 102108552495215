import Button from '@sportnet/ui/Button';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, compose } from 'redux';
import { submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { __ } from '../../utilities';
import { Competition, CompetitionPart } from '../Competitions/definitions';
import { Team } from '../Teams/definitions';
import CompetitionDetailReadonlyForm from './competitionDetailReadonlyForm';
import TeamCreationForm, { FORM_NAME } from './teamCreationForm';
import TeamSelectionForm from './teamSelectionForm';

const TEAM_SELECTION_FORM_NAME = 'TEAM_SELECTION_FORM';

type RouteProps = RouteComponentProps<{
  competitionId: string;
  clubId: string;
  partId: string;
}>;

const TeamSignup: React.FC<
  RouteProps & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  }
> = ({
  dispatch,
  match: {
    params: { competitionId, partId, clubId },
  },
  history: { replace, push },
}) => {
  const [competition, setCompetition] = React.useState<null | Competition>(
    null,
  );
  const [competitionPart, setCompetitionPart] =
    React.useState<null | CompetitionPart>(null);
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [isFetchingTeams, setIsFetchingTeams] = React.useState(false);
  const [teamIsSubmitting, setTeamIsSubmitting] = React.useState(false);
  const [teamCreationModalOpened, setTeamCreationModalOpened] =
    React.useState(false);
  const [isSubmittingTeamCreation, setIsSubmittingTeamCreation] =
    React.useState(false);

  const getTeams = React.useCallback(async () => {
    setIsFetchingTeams(true);
    try {
      const teamsData = await api.clubManagerGetCompetitionPartEligibleTeams(
        competitionId,
        partId,
        clubId,
      );
      setTeams(teamsData.items);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať zoznam tímov'));
    } finally {
      setIsFetchingTeams(false);
    }
  }, [competitionId, partId, clubId]);

  React.useEffect(() => {
    const getData = async () => {
      try {
        const competitionPartData = await api.clubManagerGetCompetitionPartById(
          competitionId,
          partId,
        );
        const alreadySignedUp = (competitionPartData.teams || []).find(
          (t) => t.appSpace === clubId,
        );
        if (!!alreadySignedUp) {
          replace(
            `/club-manager/competitions/${competitionId}/parts/${partId}/club/${clubId}/team/${alreadySignedUp._id}`,
          );
        }
        const competitionData = await api.clubManagerGetCompetition(
          competitionId,
        );
        setCompetition(competitionData);
        setCompetitionPart(competitionPartData);
        getTeams();
      } catch (e: any) {
        alert(__('Nepodarilo sa získať informácie o súťaži'));
      }
    };
    getData();
  }, [clubId, competitionId, getTeams, partId, replace]);

  const submitTeamSelectionForm = async (values: {
    teamId: { value: string };
  }) => {
    try {
      setTeamIsSubmitting(true);
      const { _id: squadId } = await api.clubManagerSignupCompetitionPartTeam(
        competitionId,
        partId,
        clubId,
        undefined,
        { teamId: values.teamId.value },
      );
      push(
        `/club-manager/competitions/${competitionId}/parts/${partId}/club/${clubId}/team/${squadId}`,
      );
    } catch (e: any) {
      alert(__('Družstvo sa nepodarilo prihlásiť.'));
    } finally {
      setTeamIsSubmitting(false);
    }
  };

  const toggleTeamCreationModal = () =>
    setTeamCreationModalOpened(!teamCreationModalOpened);

  const teamCreationSubmit = async (values: {
    name: string;
    displayName: string;
    gender?: string;
  }) => {
    if (competitionPart) {
      setIsSubmittingTeamCreation(true);
      try {
        await api.clubManagerCreateTeam(
          competitionId,
          partId,
          clubId,
          undefined,
          {
            ...values,
            sport_sector:
              competitionPart.rules &&
              (competitionPart.rules as any).sport_sector,
          },
        );
        toggleTeamCreationModal();
        getTeams();
      } catch (e: any) {
        alert(__('Družstvo sa nepodarilo vytvoriť'));
      } finally {
        setIsSubmittingTeamCreation(false);
      }
    }
  };

  if (!competition || !competitionPart) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Prihlásenie družstva')}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      {/* <AppContext
        title={__('Prihlásenie družstva')}
        breadcrumbs={[
          { name: __('Späť na hlavný prehľad'), url: '/club-manager' },
        ]}
      /> */}
      <Segment>
        <Modal
          isOpen={teamCreationModalOpened}
          handleClose={toggleTeamCreationModal}
        >
          <ModalContent>
            <TeamCreationForm onSubmit={teamCreationSubmit} />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button secondary onClick={toggleTeamCreationModal}>
                {__('Zrušiť')}
              </Button>
              <Button
                primary
                loading={isSubmittingTeamCreation}
                onClick={async () => {
                  await dispatch(submit(FORM_NAME));
                }}
              >
                {__('Prihlásiť účastníka')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Informácie o súťaži')}
            </SegmentHeader>
          }
        >
          <CompetitionDetailReadonlyForm
            initialValues={{ competition, competitionPart }}
          />
        </Segment>
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Prihlásenie družstva')}
            </SegmentHeader>
          }
        >
          <div onClick={toggleTeamCreationModal} style={{ cursor: 'pointer' }}>
            <Message warning>
              {__(
                'V prípade, že si želáte vytvoriť nové družstvo, môžete tak urobiť kliknutím na túto správu',
              )}
            </Message>
          </div>
          <TeamSelectionForm
            form={TEAM_SELECTION_FORM_NAME}
            teams={teams}
            loading={isFetchingTeams}
            onSubmit={submitTeamSelectionForm}
          />
          <Button
            primary
            loading={teamIsSubmitting}
            onClick={() => {
              dispatch(submit(TEAM_SELECTION_FORM_NAME));
            }}
          >
            {__('Prihlásiť družstvo')}
          </Button>
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect())(TeamSignup);
