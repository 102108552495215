import { Season } from '../Codelists/Seasons/definitions';
import { Player, Result } from '../Matches/definitions';

export const LIST_NAME = 'COMPETITIONS_LIST';
// TODO: Zmenit limit
export const LIST_LIMIT = 20;

interface User {
  _id: string;
}

export interface CompetitionUpdateData {
  competitionGroupId?: string;
  domain?: string;
  name: string;
  identifier?: string;
  note?: string;
  season: Season;
  sport: string;
  parts?: CompetitionPart[];
  rules?: {
    gender?: string;
    sport_sector?: string;
  };
  teams?: Array<{
    _id: string;
    name: string;
    appSpace: string;
  }>;
}

export interface Competition extends CompetitionUpdateData {
  _id: string;
  appSpace: string;
  ownerPPO?: string;
}

export interface CompetitionCreate {
  name: string;
  season: Season;
  sport: string;
}

export interface CompetitionUpdate {
  _id: string;
  appSpace?: string;
  name?: string;
  season?: Season;
  gender?: string;
  sportId?: string;
  administrators?: User[];
  parts?: CompetitionPart[];
  rules?: {
    gender?: string;
    sport_sector?: string;
  };
}

export interface CompetitionPart {
  _id: string;
  name: string;
  format?: 'points' | 'draw';
  type?: string;
  rounds?: Round[];
  rules?: {};
  teams?: ICompetitionPartTeam[];
  signup?: boolean;
  settings?: any;
  resultsTable?: {
    players?: Player[];
    results: Result[];
  };
  matches_count?: number;
  matchesCountByTeamId?: { [key: string]: number };
  dateFrom: string;
  dateTo: string;
  invitations?: Array<{ clubId: string; invitationId: string }>;
}

export interface ICompetitionPartTeam {
  _id: string;
  name: string;
  appSpace: string;
  squad?: {
    athletes?: Array<{
      additionalData: { [key: string]: any };
      sportnetUser: { _id: string; name: string };
    }>;
  };
}

export interface CompetitionPartCreate {
  name: string;
}

export interface CompetitionPartUpdate {
  _id: string;
  name: string;
  rules?: {};
}

export interface Round {
  _id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
}
