import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required, validateEmail, __ } from '../../../utilities';

const PlayerInvitationForm: React.FC<{}> = () => {
  return (
    <Field
      name="email"
      component={FormField}
      label={__('Emailová adresa')}
      required
      validate={[required, validateEmail]}
    />
  );
};

export default compose<any>(reduxForm({}))(PlayerInvitationForm);
