import Authorization from '@sportnet/ui/Authorization';
import { IAppPublic } from '@sportnet/ui/Authorization/types';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Api from './api';
import CmsApi from './CmsApi';
import Layout from './components/Layout';
import {
  getCodelist,
  getCodelistSectors,
  setActiveAppspace,
  setApplicationInfo,
  setApps,
  setAppspaces,
  setAuthUser,
} from './containers/App/actions';
import { activeAppspaceSelector } from './containers/App/selectors';
import {
  ApplicationInfo,
  Appspace,
  AuthorizationResponse,
} from './containers/App/types';
// containers
import AgeCategoryDetail from './containers/Codelists/AgeCategories/detail';
import AgeCategoriesList from './containers/Codelists/AgeCategories/list';
import SeasonDetail from './containers/Codelists/Seasons/detail';
import SeasonsList from './containers/Codelists/Seasons/list';
import CompetitionPart from './containers/Competitions/competitionPart';
import CompetitionDetail from './containers/Competitions/detail';
import CompetitionsList from './containers/Competitions/list';
import CompetitionSquadDetail from './containers/Competitions/Squads/detail';
import Domain from './containers/Domain';
import Draw from './containers/Draw';
import MatchDetail from './containers/Matches/detail';
import MatchesList from './containers/Matches/list';
import MatchPrint from './containers/MatchPrint';
import Nomination from './containers/Nominations';
import PublicSettings from './containers/PublicSettings';
import RecordDetail from './containers/Record';
import RecordsList from './containers/Records';
import SportObjectDetail from './containers/SportObjects/detail';
import SportObjectsList from './containers/SportObjects/list';
import SportGroundDetail from './containers/SportObjects/SportGrounds';
import Statistics from './containers/Statistics';
import TeamDetail from './containers/Teams/detail';
import TeamsList from './containers/Teams/list';
import TeamSignup from './containers/TeamSignup';
import { State } from './rootReducer';
import sportnetApi from './sportnetApi';
import { getProp } from './utilities';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

const mapStateToProps = (state: State) => ({
  activeAppspace: activeAppspaceSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
}> &
  OwnProps &
  IMapStateToProps;

class Routes extends React.PureComponent<Props> {
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    Api.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
    CmsApi.setBaseUrl(String(process.env.REACT_APP_CMS_API_BASE_URL));
    sportnetApi.setBaseUrl(String(process.env.REACT_APP_CORE_API_BASE_URL));
    const token =
      Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';
    Api.setToken(token);
    CmsApi.setToken(token);
    sportnetApi.setToken(token);
    if (appspace) {
      const { appspaces: appSpaces } = await sportnetApi.meAppSpacesForApp(
        process.env.REACT_APP_APP_ID || '',
      );
      const currentAppSpace = (appSpaces || []).find(
        (i) => i.app_space === appspace,
      );
      if (currentAppSpace && currentAppSpace.role === 'clubManager') {
        this.props.history.replace(`/club-manager`);
      }

      dispatch(setActiveAppspace(appspace));
      (window as any).appSpace = appspace;
      sportnetApi
        .meAppSpacesForApp(process.env.REACT_APP_APP_ID || '')
        .then((res) => {
          this.onAppspacesReceived(getProp(res, ['appspaces'], []));
        })
        .catch((e) => {
          console.error(e);
        });
    }
    const response = await sportnetApi.meAppSpaces({ expandApp: true });
    const newApps = response.apps!.map((a) => {
      const appSpaceIds = (a.appspaces || []).map((as) => as.app_space);
      return { ...a.app, appSpaceIds };
    });
    this.onAppsReceived(newApps);

    dispatch(getCodelist.action({ codelist: 'country' }));
    dispatch(getCodelist.action({ codelist: 'sports-ground-type' }));
    const sports = await dispatch(getCodelist.action({ codelist: 'sport' }));
    for (const sport of sports.items) {
      await dispatch(
        getCodelistSectors.action({
          codelist: 'sport',
          codelistItem: sport.value,
        }),
      );
    }
  }

  onAppsReceived = (data: IAppPublic[]) => {
    this.props.dispatch(setApps(data));
  };

  onAuthorized = (data: AuthorizationResponse) => {
    this.props.dispatch(setAuthUser(data));
  };
  onAppspaceSelect = async (data: Appspace) => {
    this.props.dispatch(setActiveAppspace(data.org_profile._id));
    this.props.history.push(`/admin/${data.org_profile._id}/matches`);
  };
  onAppInfoReceived = (data: ApplicationInfo) => {
    this.props.dispatch(setApplicationInfo(data));
  };
  onAppspacesReceived = (data: Appspace[]) => {
    this.props.dispatch(setAppspaces(data));
  };
  render() {
    return (
      <Authorization
        appId={process.env.REACT_APP_APP_ID as string}
        appspace={this.props.activeAppspace}
        onAppspaceSelect={this.onAppspaceSelect as any}
        onAppsReceived={this.onAppsReceived}
        onAuthorized={this.onAuthorized as any}
        onAppInfoReceived={this.onAppInfoReceived as any}
        onAppspacesReceived={this.onAppspacesReceived as any}
        scope="profile,messages"
      >
        <Route
          path="/admin/:appspace/matches/:id/print"
          component={MatchPrint}
        />
        <Route
          render={(props) => {
            if (
              props.location.pathname.match(
                new RegExp('/admin/[a-z0-9]+/matches/[a-z0-9]+/print'),
              )
            ) {
              return null;
            }
            return (
              <Layout>
                <Route
                  exact
                  path="/admin/:appspace/codelists/seasons"
                  component={SeasonsList}
                />
                <Route
                  path="/admin/:appspace/codelists/seasons/:id"
                  component={SeasonDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/codelists/age-categories"
                  component={AgeCategoriesList}
                />
                <Route
                  path="/admin/:appspace/codelists/age-categories/:id"
                  component={AgeCategoryDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/sport-objects"
                  component={SportObjectsList}
                />
                <Route
                  exact
                  path="/admin/:appspace/sport-objects/:id"
                  component={SportObjectDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/sport-objects/:sportObjectId/sport-grounds/:sportGroundId"
                  component={SportGroundDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/teams"
                  component={TeamsList}
                />
                <Route
                  exact
                  path="/admin/:appspace/teams/:id"
                  component={TeamDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions"
                  component={CompetitionsList}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions/:id"
                  component={CompetitionDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions/:id/parts/:partId/squads/:teamId"
                  component={CompetitionSquadDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions/:id/parts/:competitionPartId"
                  component={CompetitionPart}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions/:id/parts/:competitionPartId/draw"
                  component={Draw}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions/:competitionId/parts/:competitionPartId/matches/:id"
                  component={MatchDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/matches/:id"
                  component={MatchDetail}
                />
                <Route
                  exact
                  path="/admin/:appspace/matches"
                  component={MatchesList}
                />
                <Route
                  exact
                  path="/admin/:appspace/matches/:id/nomination/:teamId"
                  component={Nomination}
                />
                <Route
                  exact
                  path="/admin/:appspace/records"
                  component={RecordsList}
                />
                <Route
                  path="/admin/:appspace/records/:recordId"
                  component={RecordDetail}
                />
                <Route path="/admin/:appspace/domain" component={Domain} />
                <Route
                  path="/admin/:appspace/public-settings"
                  component={PublicSettings}
                />
                <Route
                  path="/admin/:appspace/teams/:teamId/statistics/:sportnetId"
                  component={Statistics}
                />
                <Route
                  exact
                  path="/admin/:appspace/competitions/:competitionId/parts/:competitionPartId/team-signup"
                  component={TeamSignup}
                />
              </Layout>
            );
          }}
        />
      </Authorization>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Routes));
