import DatePicker from '@sportnet/ui/DatePickers/datePicker-redux-form';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { State } from '../../../rootReducer';
import { required, __ } from '../../../utilities';
import { Season } from './definitions';

export const FORM_NAME = 'SEASON_FORM';

interface CustomFormData {
  name: string;
  dateFrom: Date;
  dateTo: Date;
  actual: boolean;
}

interface OwnProps {
  id: string;
  data: Season | null;
}

const mapStateToProps = (state: State, props: OwnProps) => {
  if (props.id !== 'new') {
    return {
      initialValues: props.data,
    };
  }
  return {};
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const SeasonForm = (
  props: InjectedFormProps<CustomFormData> & IMapStateToProps & OwnProps,
) => {
  return (
    <Form onSubmit={props.handleSubmit}>
      <Row>
        <Col xs={12} s={6}>
          <Field
            name="name"
            validate={[required]}
            component={FormField}
            required
            label={__('Označenie sezóny')}
          />
        </Col>
        <Col xs={12} s={3}>
          <FormGroup>
            <Label>{__('Trvanie od')}</Label>
            <Field
              name="dateFrom"
              validate={[required]}
              component={DatePicker}
              format={null}
            />
          </FormGroup>
        </Col>
        <Col xs={12} s={3}>
          <FormGroup>
            <Label>{__('Trvanie do')}</Label>
            <Field
              name="dateTo"
              validate={[required]}
              component={DatePicker}
              format={null}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
})(SeasonForm);

export default connect(mapStateToProps)(connected);
