import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../../api';
import { AgeCategory } from './definitions';
import { AgeCategoriesState } from './reducer';

const create = actionCreatorFactory('AGE_CATEGORIES');
const createAsync = asyncFactory<AgeCategoriesState>(create);

interface NormalizedSchema {
  entities: {
    ageCategories: {
      [key: string]: AgeCategory;
    };
  };
}

const normalize = (data: AgeCategory[]) => {
  return {
    entities: {
      ageCategories: data.reduce((acc, item: AgeCategory) => {
        return { ...acc, [item._id || '']: item };
      }, {}),
    },
  };
};

export const getAgeCategories = createAsync<
  { appspace: string },
  NormalizedSchema
>('GET_AGE_CATEGORIES', async (parameters) => {
  const response = await Api.adminGetAgeCategories(parameters.appspace);
  return normalize(response.ageCategories);
});

export const getAgeCategoryById = createAsync<
  { appspace: string; id: string },
  NormalizedSchema
>('GET_AGE_CATEGORY_BY_ID', async (parameters) => {
  const response = await Api.adminGetAgeCategory(
    parameters.appspace,
    parameters.id,
  );
  return normalize([response]);
});

export const postAgeCategory = createAsync<
  { appspace: string; ageCategory: AgeCategory },
  AgeCategory
>('POST_AGE_CATEGORY', async (parameters) => {
  const response = await Api.adminCreateAgeCategory(
    parameters.appspace,
    {},
    parameters.ageCategory,
  );
  return response;
});

export const putAgeCategory = createAsync<
  { appspace: string; ageCategoryId: string; ageCategory: AgeCategory },
  AgeCategory
>('PUT_AGE_CATEGOERY', async (parameters) => {
  const response = await Api.adminUpdateAgeCategory(
    parameters.appspace,
    parameters.ageCategoryId,
    {},
    parameters.ageCategory,
  );
  return response;
});
