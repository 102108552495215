import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import CmsApi, { Section } from '../../CmsApi';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

const mapStateToProps = (state: State) => ({
  appSpace: activeAppspaceSelector(state),
});

const HomepageSectionIdForm: React.FC<ReturnType<typeof mapStateToProps>> = ({
  appSpace,
}) => {
  const [sections, setSections] = React.useState<Section[]>([]);

  const loadSections = React.useCallback(async () => {
    const { tree = [] } = await CmsApi.getAdminSectionsTree(
      'sutaze',
      appSpace ? appSpace.app_space : '',
      'default',
    );
    setSections(tree);
  }, [appSpace]);

  React.useEffect(() => {
    loadSections();
  }, [appSpace, loadSections]);

  return (
    <Row>
      <Col xs={12}>
        <Field
          name="homepageSectionIdOrUniqId"
          component={FormField}
          label={__('Sekcia pre hlavnú stránku')}
          type="theselect"
          options={sections.map((s) => ({
            label: s.name || '',
            value: s._id || '',
          }))}
          format={(i: string | { _id: string; name: string }) => {
            if (typeof i === 'string') {
              const option = sections.find((s) => s && String(s._id) === i);
              if (option) {
                return {
                  value: option._id || '',
                  label: option.name,
                };
              }
            } else if (i) {
              return i;
            }
            return '';
          }}
        />
      </Col>
    </Row>
  );
};

export default compose<any>(
  reduxForm({
    enableReinitialize: true,
  }),
  connect(mapStateToProps),
)(HomepageSectionIdForm);
