import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import Box from '../../../components/Box';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { State } from '../../../rootReducer';
import { __ } from '../../../utilities';
import Form, { SPORT_GROUNDS_FORM_NAME } from './form';

const mapStateToProps = (state: State) => ({
  submitting: isSubmitting(SPORT_GROUNDS_FORM_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouteComponentProps<{
  appspace: string;
  sportObjectId: string;
  sportGroundId: string;
}> & { dispatch: ThunkDispatch<{}, any, any> } & IMapStateToProps;

class SportGroundDetail extends React.PureComponent<Props> {
  state = {
    initialValues: {},
    isFetching: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { sportGroundId },
      },
    } = this.props;
    if (sportGroundId !== 'new') {
      this.getSportGround();
    }
  }

  getSportGround = async () => {
    const {
      match: {
        params: { appspace, sportObjectId, sportGroundId },
      },
    } = this.props;
    this.setState({
      isFetching: true,
    });
    try {
      const initialValues = await api.adminGetSportGround(
        appspace,
        sportObjectId,
        sportGroundId,
      );
      this.setState({
        initialValues,
      });
    } catch (e: any) {
      alert(__('Ľutujeme, vyskytla sa neočakávaná chyba.'));
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  onSubmit = async (values: any) => {
    const {
      match: {
        params: { sportGroundId },
      },
    } = this.props;
    try {
      if (sportGroundId === 'new') {
        await this.createSportGround(values);
      } else {
        await this.updateSportGround(values);
      }
    } catch (e: any) {
      alert(__('Ľutujeme, vyskytla sa neočakávaná chyba.'));
    }
  };

  createSportGround = async (values: any) => {
    const {
      match: {
        params: { appspace, sportObjectId },
      },
    } = this.props;
    await api.adminCreateSportGround(
      appspace,
      sportObjectId,
      undefined,
      values,
    );
    this.props.history.push(
      `/admin/${appspace}/sport-objects/${sportObjectId}`,
    );
  };

  updateSportGround = async (values: any) => {
    const {
      match: {
        params: { appspace, sportObjectId, sportGroundId },
      },
    } = this.props;
    await api.adminUpdateSportGround(
      appspace,
      sportObjectId,
      sportGroundId,
      undefined,
      values,
    );
    this.props.history.push(
      `/admin/${appspace}/sport-objects/${sportObjectId}`,
    );
  };

  render() {
    const {
      match: {
        params: { appspace, sportObjectId, sportGroundId },
      },
      submitting,
    } = this.props;
    const { isFetching, initialValues } = this.state;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/sport-objects/${sportObjectId}`}
            />
            <HeaderBar.Header>
              {sportGroundId === 'new'
                ? __('Nové športovisko')
                : __('Úprava športoviska')}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                loading={submitting}
                onClick={() => {
                  this.props.dispatch(submit(SPORT_GROUNDS_FORM_NAME));
                }}
                primary
              >
                {__('Uložiť športovisko')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext
          breadcrumbs={[
            {
              name: __('Zoznam športových objektov'),
              url: `/admin/${appspace}/sport-objects`,
            },
            {
              name: __('Detail športového objektu'),
              url: `/admin/${appspace}/sport-objects/${sportObjectId}`,
            },
            {
              name:
                sportGroundId === 'new'
                  ? __('Nové športovisko')
                  : __('Úprava športoviska'),
            },
          ]}
        /> */}
        <Segment>
          <Box title={__('Informácie o športovisku')}>
            <Form initialValues={initialValues} onSubmit={this.onSubmit} />
          </Box>
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(SportGroundDetail);
