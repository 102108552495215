import Button from '@sportnet/ui/Button';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import TheSelect from '@sportnet/ui/TheSelect';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector } from 'redux-form';
import api from '../../../api';
import { CustomThunkDispatch } from '../../../configureStore';
import { State } from '../../../rootReducer';
import styled from 'styled-components';
import { __ } from '../../../utilities';
import { css } from 'styled-components';
import { FORM_NAME } from './index';

const Pitch = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(/lineup-pitch.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  max-width: 400px;
  height: 425px;
  margin: auto;
  justify-content: space-around;
  padding: 8px;
`;
const Row = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly;
`;
const Col = styled.div<{ width: number; active?: boolean; children?: string }>`
  width: ${(props: any) => props.width}%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${rem(8)};
  height: 100%;
  color: rgba(255, 255, 255, 0.4);
  user-select: none;
  ${(props: any) =>
    !!props.children &&
    css`
      cursor: pointer;
      :hover,
      :-moz-drag-over {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        color: white;
      }
    `}
  ${(props: any) =>
    !!props.active &&
    css`
      color: white;
      > div:first-child {
        border: 2px solid white;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 12px;
      }
      > div:nth-child(2) {
        text-align: center;
        font-size: 10px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
      }
    `}
` as any;

const mapStateToProps = (state: State) => {
  return {
    reduxAthletes: formValueSelector(FORM_NAME)(state, 'athletes'),
  };
};

interface OwnProps {
  maxPlayers: number;
}

const FutbalNominationFormation: React.FC<
  OwnProps &
    ReturnType<typeof mapStateToProps> & {
      dispatch: CustomThunkDispatch;
    }
> = ({ reduxAthletes, maxPlayers, dispatch }) => {
  const [activePosition, setActivePosition] = React.useState<string | null>(
    null,
  );
  const [selectedPlayer, setSelectedPlayer] = React.useState<string | null>(
    null,
  );
  const [isDragged, setIsDragged] = React.useState<string | null>(null);
  const [isFetchingCodelists, setIsFetchingCodelists] = React.useState(false);
  const [rows, setRows] = React.useState<Array<{ _id: string; label: string }>>(
    [],
  );
  const [cols, setCols] = React.useState<Array<{ _id: string; label: string }>>(
    [],
  );
  const [positions, setPositions] = React.useState<
    Array<{ _id: string; value?: string; label: string }>
  >([]);

  const getCodelists = async () => {
    setIsFetchingCodelists(true);
    try {
      const rowsCodelist = await api.getSettingBySportSector(
        'sport_sector_lineup_rows',
        'futbal',
      );
      const colsCodelist = await api.getSettingBySportSector(
        'sport_sector_lineup_cols',
        'futbal',
      );
      const positionsCodelist = await api.getSettingBySportSector(
        'sport_sector_lineup_positions',
        'futbal',
      );
      setRows(rowsCodelist.items);
      setCols(colsCodelist.items);
      setPositions(positionsCodelist.items);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať číselníky pre rozostavenie hráčov.'));
    } finally {
      setIsFetchingCodelists(false);
    }
  };

  React.useEffect(() => {
    getCodelists();
  }, []);

  const athletes = Object.keys(reduxAthletes || {})
    .map((k) => reduxAthletes[k])
    .filter((i) => !i.additionalData || !i.additionalData.substitute);

  const disabled =
    athletes.filter((a) => !!a.additionalData.lineupPosition).length ===
      maxPlayers && !isDragged;

  const renderPitchCol = (
    row: { label: string; _id: string },
    col: { label: string; _id: string },
  ) => {
    const colName = `${col._id}:${row._id}`;
    const position = positions.find((p) => p._id === colName);
    if (position) {
      const player = athletes.find(
        (a) => a.additionalData.lineupPosition === position._id,
      );
      if (!player && disabled) {
        return null;
      }
      const playerName: string[] = player
        ? player.sportnetUser.name.split(' ')
        : [];
      return (
        <Col
          id={position._id}
          width={100 / cols.length}
          {...(!!player && {
            active: true,
            draggable: true,
            onDrag: () => {
              if (!isDragged) {
                setIsDragged(player.sportnetUser._id);
              }
            },
            onDragEnd: () => {
              setIsDragged(null);
            },
          })}
          onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            return false;
          }}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            if (e.currentTarget.id) {
              const currentPositionPlayer = athletes.find(
                (a) =>
                  !!a.additionalData.lineupPosition &&
                  a.additionalData.lineupPosition === e.currentTarget.id,
              );
              if (currentPositionPlayer) {
                reduxAthletes[
                  currentPositionPlayer.sportnetUser._id
                ].additionalData.lineupPosition = isDragged
                  ? reduxAthletes[isDragged].additionalData.lineupPosition
                  : undefined;
              }
            }
            if (isDragged) {
              reduxAthletes[isDragged].additionalData.lineupPosition =
                e.currentTarget.id || undefined;
            }
            dispatch(change(FORM_NAME, 'athletes', reduxAthletes));
          }}
          onClick={() => {
            setActivePosition(position._id);
            if (player) {
              setSelectedPlayer(player.sportnetUser._id);
            }
          }}
        >
          <div>
            {player
              ? player.additionalData.nr || player.sportnetUser.name
              : position.value}
          </div>
          {playerName.length > 0 && (
            <div>
              {playerName.length > 1
                ? playerName
                    .map((i, idx) => {
                      if (idx === 0) {
                        return `${i[0]}.`;
                      }
                      return i;
                    })
                    .join(' ')
                : playerName.join('')}
            </div>
          )}
        </Col>
      );
    }
    if (disabled) {
      return null;
    }
    return <Col width={100 / cols.length} />;
  };

  const renderPitchRow = (row: { label: string; _id: string }) => {
    const rowPlayers = athletes.filter(
      (a) =>
        a.additionalData.lineupPosition &&
        a.additionalData.lineupPosition.endsWith(`:${row._id}`),
    );
    if (disabled && rowPlayers.length === 0) {
      return null;
    }
    return <Row>{cols.map((col) => renderPitchCol(row, col))}</Row>;
  };

  const selectedPlayerData =
    !!selectedPlayer &&
    athletes.find((a) => a.sportnetUser._id === selectedPlayer);

  const handleModalClose = () => {
    setActivePosition(null);
    setSelectedPlayer(null);
  };

  const handlePositionClear = () => {
    if (selectedPlayer) {
      reduxAthletes[selectedPlayer].additionalData.lineupPosition = undefined;
    }
    dispatch(change(FORM_NAME, 'athletes', reduxAthletes));
    handleModalClose();
  };

  const handlePositionChange = () => {
    if (activePosition) {
      const currentPositionPlayer = athletes.find(
        (a) =>
          !!a.additionalData.lineupPosition &&
          a.additionalData.lineupPosition === activePosition,
      );
      if (currentPositionPlayer) {
        reduxAthletes[
          currentPositionPlayer.sportnetUser._id
        ].additionalData.lineupPosition = undefined;
      }
    }
    if (selectedPlayer) {
      reduxAthletes[selectedPlayer].additionalData.lineupPosition =
        activePosition || undefined;
    }
    dispatch(change(FORM_NAME, 'athletes', reduxAthletes));
    handleModalClose();
  };

  if (isFetchingCodelists) {
    return <Loader size="l" />;
  }

  return (
    <>
      <Pitch>{rows.map(renderPitchRow)}</Pitch>
      <Modal handleClose={handleModalClose} isOpen={!!activePosition}>
        <ModalContent>
          <TheSelect
            filterOptions={(options, inputValue) => {
              return options;
            }}
            onChange={(opt: any) => {
              setSelectedPlayer(opt ? opt.value : null);
            }}
            value={
              selectedPlayerData
                ? {
                    value: selectedPlayerData.sportnetUser._id,
                    label: selectedPlayerData.sportnetUser.name,
                  }
                : null
            }
            options={athletes.map((a) => ({
              label: a.sportnetUser.name,
              value: a.sportnetUser._id,
            }))}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button onClick={handleModalClose}>{__('Zavrieť')}</Button>
            {selectedPlayer &&
              reduxAthletes[selectedPlayer].additionalData.lineupPosition ===
                activePosition && (
                <>
                  &nbsp;
                  <Button danger onClick={handlePositionClear}>
                    {__('Odstrániť z pozície')}
                  </Button>
                </>
              )}
            &nbsp;
            <Button primary onClick={handlePositionChange}>
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

export default compose(connect(mapStateToProps))(FutbalNominationFormation);
