import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import { format } from 'date-fns';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { __ } from '../../utilities';

const CompetitionDetailReadonlyForm: React.FC<{}> = () => {
  return (
    <>
      <Row>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Názov súťaže')}
            name="competition.name"
            component={FormField}
            readOnly
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Názov časti súťaže')}
            name="competitionPart.name"
            component={FormField}
            readOnly
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Trvanie od')}
            name="competitionPart.dateFrom"
            format={(i: string) => i && format(new Date(i), 'dd.MM.yyyy')}
            component={FormField}
            readOnly
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Trvanie do')}
            name="competitionPart.dateTo"
            format={(i: string) => i && format(new Date(i), 'dd.MM.yyyy')}
            component={FormField}
            readOnly
          />
        </Col>
      </Row>
    </>
  );
};

export default compose(
  reduxForm({ form: 'COMPETITION_DATA_FORM', enableReinitialize: true }),
)(CompetitionDetailReadonlyForm);
