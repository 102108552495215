import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Icon from '@sportnet/ui/Icon';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { reset, submit, SubmissionError } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import Box from '../../../components/Box';
import { activeAppspaceSelector } from '../../App/selectors';
import { BoxNote } from '../../Matches/form';
import CompetitionDetailReadonlyForm from '../../TeamSignup/competitionDetailReadonlyForm';
import { State } from '../../../rootReducer';
import { getProp, __ } from '../../../utilities';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { getCompetitionById, getCompetitionPartById } from '../actions';
import { Competition, CompetitionPart } from '../definitions';
import {
  competitionByIdSelector,
  competitionPartByIdSelector,
} from '../selectors';
import CrewMemberCreationForm from './crewMemberCreationForm';
import BeachFootballSquadPlayerForm from './Forms/BeachFootball/form';
import CyclingSquadPlayForm from './Forms/Cycling';
import FormulaOneSquadPlayForm from './Forms/FormulaOne';
import FutbalSquadPlayerForm, { FOOTBALL_POSITIONS } from './Forms/Futbal/form';
import FutsalSquadPlayerForm, { FUTSAL_POSITIONS } from './Forms/Futsal/form';
import MinifootballSquadPlayForm from './Forms/Minifootball/form';
import SwimmingSquadPlayForm from './Forms/Swimming';
import WaterPoloSquadPlayerForm from './Forms/WaterPolo/form';
import PlayerInvitationForm from './invitationForm';
import SquadCopyForm from './squadCopyForm';
import CrewSelectionForm from './squadCrewForm';
import TeamUpdateForm from './teamUpdateForm';
import UserCreationForm from './userCreationForm';
import sportnetApi from '../../../sportnetApi';

const SQUAD_PLAYER_FORM_NAME = 'SQUAD_PLAYER_FORM';
const SQUAD_PLAYER_INVITATION_FORM_NAME = 'SQUAD_PLAYER_INVITATION_FORM';
const USER_CREATION_FORM_NAME = 'USER_CREATION_FORM';
const SQUAD_COPY_FORM_NAME = 'SQUAD_COPY_FORM';
const CREW_USER_CREATION_FORM_NAME = 'CREW_USER_CREATION_FORM';
const CREW_USER_SELECTION_FORM_NAME = 'CREW_USER_SELECTION_FORM';
const TEAM_UPDATE_FORM_NAME = 'TEAM_UPDATE_FORM';

type Props = RouteComponentProps<{
  appspace: string;
  id: string;
  teamId: string;
  partId: string;
}> & { dispatch: ThunkDispatch<any, any, AnyAction> };

const mapStateToProps = (state: State, props: Props) => {
  return {
    competition: competitionByIdSelector(props.match.params.id)(state),
    competitionPart: competitionPartByIdSelector(
      props.match.params.id,
      props.match.params.partId,
    )(state),
    activeAppSpace: activeAppspaceSelector(state),
  };
};

class CompetitionSquadDetail extends React.PureComponent<
  Props & ReturnType<typeof mapStateToProps>
> {
  state = {
    isSubmittingTeamUpdate: false,
    crewUserCreationModalVisible: false,
    crewUserSelectionModalVisible: false,
    isSubmittingCrewUserCreation: false,
    isSubmittingCrewSelection: false,
    sport_sector: '',
    isRemovingCrewMember: null,
    squad: {
      athletes: [],
      crew: {},
    },
    athletes: [],
    athletesCodelist: [],
    expertCodelist: [],
    crewCodelist: [],
    isFetching: false,
    modalOpened: false,
    invitationModalOpened: false,
    userCreationModalOpened: false,
    isSubmittingInvitation: false,
    isSubmittingUserCreation: false,
    isSubmitting: false,
    isRemoving: null,
    activePlayer: null,
    activeCrewMember: null,
    teamCompetitions: [],
    squadCopyModalOpened: false,
    copySquadIsSubmitting: false,
  };
  componentDidMount() {
    this.getSquad();
    this.getTeamCompetitions();
    this.loadCrewCodelist();
  }

  loadCrewCodelist = async () => {
    const {
      match: {
        params: { appspace, id, partId },
      },
    } = this.props;
    const part = await api.adminGetCompetitionPartById(appspace, id, partId);
    const { items } = await api.getSettingBySportSector(
      'sport_sector_crew',
      part.rules ? part.rules.sport_sector : '',
    );
    const { codelist: athletesCodelist } = await sportnetApi.getCodelist(
      'sport-org-competence-type',
    );
    const { codelist: expertCodelist } = await sportnetApi.getCodelist(
      'sport-expert-competence-type',
    );

    this.setState({
      athletesCodelist: athletesCodelist || [],
      expertCodelist: expertCodelist || [],
      crewCodelist: items.map((i) => ({
        _id: i._id,
        label: i.label,
        competenceTypes: getProp(i, ['payload', 'acceptedCompetenceTypes'], []),
      })),
    });
  };

  getTeamCompetitions = async (props = this.props) => {
    const {
      match: {
        params: { appspace, teamId, partId },
      },
    } = props;
    try {
      this.setState({ isFetchingTeamCompetitions: true });
      const res = await api.adminGetTeamCompetitions(appspace, teamId);
      const teamCompetitions = res.competitions
        .reduce((acc: any, c: Competition) => {
          return [
            ...acc,
            ...(c.parts || []).map((p: CompetitionPart) => {
              const team = (p.teams || []).find((t) => t._id === teamId);
              return {
                _id: p._id,
                partName: p.name,
                competitionName: c.name,
                seasonName: c.season.name,
                athletes: getProp(team || {}, ['squad', 'athletes'], []),
              };
            }),
          ];
        }, [])
        .filter((p) => p._id !== partId);
      this.setState({
        teamCompetitions,
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      this.setState({ isFetchingTeamCompetitions: false });
    }
  };

  getSquad = async (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = props;
    this.setState({
      isFetching: true,
    });
    try {
      dispatch(getCompetitionById.action({ appspace, id }));
      dispatch(
        getCompetitionPartById({
          appspace,
          competitionId: id,
          competitionPartId: partId,
        }),
      );
      const team = await api.adminGetCompetitionPartTeamSquad(
        appspace,
        id,
        partId,
        teamId,
      );
      this.setState({
        sport_sector: team.sport_sector,
        squad: team.squad,
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      modalOpened: !this.state.modalOpened,
    });
  };

  toggleCrewSelectionModal = () => {
    this.setState({
      crewUserSelectionModalVisible: !this.state.crewUserSelectionModalVisible,
    });
  };

  toggleInvitationModal = () => {
    this.setState({
      invitationModalOpened: !this.state.invitationModalOpened,
    });
  };

  toggleUserCreationModal = () => {
    this.setState({
      userCreationModalOpened: !this.state.userCreationModalOpened,
    });
  };

  toggleSquadCopyModal = () => {
    this.setState({
      squadCopyModalOpened: !this.state.squadCopyModalOpened,
    });
  };

  submitSquadForm = async (values: {
    sportnetId: any;
    additionalData: {
      [key: string]: any;
    };
  }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
      dispatch,
    } = this.props;
    if (!values.sportnetId) {
      throw new SubmissionError({ sportnetId: __('Pole je povinné') });
    }
    this.setState({
      isSubmitting: true,
    });
    try {
      if (this.state.activePlayer) {
        const team = await api.adminEditPlayerInCompetitionPartTeamSquad(
          appspace,
          id,
          partId,
          teamId,
          getProp(this.state.activePlayer || {}, ['sportnetId'], ''),
          undefined,
          {
            sportnetId: (values.sportnetId.value || values.sportnetId) as any,
            additionalData: values.additionalData,
          },
        );
        this.setState({
          squad: team.squad,
        });
      } else {
        const team = await api.adminAddPlayerToCompetitionPartTeamSquad(
          appspace,
          id,
          partId,
          teamId,
          undefined,
          {
            ...values,
            sportnetId: (values.sportnetId.value || values.sportnetId) as any,
            citizenship: values.sportnetId.citizenship || null,
          },
        );
        this.setState({
          squad: team.squad,
        });
      }
      dispatch(reset(SQUAD_PLAYER_FORM_NAME));
      this.toggleModal();
    } catch (e: any) {
      console.error(e);
      if (
        getProp(e, ['details', 'name'], '') === 'PLAYER_ALREADY_IN_TEAM_SQUAD'
      ) {
        alert(__('Hráč sa už na súpiske nachádza'));
      } else {
        alert(__('Vyskytla sa neočakávaná chyba'));
      }
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  submitCrewForm = async (values: {
    sportnetId: { value: string };
    position: string;
  }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
      dispatch,
    } = this.props;
    if (!values.sportnetId) {
      throw new SubmissionError({ sportnetId: __('Pole je povinné') });
    }
    this.setState({
      isSubmittingCrewSelection: true,
    });
    try {
      if (this.state.activeCrewMember) {
        const team = await api.adminEditCrewMemberInCompetitionPartTeamSquad(
          appspace,
          id,
          partId,
          teamId,
          getProp(this.state.activeCrewMember || {}, ['sportnetId'], ''),
          undefined,
          {
            sportnetId: (values.sportnetId.value || values.sportnetId) as any,
            position: values.position,
          },
        );
        this.setState({
          squad: team.squad,
        });
      } else {
        const team = await api.adminAddCrewMemberToCompetitionPartTeamSquad(
          appspace,
          id,
          partId,
          teamId,
          undefined,
          {
            ...values,
            sportnetId: (values.sportnetId.value || values.sportnetId) as any,
          },
        );
        this.setState({
          squad: team.squad,
        });
      }
      dispatch(reset(CREW_USER_SELECTION_FORM_NAME));
      this.toggleCrewSelectionModal();
    } catch (e: any) {
      console.error(e);
      alert(__('Vyskytla sa neočakávaná chyba'));
    } finally {
      this.setState({
        isSubmittingCrewSelection: false,
      });
    }
  };

  copySquadSubmit = async (values: { partId: string }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    const part: any = this.state.teamCompetitions.find(
      (i: any) => i._id === values.partId,
    );
    if (!part) {
      alert(
        __(
          'Vyskytla sa neočakávaná chyba. Bola zvolená neexistujúca časť súťaže',
        ),
      );
      return;
    }
    try {
      this.setState({ copySquadIsSubmitting: true });
      const team = await api.adminAddPlayersToCompetitionPartTeamSquad(
        appspace,
        id,
        partId,
        teamId,
        {},
        { players: part.athletes },
      );
      this.setState({
        squad: team.squad,
      });
    } catch (e: any) {
      console.error(e);
      alert(__('Nepodarilo sa skopírovať súpisku'));
    } finally {
      this.setState({
        copySquadIsSubmitting: false,
        squadCopyModalOpened: false,
      });
    }
  };

  renderForm = () => {
    const props = {
      form: SQUAD_PLAYER_FORM_NAME,
      athletes: this.state.athletes.sort(
        (a: { displayName: string }, b: { displayName: string }) => {
          if (a.displayName < b.displayName) {
            return -1;
          } else if (a.displayName > b.displayName) {
            return 1;
          }
          return 0;
        },
      ),
      onSubmit: this.submitSquadForm,
      initialValues: this.state.activePlayer || {},
    };
    switch (this.state.sport_sector) {
      case 'water-polo':
        return <WaterPoloSquadPlayerForm {...props} />;
      case 'futbal':
        return <FutbalSquadPlayerForm {...props} />;
      case 'futsal':
        return <FutsalSquadPlayerForm {...props} />;
      case 'beachfutbal':
        return <BeachFootballSquadPlayerForm {...props} />;
      case 'minifootball':
        return <MinifootballSquadPlayForm {...props} />;
      case 'swimming':
        return <SwimmingSquadPlayForm {...props} />;
      case 'cestna-cyklistika':
        return <CyclingSquadPlayForm {...props} />;
      case 'formula-1':
        return <FormulaOneSquadPlayForm {...props} />;
      default:
        return (
          <BoxNote>
            {__(
              'Pre dané športové odvetvie momentálne neexistuje možnosť nominácie.',
            )}
          </BoxNote>
        );
    }
  };

  renderTable = () => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    const commonProps = {
      rows: this.state.squad.athletes,
      onClickRow: (i: any) => {
        this.setState(
          {
            activePlayer: {
              sportnetId: i.sportnetUser._id,
              displayName: i.sportnetUser.name,
              additionalData: i.additionalData || {},
            },
          },
          () => {
            this.toggleModal();
          },
        );
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string, disabled?: boolean) => (
      <Button
        disabled={disabled}
        loading={this.state.isRemoving === sportnetId}
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({
            isRemoving: sportnetId,
          });
          if (
            window.confirm(__('Skutočne si želáte odstrániť hráča zo súpisky?'))
          ) {
            const team =
              await api.adminRemovePlayerFromCompetitionPartTeamSquad(
                appspace,
                id,
                partId,
                teamId,
                sportnetId,
                undefined,
              );
            this.setState({
              squad: team.squad,
              isRemoving: null,
            });
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );
    const getFootballPositions = (positionId: string) => {
      const p = FOOTBALL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    const getFutsalPositions = (positionId: string) => {
      const p = FUTSAL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    switch (this.state.sport_sector) {
      case 'water-polo':
      case 'swimming':
      case 'cestna-cyklistika':
      case 'formula-1':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              RemoveButton(member.sportnetUser._id, member.removable === false),
            ]}
          />
        );
      case 'futbal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => {
              return [
                member.sportnetUser.name,
                !!member.additionalData.captain && <Icon name="check" />,
                getFootballPositions(member.additionalData.position),
                member.additionalData.nr,
                RemoveButton(
                  member.sportnetUser._id,
                  member.removable === false,
                ),
              ];
            }}
          />
        );
      case 'futsal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFutsalPositions(member.additionalData.position),
              member.additionalData.nr,
              RemoveButton(member.sportnetUser._id, member.removable === false),
            ]}
          />
        );
      case 'beachfutbal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              {
                header: __('Meno na drese'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFootballPositions(member.additionalData.position),
              member.additionalData.nr,
              member.additionalData.shirtname,
              RemoveButton(member.sportnetUser._id, member.removable === false),
            ]}
          />
        );
      case 'minifootball':
        // const getMinifootballPosition = (positionId: string) => {
        //   const p = FOOTBALL_POSITIONS.find(
        //     position => position.value === positionId,
        //   );
        //   if (p) {
        //     return p.label;
        //   }
        //   return '';
        // };
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              // {
              //   header: __('Kapitán'),
              // },
              // {
              //   header: __('Pozícia'),
              // },
              // {
              //   header: __('Číslo dresu'),
              // },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              // !!member.additionalData.captain && <Icon name="check" />,
              // getMinifootballPosition(member.additionalData.position),
              // member.additionalData.nr,
              RemoveButton(member.sportnetUser._id, member.removable === false),
            ]}
          />
        );
      default:
        return null;
    }
  };

  renderCrewTable = () => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    const commonProps = {
      rows: Array.isArray(this.state.squad.crew || [])
        ? ((this.state.squad.crew || []) as any).map((crewMember: any) => {
            const codelistItem = (
              this.state.crewCodelist as Array<{
                _id: string;
                label: string;
              }>
            ).find((i) => i._id === crewMember.position);
            return {
              ...crewMember,
              positionLabel: codelistItem ? codelistItem.label : '',
            };
          })
        : Object.keys(this.state.squad.crew || {}).map((position) => {
            const codelistItem = (
              this.state.crewCodelist as Array<{
                _id: string;
                label: string;
              }>
            ).find((i) => i._id === position);
            return {
              ...this.state.squad.crew[position],
              position,
              positionLabel: codelistItem ? codelistItem.label : '',
            };
          }),
      onClickRow: (i: any) => {
        this.setState(
          {
            activeCrewMember: {
              sportnetId: i.sportnetUser._id,
              displayName: i.sportnetUser.name,
              position: i.position,
            },
          },
          () => {
            this.toggleCrewSelectionModal();
          },
        );
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string, disabled?: boolean) => (
      <Button
        disabled={disabled}
        loading={this.state.isRemovingCrewMember === sportnetId}
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({
            isRemovingCrewMember: sportnetId,
          });
          if (
            window.confirm(__('Skutočne si želáte odstrániť osobu zo súpisky?'))
          ) {
            const team =
              await api.adminRemoveCrewMemberFromCompetitionPartTeamSquad(
                appspace,
                id,
                partId,
                teamId,
                sportnetId,
                undefined,
              );
            this.setState({
              squad: team.squad,
              isRemovingCrewMember: null,
            });
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );

    return (
      <BasicTable
        {...commonProps}
        columns={[
          {
            header: __('Pozícia'),
          },
          {
            header: __('Meno a priezvisko'),
          },
          { header: '', width: 100 },
        ]}
        renderRow={(member) => [
          member.positionLabel,
          member.sportnetUser.name,
          RemoveButton(member.sportnetUser._id, member.removable === false),
        ]}
      />
    );
  };

  submitInvitationForm = async (values: { email: string }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isSubmittingInvitation: true,
      });
      await api.adminInvitePlayerToCompetitionPartTeamSquad(
        appspace,
        id,
        partId,
        teamId,
        undefined,
        { email: values.email },
      );
      this.setState({
        invitationModalOpened: false,
      });
    } catch (e: any) {
      alert(__('Pozvánku sa nepodarilo odoslať'));
    } finally {
      this.setState({
        isSubmittingInvitation: false,
      });
    }
  };

  submitUserCreationForm = async (data: {
    name: string;
    surname: string;
    competenceType?: string;
    dateFrom?: string;
  }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isSubmittingUserCreation: true,
      });
      await api.externalRegistrationIntoSquad(
        appspace,
        id,
        partId,
        teamId,
        undefined,
        { ...data, type: 'sport_org' },
      );
      this.toggleUserCreationModal();
      window.location.reload();
    } catch (e: any) {
      alert(__('Nepodarilo sa vytvoriť novú osobu'));
    } finally {
      this.setState({
        isSubmittingUserCreation: false,
      });
    }
  };

  renderInvitationForm = () => (
    <PlayerInvitationForm
      form={SQUAD_PLAYER_INVITATION_FORM_NAME}
      onSubmit={this.submitInvitationForm}
    />
  );

  toggleCrewUserCreationForm = () =>
    this.setState({
      crewUserCreationModalVisible: !this.state.crewUserCreationModalVisible,
    });

  submitCrewUserCreationForm = async (data: {
    name: string;
    surname: string;
  }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isSubmittingCrewUserCreation: true,
      });
      await api.externalRegistrationIntoSquad(
        appspace,
        id,
        partId,
        teamId,
        undefined,
        { ...data, type: 'sport_expert_org' },
      );
      this.toggleCrewUserCreationForm();
      window.location.reload();
    } catch (e: any) {
      alert(__('Nepodarilo sa vytvoriť novú osobu'));
    } finally {
      this.setState({
        isSubmittingCrewUserCreation: false,
      });
    }
  };

  submitTeamUpdateForm = async (data: {
    logo: { public_url: string; media_url: string };
  }) => {
    const {
      match: {
        params: { appspace, id, partId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isSubmittingTeamUpdate: true,
      });
      await api.adminUpdateCompetitionPartSignedUpTeam(
        appspace,
        id,
        partId,
        teamId,
        {},
        { logo: data.logo },
      );
    } catch (e: any) {
      alert(__('Nepodarilo sa uložiť zmeny.'));
    } finally {
      this.setState({
        isSubmittingTeamUpdate: false,
      });
    }
  };

  render() {
    const { isFetching, crewCodelist } = this.state;
    const {
      activeAppSpace,
      match: {
        params: { teamId, appspace, id, partId },
      },
    } = this.props;

    if (isFetching || !this.props.competitionPart) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    const team = (this.props.competitionPart.teams || []).find(
      (t: any) => t._id === teamId,
    );

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/competitions/${id}/parts/${partId}`}
            />
            <HeaderBar.Header>
              {`${!!this.props.competition && this.props.competition.name} | ${
                !!this.props.competitionPart && this.props.competitionPart.name
              } | ${__('Súpiska družstva')}`}
            </HeaderBar.Header>
          </HeaderBar>
        }
      >
        <Segment>
          <Box title={__('Informácie o súťaži')}>
            <CompetitionDetailReadonlyForm
              form="READONLY_COMPETITION_DATA_FORM"
              initialValues={{
                competition: this.props.competition,
                competitionPart: this.props.competitionPart,
              }}
            />
          </Box>
          <Box title={__('Informácie o družstve')}>
            <TeamUpdateForm
              form={TEAM_UPDATE_FORM_NAME}
              onSubmit={this.submitTeamUpdateForm}
              initialValues={team}
            />
            <Button
              onClick={() => {
                this.props.dispatch(submit(TEAM_UPDATE_FORM_NAME));
              }}
              primary
              loading={this.state.isSubmittingTeamUpdate}
            >
              {__('Uložiť')}
            </Button>
          </Box>
          <Box title={__('Súpiska družstva v súťaži')}>
            <Button
              onClick={() => {
                this.setState(
                  {
                    activePlayer: null,
                  },
                  () => {
                    this.toggleModal();
                  },
                );
              }}
              primary
            >
              {__('Pridať hráča')}
            </Button>
            &nbsp;
            <Button
              onClick={() => {
                this.toggleInvitationModal();
              }}
              primary
            >
              {__('Pozvať hráča')}
            </Button>
            &nbsp;
            <Button
              onClick={() => {
                this.toggleUserCreationModal();
              }}
              primary
            >
              {__('Vytvoriť hráča')}
            </Button>
            &nbsp;
            <Button
              onClick={() => {
                this.toggleSquadCopyModal();
              }}
              primary
            >
              {__('Skopírovať súpisku z inej súťaže')}
            </Button>
            <br />
            <br />
            {this.renderTable()}
          </Box>
          <Box title={__('Realizačný tým')}>
            <Modal
              handleClose={this.toggleCrewUserCreationForm}
              isOpen={this.state.crewUserCreationModalVisible}
            >
              <ModalContent>
                <CrewMemberCreationForm
                  crewCodelist={this.state.crewCodelist}
                  form={CREW_USER_CREATION_FORM_NAME}
                  onSubmit={this.submitCrewUserCreationForm}
                  competenceTypes={this.state.expertCodelist}
                />
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button onClick={this.toggleCrewUserCreationForm}>
                    {__('Zavrieť')}
                  </Button>
                  &nbsp;
                  <Button
                    primary
                    loading={this.state.isSubmittingCrewUserCreation}
                    onClick={() =>
                      this.props.dispatch(submit(CREW_USER_CREATION_FORM_NAME))
                    }
                  >
                    {__('Uložiť')}
                  </Button>
                </div>
              </ModalActions>
            </Modal>
            <Button
              onClick={() => {
                this.setState(
                  {
                    activeCrewMember: null,
                  },
                  () => {
                    this.toggleCrewSelectionModal();
                  },
                );
              }}
              primary
            >
              {__('Pridať osobu')}
            </Button>
            &nbsp;
            {activeAppSpace && activeAppSpace.app_space === 'uefa' && (
              <Button primary onClick={this.toggleCrewUserCreationForm}>
                {__('Vytvoriť osobu')}
              </Button>
            )}
            <br />
            <br />
            {this.renderCrewTable()}
          </Box>
        </Segment>
        <Modal
          isOpen={this.state.squadCopyModalOpened}
          handleClose={this.toggleSquadCopyModal}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Kopírovanie súpisky')}
            </Header>
            <SquadCopyForm
              parts={this.state.teamCompetitions}
              form={SQUAD_COPY_FORM_NAME}
              onSubmit={this.copySquadSubmit}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleSquadCopyModal}>
                {__('Zavrieť')}
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(SQUAD_COPY_FORM_NAME));
                }}
                primary
                loading={this.state.copySquadIsSubmitting}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal
          isOpen={this.state.userCreationModalOpened}
          handleClose={this.toggleUserCreationModal}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Vytvorenie nového hráča')}
            </Header>
            <Message warning>
              {__(
                'Pred vytvorením hráča dôkladne skontrolujte, či už v systéme neexistuje. Predídete tým problémom s nekorektnými štatistikami. Vytvoriť je možné len hráča s inou ako slovenskou príslušnosťou. Všetky osoby, u ktorých je to možné, by sa do systému mali registrovať prostredníctvom pozvánky.',
              )}
            </Message>
            <UserCreationForm
              form={USER_CREATION_FORM_NAME}
              onSubmit={this.submitUserCreationForm}
              competenceTypes={this.state.athletesCodelist}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleUserCreationModal}>
                {__('Zavrieť')}
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(USER_CREATION_FORM_NAME));
                }}
                primary
                loading={this.state.isSubmittingUserCreation}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal
          isOpen={this.state.invitationModalOpened}
          handleClose={this.toggleInvitationModal}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Pozvánka hráča do družstva')}
            </Header>
            {this.renderInvitationForm()}
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleInvitationModal}>
                {__('Zavrieť')}
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(
                    submit(SQUAD_PLAYER_INVITATION_FORM_NAME),
                  );
                }}
                primary
                loading={this.state.isSubmittingInvitation}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal isOpen={this.state.modalOpened} handleClose={this.toggleModal}>
          <ModalContent>
            <Header size="s" withSeparator>
              {this.state.activePlayer
                ? __('Úprava hráča v družstve')
                : __('Pridanie hráča do družstva')}
            </Header>
            {this.state.activePlayer && (
              <Message primary>
                {__(
                  'Ak v tomto okne zmeníte meno hráča, automaticky bude pôvodný hráč nahradený aj v zápasoch pod touto časťou súťaže. Zároveň sa automaticky prepočíta štatistika hráčov v rámci časti súťaže.',
                )}
              </Message>
            )}
            {this.state.modalOpened && this.renderForm()}
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleModal}>{__('Zavrieť')}</Button>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(SQUAD_PLAYER_FORM_NAME));
                }}
                primary
                loading={this.state.isSubmitting}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal
          isOpen={this.state.crewUserSelectionModalVisible}
          handleClose={this.toggleCrewSelectionModal}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Pridanie člena realizačného tímu')}
            </Header>
            {this.state.crewUserSelectionModalVisible && (
              <CrewSelectionForm
                crewCodelist={crewCodelist}
                initialValues={this.state.activeCrewMember}
                onSubmit={this.submitCrewForm}
                form={CREW_USER_SELECTION_FORM_NAME}
              />
            )}
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleCrewSelectionModal}>
                {__('Zavrieť')}
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(CREW_USER_SELECTION_FORM_NAME));
                }}
                primary
                loading={this.state.isSubmittingCrewSelection}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(CompetitionSquadDetail));
