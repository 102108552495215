import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import Box from '../../components/Box';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import Form from './form';
import SmarttagsForm from './smarttagsForm';
import { Match } from '../Matches/definitions';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import FormField from '@sportnet/ui/FormField';

export const FORM_NAME = 'NOMINATION_FORM';
const NOMINATION_SMARTTAGS_FORM_NAME = 'NOMINATION_SMARTTAGS_FORM';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

export interface NominationItem {
  sportnetUser?: {
    _id?: string;
    name?: string;
  };
  additionalData?: any;
  teamId?: string;
  closed?: boolean;
}

export interface User {
  sportnetId: string;
  displayName: string;
}

const mapStateToProps = (state: State) => ({
  formIsSubmitting: isSubmitting(FORM_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouteComponentProps<{
  competitionId: string;
  partId: string;
  matchId: string;
  teamId: string;
}> &
  OwnProps &
  IMapStateToProps;

class Nomination extends React.PureComponent<
  Props,
  {
    match: Match | null;
    experts: User[];
    nomination: any;
    appSpace: string | null;
    smarttags: any;
    isFetching: boolean;
    isSubmittingSmarttags: boolean;
    settings: {};
    competitionId: null | string;
    partId: null | string;
    rules?: null | {
      sport_sector?: string;
    };
    isDownloadingLineupPDF: boolean;
    isSendingLineupPDF: boolean;
    sendLineupModalOpened: boolean;
    email: string;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      match: null,
      experts: [],
      settings: {},
      nomination: null,
      smarttags: [],
      appSpace: null,
      isFetching: false,
      isSubmittingSmarttags: false,
      competitionId: null,
      partId: null,
      rules: null,
      isDownloadingLineupPDF: false,
      isSendingLineupPDF: false,
      sendLineupModalOpened: false,
      email: '',
    };
  }
  async componentDidMount() {
    const {
      match: {
        params: { competitionId, partId, matchId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isFetching: true,
      });
      const match = await api.clubManagerGetMatchById(
        competitionId,
        partId,
        matchId,
      );
      const {
        competition,
        competitionPart,
        rules,
        teams,
        smarttagsByAppSpace,
      } = match;
      const team = teams.find((i) => i._id === teamId);
      const smarttags = (smarttagsByAppSpace || []).find(
        (i) => !!team && i.appSpace === team.appSpace,
      );
      const { settings } = await api.clubManagerGetCompetitionPartById(
        competition._id,
        competitionPart._id,
      );
      const nomination = await api.clubManagerGetMatchNominations(
        competitionId,
        partId,
        matchId,
        teamId,
      );

      const experts = await api.clubManagerGetOrganizationSportExperts(
        competitionId,
        partId,
        teamId,
      );
      this.setState({
        match: match as any,
        experts: experts.sportExperts || [],
        nomination,
        competitionId: competition._id,
        partId: competitionPart._id,
        isFetching: false,
        settings: settings || {},
        rules,
        appSpace: team ? team.appSpace : null,
        smarttags: smarttags ? smarttags.smarttags : [],
      });
    } catch (e: any) {
      this.setState({
        isFetching: false,
      });
      alert(__('Nepodarilo sa získať zoznam športovcov'));
    }
  }

  downloadLineupPDF = async () => {
    const { match } = this.state;
    if (match) {
      this.setState({ isDownloadingLineupPDF: true });
      try {
        const response = await api.getMatchProtocolPDF(
          match?.appSpace,
          match?._id,
        );
        window.location.href = (response as any).url;
      } catch (e) {
        console.error(e);
        window.alert(__('Vygenerovanie zostáv sa nepodarilo'));
      } finally {
        this.setState({ isDownloadingLineupPDF: false });
      }
    }
  };

  sendLineupPDF = async () => {
    const { match } = this.state;
    if (match) {
      this.setState({ isSendingLineupPDF: true });
      try {
        await api.sendMatchProtocolPDF(match?.appSpace, match?._id, {
          email: this.state.email,
        });
      } catch (e) {
        console.error(e);
        window.alert(__('Vygenerovanie zostáv sa nepodarilo'));
      } finally {
        this.setState({ isSendingLineupPDF: false, email: '' });
      }
    }
  };

  submit = async (formData: any) => {
    const {
      match: {
        params: { competitionId, partId, matchId, teamId },
      },
    } = this.props;
    try {
      await api.clubManagerPutMatchNominations(
        competitionId,
        partId,
        matchId,
        teamId,
        {},
        { ...formData, teamId, crew: formData.crew || {} },
      );
    } catch (e: any) {
      alert(__('Nepodarilo sa uložiť nomináciu'));
      console.error(e);
    } finally {
      this.props.history.push(`/club-manager`);
    }
  };

  submitSmarttags = async (data: any) => {
    const {
      match: {
        params: { competitionId, partId, matchId, teamId },
      },
    } = this.props;
    this.setState({
      isSubmittingSmarttags: true,
    });
    try {
      const { smarttagsByAppSpace = [] } =
        await api.clubManagerUpdateMatchSmarttags(
          competitionId,
          partId,
          matchId,
          teamId,
          {},
          data,
        );
      const smarttags = smarttagsByAppSpace.find(
        (i) => i.appSpace === this.state.appSpace,
      );
      this.setState({
        smarttags: smarttags ? smarttags.smarttags : [],
      });
    } catch (e: any) {
      alert(__('Nepodarilo sa uložiť smarttagy'));
    } finally {
      this.setState({
        isSubmittingSmarttags: false,
      });
    }
  };

  toggleSendLineupModal = () => {
    this.setState((prev) => ({
      sendLineupModalOpened: !prev.sendLineupModalOpened,
    }));
  };

  render() {
    const {
      experts,
      nomination,
      isFetching,
      settings,
      rules,
      smarttags,
      isSubmittingSmarttags,
      appSpace,
    } = this.state;
    const {
      formIsSubmitting,
      match: {
        params: { competitionId, partId },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>{__('Nominácia do stretnutia')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              {!!nomination && nomination.closed && (
                <>
                  <Button
                    basic
                    onClick={this.downloadLineupPDF}
                    loading={this.state.isDownloadingLineupPDF}
                  >
                    {__('Stiahnuť zostavy')}
                  </Button>
                  &nbsp;
                  <Button basic onClick={this.toggleSendLineupModal}>
                    {__('Poslať zostavy e-mailom')}
                  </Button>
                  &nbsp;
                </>
              )}
              <Button
                primary
                loading={formIsSubmitting}
                onClick={async () => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext
          title={__('Nominácia do stretnutia')}
          breadcrumbs={[
            { name: __('Späť na hlavný prehľad'), url: '/club-manager' },
          ]}
        /> */}
        <Modal
          isOpen={this.state.sendLineupModalOpened}
          handleClose={this.toggleSendLineupModal}
        >
          <ModalContent>
            <FormField
              value={this.state.email}
              label={__('Emailová adresa')}
              onChange={(e: any) => this.setState({ email: e.target.value })}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button basic onClick={this.toggleSendLineupModal}>
                {__('Zavrieť')}
              </Button>
              &nbsp;
              <Button
                primary
                onClick={this.sendLineupPDF}
                loading={this.state.isSendingLineupPDF}
              >
                {__('Poslať zostavy e-mailom')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Segment>
          {nomination && (
            <>
              {!!appSpace && (
                <Box title={__('Smarttagy')}>
                  <SmarttagsForm
                    initialValues={{ smarttags }}
                    appSpace={appSpace}
                    onSubmit={this.submitSmarttags}
                    form={NOMINATION_SMARTTAGS_FORM_NAME}
                  />
                  <Button
                    primary
                    loading={isSubmittingSmarttags}
                    onClick={() =>
                      this.props.dispatch(
                        submit(NOMINATION_SMARTTAGS_FORM_NAME),
                      )
                    }
                  >
                    {__('Uložiť')}
                  </Button>
                </Box>
              )}
              <Form
                handleSubmit={this.submit}
                experts={experts}
                data={nomination}
                settings={settings}
                competitionId={competitionId}
                partId={partId}
                rules={rules}
                isClubManager
              />
            </>
          )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Nomination));
