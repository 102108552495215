import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import connectQueryParameters, {
  QueryHocInterface,
  QueryHocTypes,
} from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  commit,
  getListNextOffset,
  getListParameters,
  getListParametersSerialized,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { getProp, __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { profileSportSelector } from '../App/selectors';
import { getSuitableSeasons } from '../Codelists/Seasons/actions';
import { seasonsSelector } from '../Codelists/Seasons/selectors';
import { getTeams } from './actions';
import { LIST_LIMIT, LIST_NAME, Team } from './definitions';
import { teamsSelector } from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterParameters = RouteComponentProps<{ appspace: string }>;

const mapStateToProps = (state: State) => ({
  teams: teamsSelector(state),
  sport: profileSportSelector(state),
  seasons: seasonsSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
  nextOffset: getListNextOffset(LIST_NAME)(state),
  parameters: getListParameters(LIST_NAME)(state),
  serializedParameters: getListParametersSerialized(LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouterParameters & OwnProps & IMapStateToProps & QueryHocInterface;

class TeamsList extends React.PureComponent<Props> {
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(setBreadcrumbs([__('Zoznam družstiev')]));
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
          offset: this.props.query.offset,
          seasonIds: [],
        },
      }),
    );
    dispatch(getSuitableSeasons.action({ appspace }));
    this.fetchTeamsByParameters(this.props);
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.serializedQuery !== prevProps.serializedQuery) {
      this.setParameters(this.props.query);
    }
    if (this.props.serializedParameters !== prevProps.serializedParameters) {
      this.fetchTeamsByParameters(this.props);
    }
  }
  getGenderLabel = (id?: string): string | null => {
    switch (id) {
      case 'M':
        return __('Muži');
      case 'F':
        return __('Ženy');
      default:
        return __('Zmiešané');
    }
  };
  setParameters = (parameters: any): void => {
    this.props.dispatch(
      setParams({
        listName: LIST_NAME,
        parameters,
      }),
    );
  };
  fetchTeamsByParameters = async (props: Props = this.props) => {
    const {
      match: {
        params: { appspace },
      },
      dispatch,
      parameters,
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const {
            entities: { teams: newTeams },
            total,
            nextOffset,
          } = await dispatch(
            getTeams.action({
              appspace,
              limit: LIST_LIMIT,
              offset: parameters.offset,
              seasonIds:
                (parameters.seasonIds || []).length > 0 && parameters.seasonIds,
            }),
          );
          const items = Object.keys(newTeams).map((id) => {
            return newTeams[id];
          });
          return {
            total: total || items.length,
            results: items.map((object: Team) => {
              return object._id;
            }),
            nextOffset: nextOffset || null,
          };
        },
      }),
    );
  };

  getSeasonName = (seasonId: string) => {
    const { seasons } = this.props;

    const season = seasons.find((s) => s._id === seasonId);
    return getProp(season || {}, ['name'], '');
  };

  getSportSector = (value: string) => {
    const sector = getProp(this.props, ['sport', 'sport_sector'], []).find(
      (i: any) => i.value === value,
    );
    return getProp(sector, ['label'], '');
  };

  render() {
    const {
      teams,
      isFetching,
      total,
      match: {
        params: { appspace },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>{__('Zoznam družstiev')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              {total && (
                <Paginator
                  limit={LIST_LIMIT}
                  total={total}
                  offset={this.props.query.offset as number}
                  onChangeOffset={async (newOffset: number) => {
                    this.props.setParameter({
                      offset: newOffset,
                    });
                  }}
                />
              )}
            </ContextBarItem>
            <ContextBarSpacer />
            <ContextBarItem>
              <Link to={`/admin/${appspace}/teams/new`}>
                <Button primary>{__('Pridať družstvo')}</Button>
              </Link>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <>
          {/* <AppContext title={__('Zoznam družstiev')} /> */}
          {!isFetching && teams.length === 0 ? (
            <NotFound
              icon="search"
              title={__('Pre dané parametre sa nenašli žiadne družstvá')}
            />
          ) : (
            <Segment>
              <Segment raised>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__('Názov družstva')}</Th>
                      <Th>{__('Športové odvetvie')}</Th>
                      <Th>{__('Pohlavie')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {teams.map((team: Team) => {
                      return (
                        <Tr
                          onClick={() => {
                            this.props.history.push(
                              `/admin/${appspace}/teams/${team._id}`,
                            );
                          }}
                          key={team._id}
                        >
                          <Td>{team.name}</Td>
                          <Td>{this.getSportSector(team.sport_sector)}</Td>
                          <Td>{this.getGenderLabel(team.gender)}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Segment>
            </Segment>
          )}
        </>
      </ScrollLayout>
    );
  }
}

const connectedToQuery = connectQueryParameters<any>({
  parameters: {
    seasonIds: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
  },
})(TeamsList);

export default connect(mapStateToProps)(withRouter(connectedToQuery));
