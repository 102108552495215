import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderTop from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import Box from '../../../components/Box';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { State } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { setBreadcrumbs } from '../../App/actions';
import { activeAppspaceProfileSelector } from '../../App/selectors';
import { getAgeCategoryById, postAgeCategory, putAgeCategory } from './actions';
import { AgeCategory } from './definitions';
import Form, { FORM_NAME } from './form';
import {
  ageCategoryByIdIsFetchingSelector,
  ageCategoryByIdSelector,
} from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterProps = RouteComponentProps<{
  id: string;
  appspace: string;
}>;

const mapStateToProps = (state: State, props: RouterProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return {
    ppo: activeAppspaceProfileSelector(state),
    item: ageCategoryByIdSelector(id)(state),
    isFetching: ageCategoryByIdIsFetchingSelector(id)(state),
    isSubmittingForm: isSubmitting(FORM_NAME)(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouterProps & OwnProps & IMapStateToProps;

class AgeCategoryDetail extends React.PureComponent<Props> {
  state = {
    isDeleting: false,
  };

  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace, id },
      },
    } = this.props;
    dispatch(
      setBreadcrumbs([
        <Link key={0} to={`/admin/${appspace}/codelists/age-categories`}>
          {__('Číselník vekových kategórií')}
        </Link>,
        id === 'new'
          ? __('Nová veková kategória')
          : __('Úprava vekovej kategórie'),
      ]),
    );
    if (id !== 'new') {
      try {
        await dispatch(getAgeCategoryById.action({ appspace, id }));
      } catch (e: any) {
        console.error(e);
      }
    }
  }
  onSubmit = async (data: AgeCategory) => {
    const {
      dispatch,
      history: { push },
      match: {
        params: { appspace, id },
      },
    } = this.props;
    const requestData = { ...data };
    try {
      if (id === 'new') {
        await dispatch(
          postAgeCategory.action({
            appspace,
            ageCategory: requestData,
          }),
        );
      } else {
        if (requestData._id) {
          delete requestData._id;
        }
        await dispatch(
          putAgeCategory.action({
            appspace,
            ageCategoryId: id,
            ageCategory: requestData,
          }),
        );
      }
    } catch (e: any) {
      if (e.details && e.details.name === 'DUPLICATED_AGE_CATEGORY') {
        alert(__('Veková kategória s týmto názvom už existuje'));
      }
      throw e;
    }
    push(`/admin/${appspace}/codelists/age-categories`);
  };

  deleteAgeCategory = async () => {
    const {
      match: {
        params: { appspace, id },
      },
      history: { push },
    } = this.props;
    if (window.confirm(__('Skutočne si želáte odstrániť vekovú kategóriu?'))) {
      this.setState({
        isDeleting: true,
      });
      try {
        await api.adminDeleteAgeCategory(appspace, id);
        push(`/admin/${appspace}/codelists/age-categories`);
      } catch (e: any) {
        alert(__('Vekovú kategóriu sa nepodarilo odstrániť'));
      } finally {
        this.setState({
          isDeleting: false,
        });
      }
    }
  };

  render() {
    const {
      item,
      isFetching,
      isSubmittingForm,
      match: {
        params: { appspace, id },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderTop>
            <HeaderTop.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/codelists/age-categories`}
            />
            <HeaderTop.Header>
              {!!item
                ? `${__('Detail vekovej kategórie')} | ${item.name}`
                : __('Nová veková kategória')}
            </HeaderTop.Header>
          </HeaderTop>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
                loading={isSubmittingForm}
                primary
              >
                {__('Uložiť')}
              </Button>
              {id !== 'new' && (
                <>
                  &nbsp;
                  <Button
                    onClick={() => {
                      this.deleteAgeCategory();
                    }}
                    loading={this.state.isDeleting}
                    danger
                  >
                    {__('Odstrániť')}
                  </Button>
                </>
              )}
            </ContextBarItem>
          </ContextBar>
        }
      >
        <Segment>
          <Box title={__('Základné informácie')}>
            <Form
              form={FORM_NAME}
              data={item}
              id={id}
              onSubmit={this.onSubmit}
            />
          </Box>
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(AgeCategoryDetail));
