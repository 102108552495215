import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import Box from '../../components/Box';
import sportnetApi from '../../sportnetApi';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { getProp, __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { Player, Result } from '../Matches/definitions';
import {
  getCompetitionById,
  getCompetitionPartById,
  postCompetitionPart,
  putCompetitionPart,
} from './actions';
import CompetitionPartClubManagers from './clubManagers';
import CompetitionPartForm, { FORM_NAME } from './competitionPartForm';
import {
  competitionByIdSelector,
  competitionPartByIdSelector,
} from './selectors';
import { endOfDay, format, startOfDay } from 'date-fns';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterProps = RouteComponentProps<{
  id: string;
  appspace: string;
  competitionId: string;
  competitionPartId: string;
}>;

const mapStateToProps = (state: State, props: OwnProps & RouterProps) => ({
  competitionPart: competitionPartByIdSelector(
    props.match.params.id,
    props.match.params.competitionPartId,
  )(state),
  competition: competitionByIdSelector(props.match.params.id)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouterProps & OwnProps & IMapStateToProps;

class CompetitionPartDetail extends React.PureComponent<Props> {
  state = {
    suitableClubs: [],
    isFetching: false,
    lastMatchResult: null,
    clubs: [],
  };
  componentDidMount() {
    this.getCompetitionPart();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.match.params.competitionPartId !==
      prevProps.match.params.competitionPartId ||
      getProp(this.props, ['competitionPart', 'invitations'], []).length !==
      getProp(prevProps, ['competitionPart', 'invitations'], []).length
    ) {
      this.getCompetitionPart(this.props);
    }
    if (
      getProp(this.props, ['competitionPart', 'resultsTable', 'results'], [])
        .length !==
      getProp(prevProps, ['competitionPart', 'resultsTable', 'results'], [])
        .length
    ) {
      this.getCurrentResults(this.props);
    }
  }

  getCurrentResults = async (props: Props = this.props) => {
    const {
      match: {
        params: { appspace, id, competitionPartId },
      },
    } = props;
    try {
      const lastMatchResult = await api.getCompetitionPartCurrentResultTable(
        appspace,
        id,
        competitionPartId,
      );
      this.setState({
        lastMatchResult,
      });
    } catch (e: any) {
      // silent
    }
  };

  getCompetitionPart = async (props: Props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace, id, competitionPartId },
      },
    } = props;
    this.setState({
      isFetching: true,
    });
    const competitionEntities = await dispatch(
      getCompetitionById.action({ appspace, id }),
    );
    const competition = getProp(competitionEntities, [
      'entities',
      'competitions',
      id,
    ]);
    if (competitionPartId !== 'new') {
      if (!Object.keys(competition.rules || {}).length) {
        const res = await sportnetApi.organizationPPOListRelatedPPOs(appspace);
        this.setState({
          suitableClubs: (res.items || []).map((i) => ({
            label: i.name || '',
            value: i._id || '',
          })),
        });
      }
      await this.getCurrentResults(props);
      const {
        entities: { competitionPartsByCompetitionId },
      } = await dispatch(
        getCompetitionPartById.action({
          appspace,
          competitionId: id,
          competitionPartId,
        }),
      );
      const part =
        competitionPartsByCompetitionId[competition._id][competitionPartId];
      if ((part.invitations || []).length) {
        const { items: clubs } = await sportnetApi.organizationPPOProfiles({
          ids: (part.invitations || []).map((i) => i.clubId),
        });
        this.setState({
          clubs,
        });
      }
    }
    dispatch(
      setBreadcrumbs([
        <Link key={0} to={`/admin/${appspace}/competitions`}>
          {__('Zoznam súťaží')}
        </Link>,
        <Link key={1} to={`/admin/${appspace}/competitions/${id}`}>
          {competition.name}
        </Link>,
        competitionPartId === 'new'
          ? __('Nová časť súťaže')
          : __('Úprava časti súťaže'),
      ]),
    );
    this.setState({
      isFetching: false,
    });
  };

  submit = async (competitionPart: {
    _id: string;
    name: string;
    format?: { value: string } | string;
    rules: {
      sport_sector: { value: string };
      discipline: { value: string };
    };
    resultsTableObject: {
      players: Player[];
      results: {
        [key: string]: Result;
      };
    };
    dateFrom: string;
    dateTo: string;
    resultsTable: any;
  }) => {
    const {
      dispatch,
      match: {
        params: { id: competitionId, appspace },
      },
      history: { push },
    } = this.props;
    const data: any = JSON.parse(JSON.stringify(competitionPart));
    if (competitionPart.dateFrom) {
      data.dateFrom = format(
        startOfDay(new Date(competitionPart.dateFrom)),
        'yyyy-MM-dd HH:mm:ss',
      );
    }
    if (competitionPart.dateTo) {
      data.dateTo = format(
        endOfDay(new Date(competitionPart.dateTo)),
        'yyyy-MM-dd HH:mm:ss',
      );
    }
    if (competitionPart.rules.sport_sector) {
      data.rules.sport_sector =
        competitionPart.rules.sport_sector.value ||
        competitionPart.rules.sport_sector.value;
    }
    if (competitionPart.rules.discipline) {
      data.rules.discipline =
        competitionPart.rules.discipline.value ||
        competitionPart.rules.discipline.value;
    }
    if (competitionPart._id) {
      delete data.format;
      delete data.rules;
      dispatch(
        putCompetitionPart.action({
          appspace,
          competitionId,
          competitionPart: data,
        }),
      ).then(() => {
        push(`/admin/${appspace}/competitions/${competitionId}`);
      });
    } else {
      if (competitionPart.format) {
        if (
          typeof competitionPart.format !== 'string' &&
          typeof competitionPart.format !== 'undefined'
        ) {
          data.format = competitionPart.format.value;
        }
      }
      dispatch(
        postCompetitionPart.action({
          appspace,
          competitionId,
          competitionPart: data,
        }),
      ).then((part) => {
        push(
          `/admin/${appspace}/competitions/${competitionId}/parts/${part._id}`,
        );
      });
    }
  };
  render() {
    const {
      match: {
        params: { id, competitionPartId, appspace },
      },
      competitionPart,
      competition,
    } = this.props;
    const { isFetching, clubs } = this.state;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/competitions/${!!competition && competition._id
                }`}
            />
            <HeaderBar.Header>
              {!!competition && !!competitionPart
                ? `${competition.name} | ${competitionPart.name}`
                : `${!!competition && competition.name} | ${__(
                  'Nová časť súťaže',
                )}`}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext
          title={
            !!competitionPart ? competitionPart.name : __('Nová časť súťaže')
          }
          breadcrumbs={[
            {
              name: __('Zoznam súťaží'),
              url: `/admin/${appspace}/competitions`,
            },
            {
              name: __('Detail súťaže'),
              url: `/admin/${appspace}/competitions/${id}`,
            },
          ]}
        /> */}
        <Segment>
          <CompetitionPartForm
            appspace={appspace}
            onSubmit={this.submit}
            id={competitionPartId}
            suitableClubs={this.state.suitableClubs}
            competitionId={id}
            data={competitionPart}
          />
          {competitionPart && (
            <Box title={__('Kluboví manažéri')}>
              <CompetitionPartClubManagers
                appSpace={appspace}
                competitionId={id}
                partId={competitionPartId}
                clubManagers={competitionPart.clubManagers || []}
                teams={competitionPart.teams || []}
              />
            </Box>
          )}
          {competitionPart && (competitionPart.invitations || []).length > 0 && (
            <Box title={__('Čakajúce pozvánky')}>
              <BasicTable
                columns={[{ header: __('Názov klubu') }]}
                rows={competitionPart.invitations || []}
                renderRow={(i) => {
                  const club = clubs.find(
                    (c: any) => c._id === i.clubId,
                  ) as any;
                  if (club) {
                    return [club.name];
                  }
                  return [''];
                }}
                rowKey="invitationId"
              />
            </Box>
          )}
          {this.state.lastMatchResult &&
            competitionPart &&
            competitionPart.type === 'collective' && (
              <Box title={__('Aktuálny stav časti súťaže')}>
                <BasicTable
                  columns={[
                    {
                      header: __('Družstvo'),
                    },
                    {
                      header: __('OZ'),
                      width: 40,
                    },
                    {
                      header: __('V'),
                      width: 40,
                    },
                    {
                      header: __('R'),
                      width: 40,
                    },
                    {
                      header: __('P'),
                      width: 40,
                    },
                    {
                      header: __('G'),
                      width: 80,
                    },
                    {
                      header: __('B'),
                      width: 40,
                    },
                  ]}
                  rows={getProp(
                    this.state,
                    ['lastMatchResult', 'results'],
                    [],
                  ).map((r: any) => ({
                    ...r,
                    rowKey: r.team._id,
                  }))}
                  renderRow={(result: Result) => [
                    result.team.name,
                    result.stats.matches.played,
                    result.stats.matches.won,
                    result.stats.matches.draw,
                    result.stats.matches.lost,
                    `${result.stats.goals.given}:${result.stats.goals.received}`,
                    result.stats.points,
                  ]}
                  rowKey={'rowKey'}
                />
              </Box>
            )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(CompetitionPartDetail));
