import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as listReducer } from '@sportnet/redux-list';
import {
  applicationReducer,
  entitiesReducer,
  errorReducer,
} from './containers/App/reducer';
import { ageCategoriesReducer } from './containers/Codelists/AgeCategories/reducer';
import { seasonsReducer } from './containers/Codelists/Seasons/reducer';
import {
  competitionsReducer,
  sportSectorSettingsReducer,
} from './containers/Competitions/reducer';
import { sportObjectsReducer } from './containers/SportObjects/reducer';
import { teamsReducer } from './containers/Teams/reducer';

const rootReducer = combineReducers({
  application: applicationReducer,
  error: errorReducer,
  list: listReducer,
  detail: combineReducers({
    competitions: competitionsReducer,
    sportObjects: sportObjectsReducer,
    teams: teamsReducer,
    seasons: seasonsReducer,
    ageCategories: ageCategoriesReducer,
  }),
  entities: entitiesReducer,
  sportSectorSettings: sportSectorSettingsReducer,
  form: formReducer,
});

export default rootReducer;

export type StateType<ReducerOrMap> = ReducerOrMap extends (
  ...args: any[]
) => any
  ? ReturnType<ReducerOrMap>
  : ReducerOrMap extends object
  ? { [K in keyof ReducerOrMap]: StateType<ReducerOrMap[K]> }
  : never;

export type State = StateType<typeof rootReducer>;
