import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities';
import SettingsForm from './form';

type Props = RouteComponentProps<{ appspace: string }>;

class DomainSettings extends React.PureComponent<Props> {
  render() {
    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>{__('Nastavenia domény')}</HeaderBar.Header>
          </HeaderBar>
        }
      >
        {/* <AppContext title={__('Nastavenia domény')} /> */}
        <SettingsForm />
      </ScrollLayout>
    );
  }
}

export default withRouter(DomainSettings);
