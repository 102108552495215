import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import Box from '../../components/Box';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { getProp, transformQueryParameters, __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { profileSportSelector } from '../App/selectors';
import { getSeasons } from '../Codelists/Seasons/actions';
import { Season } from '../Codelists/Seasons/definitions';
import { seasonsSelector } from '../Codelists/Seasons/selectors';
import { Team } from '../Teams/definitions';
import {
  deleteCompetitionPart,
  getCompetitionById,
  getCompetitionParts,
  postCompetition,
  putCompetition,
} from './actions';
import CmsContent from './cmsContent';
import { CompetitionPart } from './definitions';
import Form, { FORM_NAME } from './form';
import {
  competitionByIdIsFetchingSelector,
  competitionByIdSelector,
  competitionPartsByCompetitionIdSelector,
} from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterProps = RouteComponentProps<{
  id: string;
  appspace: string;
}>;

const mapStateToProps = (state: State, props: RouterProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return {
    isSubmitting: isSubmitting(FORM_NAME)(state),
    competition: competitionByIdSelector(id)(state),
    competitionParts: competitionPartsByCompetitionIdSelector(id)(state),
    isFetching: competitionByIdIsFetchingSelector(id)(state),
    seasons: seasonsSelector(state),
    profileSportValues: profileSportSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouterProps & OwnProps & IMapStateToProps;

class CompetitionDetail extends React.PureComponent<Props> {
  state = {
    suitableTeams: [],
    isFetching: false,
    isDeleting: false,
  };
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace, id },
      },
    } = this.props;

    this.setBreadcrumbs();
    if (id !== 'new') {
      await this.getCompetition();
    }
    dispatch(getSeasons.action({ appspace }));
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setBreadcrumbs(this.props);
      if (this.props.match.params.id !== 'new') {
        this.getCompetition(this.props);
      }
    }
  }
  getCompetition = async (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace, id },
      },
    } = props;
    this.setState({
      isFetching: true,
    });
    try {
      await dispatch(getCompetitionById.action({ appspace, id }));
      dispatch(getCompetitionParts.action({ appspace, competitionId: id }));
    } catch (e: any) {
      console.error(e);
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  deleteCompetition = async () => {
    const {
      match: {
        params: { appspace, id },
      },
    } = this.props;
    if (window.confirm(__('Skutočne si želáte odstrániť súťaž?'))) {
      this.setState({
        isDeleting: true,
      });
      try {
        await api.adminDeleteCompetition(appspace, id);
        this.props.history.push(`/admin/${appspace}/competitions`);
      } catch (e: any) {
        if (e.details.name === 'CANNOT_DELETE_COMPETITION') {
          alert(__('Súťaž obsahuje stretnutia, nie je možné ju odstrániť'));
        } else {
          alert(__('Súťaž sa nepodarilo odstrániť'));
        }
        console.error(e);
      } finally {
        this.setState({
          isDeleting: false,
        });
      }
    }
  };

  setBreadcrumbs = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace, id },
      },
    } = props;
    dispatch(
      setBreadcrumbs([
        <Link key={0} to={`/admin/${appspace}/competitions`}>
          {__('Zoznam súťaží')}
        </Link>,
        id === 'new' ? __('Nová súťaž') : __('Úprava súťaže'),
      ]),
    );
  };
  onSubmit = async (data: {
    season: string;
    parts: CompetitionPart[];
    appSpace: string;
    ownerPPO: string;
    teams: Team[];
    name: string;
    sport: string;
    smarttagsByAppSpace: any;
    homepageSectionIdOrUniqId: {
      value: string;
    };
    _id: string;
  }) => {
    const {
      dispatch,
      seasons,
      history: { push },
      match: {
        params: { appspace, id },
      },
    } = this.props;
    const requestData: {
      season: Season;
      parts?: CompetitionPart[];
      appSpace: string;
      teams?: Team[];
      competitionGroupId?: string;
      homepageSectionIdOrUniqId?: string | { value: string };
      name: string;
      sport: string;
      smarttagsByAppSpace: any;
      ownerPPO: string;
      _id: string;
    } = {
      ...data,
      season: seasons.find((season) => season._id === data.season)!,
    };
    const {
      _id,
      appSpace,
      parts,
      teams,
      competitionGroupId,
      smarttagsByAppSpace,
      ownerPPO,
      ...rest
    } = requestData;
    const restData: typeof rest & { competitionGroupId?: string } = rest;
    if (restData.homepageSectionIdOrUniqId) {
      restData.homepageSectionIdOrUniqId = data.homepageSectionIdOrUniqId.value;
    }
    let competitionId;
    if (id === 'new') {
      const query = transformQueryParameters(this.props.location.search);
      if (query.competitionGroupId) {
        restData.competitionGroupId = query.competitionGroupId;
      }
      const res = await dispatch(
        postCompetition.action({ appspace, competition: restData }),
      );
      competitionId = res._id;
    } else {
      await dispatch(
        putCompetition.action({
          appspace,
          competitionId: id,
          competition: restData,
        }),
      );
      competitionId = id;
    }
    push(`/admin/${appspace}/competitions/${competitionId}`);
  };
  render() {
    const {
      competition,
      competitionParts,
      seasons,
      profileSportValues,
      isSubmitting,
      match: {
        params: { id, appspace },
      },
    } = this.props;

    const { isFetching } = this.state;

    const query = transformQueryParameters(this.props.location.search);

    const { suitableTeams } = this.state;
    const anyTeamWithoutAthletes = getProp(
      competition || {},
      ['teams'],
      [],
    ).find((team: any) => !getProp(team, ['squad', 'athletes']).length);

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/competitions`}
            />
            <HeaderBar.Header>
              {!!competition ? competition.name : __('Nová súťaž')}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              {competition && (
                <>
                  <Button
                    onClick={() => {
                      this.props.history.push(
                        `/admin/${appspace}/competitions/new?competitionGroupId=${competition.competitionGroupId}`,
                      );
                    }}
                    success
                  >
                    {__('Nový ročník')}
                  </Button>
                  &nbsp;
                  <Button
                    loading={this.state.isDeleting}
                    danger
                    onClick={this.deleteCompetition}
                  >
                    {__('Zmazať')}
                  </Button>
                  &nbsp;
                </>
              )}
              <Button
                onClick={() => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
                primary
                loading={isSubmitting}
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext
          title={!!competition ? competition.name : __('Nová súťaž')}
          breadcrumbs={[
            {
              name: __('Zoznam súťaží'),
              url: `/admin/${appspace}/competitions`,
            },
          ]}
        /> */}
        <Segment>
          {anyTeamWithoutAthletes && (
            <Message danger title={__('Prázdna súpiska')}>
              {__(
                'Niektorý z tímov má prázdnu súpisku a nebude sa teda môcť zúčastniť žiadneho stretnutia.',
              )}
            </Message>
          )}
          {id === 'new' && !query.competitionGroupId && (
            <Message warning title={__('Prvý ročník súťaže')}>
              {__(
                'Súťaž bude vytvorená ako prvý ročník súťaže. V prípade, že ide o nový ročník už existujúcej súťaže, prejdite na detail predchádzajúceho ročníka a pre vytvorenie nového ročníka kliknite na tlačidlo "Nový ročník" v pravom dolnom rohu obrazovky.',
              )}
            </Message>
          )}
          <Form
            data={competition}
            appspace={appspace}
            seasons={seasons.filter((s) => s.appSpace === appspace)}
            profileSportValues={profileSportValues}
            id={id}
            onSubmit={this.onSubmit}
            suitableTeams={suitableTeams}
          />
          {id !== 'new' && (
            <Box title={__('Redakčný obsah')}>
              <CmsContent id={id} />
            </Box>
          )}
          {id !== 'new' && (
            <Box title={__('Časti súťaže')}>
              <Link to={`/admin/${appspace}/competitions/${id}/parts/new`}>
                <Button primary>{__('Pridať časť súťaže')}</Button>
              </Link>
              <br />
              <br />
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Názov časti súťaže')}</Th>
                    <Th style={{ width: rem(340) }} />
                  </Tr>
                </Thead>
                <Tbody>
                  {competitionParts.map((part: any) => {
                    return (
                      <Tr
                        key={part._id}
                        onClick={() => {
                          this.props.history.push(
                            `/admin/${appspace}/competitions/${id}/parts/${part._id}`,
                          );
                        }}
                      >
                        <Td>{part.name}</Td>
                        <Td right>
                          {part.format && part.format === 'draw' ? (
                            <>
                              <Button
                                primary
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.props.history.push(
                                    `/admin/${appspace}/competitions/${id}/parts/${part._id}/matches/new`,
                                  );
                                }}
                              >
                                {__('Pridať zápis')}
                              </Button>
                              &nbsp;
                              <Button
                                primary
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.props.history.push(
                                    `/admin/${appspace}/competitions/${id}/parts/${part._id}/draw`,
                                  );
                                }}
                              >
                                {__('Pavúk')}
                              </Button>
                              &nbsp;
                              <Button
                                primary
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.props.history.push(
                                    `/admin/${appspace}/matches?competitionId=${id}&competitionPartId=${
                                      part._id
                                    }&seasonId=${getProp(competition || {}, [
                                      'season',
                                      '_id',
                                    ])}`,
                                  );
                                }}
                              >
                                {__('Výsledky')}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                primary
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.props.history.push(
                                    `/admin/${appspace}/competitions/${id}/parts/${part._id}/matches/new`,
                                  );
                                }}
                              >
                                {__('Pridať zápis')}
                              </Button>
                              &nbsp;
                              <Button
                                primary
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.props.history.push(
                                    `/admin/${appspace}/matches?competitionId=${id}&competitionPartId=${
                                      part._id
                                    }&seasonId=${getProp(competition || {}, [
                                      'season',
                                      '_id',
                                    ])}`,
                                  );
                                }}
                              >
                                {__('Výsledky')}
                              </Button>
                            </>
                          )}
                          &nbsp;
                          <Button
                            danger
                            onClick={(e: any) => {
                              e.stopPropagation();
                              const confirm = window.confirm(
                                __('Skutočne si želáte odstrániť časť súťaže?'),
                              );
                              if (confirm) {
                                this.props.dispatch(
                                  deleteCompetitionPart.action({
                                    appspace,
                                    competitionId: id,
                                    competitionPartId: part._id,
                                  }),
                                );
                              }
                            }}
                          >
                            {__('Odstrániť')}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(CompetitionDetail));
