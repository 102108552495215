import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Input from '@sportnet/ui/Input';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { State } from '../../rootReducer';
import sportnetApi from '../../sportnetApi';
import { required, __ } from '../../utilities';
import { Team } from '../Teams/definitions';

interface OwnProps {
  teams: Team[];
}

const mapStateToProps = (state: State, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    sportnetId: selector(state, 'sportnetId'),
  };
};

const ClubManagerForm: React.FC<
  OwnProps & ReturnType<typeof mapStateToProps>
> = ({ teams, sportnetId }) => {
  const [user, setUser] = React.useState<{
    name?: string;
    surname?: string;
  } | null>(null);
  const [isFetchingUser, setIsFetchingUser] = React.useState(false);

  const fetchUser = async (userId: string) => {
    setIsFetchingUser(true);
    setUser(null);
    try {
      const userData = await sportnetApi.getPublicUserProfile(userId);
      setUser(userData);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať podrobnosti o používateľovi.'));
    } finally {
      setIsFetchingUser(false);
    }
  };

  return (
    <>
      <Field
        name="sportnetId"
        component={FormFieldRedux}
        label={__('Sportnet ID')}
        required
        validate={[required]}
      >
        <input />
        {isFetchingUser && <Input.Loading />}
        <Input.Button
          primary
          icon="search"
          onClick={() => {
            fetchUser(sportnetId);
          }}
        />
      </Field>
      {!!user && (
        <>
          <FormField
            type="input"
            disabled
            label={__('Meno a priezvisko')}
            value={`${user.name} ${user.surname}`}
          />
          <Field
            required
            validate={[required]}
            options={teams.map((t) => ({
              label: t.name || t.displayName,
              value: t.appSpace,
            }))}
            name="clubId"
            type="theselect"
            component={FormFieldRedux}
            label={__('Tím')}
          />
        </>
      )}
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm({}),
)(ClubManagerForm) as any;
