import { createSelector } from 'reselect';
import { State } from '../../rootReducer';
import { getProp } from '../../utilities';

const applicationDomainSelector = (state: State) => {
  return state.application;
};

const errorDomainSelector = (state: State) => {
  return state.error;
};

export const errorSelector = createSelector(
  errorDomainSelector,
  (substate) => substate.message,
);

const activeAppspaceIdSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.activeAppspace,
);

export const appspacesSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.appspaces,
);

const codelistsSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.codelists,
);

export const sportsSelector = createSelector(
  codelistsSelector,
  (substate) => substate.sport || [],
);

export const codelistSelector = (type: string) =>
  createSelector(codelistsSelector, (substate) => substate[type] || []);

export const sportByValueSelector = (value: string) =>
  createSelector(sportsSelector, (substate) => {
    const sport = substate.find((item) => item.value === value);
    if (sport) {
      return sport;
    }
    return null;
  });

const sportSectorsSelector = createSelector(
  codelistsSelector,
  (substate) => substate.sectors || {},
);

export const sportSectorsBySportSelector = (sport: string) =>
  createSelector(sportSectorsSelector, (substate) => substate[sport] || []);

const sportDisciplinesSelector = createSelector(
  codelistsSelector,
  (substate) => substate.disciplines || {},
);

export const sportDisciplinesBySportSectorSelector = (sector: string) =>
  createSelector(sportDisciplinesSelector, (substate) =>
    getProp(substate, [sector], []),
  );

export const activeAppspaceSelector = createSelector(
  appspacesSelector,
  activeAppspaceIdSelector,
  (appspaces, activeId) => {
    if (activeId && appspaces) {
      const ppo = appspaces.find((appspace) => {
        return appspace.app_space === activeId;
      });
      return ppo;
    }
    return null;
  },
);

export const activeAppspaceNameSelector = createSelector(
  activeAppspaceSelector,
  (substate) => {
    if (substate) {
      return substate.org_profile.name;
    }
    return null;
  },
);

export const activeAppspaceProfileSelector = createSelector(
  activeAppspaceSelector,
  (substate) => {
    if (substate) {
      return substate.org_profile;
    }
    return null;
  },
);

export const profileSportSelector = createSelector(
  activeAppspaceProfileSelector,
  sportsSelector,
  sportSectorsSelector,
  sportDisciplinesSelector,
  (profile, sports, sectors, disciplines) => {
    if (profile) {
      const sport = sports.find((item) => item.value === profile.sport);
      const sportSector = sport && sectors[sport.value];
      return {
        sport,
        sport_sector: sportSector,
      };
    }
    return null;
  },
);

export const authUserSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.authUser,
);

export const applicationInfoSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.applicationInfo,
);

export const breadcrumbsSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.breadcrumbs,
);

export const appsSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.apps,
);
