export const LIST_NAME = 'SEASONS_LIST';

export interface SeasonUpdate {
  name: string;
  dateFrom: string;
  dateTo: string;
}

export interface Season extends SeasonUpdate {
  _id: string;
  appSpace: string;
  sport: string;
}
