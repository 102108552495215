import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import {
  change,
  Field,
  formValueSelector,
  getFormSubmitErrors,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { CustomThunkDispatch } from '../../../../../configureStore';
import { State } from '../../../../../rootReducer';
import { getProp, __ } from '../../../../../utilities';
import UserSelectionInput from '../userSelectionInput';
import styled from 'styled-components';
import { rem } from 'polished';

const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

const ErrorText = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  margin-top: ${rem(3)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const FOOTBALL_POSITIONS = [
  {
    label: __('Brankár'),
    value: __('goalkeeper'),
  },
  {
    label: __('Obranca'),
    value: __('defender'),
  },
  {
    label: __('Záložník'),
    value: __('midfielder'),
  },
  {
    label: __('Útočník'),
    value: __('forward'),
  },
];

export interface Athlete {
  sportnetId: string;
  displayName: string;
}

interface OwnProps {
  athletes: Athlete[];
}

const mapStateToProps = (state: State, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    sportnetId: selector(state, 'sportnetId'),
    errors: getFormSubmitErrors(props.form)(state),
  };
};

type Props = InjectedFormProps<
  {
    displayName: string;
    sportnetId: string;
  },
  OwnProps
> &
  OwnProps &
  ReturnType<typeof mapStateToProps> & {
    dispatch: CustomThunkDispatch;
  } & RouteComponentProps<{
    id: string;
    partId: string;
    teamId: string;
    appspace: string;
  }>;

const BeachFootballSquadPlayerForm: React.FC<Props> = ({
  match: {
    params: { appspace, id, partId, teamId },
  },
  dispatch,
  form,
  sportnetId,
  errors,
}) => {
  return (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label>{__('Výber hráča (min. 4 znaky)')}</Label>
          <RelativeWrapper>
            <UserSelectionInput
              error={getProp(errors, ['sportnetId'])}
              value={sportnetId}
              appSpace={appspace}
              competitionId={id}
              partId={partId}
              teamId={teamId}
              onChange={(i) => {
                dispatch(change(form, 'sportnetId', i));
              }}
            />
            {!!getProp(errors, ['sportnetId']) && (
              <ErrorText title={getProp(errors, ['sportnetId'])}>
                {getProp(errors, ['sportnetId'])}
              </ErrorText>
            )}
          </RelativeWrapper>
        </FormGroup>
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          name="additionalData.captain"
          type="checkbox"
          label={__('Kapitán')}
        />
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          name="additionalData.position"
          type="select"
          label={__('Pozícia')}
        >
          <option hidden />
          {FOOTBALL_POSITIONS.map((position) => (
            <option key={position.value} value={position.value}>
              {position.label}
            </option>
          ))}
        </Field>
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          name="additionalData.nr"
          type="number"
          label={__('Číslo dresu')}
        />
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          name="additionalData.shirtname"
          type="number"
          label={__('Meno na drese')}
        />
      </Col>
    </Row>
  );
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  reduxForm<any, any>({ enableReinitialize: true }),
)(BeachFootballSquadPlayerForm) as any;
