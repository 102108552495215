import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Filters from '@sportnet/ui/Filter';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import withQueryHoc, {
  QueryHocInterface,
  QueryHocTypes,
} from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { commit, initialize, isCommiting } from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import LoaderWrapper from '../../components/LoaderWrapper';
import { activeAppspaceProfileSelector } from '../App/selectors';
import { getRecords } from './actions';
import { IRecord } from './definitions';
import { recordsSelector } from './selectors';

export const RECORDS_LIST_NAME = 'RECORDS_LIST';

const mapStateToProps = (state: State) => ({
  items: recordsSelector(RECORDS_LIST_NAME)(state),
  isFetching: isCommiting(RECORDS_LIST_NAME)(state),
  ppo: activeAppspaceProfileSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ appspace: string }> & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  } & QueryHocInterface;

class RecordsList extends React.PureComponent<
  Props,
  {
    disciplines: any[];
  }
> {
  state = {
    disciplines: [],
  };

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(
      initialize({
        listName: RECORDS_LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    this.getDisciplines();
    this.getRecordsList();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.ppo && this.props.ppo) {
      this.getDisciplines();
    }
  }

  getDisciplines = async () => {
    const { ppo } = this.props;
    if (ppo) {
      try {
        const res = await api.getSettingBySportSector(
          'sport_sector_disciplines',
          ppo.sport,
        );
        this.setState({
          disciplines: res.items,
        });
      } catch (e: any) {
        //
      }
    }
  };

  getRecordsList = (props = this.props) => {
    const {
      match: {
        params: { appspace },
      },
      dispatch,
    } = props;
    dispatch(
      commit.action({
        listName: RECORDS_LIST_NAME,
        load: async () => {
          const {
            entities: { records },
          } = await dispatch(
            getRecords.action({
              appspace,
            }),
          );
          const items = Object.keys(records).map((id) => {
            return records[id];
          });
          return {
            total: items.length,
            results: items.map((object) => {
              return object._id;
            }),
            nextOffset: null,
          };
        },
      }),
    );
  };

  translateGender = (gender: string) => {
    switch (gender) {
      case 'M':
        return __('Muži');
      case 'F':
        return __('Ženy');
      default:
        return '';
    }
  };

  translateRecord = (recordObject: { type: string; value: string }) => {
    const { type } = recordObject;
    const value = Number(recordObject.value).toFixed(2);
    if (type === 'time') {
      const mins = (Number(value) - (Number(value) % 60)) / 60;
      const secs = (Number(value) - mins * 60).toFixed(2);
      if (mins > 0) {
        return `${mins}:${Number(secs) < 10 ? `0${secs}` : secs}`;
      }
      return secs;
    }
    return value;
  };

  onChangeFilter = (filters: any) => {
    if (Object.keys(filters).length === 0) {
      this.props.setParameter({
        gender: '',
        discipline: '',
        ageCategory: '',
      });
    } else {
      this.props.setParameter(
        Object.keys(filters).reduce((acc, k) => {
          if (filters[k]) {
            return { ...acc, [k]: filters[k].value };
          }
          return { ...acc, [k]: '' };
        }, {}),
      );
    }
  };

  render() {
    const {
      items,
      isFetching,
      match: {
        params: { appspace },
      },
      query: { gender, discipline, ageCategory },
    } = this.props;

    const ageCategories = new Set();

    const recordsByDiscipline = items.reduce((acc: any, row: any) => {
      ageCategories.add(row.ageCategory.name);
      if (
        (gender && row.gender !== gender) ||
        (discipline && row.discipline._id !== discipline) ||
        (ageCategory && row.ageCategory.name !== ageCategory)
      ) {
        return acc;
      }

      const id = `${row.gender === 'M' ? 'Muži' : 'Ženy'} - ${
        row.ageCategory.name
      } - ${row.discipline.name}`;

      return {
        ...acc,
        [id]: [...(acc[id] || []), row],
      };
    }, {});

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>{__('Zoznam rekordov')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Link to={`/admin/${appspace}/records/new`}>
                <Button primary>{__('Pridať rekord')}</Button>
              </Link>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext title={__('Zoznam rekordov')} /> */}
        <Segment>
          <Segment raised>
            <Filters
              onChange={this.onChangeFilter}
              value={{
                gender: { value: String(gender), label: '' },
                discipline: {
                  value: String(discipline),
                  label: String(discipline),
                },
                ageCategory: {
                  value: String(ageCategory),
                  label: String(ageCategory),
                },
              }}
              filters={[
                {
                  type: 'select' as const,
                  name: 'gender',
                  label: __('Pohlavie'),
                  options: [
                    { value: '', label: __('Bez rozdielu') },
                    { value: 'M', label: __('Muži') },
                    { value: 'F', label: __('Ženy') },
                  ],
                },
                {
                  type: 'select' as const,
                  name: 'discipline',
                  label: __('Disciplína'),
                  options: this.state.disciplines.map(
                    (i: { _id: string; name: string }) => ({
                      value: i._id,
                      label: i.name,
                    }),
                  ),
                },
                {
                  type: 'select' as const,
                  name: 'ageCategory',
                  label: __('Veková kategória'),
                  options: [...ageCategories].map((i: any) => {
                    return {
                      value: i,
                      label: i,
                    };
                  }),
                },
              ]}
            />
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Disciplína')}</Th>
                  <Th>{__('Parametre')}</Th>
                  <Th>{__('Pohlavie')}</Th>
                  <Th>{__('Veková kategória')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(recordsByDiscipline).map((idx) =>
                  recordsByDiscipline[idx].map((record: IRecord) => (
                    <Tr
                      key={record._id}
                      onClick={() => {
                        this.props.history.push(
                          `/admin/${appspace}/records/${record._id}`,
                        );
                      }}
                    >
                      <Td>{record.discipline.name}</Td>
                      <Td>
                        {record.discipline.parameters
                          .map((p) => `${p.name} - ${p.value}`)
                          .join(', ')}
                      </Td>
                      <Td>{this.translateGender(record.gender)}</Td>
                      <Td>{record.ageCategory.name}</Td>
                    </Tr>
                  )),
                )}
              </Tbody>
            </Table>
          </Segment>
        </Segment>
      </ScrollLayout>
    );
  }
}

export default compose<any>(
  withRouter,
  withQueryHoc({
    parameters: {
      gender: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      discipline: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      ageCategory: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
    },
  }),
  connect(mapStateToProps),
)(RecordsList);
