import * as React from 'react';
import { reduxForm } from 'redux-form';

export const EVENT_FORM_NAME = 'EVENT_FORM';

type OwnProps = { children?: React.ReactNode };

const EventForm: React.FC<OwnProps> = ({ children }) => <>{children}</>;

export default reduxForm<{}, OwnProps>({ form: EVENT_FORM_NAME })(EventForm);
