import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { arrayRemove, change, Field } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import { required, __ } from '../../../utilities';
import { FORM_NAME, MatchNomination } from '../../Matches/form';
import { Athlete, Team } from '../../Teams/definitions';

interface OwnProps {
  appSpace: string;
  competitionId: string;
  partId: string;
  matchId: string;
  attendants: Athlete[];
  nominations: MatchNomination[];
  teams: Team[];
  closed: boolean;
  submitMatch: () => void;
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

const validateAthletesLength = (i: any[]) => {
  if (!i || i.length === 0) {
    return __('Vyberte aspoň jedného športovca');
  }
  return null;
};

const CyclingNominationList: React.FC<OwnProps> = ({
  teams,
  appSpace,
  competitionId,
  partId,
  dispatch,
  nominations,
  closed,
}) => {
  const [isFetchingTeamPlayers, setIsFetchingTeamPlayers] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [availableTeamPlayers, setAvailableTeamPlayers] = React.useState<{
    [key: string]: Array<{ _id: string; name: string }>;
  }>({});

  const resetPlayers = async (
    teamId: string,
    nIdx: number,
    initial = false,
  ) => {
    if (teamId) {
      if (!initial) {
        dispatch(change(FORM_NAME, `nominations.${nIdx}.athletes`, []));
        dispatch(change(FORM_NAME, `nominations.${nIdx}.closed`, true));
      }
      dispatch(change(FORM_NAME, 'teams', teams));
    } else {
      dispatch(arrayRemove(FORM_NAME, 'nominations', nIdx));
    }
  };

  React.useEffect(() => {
    const getTeamPlayers = async (teamIds: string[]) => {
      setIsFetchingTeamPlayers(
        teamIds.reduce((acc, t) => ({ ...acc, [t]: true }), {}),
      );
      setAvailableTeamPlayers(
        teamIds.reduce((acc, t) => ({ ...acc, [t]: [] }), {}),
      );
      try {
        const promises = teamIds.map((t) =>
          api.adminGetCompetitionPartTeamSquad(
            appSpace,
            competitionId,
            partId,
            t,
          ),
        );
        const data = await Promise.all(promises);
        const newPlayersList = {};
        data.forEach((i) => {
          if (i && i.squad) {
            newPlayersList[i._id] = (i.squad.athletes || []).map(
              (a) => a.sportnetUser,
            );
          } else {
            newPlayersList[i._id] = [];
          }
        });
        setAvailableTeamPlayers(newPlayersList);
      } catch (e: any) {
        alert(__('Nedarilo sa získať zoznam hráčov'));
      } finally {
        setIsFetchingTeamPlayers(
          teamIds.reduce((acc, t) => ({ ...acc, [t]: false }), {}),
        );
      }
    };

    const onMountResetPlayers = async (
      teamId: string,
      nIdx: number,
      initial = false,
    ) => {
      if (teamId) {
        if (!initial) {
          dispatch(change(FORM_NAME, `nominations.${nIdx}.athletes`, []));
          dispatch(change(FORM_NAME, `nominations.${nIdx}.closed`, true));
        }
        dispatch(change(FORM_NAME, 'teams', teams));
      } else {
        dispatch(arrayRemove(FORM_NAME, 'nominations', nIdx));
      }
    };

    (nominations || []).forEach((n, nIdx) =>
      onMountResetPlayers(n.teamId, nIdx, true),
    );
    getTeamPlayers(teams.map((t) => t._id));
  }, [appSpace, competitionId, partId, teams, dispatch, nominations]);

  return (
    <>
      {[...nominations, { teamId: '', nomination: [] }].map(
        ({ nomination, teamId }, idx) => (
          <Row
            key={`${teamId}_${(nomination || [])
              .map((n) => n.sportnetUser && n.sportnetUser.name)
              .join(',')}`}
          >
            <Col xs={12} s={6}>
              <Field
                format={(i: string | { _id: string; displayName: string }) => {
                  if (typeof i === 'string') {
                    const option = teams.find((t) => t && String(t._id) === i);
                    if (option) {
                      return {
                        value: option._id || '',
                        label: option.displayName,
                      };
                    }
                  } else if (i) {
                    return {
                      value: i._id,
                      label: i.displayName,
                    };
                  }
                  return '';
                }}
                label={__('Družstvo')}
                loading={teamId && isFetchingTeamPlayers[teamId]}
                disabled={closed}
                required={!!teamId}
                validate={!!teamId ? required : []}
                clearable
                onChange={(e: any) => {
                  if (!e.value) {
                    e.preventDefault();
                    dispatch(
                      change(FORM_NAME, `nominations.${idx}.closed`, false),
                    );
                  } else {
                    dispatch(
                      change(FORM_NAME, `nominations.${idx}.closed`, true),
                    );
                  }
                  resetPlayers(e.value, idx);
                }}
                parse={(i: any) => {
                  if (i && i.value) {
                    const option = teams.find(
                      (t) => t && String(t._id) === i.value,
                    );
                    if (option) {
                      return option._id;
                    }
                    return null;
                  }
                  return null;
                }}
                type="theselect"
                options={teams.map((t) => ({
                  label: t.displayName,
                  value: t._id,
                }))}
                name={`nominations.${idx}.teamId`}
                component={FormField}
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                disabled={
                  // (teamId && (availableTeamPlayers[teamId] || []).length === 0) ||
                  closed
                }
                label={__('Športovci')}
                required={!!teamId}
                validate={!!teamId ? validateAthletesLength : []}
                format={(i: any) => {
                  if (Array.isArray(i) && i.length > 0) {
                    return i.map((item) => ({
                      label: item.sportnetUser.name,
                      value: item.sportnetUser._id,
                    }));
                  }
                  return [];
                }}
                parse={(i: any) => {
                  if (Array.isArray(i) && i.length > 0) {
                    const ids = i.map((item) => item.value);
                    const players = (availableTeamPlayers[teamId] || []).reduce(
                      (
                        acc: Array<{
                          sportnetUser: { _id: string; name: string };
                        }>,
                        p,
                      ) => {
                        if (ids.includes(p._id)) {
                          return [...acc, { sportnetUser: p }];
                        }
                        return acc;
                      },
                      [],
                    );
                    return players;
                  }
                  return [];
                }}
                options={
                  !!teamId
                    ? (availableTeamPlayers[teamId] || []).map((t) => ({
                        label: t.name,
                        value: t._id,
                      }))
                    : []
                }
                type="theselect"
                multiple
                name={`nominations.${idx}.athletes`}
                component={FormField}
              />
            </Col>
          </Row>
        ),
      )}
    </>
  );
};

export default connect()(CyclingNominationList);
