import baseTheme from '@sportnet/ui/Themes/baseTheme';

export const theme = {
  ...baseTheme,
  app: {
    textColor: baseTheme.textColor,
  },
};

export interface ThemeInterface {
  color: {
    primary: string;
  };
}
