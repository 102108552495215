import { OrganizationProfile } from '@sportnet/ui/Authorization/SportnetApi';
import FormField from '@sportnet/ui/FormField';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { format } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Box from '../../components/Box';
import LoaderWrapper from '../../components/LoaderWrapper';
import sportnetApi from '../../sportnetApi';
import { __ } from '../../utilities';
import styled from 'styled-components';
import Api from '../../api';

const CompetitionName = styled.div`
  * {
    margin-bottom: 0;
  }
`;

type Props = RouteComponentProps<{}>;

const ClubManagerDashboard: React.FC<Props> = ({ history: { push } }) => {
  const [partsDateFrom, setPartsDateFrom] = React.useState(new Date());
  const [matchesDateFrom, setMatchesDateFrom] = React.useState(new Date());

  const [clubs, setClubs] = React.useState<OrganizationProfile[]>([]);
  const [parts, setParts] = React.useState<
    Array<{
      _id: string;
      name: string;
      competition: { _id: string; name: string };
      appSpace: string;
      teamNominated?: null | { _id: string; name: string };
    }>
  >([]);
  const [matches, setMatches] = React.useState<
    Array<{
      _id: string;
      managedTeamId: string;
      nominationStatus: string;
      startDate: string;
      sportGround?: { name: string };
      competition: { _id: string; name: string };
      competitionPart: { _id: string; name: string };
      teams?: Array<{
        name: string;
        additionalProperties?: { homeaway: 'home' | 'away' };
      }>;
    }>
  >([]);

  const [matchesTotal, setMatchesTotal] = React.useState(0);
  const [matchesOffset, setMatchesOffset] = React.useState(0);

  const [isFetchingMatches, setIsFetchingMatches] = React.useState(false);
  const [isFetchingParts, setIsFetchingParts] = React.useState(false);

  const fetchParts = React.useCallback(async (dateFrom: Date) => {
    setIsFetchingParts(true);
    try {
      const res = await Api.clubManagerGetCompetitionParts({
        dateTo: format(dateFrom, 'yyyy-MM-dd'),
      });
      const appSpaces = (res.parts || []).map((p) => p.appSpace);
      const profilesRes = await sportnetApi.organizationPPOProfiles({
        ids: appSpaces,
      });
      setParts(res.parts || []);
      setClubs(profilesRes.items);
    } catch (e: any) {
      alert(
        __(
          'Nepodarilo sa získať zoznam časti súťaží v ktorých účinkuje váš klub.',
        ),
      );
    } finally {
      setIsFetchingParts(false);
    }
  }, []);

  const fetchMatches = React.useCallback(
    async (offset: number, dateFrom: Date) => {
      setIsFetchingMatches(true);
      try {
        const matchesRes = await Api.clubManagerGetMatches({
          dateFrom: format(dateFrom, 'yyyy-MM-dd'),
          limit: 10,
          offset,
        });
        setMatches(matchesRes.matches);
        setMatchesTotal(matchesRes.total);
      } catch (e: any) {
        alert(
          __(
            'Nepodarilo sa získať zoznam udalostí v ktorých účinkuje váš klub.',
          ),
        );
      } finally {
        setIsFetchingMatches(false);
      }
    },
    [],
  );

  // React.useEffect(() => {
  //   fetchParts();
  // }, [fetchParts]);

  React.useEffect(() => {
    fetchParts(partsDateFrom);
  }, [fetchParts, partsDateFrom]);

  React.useEffect(() => {
    fetchMatches(matchesOffset, matchesDateFrom);
  }, [fetchMatches, matchesDateFrom, matchesOffset]);

  if (isFetchingParts || isFetchingMatches) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  const renderNominationStatusLabel = (statusName: string) => {
    switch (statusName) {
      case 'EMPTY':
        return __('Prázdna');
      case 'FILLED':
        return __('Vyplnená');
      case 'CLOSED':
        return __('Uzatvorená');
      default:
        return __('Neznámy stav nominácie');
    }
  };

  return (
    <ScrollLayout>
      <Segment>
        <Row>
          <Col xs={12} xl={6}>
            <Box size="s" withSeparator={false} title={__('Súpisky')}>
              <FormField
                type="date"
                value={partsDateFrom}
                onChange={(i: Date) => {
                  setPartsDateFrom(i);
                }}
                label={__('Dátum od')}
                isClearable={false}
              />
              {parts.length > 0 ? (
                <Table>
                  <Thead>
                    <Tr>
                      <Th width={200}>{__('Názov')}</Th>
                      <Th>{__('Klub')}</Th>
                      <Th>{__('Družstvo')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {parts.map((part) => {
                      const club = clubs.find((c) => c._id === part.appSpace);
                      return (
                        <Tr
                          key={`${part._id}_${part.appSpace}`}
                          onClick={() => {
                            push(
                              `/club-manager/competitions/${part.competition._id}/parts/${part._id}/club/${part.appSpace}/teams`,
                            );
                          }}
                        >
                          <Td>
                            <CompetitionName>
                              <Header size="xs" subtitle={part.name}>
                                {part.competition.name}
                              </Header>
                            </CompetitionName>
                          </Td>
                          <Td>{club && club.name}</Td>
                          <Td>
                            {part.teamNominated ? (
                              part.teamNominated.name
                            ) : (
                              <b>{__('Nie')}</b>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <NotFound
                  icon="error"
                  title={__('Žiadne aktívne časti súťaží')}
                />
              )}
            </Box>
          </Col>
          <Col xs={12} xl={6}>
            <Box size="s" withSeparator={false} title={__('Udalosti')}>
              <FormField
                type="date"
                value={matchesDateFrom}
                onChange={(i: Date) => {
                  setMatchesDateFrom(i);
                }}
                label={__('Dátum od')}
                isClearable={false}
              />
              {matches.length > 0 ? (
                <>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th width={200}>{__('Dátum a miesto konania')}</Th>
                        <Th>{__('Súťaž')}</Th>
                        <Th>{__('Účastníci')}</Th>
                        <Th>{__('Stav nominácie')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {matches.map((match) => {
                        return (
                          <Tr
                            key={`${match._id}`}
                            onClick={() => {
                              push(
                                `/club-manager/competitions/${match.competition._id}/parts/${match.competitionPart._id}/matches/${match._id}/teams/${match.managedTeamId}/nominations`,
                              );
                            }}
                          >
                            <Td>
                              <CompetitionName>
                                <Header
                                  size="xs"
                                  {...(match.sportGround && {
                                    subtitle: match.sportGround.name,
                                  })}
                                >
                                  {format(
                                    new Date(match.startDate),
                                    'dd.MM.yyyy HH:mm',
                                  )}
                                </Header>
                              </CompetitionName>
                            </Td>
                            <Td>
                              <CompetitionName>
                                <Header
                                  size="xs"
                                  subtitle={match.competitionPart.name}
                                >
                                  {match.competition.name}
                                </Header>
                              </CompetitionName>
                            </Td>
                            <Td>
                              {(match.teams || [])
                                .sort((a, b) => {
                                  if (
                                    a.additionalProperties &&
                                    a.additionalProperties.homeaway === 'home'
                                  ) {
                                    return -1;
                                  } else if (
                                    b.additionalProperties &&
                                    b.additionalProperties.homeaway === 'away'
                                  ) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((t) => (
                                  <>
                                    {t.name}
                                    <br />
                                  </>
                                ))}
                            </Td>
                            <Td>
                              {renderNominationStatusLabel(
                                match.nominationStatus,
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                  <Paginator
                    limit={10}
                    total={matchesTotal}
                    offset={matchesOffset}
                    onChangeOffset={async (newOffset: number) => {
                      setMatchesOffset(newOffset);
                    }}
                  />
                </>
              ) : (
                <NotFound
                  icon="error"
                  title={__('Žiadne najbližšie udalosti')}
                />
              )}
            </Box>
          </Col>
        </Row>
      </Segment>
    </ScrollLayout>
  );
};

export default compose()(ClubManagerDashboard);
