import Authorization from '@sportnet/ui/Authorization';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { AnyAction, compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Api from './api';
import CmsApi from './CmsApi';
import Layout from './components/Layout';
import { setApplicationInfo, setAuthUser } from './containers/App/actions';
import { ApplicationInfo, AuthorizationResponse } from './containers/App/types';
import ClubManagerDashboard from './containers/ClubManagerDashboard';
import ClubManagerNomination from './containers/Nominations/clubManagerIndex';
import TeamSignup from './containers/TeamSignup';
import SquadDetail from './containers/TeamSignup/squadDetail';
import sportnetApi from './sportnetApi';

const ClubManagerAuthorization: React.FC<{
  dispatch: ThunkDispatch<any, any, AnyAction>;
}> = ({ dispatch }) => {
  React.useEffect(() => {
    Api.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
    CmsApi.setBaseUrl(String(process.env.REACT_APP_CMS_API_BASE_URL));
    sportnetApi.setBaseUrl(String(process.env.REACT_APP_CORE_API_BASE_URL));
    const token =
      Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';
    Api.setToken(token);
    CmsApi.setToken(token);
    sportnetApi.setToken(token);
  }, []);

  const onAuthorized = (data: AuthorizationResponse) => {
    dispatch(setAuthUser(data));
  };

  const onAppInfoReceived = (data: ApplicationInfo) => {
    dispatch(setApplicationInfo(data));
  };

  return (
    <Authorization
      appId={process.env.REACT_APP_APP_ID as string}
      onAuthorized={onAuthorized as any}
      onAppInfoReceived={onAppInfoReceived as any}
      scope="profile,messages"
      basePath="/club-manager"
    >
      <Layout withoutMenu>
        <Route exact path="/club-manager" component={ClubManagerDashboard} />
        <Route
          exact
          path="/club-manager/competitions/:competitionId/parts/:partId/club/:clubId/teams"
          component={TeamSignup}
        />
        <Route
          path="/club-manager/competitions/:competitionId/parts/:partId/club/:clubId/team/:teamId"
          component={SquadDetail}
        />
        <Route
          path="/club-manager/competitions/:competitionId/parts/:partId/matches/:matchId/teams/:teamId/nominations"
          component={ClubManagerNomination}
        />
      </Layout>
    </Authorization>
  );
};

export default compose(connect())(ClubManagerAuthorization);
