import {
  default as FormField,
  default as FormFieldRedux,
} from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import Select from '@sportnet/ui/Select/redux-form';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import {
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  getFormSyncErrors,
  getFormMeta,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import { State } from '../../rootReducer';
import { getProp, required, __ } from '../../utilities';
import { activeAppspaceSelector, profileSportSelector } from '../App/selectors';
import Message from '@sportnet/ui/Message';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const FORM_NAME = 'TEAM_FORM';

const ErrorText = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  margin-top: ${rem(3)};
`;

const StyledSelect = styled(Select)<{ error?: boolean }>`
  ${({ error }) =>
    !!error &&
    css`
      > select {
        border: solid red ${rem(1)};
        overflow: hidden;
        border-radius: ${rem(4)};
      }
    `}
`;

interface CustomFormData {
  name: string;
}

interface OwnProps {
  id: string;
  omitSportSector?: boolean;
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const storeValues = {
    sport: profileSportSelector(state),
    appSpace: activeAppspaceSelector(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    formMeta: getFormMeta(FORM_NAME)(state),
  };
  return storeValues;
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const TeamForm = (
  props: InjectedFormProps<CustomFormData> &
    IMapStateToProps &
    OwnProps & { dispatch: ThunkDispatch<any, any, AnyAction> },
) => {
  const { id, sport, omitSportSector } = props;

  const accessToken =
    Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';

  return (
    <Form onSubmit={props.handleSubmit}>
      <Row>
        <Col xs={12} m={3}>
          <Field
            label={__('Názov družstva')}
            name="name"
            required
            validate={[required]}
            component={FormField}
          />
        </Col>
        <Col xs={12} m={3}>
          <Field
            label={__('Marketingový názov družstva')}
            name="displayName"
            required
            validate={[required]}
            component={FormField}
          />
        </Col>
        <Col xs={12} m={3}>
          <Field
            label={__('Skrátený názov družstva')}
            name="shortName"
            info={__(
              'Skrátený názov by mal obsahovať 3 až 4 znaky. Používa sa na mobilných zariadeniach, alebo v grafike, ktorá vyžaduje skracovanie názvov tímov.',
            )}
            component={FormField}
          />
        </Col>
        <Col xs={12} m={3}>
          <Field
            name="detailUrl"
            label={__('URL pre detail družstva')}
            info={__('Napríklad link na podstránku tímu.')}
            component={FormField}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Message primary>
            Dátumové obmedzenie sa týka len automatických súpisiek. Manuálne
            súpisky majú svoju platnosť nastavenú na súpiske.
          </Message>
        </Col>
        <Col xs={12} m={6}>
          <Field
            label={__('Predvolený dátum od pre súpisku')}
            name="filter.dateFrom"
            type="date"
            component={FormField}
          />
        </Col>
        <Col xs={12} m={6}>
          <Field
            label={__('Predvolený dátum do pre súpisku')}
            name="filter.dateTo"
            type="date"
            component={FormField}
          />
        </Col>
        {id === 'new' && (
          <React.Fragment>
            <Col xs={12} m={4}>
              <FormGroup>
                <Label>{__('Pohlavie')}</Label>
                <Field name="gender" component={Select}>
                  <option value="">{__('Zmiešané')}</option>
                  <option value="M">{__('Muži')}</option>
                  <option value="F">{__('Ženy')}</option>
                </Field>
              </FormGroup>
            </Col>
            {!omitSportSector && (
              <Col xs={12} m={4}>
                <FormGroup>
                  <Label required>{__('Šport. odvetvie')}</Label>
                  <Field
                    name="sport_sector"
                    required
                    error={
                      !!props.formMeta['sport_sector']?.touched &&
                      !!props.formSyncErrors['sport_sector']
                    }
                    validate={[required]}
                    component={StyledSelect}
                  >
                    <option hidden />
                    {getProp(sport || {}, ['sport_sector'], []).map(
                      (s: any) => {
                        return (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        );
                      },
                    )}
                  </Field>
                  {!!props.formMeta['sport_sector']?.touched &&
                    !!getProp(props.formSyncErrors, ['sport_sector']) && (
                      <ErrorText
                        title={getProp(props.formSyncErrors, ['sport_sector'])}
                      >
                        {getProp(props.formSyncErrors, ['sport_sector'])}
                      </ErrorText>
                    )}
                </FormGroup>
              </Col>
            )}
          </React.Fragment>
        )}
      </Row>
      {props.appSpace && (
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>{__('Logo')}</Label>
              <Field
                name="logo"
                component={FormFieldRedux}
                type="image"
                auth={{
                  appId: process.env.REACT_APP_APP_ID,
                  appSpace: props.appSpace.app_space,
                  token: accessToken,
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <TagmanagerConnector
              appspace={props.appSpace.app_space}
              appId={process.env.REACT_APP_APP_ID || ''}
              accessToken={accessToken}
            >
              {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
                <Field
                  getSmarttagsValues={getSmarttagsValues}
                  getSmarttagsKeys={getSmarttagsKeys}
                  getSmarttagsByFts={getSmarttagsByFts}
                  name="smarttags"
                  type="smarttag"
                  label={__('Smarttagy')}
                  component={FormField}
                  placeholder={__('Zadajte Smart:tag a stlačte Enter')}
                />
              )}
            </TagmanagerConnector>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
})(TeamForm);

export default connect(mapStateToProps)(connected);
