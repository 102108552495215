import Domains from '@sportnet/ui/Domains';
import Col, { Row } from '@sportnet/ui/Grid';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { State } from '../../rootReducer';
import { activeAppspaceSelector } from '../App/selectors';

export interface DomainComponentResponse {
  _id: string;
  status: string;
  ppo: string;
  payload?: { [key: string]: any };
  cert: { [key: string]: any };
  appId: string;
  aliases?: string[];
}

const mapStateToProps = (state: State) => ({
  appSpace: activeAppspaceSelector(state),
});

type Props = {
  domain: null | string;
  payload?: { [key: string]: any };
  onSubmitForm: (
    payload: DomainComponentResponse | null,
    appSpace: string,
  ) => Promise<void>;
} & ReturnType<typeof mapStateToProps> & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  };

class DomainForm extends React.PureComponent<Props> {
  render() {
    const { appSpace, domain, payload } = this.props;

    return (
      <Row>
        <Col xs={12}>
          <Domains
            accessToken={
              Cookies.get(
                String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME),
              ) || ''
            }
            appId={'sutaze'}
            payload={payload || {}}
            appSpace={appSpace ? appSpace.app_space : ''}
            onChange={(data: DomainComponentResponse | null) =>
              this.props.onSubmitForm(
                data,
                this.props.appSpace ? this.props.appSpace.app_space : '',
              )
            }
            domain={domain || undefined}
          />
        </Col>
      </Row>
    );
  }
}

export default compose(connect(mapStateToProps))(DomainForm);
