import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { State } from '../../rootReducer';
import { getProp } from '../../utilities';
import { LIST_NAME, Team } from './definitions';

const teamsDomainSelector = (state: State) => {
  return state.entities.teams;
};
export const teamsListSelector = createSelector(teamsDomainSelector, (teams) =>
  Object.keys(teams).reduce((acc: Team[], k) => [...acc, teams[k]], []),
);

export const teamsSelector = createSelector(
  getListResults(LIST_NAME),
  teamsDomainSelector,
  (results, objects) => {
    if (results) {
      const res = results.map((id: string) => {
        return objects[id];
      });
      return res;
    }
    return [];
  },
);

export const teamByIdSelector = (id: string) =>
  createSelector(teamsDomainSelector, (objects) => {
    if (objects && objects[id]) {
      return objects[id];
    }
    return null;
  });

const teamDetailSelector = (state: State) => {
  return state.detail.teams;
};

export const teamByIdIsFetchingSelector = (id: string) =>
  createSelector(teamDetailSelector, (substate) =>
    getProp(substate, [id, 'isFetching'], false),
  );
