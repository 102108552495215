export const LIST_NAME = 'TEAMS_LIST';
export const SQUAD_LIST_NAME = 'SQUAD_LIST';
export const LIST_LIMIT = 100;

export interface Team {
  _id: string;
  appSpace: string;
  name: string;
  displayName: string;
  sport_sector: string;
  gender?: string;
  athletes?: Athlete[];
  additionalProperties?: { [key: string]: any };
}

export interface Athlete {
  additionalData?: {
    [key: string]: string;
  };
  sportnetUser: {
    _id: string;
    name?: string;
  };
}

export interface CrewMember {
  position: string;
  sportnetUser: {
    _id: string;
    name?: string;
  };
}
