import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import Select from '@sportnet/ui/Select/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required, __ } from '../../utilities';

export const FORM_NAME = 'TEAM_FORM';

interface CustomFormData {
  name: string;
}

const TeamForm = () => {
  return (
    <Row>
      <Col xs={12}>
        <Field
          label={__('Názov družstva')}
          name="name"
          required
          validate={[required]}
          component={FormField}
        />
      </Col>
      <Col xs={12}>
        <Field
          label={__('Marketingový názov družstva')}
          name="displayName"
          required
          validate={[required]}
          component={FormField}
        />
      </Col>
      <React.Fragment>
        <Col xs={12}>
          <FormGroup>
            <Label>{__('Pohlavie')}</Label>
            <Field name="gender" component={Select}>
              <option value="">{__('Zmiešané')}</option>
              <option value="M">{__('Muži')}</option>
              <option value="F">{__('Ženy')}</option>
            </Field>
          </FormGroup>
        </Col>
      </React.Fragment>
    </Row>
  );
};

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
})(TeamForm);

export default connect()(connected);
