export const LIST_NAME = 'SPORT_OBJECTS_LIST';

export interface SportGround {
  _id: string;
  name: string;
  sportObjectName?: string;
  sportGroundType?: string;
  additionalData?: any;
  city?: string;
  street?: string;
  number?: string;
  zip?: string;
  country?: string;
}

export interface SportObjectUpdate {
  name: string;
  sport: string;
  street: string;
  number: string;
  zip: string;
  city: string;
  country: string;
  sportGrounds?: SportGround[];
}

export interface SportObject extends SportObjectUpdate {
  _id?: string;
  appSpace?: string;
}

export interface Organization {
  name?: string;
  vatNr?: string;
  street?: string;
  number?: string;
  zip?: string;
  country?: string;
  city?: string;
}
