import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import Select from '@sportnet/ui/Select/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import api from '../../../api';
import { activeAppspaceProfileSelector } from '../../App/selectors';
import { State } from '../../../rootReducer';
import { required, __ } from '../../../utilities';
import { AgeCategory } from './definitions';

export const FORM_NAME = 'AGE_CATEGORY_FORM';

interface CustomFormData {
  name: string;
}

interface OwnProps {
  id: string;
  data: AgeCategory | null;
  form: string;
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const selector = formValueSelector(props.form);
  const values = {
    gender: selector(state, 'gender'),
    orgProfile: activeAppspaceProfileSelector(state),
  };
  if (props.id !== 'new') {
    return {
      ...values,
      initialValues: props.data,
    };
  }
  return { ...values };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = InjectedFormProps<CustomFormData> & IMapStateToProps & OwnProps;

const AgeCategoryForm: React.FC<Props> = ({
  handleSubmit,
  orgProfile,
  gender,
}) => {
  const [sportAgeCategories, setSportAgeCategories] = React.useState<
    Array<{ value: string; label: string }>
  >([]);
  const [isFetchingSportAgeCategories, setIsFetchingSportAgeCategories] =
    React.useState(false);

  React.useEffect(() => {
    if (orgProfile) {
      setIsFetchingSportAgeCategories(true);
      api
        .getSettingBySportSector('sport_sector_age_category', orgProfile.sport)
        .then((res) => {
          setSportAgeCategories(
            res.items.map((item) => ({ label: item.label, value: item._id })),
          );
        })
        .catch((e) => {
          alert(__('Nepodarilo sa získať vekové kategórie pre šport'));
        })
        .finally(() => {
          setIsFetchingSportAgeCategories(false);
        });
    }
  }, [orgProfile]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} s={6}>
          <Field
            name="name"
            validate={[required]}
            component={FormField}
            required
            label={__('Označenie vekovej kategórie')}
          />
        </Col>
        <Col xs={12} m={6}>
          <FormGroup>
            <Label>{__('Pohlavie')}</Label>
            <Field
              name="gender"
              component={Select}
              readOnly={false}
              disabled={false}
            >
              <option value="">{__('Zmiešané')}</option>
              <option value="M">{__('Muži')}</option>
              <option value="F">{__('Ženy')}</option>
            </Field>
          </FormGroup>
        </Col>
        {gender !== 'F' && (
          <>
            <Col xs={12} s={6}>
              <Field
                name="maleAgeCategoryFrom"
                validate={[required]}
                component={FormField}
                type="theselectsimple"
                required
                label={__('Muži od')}
                options={sportAgeCategories}
                loading={isFetchingSportAgeCategories}
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                name="maleAgeCategoryTo"
                validate={[required]}
                component={FormField}
                type="theselectsimple"
                required
                label={__('Muži do')}
                options={sportAgeCategories}
                loading={isFetchingSportAgeCategories}
              />
            </Col>
          </>
        )}
        {gender !== 'M' && (
          <>
            <Col xs={12} s={6}>
              <Field
                name="femaleAgeCategoryFrom"
                validate={[required]}
                component={FormField}
                type="theselectsimple"
                required
                label={__('Ženy od')}
                options={sportAgeCategories}
                loading={isFetchingSportAgeCategories}
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                name="femaleAgeCategoryTo"
                validate={[required]}
                component={FormField}
                type="theselectsimple"
                required
                label={__('Ženy do')}
                options={sportAgeCategories}
                loading={isFetchingSportAgeCategories}
              />
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
})(AgeCategoryForm);

export default connect(mapStateToProps)(connected);
