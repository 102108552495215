import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../api';
import { IRecord } from './definitions';
import { RecordState } from './reducer';

const create = actionCreatorFactory('RECORDS');
const createAsync = asyncFactory<RecordState>(create);

interface NormalizedSchema {
  entities: {
    records: {
      [key: string]: IRecord;
    };
  };
}

const normalize = (data: IRecord[]) => {
  return {
    entities: {
      records: data.reduce((acc, item: IRecord) => {
        return { ...acc, [item._id]: item };
      }, {}),
    },
  };
};

export const getRecords = createAsync<
  {
    appspace: string;
  },
  NormalizedSchema
>('GET_RECORDS', async parameters => {
  const { appspace } = parameters;
  const params: { [key: string]: any } = {};
  const response = await Api.adminGetRecords(appspace, params);
  return {
    ...normalize(response.items),
  };
});

export const getRecordById = createAsync<
  { appspace: string; id: string },
  NormalizedSchema
>('GET_RECORD_BY_ID', async parameters => {
  const response = await Api.adminGetRecordById(
    parameters.appspace,
    parameters.id,
  );
  return normalize([response]);
});
