export const LIST_NAME = 'AGE_CATEGORIES_LIST';

export interface AgeCategory {
  _id?: string;
  appSpace: string;
  name: string;
  gender?: string;
  maleAgeCategoryFrom?: string;
  femaleAgeCategoryFrom?: string;
  maleAgeCategoryTo?: string;
  femaleAgeCategoryTo?: string;
}
