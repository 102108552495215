import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import api from '../../api';
import { __ } from '../../utilities';
import DomainForm, { DomainComponentResponse } from './domainForm';

export const SETTINGS_FORM_NAME = 'SETTINGS_FORM';

class SettingsForm extends React.PureComponent<
  RouteComponentProps<{ appspace: string }>
> {
  state = {
    domain: null,
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = async () => {
    try {
      const { domain = null } = await api.adminGetSettings(
        this.props.match.params.appspace,
      );
      this.setState({
        domain: domain || '',
      });
    } catch (e: any) {
      alert(__('Nepodarilo sa získať nastavenia'));
    }
  };

  onSubmitForm = async (
    payload: DomainComponentResponse | null,
    appSpace: string,
  ) => {
    const domain = payload ? payload._id : null;
    await api.adminEditSettings(appSpace, {}, { domain: domain || '' });
  };

  render() {
    return (
      <Segment>
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Nastavenie domény')}
            </SegmentHeader>
          }
        >
          {this.state.domain !== null && (
            <DomainForm
              domain={this.state.domain}
              onSubmitForm={this.onSubmitForm}
            />
          )}
        </Segment>
      </Segment>
    );
  }
}

export default compose<any>(
  reduxForm({
    form: SETTINGS_FORM_NAME,
    enableReinitialize: true,
  }),
  withRouter,
  connect(),
)(SettingsForm);
