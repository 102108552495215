import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarSpacer,
  ContextBarItem,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import { endOfDay, startOfDay } from 'date-fns';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import { submit } from 'redux-form';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { transformQueryParameters, __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { getTeamById, postTeam, putTeam } from './actions';
import Form, { FORM_NAME } from './form';
import { teamByIdIsFetchingSelector, teamByIdSelector } from './selectors';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Tabber from '@sportnet/ui/Tabber';
import TeamSquads from './Squads';
// import Message from '@sportnet/ui/Message';
import TeamMembers from './Members';
import TeamStats from './Stats';

const tabsMap = {
  basicInfo: 'Základné informácie',
  squads: 'Súpisky',
  members: 'Osoby v tíme',
  stats: 'Štatistika hráčov',
};

const TabWrapper = styled.div`
  padding-top: ${rem(16)};
`;

type RouterProps = {
  id: string;
  appspace: string;
};

const TeamDetail: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const { id, appspace } = useParams<RouterProps>();
  const history = useHistory();
  const location = useLocation();
  const team = useSelector((state: State) => teamByIdSelector(id)(state));
  const isFetchingTeam = useSelector((state: State) =>
    teamByIdIsFetchingSelector(id)(state),
  );

  const [activeTab, setActiveTab] = React.useState(
    tabsMap[location.hash.replace('#', '')] || 'Základné informácie',
  );

  const handleSetActiveTab = (tab: string) => {
    setActiveTab(tab);
    if (tab === tabsMap.squads) location.hash = 'squads';
    else if (tab === tabsMap.members) location.hash = 'members';
    else if (tab === tabsMap.stats) location.hash = 'stats';
    else location.hash = undefined as any;
    history.replace(location);
  };

  React.useEffect(() => {
    dispatch(
      setBreadcrumbs([
        <Link key={0} to={`/admin/${appspace}/teams`}>
          {__('Zoznam družstiev')}
        </Link>,
        id === 'new' ? __('Nové družstvo') : __('Úprava družstva'),
      ]),
    );
    if (id !== 'new') {
      try {
        dispatch(getTeamById.action({ appspace, id }));
      } catch (e: any) {
        console.error(e);
      }
    }
  }, [dispatch, id, appspace]);

  const onSubmit = React.useCallback(
    async (data: {
      _id: string;
      name: string;
      logo?: {
        media_url: string;
        public_url: string;
      };
      displayName: string;
      shortName?: string;
      appSpace: string;
      sport_sector: string;
      gender?: string;
      smarttags?: any[];
      detailUrl?: string;
      filter?: {
        dateFrom?: string;
        dateTo?: string;
      };
    }) => {
      const requestData: typeof data & {
        filter: { dateFrom?: string; dateTo?: string };
      } = {
        ...data,
        filter: {},
      };
      if (data.filter && data.filter.dateFrom) {
        requestData.filter.dateFrom = format(
          startOfDay(new Date(data.filter.dateFrom)),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        );
      }
      if (data.filter && data.filter.dateTo) {
        requestData.filter.dateTo = format(
          endOfDay(new Date(data.filter.dateTo)),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        );
      }
      if (id === 'new') {
        await dispatch(postTeam.action({ appspace, team: requestData }));
      } else {
        const updateRequestData: {
          name?: string;
          displayName?: string;
          shortName?: string;
          smarttags: any[];
          logo?: {
            media_url: string;
            public_url: string;
          };
          detailUrl?: string;
          filter: {
            dateFrom?: string;
            dateTo?: string;
          };
        } = {
          smarttags: requestData.smarttags || [],
          filter: requestData.filter || {},
        };
        if (requestData.name) {
          updateRequestData.name = requestData.name;
        }
        if (requestData.displayName) {
          updateRequestData.displayName = requestData.displayName;
        }
        if (requestData.shortName !== undefined) {
          updateRequestData.shortName = requestData.shortName;
        }
        if (typeof requestData.logo !== 'undefined') {
          updateRequestData.logo = requestData.logo;
        }
        if (requestData.detailUrl) {
          updateRequestData.detailUrl = requestData.detailUrl;
        }
        await dispatch(
          putTeam.action({
            appspace,
            teamId: id,
            team: updateRequestData,
          }),
        );
      }

      const qp = transformQueryParameters(location.search);

      if (qp.redirectAfterSubmit) {
        history.push(qp.redirectAfterSubmit);
      } else {
        history.push(`/admin/${appspace}/teams`);
      }
    },
    [dispatch, id, appspace, history, location],
  );

  if (isFetchingTeam) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  // if (!team) {
  //   return (
  //     <LoaderWrapper>
  //       <Message primary title="Tím sa nenašiel" />
  //     </LoaderWrapper>
  //   );
  // }

  const tabs = {
    'Základné informácie':
      (id !== 'new' && team) || id === 'new' ? (
        <Form initialValues={team} id={id} onSubmit={onSubmit} />
      ) : null,
    ...(!!team
      ? {
          Súpisky: <TeamSquads team={team} />,
          'Osoby v tíme': <TeamMembers team={team} />,
          'Štatistika hráčov': <TeamStats team={team} />,
        }
      : {}),
  };

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={`/admin/${appspace}/teams`}
          />
          <HeaderBar.Header>
            {!!team ? team.name : __('Nové družstvo')}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() => {
                dispatch(submit(FORM_NAME));
              }}
              primary
            >
              {__('Uložiť')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment>
        <Segment raised>
          <Tabber
            active={activeTab}
            onClickItem={handleSetActiveTab}
            items={Object.keys(tabs)}
          >
            <TabWrapper>{tabs[activeTab]}</TabWrapper>
          </Tabber>
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default TeamDetail;
