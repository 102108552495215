import Button from '@sportnet/ui/Button';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { formValueSelector, isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { formatUserName } from 'sportnet-utilities';
import api from '../../api';
import Box from '../../components/Box';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import sportnetApi from '../../sportnetApi';
import { getProp, __ } from '../../utilities';
import styled, { css } from 'styled-components';
import { codelistSelector } from '../App/selectors';
import { Match } from '../Matches/definitions';
import PersonalInfoForm from './personalInfoForm';
import { format } from 'date-fns';

const PERSONAL_INFO_FORM_NAME = 'PERSONAL_INFO_FORM';

const STAT_TYPES = {
  match_starts: __('Počet štartov v základnej zostave'),
  match_appearances: __('Počet štartov'),
  minutes: __('Počet odohraných minút'),
  goals: __('Góly'),
  yellow_cards: __('Žlté karty'),
  red_cards: __('Červené karty'),
  own_goals: __('Vlastné góly'),
};

interface IPlayerStat {
  match_starts?: number;
  match_appearances?: number;
  minutes?: number;
  goals?: number;
  assists?: number;
  yellow_cards?: number;
  red_cards?: number;
  substitutions?: number;
  own_goals?: number;
}

interface IPlayerStats {
  sportnetUser: {
    _id: string;
    name: string;
  };
  summary: {
    stats: IPlayerStat;
  };
  byCompetition: Array<{
    _id: string;
    name: string;
    season: {
      name: string;
    };
    stats: IPlayerStat;
  }>;
  byCompetitionGroup: Array<{
    _id: string;
    name: string;
    stats: IPlayerStat;
  }>;
}

const Score = styled.div<{ isComputed: boolean }>`
  ${({ isComputed }) => css`
    color: ${isComputed ? 'green' : 'red'};
  `}
`;

const mapStateToProps = (state: State) => {
  const selector = formValueSelector(PERSONAL_INFO_FORM_NAME);
  return {
    selectedCompetitions: selector(state, 'competitions'),
    isSubmittingPersonalForm: isSubmitting(PERSONAL_INFO_FORM_NAME)(state),
    countryCodelist: codelistSelector('country')(state),
  };
};

type Props = RouteComponentProps<{
  appspace: string;
  sportnetId: string;
  teamId: string;
}> &
  ReturnType<typeof mapStateToProps> & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  };

const C: React.FC<Props> = ({
  match: {
    params: { appspace, sportnetId, teamId },
  },
  history,
  dispatch,
  selectedCompetitions,
  isSubmittingPersonalForm,
  countryCodelist,
}) => {
  const [user, setUser] = React.useState<{
    _id: string;
    name: string;
    surname: string;
    titles?: {
      before?: string;
      after?: string;
    };
    citizenship?: string;
  } | null>(null);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [data, setData] = React.useState<IPlayerStats | null>(null);

  const [matchesFilter, setMatchesFilter] = React.useState<{
    competitionId?: string;
    competitionGroupId?: string;
  } | null>(null);
  const [matchesModalOpened, setMatchesModalOpened] = React.useState(false);

  const [isFetchingMatches, setIsFetchingMatches] = React.useState(false);
  const [matches, setMatches] = React.useState<Match[]>([]);

  const toggleMatchesModal = (filter?: { [key: string]: string }) => {
    setMatchesModalOpened((prevState) => !prevState);
    setMatchesFilter(filter ? filter : null);
    if (!filter) {
      setMatches([]);
    }
  };

  React.useEffect(() => {
    if (matchesFilter && matchesModalOpened) {
      setIsFetchingMatches(true);
      api
        .getPlayerMatches(appspace, sportnetId, {
          teamId,
          limit: 1000,
          offset: 0,
          ...matchesFilter,
        })
        .then((res) => {
          setMatches(res.matches);
        })
        .catch((e) => {
          alert(__('Nepodarilo sa získať zoznam zápasov hráča'));
        })
        .finally(() => {
          setIsFetchingMatches(false);
        });
    }
  }, [matchesFilter, matchesModalOpened, appspace, sportnetId, teamId]);

  React.useEffect(() => {
    setIsFetchingData(true);
    api
      .getPlayerStats(appspace, sportnetId, { teamId })
      .then((res) => {
        setData(res);
        sportnetApi
          .organizationPPOUser(appspace, sportnetId)
          .then((userRes) => {
            setUser({
              _id: userRes._id || '',
              name: userRes.name || '',
              surname: userRes.surname || '',
              titles: userRes.titles || {},
              citizenship: userRes.citizenship || '',
            });
          });
      })
      .catch((e) => {
        alert(__('Nepodarilo sa získať štatistiku hráča'));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, [appspace, sportnetId, teamId]);

  const handlePersonalFormSubmit = async (values: any) => {
    try {
      await api.adminUpdateTeamPlayerData(
        appspace,
        teamId,
        sportnetId,
        {},
        {
          sportnetUser: {
            titles: values.titles,
            name: values.name,
            surname: values.surname,
            citizenship: values.citizenship,
          },
          competitions: Object.keys(values.competitions).filter(
            (id) => values.competitions[id],
          ),
        },
      );
    } catch (e) {
      alert(__('Zmeny sa  nepodarilo uložiť'));
    }
  };

  const getMatchStatus = (match: Match) => {
    if (match.contumation && match.contumation.isContumated) {
      return __('Kontumované');
    } else if (match.closed) {
      return __('Uzavreté');
    }
    return __('Neuzavreté');
  };

  const anyHasScore = !!Array.from(matches || []).find((i) => !!i.score);

  return (
    <ScrollLayout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/teams/${teamId}`}
            />
            <HeaderBar.Header>
              {__('Štatistika hráča')}
              {user && ` - ${formatUserName(user)} (${user._id})`}
            </HeaderBar.Header>
          </HeaderBar>
        </>
      }
    >
      <Modal
        isOpen={matchesModalOpened}
        handleClose={() => toggleMatchesModal()}
      >
        <ModalContent>
          {isFetchingMatches ? (
            <LoaderWrapper>
              <Loader size="m" />
            </LoaderWrapper>
          ) : (
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Stav')}</Th>
                  {anyHasScore && <Th>{__('Skóre')}</Th>}
                  <Th>{__('Súťaž')}</Th>
                  <Th>{__('Sezóna')}</Th>
                  <Th>{__('Kolo')}</Th>
                  <Th>{__('Termín konania')}</Th>
                  <Th>{__('Miesto konania')}</Th>
                  <Th>{__('Účastníci')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.from(matches ? matches : []).map((match: Match) => {
                  return (
                    <Tr
                      key={match._id}
                      onClick={() => {
                        history.push(
                          `/admin/${match.appSpace}/competitions/${match.competition._id}/parts/${match.competitionPart._id}/matches/${match._id}`,
                        );
                      }}
                    >
                      <Td>{getMatchStatus(match)}</Td>
                      {anyHasScore && (
                        <Td>
                          <Score isComputed={!!match.scoreIsComputed}>
                            <div>
                              {match.score &&
                                `${match.score[0]}:${match.score[1]}`}
                              {match.penaltiesScore &&
                                (match.penaltiesScore || []).length > 0 &&
                                ` (${match.penaltiesScore[0]}:${match.penaltiesScore[1]})`}
                            </div>
                            {(match.scoreByPhases || []).length > 0 && (
                              <div style={{ color: '#333', fontSize: rem(11) }}>
                                (
                                {(match.scoreByPhases || [])
                                  .map((i) => (i ? i.join(':') : ''))
                                  .join(', ')}
                                )
                              </div>
                            )}
                          </Score>
                        </Td>
                      )}
                      <Td>
                        {match.competition.name}
                        <br />
                        <i>{match.competitionPart.name}</i>
                      </Td>
                      <Td>{match.season.name}</Td>
                      <Td>{match.round && match.round.name}</Td>
                      <Td>
                        {format(new Date(match.startDate), 'dd.MM.yyyy HH:mm')}
                      </Td>
                      <Td>{!!match.sportGround && match.sportGround.name}</Td>
                      <Td>
                        {(match.teams || []).map(
                          (team: { _id: string; name: string }) => {
                            return (
                              <div key={`${match._id}_${team._id}`}>
                                {team.name}
                              </div>
                            );
                          },
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </ModalContent>
      </Modal>
      {isFetchingData ? (
        <LoaderWrapper>
          <Loader size="l" />
        </LoaderWrapper>
      ) : (
        <Segment>
          {data && (
            <>
              <Box title={__('Zmena údajov')}>
                <PersonalInfoForm
                  initialValues={{
                    ...user,
                    displayName: data.sportnetUser.name,
                  }}
                  competitions={data.byCompetition.map((i) => ({
                    _id: i._id,
                    name: `${i.name} (${i.season.name})`,
                  }))}
                  countries={countryCodelist || []}
                  form={PERSONAL_INFO_FORM_NAME}
                  onSubmit={handlePersonalFormSubmit}
                />
                <div>
                  <Button
                    primary
                    onClick={() => {
                      dispatch(submit(PERSONAL_INFO_FORM_NAME));
                    }}
                    loading={isSubmittingPersonalForm}
                    disabled={
                      Object.values(selectedCompetitions || {}).filter((o) => o)
                        .length === 0
                    }
                  >
                    {__('Uložiť zmeny')}
                  </Button>
                </div>
              </Box>
              <Box title={__('Sumárna štatistika')}>
                <Table>
                  <Thead>
                    <Th width={100} />
                    {Object.keys(
                      getProp(data || {}, ['summary', 'stats'], {}),
                    ).map((stat) => {
                      return STAT_TYPES[stat] ? (
                        <Th width={100}>{STAT_TYPES[stat]}</Th>
                      ) : null;
                    })}
                  </Thead>
                  <Tbody>
                    <Tr onClick={() => toggleMatchesModal({})}>
                      <Td>{__('Sumárne')}</Td>
                      {Object.keys(
                        getProp(data || {}, ['summary', 'stats'], {}),
                      ).map((stat) => {
                        return STAT_TYPES[stat] ? (
                          <Td>
                            {getProp(data || {}, ['summary', 'stats', stat])}
                          </Td>
                        ) : null;
                      })}
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              <Box title={__('Sumárne podľa súťaží')}>
                <Table>
                  <Thead>
                    <Th width={100} />
                    {Object.keys(
                      getProp(data || {}, ['summary', 'stats'], {}),
                    ).map((stat) => {
                      return STAT_TYPES[stat] ? (
                        <Th width={100}>{STAT_TYPES[stat]}</Th>
                      ) : null;
                    })}
                  </Thead>
                  <Tbody>
                    {data.byCompetitionGroup.map((competitionGroup) => {
                      return (
                        <Tr
                          key={competitionGroup._id}
                          onClick={() =>
                            toggleMatchesModal({
                              competitionGroupId: competitionGroup._id,
                            })
                          }
                        >
                          <Td>{competitionGroup.name}</Td>
                          {Object.keys(
                            getProp(data || {}, ['summary', 'stats'], {}),
                          ).map((stat) => {
                            return STAT_TYPES[stat] ? (
                              <Td>
                                {getProp(competitionGroup || {}, [
                                  'stats',
                                  stat,
                                ])}
                              </Td>
                            ) : null;
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
              <Box title={__('Sumárne podľa ročníkov súťaží')}>
                <Table>
                  <Thead>
                    <Th width={100} />
                    {Object.keys(
                      getProp(data || {}, ['summary', 'stats'], {}),
                    ).map((stat) => {
                      return STAT_TYPES[stat] ? (
                        <Th width={100}>{STAT_TYPES[stat]}</Th>
                      ) : null;
                    })}
                  </Thead>
                  <Tbody>
                    {data.byCompetition.map((competition: any) => {
                      return (
                        <Tr
                          key={competition._id}
                          onClick={() =>
                            toggleMatchesModal({
                              competitionId: competition._id,
                            })
                          }
                        >
                          <Td>{`${competition.name} (${competition.season.name})`}</Td>
                          {Object.keys(
                            getProp(data || {}, ['summary', 'stats'], {}),
                          ).map((stat) => {
                            return STAT_TYPES[stat] ? (
                              <Td>
                                {getProp(competition || {}, ['stats', stat])}
                              </Td>
                            ) : null;
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}
        </Segment>
      )}
    </ScrollLayout>
  );
};

export default compose(withRouter, connect(mapStateToProps))(C) as any;
