import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { Theme } from '@sportnet/ui/Themes/styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import {
  arrayRemove,
  change,
  Field,
  Form,
  formValueSelector,
  submit,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import Icons from '../../../components/Icons';
import { competitionPartByIdSelector } from '../../Competitions/selectors';
import { Match, Result } from '../../Matches/definitions';
import styled, { withTheme } from 'styled-components';
import Box from '../../../components/Box';
import { State } from '../../../rootReducer';
import {
  getProp,
  isTime,
  parseToTimeString,
  required,
  __,
} from '../../../utilities';
import { Athlete, Team } from '../../Teams/definitions';
import CommonProtocol from '../Common';
import EventForm, { EVENT_FORM_NAME } from '../eventForm';
import Timeline from '../timeline';
import ProtocolTimer from '../timer';

interface User {
  _id: string;
  name: string;
}

interface Event {
  eventTime: string;
  eventType: string;
  type: string;
  assist: User;
  replacement: User;
  player: User;
  reason: string;
  team: string;
  score: string;
  phase: string;
}

// VALIDACIA

const isRequired = (value: string) => {
  if (!value) {
    return __('Pole je povinné');
  }
  return null;
};

const PlayerInfo = styled('div')`
  display: flex;
  & > div {
    padding: 0 5px;
  }
`;

const Player = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  font-size: 14px;
`;

const Situations = styled('div')`
  display: flex;
`;

const SituationItem = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${rem(2)};
`;

const FORM_NAME = 'MATCH_FORM';

interface OwnProps {
  teams: Team[];
  nominations: Array<{
    athletes: Athlete[];
    crew: any;
    teamId: string;
  }>;
  closed: boolean;
  data?: Match | null;
}

const selector = formValueSelector(FORM_NAME);
const eventFormSelector = formValueSelector(EVENT_FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  let competitionPart = null;
  let settings = null;

  if (props.data) {
    competitionPart = competitionPartByIdSelector(
      props.data.competition._id,
      props.data.competitionPart._id,
    )(state);
    settings = props.data.settings;
  }
  return {
    competitionPart,
    settings,
    protocol: selector(state, 'protocol'),
    timer: selector(state, 'timer'),
    startDate: selector(state, 'startDate'),
    events: selector(state, 'protocol.events'),
    delegates: selector(state, 'protocol.delegates'),
    toClose: selector(state, 'toClose') || false,
    matchTimer: selector(state, 'timer') || {},
    eventFormValues: {
      team: eventFormSelector(state, 'team'),
      player: eventFormSelector(state, 'player'),
    },
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
  id: string;
}> &
  OwnProps & { dispatch: ThunkDispatch<any, any, AnyAction> } & Theme &
  IMapStateToProps;

class WaterPoloProtocol extends CommonProtocol<Props> {
  getSituationFields = (name: string, idx: number) => {
    const nominatedTeams = this.getNominatedTeams() as any;
    let nominatedPlayers = this.getNominatedPlayers();

    const { team } = this.props.eventFormValues;

    if (team) {
      nominatedPlayers = nominatedPlayers[team];
    } else {
      nominatedPlayers = Object.keys(nominatedPlayers).reduce(
        (acc: any[], k) => [...acc, ...nominatedPlayers[k]],
        [],
      );
    }

    const situations = {
      timeout: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`.padStart(2, '0');
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Štvrtina')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
        </>
      ),
      goal: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`.padStart(2, '0');
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Štvrtina')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Typ gólu')}
            name={`type`}
            required
            validate={[required]}
            options={this.state.codelists.events.filter(
              (e) => e.eventType === 'goal',
            )}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Strelec gólu')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
        </>
      ),
      exclusion: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Štvrtina')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Typ')}
            name={`type`}
            required
            validate={[required]}
            options={this.state.codelists.events.filter(
              (e) => e.eventType === 'exclusion',
            )}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Vylúčený hráč')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
        </>
      ),
      yellow_card: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Štvrtina')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Potrestaný hráč')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            label={__('Dôvod')}
            name={`reason`}
            required
            validate={[required]}
          />
        </>
      ),
      red_card: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Štvrtina')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Potrestaný hráč')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            label={__('Dôvod')}
            name={`reason`}
            required
            validate={[required]}
          />
        </>
      ),
    };
    return situations[name];
  };

  getSituations = (
    nominatedTeams: Array<{ label: string; value: string }>,
    players: Array<{ label: string; value: string }>,
  ) => [
    {
      label: __('Gól'),
      name: __('Gól'),
      type: 'goal',
    },
    {
      label: __('Vylúčenie'),
      name: __('Vylúčenie'),
      type: 'exclusion',
    },
    {
      label: __('Timeout'),
      name: __('Timeout'),
      noIcon: true,
      type: 'timeout',
    },
    {
      label: __('Žltá karta'),
      name: __('Žltá karta'),
      type: 'yellow_card',
    },
    {
      label: __('Červená karta'),
      name: __('Červená karta'),
      type: 'red_card',
    },
    {
      label: __('Nepremenený gól'),
      name: __('Nepremenený gól'),
      type: 'failed_goal',
    },
  ];

  renderEventRow = (event: Event) => {
    const team = this.props.teams.find((t) => t._id === event.team);
    const goalType = this.state.codelists.events
      .filter((e) => e.eventType === 'goal')
      .find((i) => i.value === event.type);
    const failedGoalType = this.state.codelists.events
      .filter((e) => e.eventType === 'failed_goal')
      .find((i) => i.value === event.type);
    const exclusionType = this.state.codelists.events
      .filter((e) => e.eventType === 'exclusion')
      .find((i) => i.value === event.type);
    switch (event.eventType) {
      case 'goal':
        return (
          <div>
            <div>
              <b>{goalType ? goalType.label : __('Gól')}</b>
            </div>
            {team && (
              <div>
                {__('Družstvo')}: {team.name}
              </div>
            )}
            {event.player && (
              <div>
                {__('Strelec')}: {event.player.name}
              </div>
            )}
            {event.assist && (
              <div>
                {__('Asistencia')}: {event.assist.name}
              </div>
            )}
          </div>
        );
      case 'failed_goal':
        return (
          <div>
            <div>
              <b>
                {failedGoalType ? failedGoalType.label : __('Nepremenený gól')}
              </b>
            </div>
            {team && (
              <div>
                {__('Družstvo')}: {team.name}
              </div>
            )}
            {event.player && (
              <div>
                {__('Strelec')}: {event.player.name}
              </div>
            )}
          </div>
        );
      case 'exclusion':
        return (
          <div>
            <div>
              <b>{exclusionType ? exclusionType.label : ''}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Vylúčený hráč')}: {event.player.name}
              </div>
              {event.replacement && (
                <div>
                  {__('Náhradník')}: {event.replacement.name}
                </div>
              )}
            </div>
          </div>
        );
      case 'timeout':
        return (
          <div>
            <div>
              <b>{__('Timeout')}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
            </div>
          </div>
        );
      case 'yellow_card':
        return (
          <div>
            <div>
              <b>{__('Žltá karta')}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Potrestaný hráč')}: {event.player.name}
              </div>
              <div>
                {__('Dôvod')}: {event.reason}
              </div>
            </div>
          </div>
        );
      case 'red_card':
        return (
          <div>
            <div>
              <b>{__('Červená karta')}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Potrestaný hráč')}: {event.player.name}
              </div>
              <div>
                {__('Dôvod')}: {event.reason}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { toClose, closed, theme, data } = this.props;
    const events = getProp(this.props, ['events'], []);
    // const delegates = getProp(this.props, ['delegates'], []);

    const nominatedPlayers = this.getNominatedPlayers();
    const nominatedTeams = this.getNominatedTeams();

    const situations = this.getSituations(
      nominatedTeams as any,
      Object.keys(nominatedPlayers).reduce(
        (acc: any[], k) => [...acc, ...nominatedPlayers[k]],
        [],
      ),
    );

    return (
      <Form>
        <Segment>
          <Box title={__('Základné informácie')}>
            <Row>
              <Col xs={12} s={4}>
                <Field
                  label={__('Číslo stretnutia')}
                  name="protocol.matchNr"
                  component={FormField}
                  validate={toClose ? [isRequired] : []}
                  type="input"
                  disabled={closed}
                />
              </Col>
            </Row>
          </Box>
          {data && data.resultsTable && (
            <Box title={__('Výsledková tabuľka po stretnutí')}>
              <BasicTable
                columns={[
                  {
                    header: __('Družstvo'),
                  },
                  {
                    header: __('OZ'),
                    width: 40,
                  },
                  {
                    header: __('V'),
                    width: 40,
                  },
                  {
                    header: __('R'),
                    width: 40,
                  },
                  {
                    header: __('P'),
                    width: 40,
                  },
                  {
                    header: __('G'),
                    width: 80,
                  },
                  {
                    header: __('B'),
                    width: 40,
                  },
                ]}
                rows={data.resultsTable.results.map((r) => ({
                  ...r,
                  rowKey: r.team._id,
                }))}
                renderRow={(result: Result) => [
                  result.team.name,
                  result.stats.matches.played,
                  result.stats.matches.won,
                  result.stats.matches.draw,
                  result.stats.matches.lost,
                  `${result.stats.goals.given}:${result.stats.goals.received}`,
                  result.stats.points,
                ]}
                rowKey="rowKey"
              />
            </Box>
          )}
          {/* <Box title={__('Zodpovedné osoby')}>
            <TableForm
              disabled={closed}
              buttonLabel={__('Pridať osobu')}
              headers={[
                { label: __('Typ') },
                { label: __('Meno a priezvisko') },
              ]}
              items={delegates}
              list={delegates.map((i: { type: string; name: string }) => {
                return {
                  type: i.type,
                  name: i.name,
                };
              })}
              fields={[
                {
                  type: 'select',
                  label: __('Typ'),
                  items: this.state.codelists.delegates,
                  name: 'type',
                  required: true,
                },
                {
                  label: __('Meno a priezvisko'),
                  type: 'input',
                  name: 'name',
                  required: true,
                },
              ]}
              onChange={e => {
                this.props.dispatch(change(FORM_NAME, 'protocol.delegates', e));
              }}
            />
          </Box> */}
          <Box title={__('Priebeh stretnutia')}>
            <Message warning>
              {__(
                'V prípade, že nevyplníte čas začiatku a konca zápasu, na verejnej časti nebude možné zobraziť priebeh stretnutia.',
              )}
            </Message>
            <Box title={__('Začiatok a koniec')}>
              <Row>
                {this.state.codelists.phases
                  .filter((phase) => phase.playPhase)
                  .map((phase) => (
                    <>
                      <Col xs={12} s={6} m={3}>
                        <Field
                          name={`timer.${phase._id}.start.date`}
                          label={`${phase.label} - ${__('začiatok')}`}
                          component={FormField}
                          type="time"
                          timeIntervals={1}
                          disabled={closed}
                        />
                      </Col>
                      <Col xs={12} s={6} m={3}>
                        <Field
                          name={`timer.${phase._id}.end.date`}
                          label={`${phase.label} - ${__('koniec')}`}
                          component={FormField}
                          type="time"
                          timeIntervals={1}
                          disabled={closed}
                        />
                      </Col>
                    </>
                  ))}
              </Row>
            </Box>
            {!closed && this.props.competitionPart && (
              <Box title={__('Live stretnutie')}>
                <ProtocolTimer
                  matchId={!!this.props.data ? this.props.data._id : undefined}
                  phases={this.state.codelists.phases}
                  onToggle={this.onToggleTimer}
                  initialData={this.state.timer}
                  settings={this.props.competitionPart.settings}
                  getCurrentTime={(diff: number) => {
                    this.currentTimerValue = diff;
                  }}
                  getCurrentPhase={(phase: string) => {
                    this.currentTimerPhase = phase;
                  }}
                />
              </Box>
            )}
            <Row>
              {nominatedTeams.map((team: any) => (
                <Col xs={12} m={6} key={team.value}>
                  <Segment
                    raised
                    header={
                      <SegmentHeader withSeparator size="xs">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {team.label}
                          {!closed && (
                            <Situations>
                              <SituationItem
                                onClick={() => {
                                  this.setActiveSituation(
                                    situations.find(
                                      (s) => s.type === 'timeout',
                                    )!,
                                    team,
                                  );
                                }}
                              >
                                <Icons
                                  name="timeout"
                                  color={theme.color.base}
                                  size={15}
                                />
                                &nbsp;&nbsp;
                                <div style={{ fontSize: rem(12) }}>
                                  {__('Timeout')}
                                </div>
                              </SituationItem>
                            </Situations>
                          )}
                        </div>
                      </SegmentHeader>
                    }
                  >
                    {nominatedPlayers[team.value].map(
                      (player: {
                        label: string;
                        value: string;
                        additionalData: { nr: string };
                      }) => (
                        <Player key={player.value}>
                          <PlayerInfo>
                            <div>{player.additionalData.nr}</div>
                            <div>{player.label}</div>
                          </PlayerInfo>
                          {!closed && (
                            <Situations>
                              {situations
                                .filter((s) => !s.noIcon)
                                .map((situation) => (
                                  <SituationItem
                                    key={situation.type}
                                    onClick={() => {
                                      this.setActiveSituation(
                                        situation,
                                        team,
                                        player,
                                      );
                                    }}
                                  >
                                    {situation.type === 'goal' && (
                                      <Icons
                                        name="goal"
                                        size={15}
                                        color={this.props.theme.color.primary}
                                      />
                                    )}
                                    {situation.type === 'failed_goal' && (
                                      <Icons
                                        name="failed_goal"
                                        size={15}
                                        color={this.props.theme.color.warning}
                                      />
                                    )}
                                    {situation.type === 'yellow_card' && (
                                      <Icons name="yellow_card" size={15} />
                                    )}
                                    {situation.type === 'red_card' && (
                                      <Icons name="red_card" size={15} />
                                    )}
                                    {situation.type === 'exclusion' && (
                                      <Icons
                                        name="exclusion"
                                        color={theme.color.danger}
                                        size={17.5}
                                      />
                                    )}
                                  </SituationItem>
                                ))}
                            </Situations>
                          )}
                        </Player>
                      ),
                    )}
                  </Segment>
                </Col>
              ))}
            </Row>
            {this.props.settings && (
              <Timeline
                protocol={this.props.protocol}
                phases={this.state.codelists.phases}
                eventTypes={this.state.codelists.events}
                teams={this.props.teams}
                settings={{ ...this.props.settings, displaySeconds: true }}
                timer={this.props.data && this.props.data.timer}
                onClickEvent={(event, idx) => {
                  const situation = situations.find(
                    (s) => s.type === event.eventType,
                  );
                  const team = this.props.teams.find(
                    (t) => t._id === event.team,
                  );
                  if (situation && team) {
                    this.setActiveSituation(
                      situation,
                      { label: team.name, value: team._id },
                      {
                        label: getProp(event, ['player', 'name']),
                        value: getProp(event, ['player', '_id']),
                      },
                      event,
                      idx > -1 ? idx : undefined,
                      event.eventTime,
                      event.phase,
                    );
                  }
                }}
              />
            )}
            {this.state.isEventsFormOpened && this.state.activeSituation && (
              <Modal
                isOpen
                handleClose={() =>
                  this.setState({
                    situationTeamId: '',
                    isEventsFormOpened: false,
                    activeSituation: null,
                  })
                }
              >
                <ModalContent>
                  <EventForm
                    initialValues={this.state.activeSituation.data}
                    onSubmit={(values: any) => {
                      const eventTime =
                        typeof values.eventTime === 'number'
                          ? parseToTimeString(values.eventTime)
                          : values.eventTime;

                      if (
                        this.state.activeSituation &&
                        typeof this.state.activeSituation.idx !== 'undefined'
                      ) {
                        this.props.dispatch(
                          change(
                            FORM_NAME,
                            `protocol.events.${this.state.activeSituation.idx}`,
                            {
                              ...values,
                              eventTime,
                            },
                          ),
                        );
                      } else {
                        this.props.dispatch(
                          change(FORM_NAME, 'protocol.events', [
                            ...events,
                            {
                              ...values,
                              eventTime,
                            },
                          ]),
                        );
                      }
                      this.setState(
                        {
                          situationTeamId: '',
                          isEventsFormOpened: false,
                          activeSituation: null,
                        },
                        () => {
                          this.props.dispatch(submit(FORM_NAME));
                        },
                      );
                    }}
                  >
                    {this.getSituationFields(
                      this.state.activeSituation.situation.type,
                      this.state.activeSituation.idx || 0,
                    )}
                  </EventForm>
                </ModalContent>
                <ModalActions>
                  <div>&nbsp;</div>
                  <div>
                    <Button
                      secondary
                      onClick={() => {
                        this.setState({
                          situationTeamId: '',
                          isEventsFormOpened: false,
                        });
                      }}
                    >
                      {__('Zavrieť')}
                    </Button>
                    &nbsp;
                    {!!this.state.activeSituation &&
                      typeof this.state.activeSituation.idx !== 'undefined' && (
                        <>
                          <Button
                            danger
                            onClick={() => {
                              if (this.state.activeSituation) {
                                this.props.dispatch(
                                  arrayRemove(
                                    FORM_NAME,
                                    'protocol.events',
                                    this.state.activeSituation.idx || 0,
                                  ),
                                );
                                this.setState(
                                  {
                                    situationTeamId: '',
                                    isEventsFormOpened: false,
                                    activeSituation: null,
                                  },
                                  () => {
                                    this.props.dispatch(submit(FORM_NAME));
                                  },
                                );
                              }
                            }}
                          >
                            {__('Odstrániť')}
                          </Button>
                          &nbsp;
                        </>
                      )}
                    <Button
                      primary
                      onClick={() => {
                        this.props.dispatch(submit(EVENT_FORM_NAME));
                      }}
                    >
                      {this.state.activeSituation &&
                      this.state.activeSituation.idx
                        ? __('Upraviť')
                        : __('Pridať')}
                    </Button>
                  </div>
                </ModalActions>
              </Modal>
            )}
          </Box>
        </Segment>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(
  withRouter(withTheme(WaterPoloProtocol)),
);
