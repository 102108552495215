import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { codelistSelector } from '../../App/selectors';
import { State } from '../../../rootReducer';
import { required, __ } from '../../../utilities';

const mapStateToProps = (state: State) => ({
  countryCodelist: codelistSelector('country')(state),
});

interface OwnProps {
  crewCodelist: Array<{ _id: string; label: string }>;
  competenceTypes: Array<{ label: string; value: string }>;
}

const CrewMemberCreationForm: React.FC<
  ReturnType<typeof mapStateToProps> & OwnProps
> = ({ countryCodelist, crewCodelist, competenceTypes }) => {
  return (
    <>
      <Field
        name="name"
        component={FormField}
        label={__('Meno')}
        required
        validate={[required]}
      />
      <Field
        name="surname"
        component={FormField}
        label={__('Priezvisko')}
        required
        validate={[required]}
      />
      <Field
        component={FormField}
        name="position"
        type="theselectsimple"
        required
        validate={[required]}
        label={__('Pozícia')}
        options={crewCodelist.map((i) => ({
          label: i.label,
          value: i._id,
        }))}
      />
      <Field
        name="citizenship"
        component={FormField}
        label={__('Štátna príslušnosť')}
        type="theselectsimple"
        options={countryCodelist}
      />
      <Field
        name="birthdate"
        component={FormField}
        label={__('Dátum narodenia')}
        type="date"
      />
      <Field
        name="competenceType"
        component={FormField}
        label={__('Typ príslušnosti')}
        type="theselectsimple"
        options={competenceTypes}
        required
        validate={[required]}
      />
      <Field
        name="dateFrom"
        component={FormField}
        label={__('Začiatok dátumu platnosti príslušnosti')}
        type="date"
      />
    </>
  );
};

export default compose<any>(
  connect(mapStateToProps),
  reduxForm({}),
)(CrewMemberCreationForm);
