import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import {
  change,
  formValueSelector,
  getFormSubmitErrors,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { CustomThunkDispatch } from '../../../../configureStore';
import { State } from '../../../../rootReducer';
import { getProp, __ } from '../../../../utilities';
import styled from 'styled-components';
import { rem } from 'polished';
import UserSelectionInput from '../userSelectionInput';

const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

const ErrorText = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  margin-top: ${rem(3)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export interface Athlete {
  sportnetId: string;
  displayName: string;
}

interface OwnProps {
  update: boolean;
}

const mapStateToProps = (state: State, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    sportnetId: selector(state, 'sportnetId'),
    errors: getFormSubmitErrors(props.form)(state),
  };
};

type Props = InjectedFormProps<
  {
    displayName: string;
    sportnetId: string;
  },
  OwnProps
> &
  OwnProps &
  ReturnType<typeof mapStateToProps> & {
    dispatch: CustomThunkDispatch;
  } & RouteComponentProps<{
    competitionId: string;
    partId: string;
    teamId: string;
    clubId: string;
  }>;

const WaterPoloSquadPlayerForm: React.FC<Props> = ({
  match: {
    params: { clubId, competitionId: id, partId, teamId },
  },
  dispatch,
  form,
  sportnetId,
  errors,
  update,
}) => {
  return (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label>{__('Výber hráča (min. 4 znaky)')}</Label>
          <RelativeWrapper>
            <UserSelectionInput
              error={getProp(errors, ['sportnetId'])}
              value={sportnetId}
              appSpace={clubId}
              disabled={update}
              competitionId={id}
              partId={partId}
              teamId={teamId}
              onChange={(i) => {
                dispatch(change(form, 'sportnetId', i));
              }}
            />
            {!!getProp(errors, ['sportnetId']) && (
              <ErrorText title={getProp(errors, ['sportnetId'])}>
                {getProp(errors, ['sportnetId'])}
              </ErrorText>
            )}
          </RelativeWrapper>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  reduxForm<any, any>({ enableReinitialize: true }),
)(WaterPoloSquadPlayerForm) as any;
