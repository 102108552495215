import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { required, __ } from '../../../utilities';

export const NEW_ROUND_FORM_NAME = 'NEW_ROUND_FORM';

type Props = InjectedFormProps;

class NewRoundForm extends React.PureComponent<Props> {
  render() {
    return (
      <Row>
        <Col xs={12}>
          <Field
            required
            validate={[required]}
            name="name"
            label={__('Názov kola')}
            component={FormField}
          />
        </Col>
        <Col xs={12}>
          <Field
            required
            validate={[required]}
            name="dateFrom"
            type="date"
            format={null}
            label={__('Začiatok kola')}
            component={FormField}
          />
        </Col>
        <Col xs={12}>
          <Field
            required
            validate={[required]}
            name="dateTo"
            type="date"
            format={null}
            label={__('Koniec kola')}
            component={FormField}
          />
        </Col>
      </Row>
    );
  }
}

export default reduxForm({
  form: NEW_ROUND_FORM_NAME,
})(NewRoundForm);
