import FormField from '@sportnet/ui/FormField/redux-form';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { State } from '../../rootReducer';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import { __ } from '../../utilities';

const mapStateToProps = (state: State) => ({});

interface OwnProps {
  appSpace: string;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const SquadSmarttagsForm: React.FC<Props> = ({ appSpace }) => {
  const accessToken =
    Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';

  return (
    <>
      {appSpace && (
        <TagmanagerConnector
          appspace={appSpace}
          appId={process.env.REACT_APP_APP_ID || ''}
          accessToken={accessToken}
        >
          {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
            <Field
              getSmarttagsValues={getSmarttagsValues}
              getSmarttagsKeys={getSmarttagsKeys}
              getSmarttagsByFts={getSmarttagsByFts}
              name="smarttags"
              type="smarttag"
              label={__('Smarttagy')}
              component={FormField}
              placeholder={__('Zadajte Smart:tag a stlačte Enter')}
            />
          )}
        </TagmanagerConnector>
      )}
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm({}),
)(SquadSmarttagsForm) as any;
