import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import Filter from '@sportnet/ui/Filter';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import connectToQueryHoc, {
  QueryHocInterface,
  QueryHocTypes,
} from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction } from 'redux';
import {
  commit,
  getListNextOffset,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import styled, { css } from 'styled-components';
import api from '../../api';
import { State } from '../../rootReducer';
import { getProp, __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { Season } from '../Codelists/Seasons/definitions';
import { seasonsSelector } from '../Codelists/Seasons/selectors';
import {
  Competition,
  CompetitionPart,
  Round,
} from '../Competitions/definitions';
import {
  competitionPartsByCompetitionIdSelector,
  competitionsResultsSelector,
} from '../Competitions/selectors';
import { getMatches } from './actions';
import { Match } from './definitions';
import { currentListMatchesSelector } from './selectors';
import { format } from 'date-fns';

const LIST_LIMIT = 20;
export const LIST_NAME = 'MATCHES_LIST';

const closedOptions = [
  {
    value: 'true',
    label: __('Uzavreté'),
  },
  {
    value: 'false',
    label: __('Neuzavreté'),
  },
];

const Score = styled.div<{ isComputed: boolean }>`
  ${({ isComputed }) => css`
    color: ${isComputed ? 'green' : 'red'};
  `}
`;

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

const mapStateToProps = (state: State, props: OwnProps & QueryHocInterface) => {
  const {
    query: { seasonIds, competitionId },
  } = props;
  const reduxProps: {
    seasons: Season[];
    competitions?: Competition[];
    competitionParts?: CompetitionPart[];
    matches: Match[] | null;
    total: number;
    nextOffset: number | null;
    isCommiting: boolean | null;
  } = {
    seasons: seasonsSelector(state),
    matches: currentListMatchesSelector(LIST_NAME)(state),
    total: getListTotal(LIST_NAME)(state) || 0,
    nextOffset: getListNextOffset(LIST_NAME)(state) || null,
    isCommiting: isCommiting(LIST_NAME)(state),
  };
  if (seasonIds) {
    reduxProps.competitions = competitionsResultsSelector(state);
  }
  if (competitionId) {
    reduxProps.competitionParts = competitionPartsByCompetitionIdSelector(
      String(competitionId[0]),
    )(state);
  }
  return reduxProps;
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
}> &
  OwnProps &
  IMapStateToProps &
  QueryHocInterface;

class MatchesList extends React.PureComponent<
  Props,
  {
    seasons: any;
    competitions: any;
    parts: any;
    rounds: any;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seasons: [],
      competitions: [],
      parts: [],
      rounds: [],
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setBreadcrumbs([__('Stretnutia')]));
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: this.props.query,
      }),
    );
    this.getMatches();
    this.getFilterValues();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.serializedQuery !== prevProps.serializedQuery) {
      this.props.dispatch(
        setParams({
          listName: LIST_NAME,
          parameters: this.props.query,
        }),
      );
      this.getMatches(this.props);
      this.getFilterValues(this.props);
    }
  }

  getMatches = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query: {
        competitionId,
        competitionPartId,
        seasonId,
        roundId,
        closed,
        offset,
        dateFrom,
        dateTo,
      },
    } = props;
    let competitionIds: any[] = [];
    let partIds: any[] = [];
    let seasonIds: any[] = [];
    let roundIds: any[] = [];
    if (Array.isArray(competitionId)) {
      competitionIds = competitionId as any[];
    }
    if (Array.isArray(competitionPartId)) {
      partIds = competitionPartId as any[];
    }
    if (Array.isArray(seasonId)) {
      seasonIds = seasonId as any[];
    }
    if (Array.isArray(roundId)) {
      roundIds = roundId as any[];
    }

    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const res = await dispatch(
            getMatches.action({
              appspace,
              limit: LIST_LIMIT,
              offset: Number(offset),
              competitionIds: competitionIds.map((i) => String(i)),
              partIds: partIds.map((i) => String(i)),
              seasonIds: seasonIds.map((i) => String(i)),
              roundIds: roundIds.map((i) => String(i)),
              closed: String(closed),
              dateFrom: dateFrom
                ? format(new Date(String(dateFrom)), 'yyyy-MM-dd')
                : undefined,
              dateTo: dateTo
                ? format(new Date(String(dateTo)), 'yyyy-MM-dd')
                : undefined,
            }),
          );
          return {
            results: Object.keys(res.entities.matches).map((i) => i),
            total: res.total || 0,
            nextOffset: res.nextOffset || null,
          };
        },
      }),
    );
  };

  getFilterValues = async (props = this.props) => {
    const {
      match: {
        params: { appspace },
      },
      query: { seasonId, competitionId, competitionPartId },
    } = props;
    let seasons: any[] = [];
    let competitions: any[] = [];
    let parts: any[] = [];
    let rounds: any[] = [];

    const seasonsResponse = await api.adminGetSeasons(appspace);
    seasons = seasonsResponse.seasons;

    if (seasonId) {
      let sIds: string[] = [];
      if (Array.isArray(seasonId)) {
        sIds = (seasonId as string[]).map((item) => {
          return String(item);
        });
      }
      const res = await api.adminGetCompetitions(appspace, {
        seasonIds: sIds,
      });
      competitions = res.competitions;
    }

    if (competitionId && competitionId[0]) {
      const res = await api.adminGetCompetitionParts(
        appspace,
        competitionId[0],
      );
      parts = res.parts || [];
    }
    if (
      competitionId &&
      competitionId[0] &&
      competitionPartId &&
      competitionPartId[0]
    ) {
      const res = await api.adminGetCompetitionPartById(
        appspace,
        competitionId[0],
        competitionPartId[0],
      );
      rounds = res.rounds || [];
    }

    this.setState({
      seasons,
      competitions,
      parts,
      rounds,
    });
  };

  getMatchStatus = (match: Match) => {
    if (match.contumation && match.contumation.isContumated) {
      return __('Kontumované');
    } else if (match.closed) {
      return __('Uzavreté');
    }
    return __('Neuzavreté');
  };

  render() {
    const {
      match: {
        params: { appspace },
      },
      query,
      query: {
        seasonId: seasonIdRaw = [],
        competitionId: competitionIdRaw = [],
        competitionPartId: competitionPartIdRaw = [],
        offset,
      },
      setParameter,
      matches,
      total,
      nextOffset,
      isCommiting,
    } = this.props;
    const {
      seasons,
      competitions,
      parts: competitionParts,
      rounds,
    } = this.state;

    const seasonId = seasonIdRaw as string[];
    const competitionId = competitionIdRaw as string[];
    const competitionPartId = competitionPartIdRaw as string[];

    const anyHasScore = !!Array.from(matches || []).find((i) => !!i.score);

    const transformedQuery = Object.keys(query).reduce((acc, k) => {
      if (k === 'closed') {
        const val: any = [];
        (query[k] || ([] as any)).forEach((value: string) => {
          const opt = value && closedOptions.find((i) => i.value === value);
          if (opt) {
            val.push(opt);
          }
        });
        return { ...acc, [k]: val };
      } else if (k === 'seasonId') {
        const val: any = [];
        (query[k] || ([] as any)).forEach((value: string) => {
          const opt = value && seasons.find((i: any) => i._id === value);
          if (opt) {
            val.push({ value: opt._id, label: opt.name });
          }
        });
        return { ...acc, [k]: val };
      } else if (k === 'competitionId') {
        const val: any = [];
        (query[k] || ([] as any)).forEach((value: string) => {
          const opt =
            value && (competitions || []).find((i: any) => i._id === value);
          if (opt) {
            val.push({ value: opt._id, label: opt.name });
          }
        });
        return { ...acc, [k]: val };
      } else if (k === 'competitionPartId') {
        const val: any = [];
        (query[k] || ([] as any)).forEach((value: string) => {
          const opt =
            value && (competitionParts || []).find((i: any) => i._id === value);
          if (opt) {
            val.push({ value: opt._id, label: opt.name });
          }
        });
        return { ...acc, [k]: val };
      } else if (k === 'roundId') {
        const val: any = [];
        (query[k] || ([] as any)).forEach((value: string) => {
          const opt = value && (rounds || []).find((i: any) => i._id === value);
          if (opt) {
            val.push({ value: opt._id, label: opt.name });
          }
        });
        return { ...acc, [k]: val };
      } else if (k === 'dateFrom' || k === 'dateTo') {
        return {
          ...acc,
          [k]: query[k] ? new Date(query[k] as string) : undefined,
        };
      }
      return acc;
    }, {});

    return (
      <ScrollLayout
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              {total && (
                <Paginator
                  limit={LIST_LIMIT}
                  offset={offset as number}
                  nextOffset={nextOffset}
                  onChangeOffset={async (newOffset: number) => {
                    this.props.setParameter({
                      offset: newOffset,
                    });
                  }}
                />
              )}
            </ContextBarItem>
          </ContextBar>
        }
        topFixed={
          <>
            <HeaderBar>
              <HeaderBar.Header>{__('Zoznam stretnutí')}</HeaderBar.Header>
            </HeaderBar>
            <Filter
              onChange={(values: any) => {
                const parameters: { [key: string]: any } = {
                  offset: 0,
                };
                Object.keys(values).forEach((k) => {
                  if (k === 'dateFrom' || k === 'dateTo') {
                    parameters[k] = values[k]
                      ? format(new Date(values[k]), 'yyyy-MM-dd HH:mm:ss')
                      : undefined;
                  } else if (values[k] && values[k].length) {
                    parameters[k] = values[k].map((i: any) => i.value);
                  } else {
                    parameters[k] = [];
                  }
                });

                if (
                  (parameters.seasonId || []).length !==
                  ((query.seasonId || []) as any).length
                ) {
                  parameters.competitionId = [];
                  parameters.competitionPartId = [];
                  parameters.roundId = [];
                } else if (
                  (parameters.competitionId || []).length !==
                  ((query.competitionId || []) as any).length
                ) {
                  parameters.competitionPartId = [];
                  parameters.roundId = [];
                } else if (
                  (parameters.competitionPartId || []).length !==
                  ((query.competitionPartId || []) as any).length
                ) {
                  parameters.roundId = [];
                }

                setParameter(parameters);
              }}
              value={transformedQuery}
              filters={[
                {
                  type: 'select',
                  name: 'closed',
                  label: 'Stav',
                  multiple: true,
                  options: closedOptions,
                },
                {
                  type: 'select',
                  name: 'seasonId',
                  label: 'Sezóna',
                  multiple: true,
                  options: seasons
                    .filter((s: Season) => s.appSpace === appspace)
                    .map((season: any) => {
                      return {
                        value: season._id,
                        label: season.name,
                      };
                    }),
                },
                {
                  type: 'select',
                  name: 'competitionId',
                  label: 'Súťaž',
                  disabled: !seasonId || (seasonId || []).length === 0,
                  multiple: true,
                  options: (competitions || []).map((competition: any) => {
                    return {
                      value: competition._id,
                      label: competition.name,
                    };
                  }),
                },
                {
                  type: 'select',
                  name: 'competitionPartId',
                  label: 'Časť súťaže',
                  multiple: true,
                  disabled:
                    !competitionId || (competitionId || []).length === 0,
                  options: (competitionParts || []).map((part: any) => {
                    return {
                      value: part._id,
                      label: part.name,
                    };
                  }),
                },
                {
                  type: 'select',
                  name: 'roundId',
                  label: 'Kolo',
                  disabled:
                    !competitionPartId ||
                    (competitionPartId || []).length === 0,
                  multiple: true,
                  options: rounds
                    .sort(
                      (a: Round, b: Round) =>
                        getProp(a, ['name'], '') > getProp(b, ['name'], ''),
                    )
                    .map((round: any) => {
                      return {
                        value: round._id,
                        label: round.name,
                      };
                    }),
                },
                {
                  type: 'date',
                  name: 'dateFrom',
                  label: 'Dátum od',
                  showYearDropdown: true,
                },
                {
                  type: 'date',
                  name: 'dateTo',
                  label: 'Dátum do',
                  showYearDropdown: true,
                },
              ]}
            />
          </>
        }
      >
        {!isCommiting && Array.from(matches ? matches : []).length === 0 ? (
          <NotFound
            icon="search"
            title={__('Pre dané parametre sa nenašli žiadne stretnutia')}
          />
        ) : (
          <Segment loading={!!isCommiting}>
            <Segment raised>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Stav')}</Th>
                    {anyHasScore && <Th>{__('Skóre')}</Th>}
                    <Th>{__('Súťaž')}</Th>
                    <Th>{__('ID Súťaže')}</Th>
                    <Th>{__('Sezóna')}</Th>
                    <Th>{__('Kolo')}</Th>
                    <Th>{__('Termín konania')}</Th>
                    <Th>{__('Miesto konania')}</Th>
                    <Th>{__('Účastníci')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.from(matches ? matches : []).map((match: Match) => {
                    return (
                      <Tr
                        key={match._id}
                        onClick={() => {
                          this.props.history.push(
                            `/admin/${appspace}/competitions/${match.competition._id}/parts/${match.competitionPart._id}/matches/${match._id}`,
                          );
                        }}
                      >
                        <Td>{this.getMatchStatus(match)}</Td>
                        {anyHasScore && (
                          <Td>
                            <Score isComputed={!!match.scoreIsComputed}>
                              <div>
                                {match.score &&
                                  `${match.score[0]}:${match.score[1]}`}
                                {match.penaltiesScore &&
                                  (match.penaltiesScore || []).length > 0 &&
                                  ` (${match.penaltiesScore[0]}:${match.penaltiesScore[1]})`}
                              </div>
                              {(match.scoreByPhases || []).length > 0 && (
                                <div
                                  style={{ color: '#333', fontSize: rem(11) }}
                                >
                                  (
                                  {(match.scoreByPhases || [])
                                    .map((i) => (i ? i.join(':') : ''))
                                    .join(', ')}
                                  )
                                </div>
                              )}
                            </Score>
                          </Td>
                        )}
                        <Td>
                          {match.competition.name}
                          <br />
                          <i>{match.competitionPart.name}</i>
                        </Td>
                        <Td>{match.competition.identifier}</Td>
                        <Td>{match.season.name}</Td>
                        <Td>{match.round && match.round.name}</Td>
                        <Td>
                          {format(
                            new Date(match.startDate),
                            'dd.MM.yyyy HH:mm',
                          )}
                        </Td>
                        <Td>{!!match.sportGround && match.sportGround.name}</Td>
                        <Td>
                          {(match.teams || []).map(
                            (team: { _id: string; name: string }) => {
                              return (
                                <div key={`${match._id}_${team._id}`}>
                                  {team.name}
                                </div>
                              );
                            },
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Segment>
          </Segment>
        )}
      </ScrollLayout>
    );
  }
}

const connected = connect(mapStateToProps)(MatchesList);

export default connectToQueryHoc<any>({
  parameters: {
    seasonId: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    competitionId: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    competitionPartId: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    roundId: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    closed: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
  },
})(connected);
