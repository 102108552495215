import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { CustomThunkDispatch } from '../../../configureStore';
import {
  formatSecondsToString,
  formatStringToSeconds,
  isFloatFromObject,
  isNumberFromObject,
  __,
} from '../../../utilities';
import { Match } from '../../Matches/definitions';
import { FORM_NAME } from '../../Matches/form';
import { Athlete, Team } from '../../Teams/definitions';

interface IPoint {
  name: string;
  value: string | number | null;
}

interface OwnProps {
  teams: Team[];
  nominations: Array<{
    athletes: Athlete[];
    teamId: string;
  }>;
  closed: boolean;
  data?: Match | null;
}

const CyclingProtocol: React.FC<
  OwnProps & {
    dispatch: CustomThunkDispatch;
  }
> = ({ nominations, teams, closed, dispatch }) => {
  return (
    <Segment>
      {nominations.map((nomination, idx) => {
        const team = teams.find((t) => t._id === nomination.teamId);
        if (team) {
          dispatch(
            change(FORM_NAME, `protocol.results.${idx}.teamId`, team._id),
          );
          dispatch(
            change(
              FORM_NAME,
              `protocol.results.${idx}.players`,
              nomination.athletes.map((a) => a.sportnetUser),
            ),
          );
          return (
            <Segment
              key={`${nomination.teamId}_${nomination.athletes
                .map((a) => a.sportnetUser.name)
                .join(',')}`}
              header={
                <SegmentHeader
                  withSeparator
                  subtitle={team.displayName}
                  size="xs"
                >
                  {nomination.athletes
                    .map((a) => a.sportnetUser.name)
                    .join(', ')}
                </SegmentHeader>
              }
              raised
            >
              <Field
                parse={(i: string) => {
                  const output: IPoint = {
                    name: 'individual',
                    value: '',
                  };
                  if (i.slice(-1) === '.' || i.slice(-2) === '.0') {
                    output.value = i;
                  }
                  output.value =
                    i && !Number.isNaN(Number(i)) ? Number(i) : null;
                  return output;
                }}
                disabled={closed}
                name={`protocol.results.${idx}.points.0`}
                label={__('Poradie')}
                component={FormField}
                format={(i: { value: number }) => {
                  return i ? i.value : null;
                }}
                validate={[isFloatFromObject]}
              />
              <Field
                format={(i: { value: number }) => {
                  if (i && typeof i.value === 'number') {
                    return formatSecondsToString(i.value);
                  }
                  return i ? i.value : null;
                }}
                disabled={closed}
                validate={[isNumberFromObject]}
                normalize={(i: string) => {
                  const output: IPoint = {
                    name: 'time',
                    value: '',
                  };
                  const split = i.split(':');
                  const secondsSplit = split[split.length - 1].split('.');
                  if (
                    (split.length === 2 &&
                      split[0].length >= 2 &&
                      ((Number(split[1]) <= 59 && split[1].length === 2) ||
                        (secondsSplit &&
                          secondsSplit[1] &&
                          secondsSplit[1].length >= 2))) ||
                    (split.length === 3 &&
                      split[0].length >= 2 &&
                      split[1].length >= 2 &&
                      ((Number(split[2]) <= 59 && split[2].length === 2) ||
                        (secondsSplit &&
                          secondsSplit[1] &&
                          secondsSplit[1].length >= 2)))
                  ) {
                    output.value = formatStringToSeconds(i);
                  } else {
                    output.value = i;
                  }
                  return output;
                }}
                name={`protocol.results.${idx}.points.1`}
                label={__('Čas')}
                component={FormField}
              />
              <Field
                disabled={closed}
                format={(i: { value: number }) => {
                  if (i && typeof i.value === 'number') {
                    return formatSecondsToString(i.value);
                  }
                  return i ? i.value : null;
                }}
                validate={[isNumberFromObject]}
                normalize={(i: string) => {
                  const output: IPoint = {
                    name: 'young_rider_time',
                    value: '',
                  };
                  const split = i.split(':');
                  const secondsSplit = split[split.length - 1].split('.');
                  if (
                    (split.length === 2 &&
                      split[0].length >= 2 &&
                      ((Number(split[1]) <= 59 && split[1].length === 2) ||
                        (secondsSplit &&
                          secondsSplit[1] &&
                          secondsSplit[1].length >= 2))) ||
                    (split.length === 3 &&
                      split[0].length >= 2 &&
                      split[1].length >= 2 &&
                      ((Number(split[2]) <= 59 && split[2].length === 2) ||
                        (secondsSplit &&
                          secondsSplit[1] &&
                          secondsSplit[1].length >= 2)))
                  ) {
                    output.value = formatStringToSeconds(i);
                  } else {
                    output.value = i;
                  }
                  return output;
                }}
                name={`protocol.results.${idx}.points.2`}
                label={__('Čas (do 25 rokov)')}
                component={FormField}
              />
              <Field
                parse={(i: string) => {
                  const output: IPoint = {
                    name: 'mountains_points',
                    value: '',
                  };
                  if (i.slice(-1) === '.' || i.slice(-2) === '.0') {
                    output.value = i;
                  }
                  output.value =
                    i && !Number.isNaN(Number(i)) ? Number(i) : null;
                  return output;
                }}
                disabled={closed}
                name={`protocol.results.${idx}.points.3`}
                label={__('Vrchárske body')}
                component={FormField}
                format={(i: { value: number }) => {
                  return i ? i.value : null;
                }}
                validate={[isFloatFromObject]}
              />
              <Field
                parse={(i: string) => {
                  const output: IPoint = {
                    name: 'points',
                    value: '',
                  };
                  if (i.slice(-1) === '.' || i.slice(-2) === '.0') {
                    output.value = i;
                  }
                  output.value =
                    i && !Number.isNaN(Number(i)) ? Number(i) : null;
                  return output;
                }}
                disabled={closed}
                name={`protocol.results.${idx}.points.4`}
                label={__('Body')}
                component={FormField}
                format={(i: { value: number }) => {
                  return i ? i.value : null;
                }}
                validate={[isFloatFromObject]}
              />
              <Field
                parse={(i: string) => {
                  const output: IPoint = {
                    name: 'individual_young',
                    value: '',
                  };
                  if (i.slice(-1) === '.' || i.slice(-2) === '.0') {
                    output.value = i;
                  }
                  output.value =
                    i && !Number.isNaN(Number(i)) ? Number(i) : null;
                  return output;
                }}
                disabled={closed}
                name={`protocol.results.${idx}.points.5`}
                label={__('Poradie - Čas do 25 rokov')}
                component={FormField}
                format={(i: { value: number }) => {
                  return i ? i.value : null;
                }}
                validate={[isFloatFromObject]}
              />
              <Field
                parse={(i: string) => {
                  const output: IPoint = {
                    name: 'individual_points',
                    value: '',
                  };
                  if (i.slice(-1) === '.' || i.slice(-2) === '.0') {
                    output.value = i;
                  }
                  output.value =
                    i && !Number.isNaN(Number(i)) ? Number(i) : null;
                  return output;
                }}
                disabled={closed}
                name={`protocol.results.${idx}.points.ž`}
                label={__('Poradie - Body')}
                component={FormField}
                format={(i: { value: number }) => {
                  return i ? i.value : null;
                }}
                validate={[isFloatFromObject]}
              />
              <Field
                parse={(i: string) => {
                  const output: IPoint = {
                    name: 'individual_mountains_points',
                    value: '',
                  };
                  if (i.slice(-1) === '.' || i.slice(-2) === '.0') {
                    output.value = i;
                  }
                  output.value =
                    i && !Number.isNaN(Number(i)) ? Number(i) : null;
                  return output;
                }}
                disabled={closed}
                name={`protocol.results.${idx}.points.6`}
                label={__('Poradie - Vrchárske body')}
                component={FormField}
                format={(i: { value: number }) => {
                  return i ? i.value : null;
                }}
                validate={[isFloatFromObject]}
              />
            </Segment>
          );
        }
        return null;
      })}
    </Segment>
  );
};

export default connect()(CyclingProtocol);
