import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Api from '../../../api';
import { Members, MembersDetail } from './definitions';
import { TeamState } from '../reducer';

const create = actionCreatorFactory('TEAMS');
const createAsync = asyncFactory<TeamState>(create);

interface NormalizedMembersSchema {
  entities: {
    membersByTeamId: {
      [key: string]: {
        [key: string]: Members;
      };
    };
  };
}

const normalizeMembers = (teamId: string, data: Members[]) => {
  return {
    entities: {
      membersByTeamId: {
        [teamId]: data.reduce((acc, item: Members) => {
          return { ...acc, [item._id]: item };
        }, {}),
      },
    },
  };
};

export const getMembersList = createAsync<
  {
    appspace: string;
    teamId: string;
  },
  NormalizedMembersSchema
>('GET_TEAM_MEMBERS', async (parameters) => {
  const { teamId, appspace } = parameters;
  const response = (await Api.adminGetTeamMembersList(
    appspace,
    teamId,
  )) as unknown as {
    items: Members[];
  };
  return normalizeMembers(teamId, response.items || []);
});

export const getMembersById = createAsync<
  {
    appspace: string;
    teamId: string;
    members: Members[];
    membersId: string;
  },
  NormalizedMembersSchema
>('GET_TEAM_MEMBERS_BY_ID', async (parameters) => {
  const { teamId, membersId, appspace, members } = parameters;
  const response = (await Api.adminGetTeamMembers(
    appspace,
    teamId,
    membersId,
  )) as MembersDetail;
  members[members.findIndex((s) => s._id === response._id)] = response;
  return normalizeMembers(teamId, members);
});

export const postMembers = createAsync<
  { appspace: string; teamId: string; members: Omit<MembersDetail, '_id'> },
  MembersDetail
>('POST_MEMBERS', async (parameters) => {
  const response = await Api.adminCreateTeamMembers(
    parameters.appspace,
    parameters.teamId,
    {},
    parameters.members as any,
  );
  return response as any;
});

export const deleteMembers = createAsync<
  { appspace: string; teamId: string; membersId: string },
  MembersDetail
>('DELETE_MEMBERS', async (parameters) => {
  const response = await Api.adminDeleteTeamMembers(
    parameters.appspace,
    parameters.teamId,
    parameters.membersId,
  );
  return response as any;
});

export const putMembers = createAsync<
  {
    appspace: string;
    teamId: string;
    membersId: string;
    members: Omit<MembersDetail, '_id'>;
  },
  MembersDetail
>('PUT_MEMBERS', async (parameters: any) => {
  const data = { ...parameters.members };
  const response = await Api.adminUpdateTeamMembers(
    parameters.appspace,
    parameters.teamId,
    parameters.membersId,
    {},
    data,
  );
  return response as any;
});
