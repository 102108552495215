import { rem } from 'polished';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import Api from 'src/api';
import { getProp, __ } from 'src/utilities';
import styled, { createGlobalStyle } from 'styled-components';
import { Match } from '../Matches/definitions';
import { ISportSectorEvent, ISportSectorPhase } from '../Protocols/timeline';
import MatchPreview from './MatchOverview';
import TimeLine from './TimeLine';

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  // background-color: #FAFAFA;
  // font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
  }
}
`;

const ContentItem = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  > div:nth-child(1) {
    width: 25%;
  }
  > div:nth-child(2) {
    width: 50%;
  }
  > div:nth-child(3) {
    width: 25%;
  }
`;

const Nomination = styled.div`
  padding-bottom: ${rem(16)};
  display: flex;
  flex-direction: column;
  > div {
    font-size: ${rem(11)};
    display: flex;
  }
`;

const Managers = styled.div`
  padding-bottom: ${rem(16)};
  display: flex;
  flex-direction: column;
  > div {
    font-size: ${rem(11)};
    display: flex;
    padding-bottom: ${rem(4)};
    flex-direction: column;
    > div {
      font-style: italic;
    }
  }
`;

const Jersey = styled.div`
  width: ${rem(24)};
  font-weight: bold;
`;

type Props = RouteComponentProps<{ appspace: string; id: string }>;

const MatchPrint: React.FC<Props> = ({
  match: {
    params: { appspace, id },
  },
}) => {
  const [match, setMatch] = React.useState<Match | null>(null);
  const [phases, setPhases] = React.useState<ISportSectorPhase[]>([]);
  const [events, setEvents] = React.useState<ISportSectorEvent[]>([]);
  const [isFetchingMatch, setIsFetchingMatch] = React.useState(false);

  const fetchMatch = React.useCallback(async () => {
    try {
      setIsFetchingMatch(true);
      const res = await Api.adminGetMatchById(appspace, id);
      const { items: sportSectorPhases } = await Api.getSettingBySportSector(
        'sport_sector_phases',
        res.rules?.sport_sector || '',
      );
      const { items: sportSectorEvents } = await Api.getSettingBySportSector(
        'sport_sector_events',
        res.rules?.sport_sector || '',
      );
      setMatch(res as any);
      setPhases(sportSectorPhases as any);
      setEvents(sportSectorEvents as any);
    } catch (e) {
      alert(__('Nepodarilo sa získať detail zápisu o stretnutí'));
    } finally {
      setIsFetchingMatch(false);
    }
  }, [appspace, id]);

  React.useEffect(() => {
    fetchMatch();
  }, [fetchMatch]);

  if (isFetchingMatch || !match) {
    return null;
  }

  const homeTeam =
    (match.teams || []).find(
      (i) => i.additionalProperties?.homeaway === 'home',
    ) || (match.teams ? match.teams[0] : undefined);
  const awayTeam =
    (match.teams || []).find(
      (i) => i.additionalProperties?.homeaway === 'away',
    ) || (match.teams ? match.teams[1] : undefined);

  const homeTeamNomination = (match.nominations || []).find(
    (i) => i.teamId === homeTeam?._id,
  );
  const awayTeamNomination = (match.nominations || []).find(
    (i) => i.teamId === awayTeam?._id,
  );

  const renderNomination = (
    athletes:
      | {
          sportnetUser: {
            _id: string;
            name: string;
          };
          additionalData?:
            | {
                [key: string]: string | number | boolean;
              }
            | undefined;
        }[]
      | undefined,
    substitute?: boolean,
  ) => {
    return (athletes || [])
      .filter(
        (i) =>
          typeof substitute === 'undefined' ||
          i.additionalData?.substitute === substitute,
      )
      .sort((a, b) => {
        if (
          a.additionalData?.nr &&
          b.additionalData?.nr &&
          Number(a.additionalData?.nr) > Number(b.additionalData?.nr)
        ) {
          return 1;
        } else if (
          a.additionalData?.nr &&
          b.additionalData?.nr &&
          Number(a.additionalData?.nr) < Number(b.additionalData?.nr)
        ) {
          return -1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (
          a.additionalData?.position !== 'goalkeeper' &&
          b.additionalData?.position === 'goalkeeper'
        ) {
          return 1;
        } else if (
          a.additionalData?.position === 'goalkeeper' &&
          b.additionalData?.position !== 'goalkeeper'
        ) {
          return -1;
        }
        return 0;
      })
      .map((i) => {
        return (
          <div>
            {i.additionalData?.nr ? (
              <Jersey>{i.additionalData?.nr}</Jersey>
            ) : (
              <></>
            )}{' '}
            <div>{i.sportnetUser.name}</div>
          </div>
        );
      });
  };

  const homeTeamCoach = (homeTeamNomination?.crew || []).find(
    (i) => i.position === 'coach',
  );
  const awayTeamCoach = (awayTeamNomination?.crew || []).find(
    (i) => i.position === 'coach',
  );

  return (
    <>
      <GlobalStyle />
      <div className="page">
        <MatchPreview match={match} sportSectorPhases={[]} />
        <ContentWrapper>
          <ContentItem>
            <Nomination>
              {renderNomination(homeTeamNomination?.athletes, false)}
            </Nomination>
            <Nomination>
              {renderNomination(homeTeamNomination?.athletes, true)}
            </Nomination>
            {homeTeamCoach && (
              <Nomination>
                <div>
                  <div>{__('Tréner')}:</div>&nbsp;
                  <div>{homeTeamCoach.sportnetUser.name}</div>
                </div>
              </Nomination>
            )}
            {(match.managers || []).length > 0 && (
              <Managers>
                {match.managers?.map((i) => (
                  <div>
                    <div>{i.type.label}:</div>
                    <div>{i.user.name}</div>
                  </div>
                ))}
              </Managers>
            )}
          </ContentItem>
          <ContentItem>
            <TimeLine
              sport={getProp(match, ['rules', 'sport_sector'], '')}
              teams={match.teams || []}
              protocol={match.protocol}
              timer={match.timer}
              settings={match.settings}
              eventTypes={events}
              phases={phases}
            />
          </ContentItem>
          <ContentItem>
            <Nomination>
              {renderNomination(awayTeamNomination?.athletes, false)}
            </Nomination>
            <Nomination>
              {renderNomination(awayTeamNomination?.athletes, true)}
            </Nomination>
            {awayTeamCoach && (
              <Nomination>
                <div>
                  <div>{__('Tréner')}:</div>&nbsp;
                  <div>{awayTeamCoach.sportnetUser.name}</div>
                </div>
              </Nomination>
            )}
          </ContentItem>
        </ContentWrapper>
      </div>
    </>
  );
};

export default MatchPrint;
