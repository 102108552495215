import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required, __ } from '../../utilities';

type IOwnProps = {
  competitions: Array<{ _id: string; name: string }>;
  countries: Array<{ label: string; value: string }>;
};

const C: React.FC<IOwnProps> = ({ competitions, countries, ...props }) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Field
            label={__('Aktuálne meno v tíme')}
            name="displayName"
            disabled
            component={FormField}
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Titul pred menom')}
            name="titles.before"
            component={FormField}
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Titul za menom')}
            name="titles.after"
            component={FormField}
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            required
            validate={[required]}
            label={__('Meno')}
            name="name"
            component={FormField}
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            label={__('Priezvisko')}
            name="surname"
            component={FormField}
            required
            validate={[required]}
          />
        </Col>
        <Col xs={12}>
          <Field
            name="citizenship"
            component={FormField}
            label={__('Štátna príslušnosť')}
            type="theselectsimple"
            options={countries}
          />
        </Col>
      </Row>
      <Row>
        {competitions.map((i) => (
          <Col xs={12} s={6} m={3}>
            <Field
              label={i.name}
              name={`competitions.${i._id}`}
              type="checkbox"
              component={FormField}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default compose<IOwnProps>(reduxForm({ enableReinitialize: true }))(
  C,
) as any;
