import { IWidgetCompetitionDraw } from '@sportnet/content/utilities/library';
import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import SegmentMatches, { SEGMENT_HEIGHT } from './SegmentMatches';
import { Link } from 'react-router-dom';
import { __ } from 'src/utilities';

const SEGMENT_WIDTH = 180;
const BASE_SPACE = 9;

const BASE_MARGIN = (BASE_SPACE / 2 + SEGMENT_HEIGHT / 2) * 2;

const getMargin = (idx: number, difference: number = 0) => {
  let totalMargin = BASE_SPACE;
  let modifier = BASE_MARGIN;

  for (let i = 0; i <= idx; i++) {
    if (i > 0) {
      totalMargin = totalMargin + modifier;
      modifier = modifier * 2;
    }
  }
  if (typeof difference !== 'undefined') {
    return totalMargin / 2 - difference;
  }
  return totalMargin / 2;
};

const DrawID = styled.div`
  position: absolute;
  width: 100%;
  text-align: right;
  padding: ${rem(4)};
  display: flex;
  justify-content: space-between;
  font-size: ${rem(12)};
  color: #aaa;
  a {
    color: #aaa;
    text-decoration: underline;
  }
`;

const Rounds = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow-x: auto;
  width: 100%;
  padding: ${rem(4)} 0;
`;

export const RoundName = styled.div<{ match?: boolean }>`
  margin-bottom: ${rem(16)};
  color: #333;
  opacity: 0.56;
  font-size: ${rem(12)};
  text-transform: uppercase;
  ${({ match }) =>
    !!match &&
    css`
      margin-top: ${rem(16)};
    `}
`;

const RoundSegment = styled.div<{ margin: number; roundIdx: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ roundIdx }) => `${getMargin(roundIdx)}px 0`};
  height: ${rem(SEGMENT_HEIGHT)};
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const RoundConnector = styled.div<{
  margin: number;
  idx: number;
  roundIdx: number;
}>`
  height: ${({ roundIdx }) =>
    `${getMargin(roundIdx) * 2 + 2 * SEGMENT_HEIGHT - SEGMENT_HEIGHT}px`};
  position: absolute;
  left: ${`calc(100% + ${rem(16)})`};
  width: 1px;
  top: ${({ idx, roundIdx }) =>
    rem(
      SEGMENT_HEIGHT / 2 +
      (getMargin(roundIdx) * 2 + 2 * SEGMENT_HEIGHT - SEGMENT_HEIGHT) * idx,
    )};
  background: ${({ theme }) => theme.separatorColor};
`;

const RoundWrapper = styled.div`
  margin-right: ${rem(32)};
  min-width: ${rem(SEGMENT_WIDTH)};
  &:not(:first-child) ${RoundSegment} {
    &:before {
      content: '';
      width: ${rem(16)};
      height: ${rem(1)};
      position: absolute;
      top: 50%;
      right: 100%;
      background: ${({ theme }) => theme.separatorColor};
    }
  }
  &:not(:last-child) ${RoundSegment} {
    &:after {
      content: '';
      width: ${rem(16)};
      height: ${rem(1)};
      position: absolute;
      top: 50%;
      left: 100%;
      background: ${({ theme }) => theme.separatorColor};
    }
  }
  &:last-child {
    ${RoundConnector} {
      display: none;
    }
  }
`;

const Round = styled.div<{ roundIdx: number; withMatchRoundNames: boolean }>`
  margin-top: ${({ roundIdx, withMatchRoundNames }) =>
    `${getMargin(roundIdx, withMatchRoundNames ? 38 : 0) + BASE_SPACE}px`};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FINALE_ALLOWED_SEGMENTS_BY_BRANCHES_LENGTH = {
  1: [],
  2: [],
  3: [2, 3, 4, 5],
  4: [2, 3, 4, 5, 8, 9, 10, 11],
  5: [2, 3, 4, 5, 8, 9, 10, 11, 16, 17, 18, 19, 20, 21, 22, 23],
  6: [2, 3, 4, 5, 8, 9, 10, 11, 16, 17, 18, 19, 20, 21, 22, 23, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
}

type IData = Omit<IWidgetCompetitionDraw, 'type'>;
interface IOwnProps {
  data: IData;
  appSpace: string;
  competitionId: string;
  partId: string;
}

const WidgetCompetitionDraw: React.FC<IOwnProps> = ({ data, competitionId, partId, appSpace }) => {
  const getBranches = (rounds: any[]) => {
    const o: any[] = [];
    let modifier = 0;
    let iterationIdx = 1;
    for (let i = rounds.length - 1; i >= 0; i--) {
      const segments: any[] = [];
      let segmentMargin = 0;
      let roundMargin = 0;
      for (let j = 0; j < iterationIdx; j++) {
        segmentMargin = segmentMargin + Math.pow(2, j);
        if (j > 0) {
          roundMargin = roundMargin + Math.pow(2, j - 1);
        }
      }
      for (let k = Math.pow(2, i) - 1; k >= 0; k--) {
        segments.push({ drawId: k + modifier });
      }
      modifier = modifier + Math.pow(2, i);
      iterationIdx = iterationIdx + 1;
      const round = rounds[rounds.length - 1 - i];
      o.push({
        _id: round._id,
        name: round.name,
        segments,
        segmentMargin,
        roundMargin,
      });
    }
    return o;
  };

  if (data.data) {
    const { competitionPart, matches } = data.data;

    const branches = getBranches(competitionPart.rounds || []);
    let totalSegments = 0;
    for (let a = 0; a < (competitionPart.rounds || []).length; a++) {
      totalSegments = totalSegments + Math.pow(2, a);
    }

    const hasFinaleBranch = branches.find(i => i.name === 'Koniec');

    return branches.length > 0 ? (
      <Rounds>
        {branches.map((branch, roundIdx) => {
          const branchMatches = matches.filter(
            (m) => m.round && m.round._id === branch._id && m.drawId,
          );

          const anyMatchHasDifferentRoundName = branchMatches.find(
            (match) => match.round && match.round.name !== branch.name,
          );
          if (branch.name === 'Koniec') {
            return null;
          }
          if (!branchMatches.length) {
            return null;
          }

          return (
            <RoundWrapper key={`round_${roundIdx}`}>
              {!anyMatchHasDifferentRoundName && (
                <RoundName>{branch.name}</RoundName>
              )}
              <Round
                roundIdx={roundIdx}
                withMatchRoundNames={!!anyMatchHasDifferentRoundName}
              >
                {branch.segments.map((segment: any, idx: number) => {
                  const segmentDrawId = totalSegments - segment.drawId;
                  if (!!hasFinaleBranch && !FINALE_ALLOWED_SEGMENTS_BY_BRANCHES_LENGTH[branches.length].includes(segmentDrawId)) {
                    return null;
                  }

                  const segmentMatches = matches.filter(
                    (m) => m.drawId && m.drawId === segmentDrawId,
                  ) as IWidgetMatchesListMatchItem[];
                  const homeTeam =
                    segmentMatches.length > 0 &&
                    ((segmentMatches[0].teams || []).find(
                      (t) =>
                        t.additionalProperties &&
                        t.additionalProperties.homeaway &&
                        t.additionalProperties.homeaway === 'home',
                    ) ||
                      (segmentMatches[0].teams || [])[0]);
                  const awayTeam =
                    segmentMatches.length > 0 &&
                    ((segmentMatches[0].teams || []).find(
                      (t) =>
                        t.additionalProperties &&
                        t.additionalProperties.homeaway &&
                        t.additionalProperties.homeaway === 'away',
                    ) ||
                      (segmentMatches[0].teams || [])[1]);
                  return (
                    <React.Fragment key={`segment_${idx}`}>
                      {hasFinaleBranch &&
                        segmentMatches.length > 0 &&
                        anyMatchHasDifferentRoundName && (
                          <RoundName match>
                            {segmentMatches[0].round?.name}
                          </RoundName>
                        )}
                      <RoundSegment
                        {...(branch.name === 'Finále'
                          ? {
                            roundIdx: 0,
                            margin: branch.segmentMargin,
                          }
                          : {
                            roundIdx,
                            margin: branch.segmentMargin,
                          })}
                      >
                        <DrawID>
                          <Link
                            to={`/admin/${appSpace}/competitions/${competitionId}/parts/${partId}/matches/new?drawId=${segmentDrawId}&roundId=${branch._id}`}
                          >
                            {__('Pridať stretnutie')}
                          </Link>
                          <div>{segmentDrawId}</div>
                        </DrawID>
                        {segmentMatches.length > 0 && (
                          <SegmentMatches
                            segmentMatches={segmentMatches}
                            homeTeam={homeTeam || undefined}
                            awayTeam={awayTeam || undefined}
                          />
                        )}
                      </RoundSegment>
                      {idx % 2 === 0 && roundIdx !== branches.length - 1 && (
                        <RoundConnector
                          margin={branch.segmentMargin}
                          idx={idx}
                          roundIdx={roundIdx}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </Round>
            </RoundWrapper>
          );
        })}
      </Rounds>
    ) : null;
  }
  return null;
};

export default WidgetCompetitionDraw;
