import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { State } from '../../../rootReducer';
import { getProp } from '../../../utilities';
import { LIST_NAME } from './definitions';

const seasonsDomainSelector = (state: State) => {
  return state.entities.seasons;
};

export const seasonsSelector = createSelector(
  seasonsDomainSelector,
  (substate) =>
    substate ? Object.keys(substate).map((key) => substate[key]) : [],
);

export const seasonsCurrentListSelector = createSelector(
  getListResults(LIST_NAME),
  seasonsDomainSelector,
  (results, seasons) => {
    if (results) {
      const res = results.map((id: string) => {
        return seasons[id];
      });
      return res;
    }
    return [];
  },
);

export const seasonByIdSelector = (id: string) =>
  createSelector(seasonsDomainSelector, (seasons) => {
    if (seasons && seasons[id]) {
      return seasons[id];
    }
    return null;
  });

const seasonDetailSelector = (state: State) => {
  return state.detail.seasons;
};

export const seasonByIdIsFetchingSelector = (id: string) =>
  createSelector(seasonDetailSelector, (substate) =>
    getProp(substate, [id, 'isFetching'], false),
  );
