import { endOfDay, format, startOfDay } from 'date-fns';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import api from '../../api';
import { getProp } from './../../utilities';
import { Match } from './definitions';
import { MatchState } from './reducer';

const create = actionCreatorFactory('MATCHES');
const createAsync = asyncFactory<MatchState>(create);

interface NormalizedSchema {
  entities: {
    matches: {
      [key: string]: Match;
    };
  };
  total?: number;
  nextOffset?: number | null;
}

const normalize = (data: Match[]) => {
  return {
    entities: {
      matches: data.reduce((acc, item: Match) => {
        return { ...acc, [item._id]: item };
      }, {}),
    },
  };
};

export const getMatches = createAsync<
  {
    appspace: string;
    limit: number;
    offset: number;
    competitionIds?: any;
    partIds?: any;
    seasonIds?: any;
    roundIds?: any;
    closed?: string;
    dateFrom?: string;
    dateTo?: string;
  },
  NormalizedSchema
>('GET_MATCHES', async (parameters) => {
  const {
    limit,
    offset,
    appspace,
    competitionIds,
    partIds,
    seasonIds,
    roundIds,
    closed,
    dateFrom,
    dateTo,
  } = parameters;
  const params: {
    limit: number;
    offset?: number;
    competitionIds?: string[];
    partIds?: string[];
    seasonIds?: string[];
    roundIds?: string[];
    closed?: string;
    dateFrom?: string;
    dateTo?: string;
  } = {
    limit,
    offset: offset || 0,
  };

  if (competitionIds) {
    params.competitionIds = competitionIds;
  }
  if (partIds) {
    params.partIds = partIds;
  }
  if (seasonIds) {
    params.seasonIds = seasonIds;
  }
  if (roundIds) {
    params.roundIds = roundIds;
  }
  if (closed) {
    params.closed = closed;
  }
  if (dateFrom) {
    params.dateFrom = format(
      startOfDay(new Date(dateFrom)),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    );
  }
  if (dateTo) {
    params.dateTo = format(
      endOfDay(new Date(dateTo)),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    );
  }

  const response = await api.adminGetMatches(appspace, params);
  return {
    ...normalize(response.matches),
    total: response.total,
    nextOffset: response.nextOffset,
  };
});

export const getMatchById = createAsync<
  {
    appspace: string;
    matchId: string;
  },
  NormalizedSchema
>('GET_MATCH', async (parameters) => {
  const { appspace, matchId } = parameters;
  const res = await api.adminGetMatchById(appspace, matchId);
  return normalize([res as any]);
});

export const getMatchManagerMatchById = createAsync<
  {
    competitionId: string;
    partId: string;
    matchId: string;
  },
  NormalizedSchema
>('GET_MATCH_MANAGER_MATCH', async (parameters) => {
  const { competitionId, partId, matchId } = parameters;
  const res = await api.matchManagerGetMatchById(
    competitionId,
    partId,
    matchId,
  );
  return normalize([res as any]);
});

export const postMatch = createAsync<
  {
    appspace: string;
    match: any;
  },
  Match
>('POST_MATCH', async (parameters) => {
  const { appspace, match } = parameters;
  const res = await api.adminCreateMatch(appspace, {}, match);
  return res as any;
});

export const updateMatch = createAsync<
  {
    appspace: string;
    matchId: string;
    match: any;
    cb?: any;
  },
  NormalizedSchema
>('UPDATE_MATCH', async (parameters, dispatch) => {
  const { appspace, matchId, match, cb } = parameters;

  // odfiltrovanie prazdnych hodnot timera
  if (match.timer) {
    Object.keys(match.timer).forEach((phase) => {
      if (!getProp(match.timer, [phase, 'start', 'date'])) {
        delete match.timer[phase].start;
      }
      if (!getProp(match.timer, [phase, 'end', 'date'])) {
        delete match.timer[phase].end;
      }
      if (!Object.keys(match.timer[phase]).length) {
        delete match.timer[phase];
      }
    });
  }

  await api.adminUpdateMatch(appspace, matchId, {}, match);
  if (cb) {
    cb();
  }
  const res = await dispatch(getMatchById.action({ appspace, matchId }));
  return res;
});

export const updateMatchManagerMatch = createAsync<
  {
    competitionId: string;
    partId: string;
    matchId: string;
    match: any;
  },
  NormalizedSchema
>('UPDATE_MATCH_MANAGER_MATCH', async (parameters, dispatch) => {
  const { competitionId, partId, matchId, match } = parameters;
  await api.matchManagerUpdateMatch(competitionId, partId, matchId, {}, match);
  const res = await dispatch(
    getMatchManagerMatchById.action({ competitionId, partId, matchId }),
  );
  return res;
});
