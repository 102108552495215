import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../rootReducer';
import { required, __ } from '../../utilities';
import { AnyAction } from 'typescript-fsa';
import { CompetitionPart } from './definitions';

export const COMPETITION_PART_INITIAL_STATUS_FORM_NAME =
  'COMPETITION_PART_INITIAL_STATUS_FORM';

interface OwnProps {
  parts: CompetitionPart[];
  appSpace: string;
  competitionId: string;
  partId?: string;
}

const selector = formValueSelector(COMPETITION_PART_INITIAL_STATUS_FORM_NAME);
const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  resultsTable: selector(state, 'resultsTable'),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = InjectedFormProps &
  OwnProps &
  IMapStateToProps & { dispatch: ThunkDispatch<any, any, AnyAction> };

class CompetitionPartInitialStatusForm extends React.PureComponent<Props> {
  state = {
    resultsTableIsFetching: false,
  };
  // onChangeCompetitionPart = async (
  //   e: React.ChangeEvent<HTMLSelectElement>,
  //   competitionPartId: string,
  // ) => {
  //   const { appSpace, competitionId, dispatch } = this.props;
  //   this.setState({
  //     resultsTableIsFetching: true,
  //   });
  //   try {
  //     const resultsTable = await api.getCompetitionPartCurrentResultTable(
  //       appSpace,
  //       competitionId,
  //       competitionPartId,
  //     );
  //     dispatch(
  //       change(
  //         COMPETITION_PART_INITIAL_STATUS_FORM_NAME,
  //         'resultsTable',
  //         resultsTable,
  //       ),
  //     );
  //   } catch (error) {
  //     //
  //   } finally {
  //     this.setState({
  //       resultsTableIsFetching: false,
  //     });
  //   }
  //   return e;
  // };

  render() {
    return (
      <Row>
        <Col xs={12}>
          <Field
            name="type"
            component={FormField}
            type="theselectsimple"
            label={__('Typ prepočtu')}
            required
            validate={[required]}
            options={[
              { label: __("Bez prepočtu"), value: 'BEZ_PREPOCTU' },
              { label: __("S prepočtom"), value: 'S_PREPOCTOM' },
              { label: __("S prepočtom vzájomných zápasov"), value: 'S_PREPOCTOM_VZAJOMNYCH_ZAPASOV' },
            ]}
          />
        </Col>
        <Col xs={12}>
          <Field
            name="sourcePartIds"
            component={FormField}
            type="theselectsimple"
            label={__('Predchádzajúca časť súťaže')}
            required
            multiple
            validate={[required]}
            options={this.props.parts.map((part) => ({
              label: part.name,
              value: part._id,
            }))}
          />
        </Col>
      </Row>
    );
  }
}

export default reduxForm<Props, any>({
  form: COMPETITION_PART_INITIAL_STATUS_FORM_NAME,
})(connect(mapStateToProps)(CompetitionPartInitialStatusForm));
