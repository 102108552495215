import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { State } from '../../rootReducer';
import { getProp } from '../../utilities';
import { LIST_NAME } from './definitions';

const sportObjectsDomainSelector = (state: State) => {
  return state.entities.sportObjects;
};

export const sportObjectsSelector = createSelector(
  getListResults(LIST_NAME),
  sportObjectsDomainSelector,
  (results, objects) => {
    if (results) {
      const res = results.map((id: string) => {
        return objects[id];
      });
      return res;
    }
    return [];
  },
);

export const sportObjectByIdSelector = (id: string) =>
  createSelector(sportObjectsDomainSelector, (objects) => {
    if (objects && objects[id]) {
      return objects[id];
    }
    return null;
  });

const sportObjectDetailSelector = (state: State) => {
  return state.detail.sportObjects;
};

export const sportObjectByIdIsFetchingSelector = (id: string) =>
  createSelector(sportObjectDetailSelector, (substate) =>
    getProp(substate, [id, 'isFetching'], false),
  );
