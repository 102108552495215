import { reducerWithInitialState } from '../../../node_modules/typescript-fsa-reducers/dist';
import * as actions from './actions';
import { Competition } from './definitions';

export type CompetitionState = {
  readonly [key: string]: Competition;
};

export const sportSectorSettingsReducer = reducerWithInitialState({})
  .case(actions.getSettingBySportSector.async.started, (state, parameters) => ({
    ...state,
    [parameters.sportSector]: {
      ...state[parameters.sportSector],
      [parameters.settingName]: {
        isFetching: true,
      },
    },
  }))
  .case(actions.getSettingBySportSector.async.done, (state, parameters) => ({
    ...state,
    [parameters.params.sportSector]: {
      ...state[parameters.params.sportSector],
      [parameters.params.settingName]: {
        isFetching: false,
        items: parameters.result,
      },
    },
  }));

export const competitionsReducer = reducerWithInitialState({})
  .case(actions.getCompetitionById.async.started, (state, parameters) => ({
    ...state,
    [parameters.id]: {
      isFetching: true,
    },
  }))
  .case(actions.getCompetitionParts.async.started, (state, parameters) => ({
    ...state,
    [parameters.competitionId]: {
      isFetching: true,
    },
  }))
  .case(actions.getCompetitionById.async.failed, (state, parameters) => ({
    ...state,
    [parameters.params.id]: {
      isFetching: false,
    },
  }))
  .case(actions.getCompetitionParts.async.failed, (state, parameters) => ({
    ...state,
    [parameters.params.competitionId]: {
      isFetching: false,
    },
  }))
  .case(actions.getCompetitionById.async.done, (state, parameters) => ({
    ...state,
    [parameters.params.id]: {
      isFetching: false,
    },
  }))
  .case(actions.getCompetitionParts.async.done, (state, parameters) => ({
    ...state,
    [parameters.params.competitionId]: {
      isFetching: false,
    },
  }));
