import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Input from '@sportnet/ui/Input/redux-form';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import {
  change,
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  isSubmitting,
  reduxForm,
  submit,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import sportnetApi from '../../sportnetApi';
import styled from 'styled-components';
import Box from '../../components/Box';
import { State } from '../../rootReducer';
import {
  formatSecondsToString,
  formatStringToSeconds,
  getProp,
  isInteger,
  isNumberFromObject,
  required,
  __,
} from '../../utilities';
import {
  activeAppspaceProfileSelector,
  profileSportSelector,
} from '../App/selectors';
import { RaceResult, Result, TeamRaceResult } from '../Matches/definitions';
import { BoxNote } from '../Matches/form';
import { getCompetitionPartById } from './actions';
import ClubInvitationForm, {
  CLUB_INVITATION_FORM_NAME,
} from './clubInvitationForm';
import CompetitionPartInitialStatusForm, {
  COMPETITION_PART_INITIAL_STATUS_FORM_NAME,
} from './competitionPartInitialStatusForm';
import BeachFootballCompetitionPartRulesForm from './CompetitionPartRulesForms/BeachFootball';
import CyclingCompetitionPartRulesForm from './CompetitionPartRulesForms/Cycling';
import FutbalCompetitionPartRulesForm from './CompetitionPartRulesForms/Futbal';
import FutsalCompetitionPartRulesForm from './CompetitionPartRulesForms/Futsal';
import MinifootballCompetitionPartRulesForm from './CompetitionPartRulesForms/Minifootball';
import SwimmingCompetitionPartRulesForm from './CompetitionPartRulesForms/Swimming';
import WaterPoloCompetitionPartRulesForm from './CompetitionPartRulesForms/WaterPolo';
import { CompetitionPart } from './definitions';
import ParticipantForm, { PARTICIPANT_FORM_NAME } from './participantForm';
import { competitionByIdSelector } from './selectors';
import FormulaOneCompetitionPartRulesForm from './CompetitionPartRulesForms/FormulaOne';

interface IPoint {
  name: string;
  value: string | number | null;
}

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(12)};
  color: #666;
  margin-left: -${rem(16)};
  padding-top: ${rem(8)};
`;
const LegendItem = styled.div`
  padding: ${rem(8)} ${rem(16)};
  display: flex;
`;
const LegendItemLabel = styled.div`
  padding-right: ${rem(8)};
  font-weight: bold;
`;

export const FORM_NAME = 'COMPETITION_PART_FORM';

const COMPETITION_PART_FORMATS = [
  { label: __('Bodovací'), value: 'points' },
  { label: __('Pavúk'), value: 'draw' },
];

interface CustomFormData {
  name: string;
  type?: string;
}

interface OwnProps {
  id: string;
  appspace: string;
  competitionId: string;
  data: CompetitionPart | null;
  suitableClubs: Array<{
    _id: string;
    name: string;
  }>;
}

// const formatStringToSeconds = (string: string) => {
//   const arr = string.split(':');
//   let seconds = Number(arr[0]) * 60 + Number(arr[1]) + 1;
//   if (Number.isNaN(seconds)) {
//     seconds = 0;
//   }
//   return seconds;
// };

// const str_pad_left = (unit: number, pad: string, length: number) => {
//   return (new Array(length + 1).join(pad) + unit).slice(-length);
// };

// const formatSecondsToString = (seconds: number) => {
//   return (
//     str_pad_left(Math.floor(seconds / 60), '0', 2) +
//     ':' +
//     str_pad_left(seconds % 60, '0', 2)
//   );
// };

const getCompetitionPartRulesInitialValues = (rules: {
  sport_sector: string;
}) => {
  switch (getProp(rules, ['sport_sector'])) {
    case 'water-polo':
      return {
        players: {
          field: {
            min: 7,
            max: 13,
          },
          substitutes: {
            max: 6,
          },
        },
        contumation: {
          score: 5,
        },
        reverseTime: true,
        overlapTime: false,
        phases: {
          '1Q': {
            startTime: 480,
            endTime: 0,
          },
          '2Q': {
            startTime: 480,
            endTime: 0,
          },
          '3Q': {
            startTime: 480,
            endTime: 0,
          },
          '4Q': {
            startTime: 480,
            endTime: 0,
          },
        },
      };
    case 'futbal':
      return {
        players: {
          field: {
            min: 7,
            max: 11,
          },
          substitutes: {
            max: 12,
          },
        },
        contumation: {
          score: 3,
        },
        reverseTime: false,
        overlapTime: true,
        phases: {
          '1HT': {
            startTime: 0,
            endTime: 2700,
          },
          '2HT': {
            startTime: 2700,
            endTime: 5400,
          },
          '1ET': {
            startTime: 5400,
            endTime: 6300,
          },
          '2ET': {
            startTime: 6300,
            endTime: 7200,
          },
        },
      };
    case 'futsal':
      return {
        players: {
          field: {
            min: 7,
            max: 11,
          },
          substitutes: {
            max: 12,
          },
        },
        contumation: {
          score: 3,
        },
        reverseTime: false,
        overlapTime: true,
        phases: {
          '1HT': {
            startTime: 0,
            endTime: 2700,
          },
          '2HT': {
            startTime: 2700,
            endTime: 5400,
          },
          '1ET': {
            startTime: 5400,
            endTime: 6300,
          },
          '2ET': {
            startTime: 6300,
            endTime: 7200,
          },
        },
      };
    case 'beachfutbal':
      return {
        players: {
          field: {
            min: 5,
            max: 5,
          },
          substitutes: {
            min: 3,
            max: 10,
          },
        },
        contumation: {
          score: 3,
        },
        reverseTime: false,
        overlapTime: true,
        phases: {
          '1Q': {
            startTime: 0,
            endTime: 720,
          },
          '2Q': {
            startTime: 720,
            endTime: 1440,
          },
          '3Q': {
            startTime: 1440,
            endTime: 2160,
          },
          '1ET': {
            startTime: 2160,
            endTime: 2340,
          },
        },
      };
    case 'minifootball':
      return {
        players: {
          field: {
            min: 4,
            max: 16,
          },
          substitutes: {
            max: 12,
          },
        },
        contumation: {
          score: 3,
        },
        reverseTime: false,
        overlapTime: true,
        phases: {
          '1HT': {
            startTime: 0,
            endTime: 1200,
          },
          '2HT': {
            startTime: 1200,
            endTime: 2400,
          },
          '1ET': {
            startTime: 2400,
            endTime: 2700,
          },
          '2ET': {
            startTime: 2700,
            endTime: 3000,
          },
        },
      };
    case 'swimming':
    case 'cestna-cyklistika':
    case 'formula-1':
      return {
        roundPoints: [],
      };
    default:
      return {};
  }
};

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  const competition = competitionByIdSelector(props.competitionId)(state);
  // const rules = getProp(competition, ['rules'], {});
  // let settings = getCompetitionPartRulesInitialValues(rules);
  // if (!Object.keys(rules).length) {
  //   settings = getCompetitionPartRulesInitialValues(
  //     selector(state, 'rules') || {},
  //   );
  // }
  const name = selector(state, 'name') || '';
  const sportSectorType = selector(state, 'type') || '';
  const signup = selector(state, 'signup') || false;
  const rules = selector(state, 'rules') || {};
  const resultsTable = selector(state, 'resultsTableObject') || {
    players: [],
    results: {},
  };
  const settings = getCompetitionPartRulesInitialValues(
    Object.keys(getProp(competition || {}, ['rules'], {})).length
      ? getProp(competition || {}, ['rules'])
      : rules,
  );

  let resultsTableObject = {};
  if (props.data && props.data.type === 'collective') {
    resultsTableObject = {
      ...getProp(props.data || {}, ['resultsTable']),
      results: getProp(
        props.data || {},
        ['resultsTable', 'results'],
        [],
      ).reduce((acc: { [key: string]: Result }, next: Result) => {
        return { ...acc, [next.team._id]: next };
      }, {}),
    };
  } else if (props.data && props.data.type === 'race') {
    resultsTableObject = {
      ...getProp(props.data || {}, ['resultsTable']),
      results: getProp(
        props.data || {},
        ['resultsTable', 'results'],
        [],
      ).reduce((acc: { [key: string]: RaceResult }, next: RaceResult) => {
        return {
          ...acc,
          [next.players.map((i) => i._id).join('|')]: {
            ...next,
            totalPoints: next.totalPoints.reduce(
              (pAcc, point) => ({
                ...pAcc,
                [point.name]: point.value,
              }),
              {},
            ),
          },
        };
      }, {}),
      teamResults: getProp(
        props.data || {},
        ['resultsTable', 'teamResults'],
        [],
      ).reduce((acc: { [key: string]: RaceResult }, next: RaceResult) => {
        return {
          ...acc,
          [next.team._id]: {
            ...next,
            totalPoints: next.totalPoints.reduce(
              (pAcc, point) => ({
                ...pAcc,
                [point.name]: point.value,
              }),
              {},
            ),
          },
        };
      }, {}),
    };
  }

  return {
    initialValues:
      props.id !== 'new'
        ? {
            ...props.data,
            resultsTableObject,
          }
        : {
            name,
            signup,
            rules,
            ...{ settings },
          },
    competition,
    sportSectorType,
    signup,
    resultsTable,
    rules: Object.keys(getProp(competition || {}, ['rules'], {})).length
      ? getProp(competition || {}, ['rules'])
      : rules,
    profileSportValues: profileSportSelector(state),
    profile: activeAppspaceProfileSelector(state),
    isSubmittingParticipant: isSubmitting(PARTICIPANT_FORM_NAME)(state),
    isSubmittingClubInvitation: isSubmitting(CLUB_INVITATION_FORM_NAME)(state),
    isSubmittingInitialResultsTable: isSubmitting(
      COMPETITION_PART_INITIAL_STATUS_FORM_NAME,
    )(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = InjectedFormProps<CustomFormData> &
  IMapStateToProps &
  RouteComponentProps<{}> &
  OwnProps & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  };

class CompetitionPartForm extends React.PureComponent<Props> {
  state: {
    participantFormOpened: boolean;
    clubInvitationFormOpened: boolean;
    selectPart: boolean;
    selectedPart: null | {
      name: string;
    };
    removingParticipantId: null | string;
    phases: Array<{ _id: string; label: string }>;
    points: Array<{ _id: string; label: string; format?: 'time' }>;
    isFetchingDisciplines: boolean;
    disciplines: Array<{ value: string; label: string }>;
    isFetchingInvitations: boolean;
    invitations: Array<{ club: { name: string } }>;
    isFetchingAgeCategories: boolean;
    ageCategories: Array<{ label: string; value: string }>;
  } = {
    participantFormOpened: false,
    clubInvitationFormOpened: false,
    selectPart: false,
    selectedPart: null,
    removingParticipantId: null,
    phases: [],
    points: [],
    isFetchingDisciplines: false,
    disciplines: [],
    isFetchingInvitations: false,
    invitations: [],
    isFetchingAgeCategories: false,
    ageCategories: [],
  };

  componentDidMount() {
    if (this.props.rules.sport_sector) {
      this.getSettings(this.props);
    }
    this.getAgeCategories(this.props);
    this.getInvitations();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.rules.gender !== prevProps.rules.gender) {
      this.getAgeCategories(this.props);
    }
    if (this.props.rules.sport_sector !== prevProps.rules.sport_sector) {
      const sportSectors = getProp(
        this.props,
        ['profileSportValues', 'sport_sector'],
        [],
      );
      const sportSector = sportSectors.find(
        (s: { value: string; label: string; payload?: { type?: string } }) =>
          s &&
          s.value ===
            (this.props.rules.sport_sector.value
              ? this.props.rules.sport_sector.value
              : this.props.rules.sport_sector),
      );
      const type =
        sportSector && sportSector.payload && sportSector.payload.type
          ? sportSector.payload.type
          : 'collective';
      this.props.dispatch(change(FORM_NAME, 'type', type));
      this.getSettings(this.props);
    }
  }

  getAgeCategories = async (props: Props) => {
    this.setState({ isFetchingAgeCategories: true });
    try {
      const res = await api.adminGetAgeCategories(props.appspace);
      this.setState({
        ageCategories: (res.ageCategories || [])
          .filter((item) =>
            props.rules.gender
              ? item.gender === props.rules.gender
              : typeof item.gender === 'undefined',
          )
          .map((item) => ({
            label: item.name,
            value: item.name,
          })),
      });
    } catch (e: any) {
      alert(__('Nepodarilo sa získať vekové kategórie'));
    } finally {
      this.setState({ isFetchingAgeCategories: false });
    }
  };

  onSubmitInitialResultsTableForm = async (values: any) => {
    const { competitionId, id: partId, appspace: appSpace } = this.props;
    try {
      await api.adminCalculateCompetitionPartResultTable(
        appSpace,
        competitionId,
        partId!,
        { regenerateMatches: false },
        values,
      );
      window.location.reload();
    } catch (e) {
      console.error(e);
      alert(__('Nepodarilo sa vypočítať začiatočný stav tabuľky'));
    } finally {
      this.setState({
        selectPart: false,
      });
    }
  };

  getInvitations = async () => {
    try {
      this.setState({
        isFetchingInvitations: true,
      });
      const { invitations: items } = await api.getClubInvitations(
        this.props.appspace,
        this.props.competitionId,
        this.props.id,
      );
      const regex = new RegExp(`/parts/${this.props.id}/`);
      const partInvitations = (items || []).filter((i) =>
        i.callback.match(regex),
      );
      const appspaces = partInvitations
        .map((i) => i.payload && (i.payload as any).clubId)
        .filter((i) => i);
      const { items: clubs } = await sportnetApi.organizationPPOProfiles({
        ids: appspaces,
      });
      this.setState({
        invitations: clubs.map((c) => ({ club: { name: c.name } })),
      });
    } catch (e: any) {
      //
    } finally {
      this.setState({
        isFetchingInvitations: false,
      });
    }
  };

  getSettings = async (props = this.props) => {
    const sportSector =
      typeof props.rules.sport_sector === 'string'
        ? props.rules.sport_sector
        : props.rules.sport_sector && props.rules.sport_sector.value;
    this.setState({
      isFetchingDisciplines: true,
      disciplines: [],
    });
    if (props.profile) {
      try {
        const { codelist: disciplines } =
          await sportnetApi.getCodelistSectorDiscipline(
            'sport',
            props.profile.sport,
            sportSector,
          );
        this.setState({
          disciplines: disciplines || [],
        });
      } catch (e: any) {
        //
      } finally {
        this.setState({
          isFetchingDisciplines: false,
        });
      }
    }
    try {
      const phases = await api.getSettingBySportSector(
        'sport_sector_phases',
        sportSector,
      );
      this.setState({
        phases: phases.items.filter(
          (i: any) =>
            i.playPhase &&
            typeof i.startTime !== 'undefined' &&
            typeof i.endTime !== 'undefined',
        ),
      });
    } catch (e: any) {
      //
    }
    try {
      const { items: points } = await api.getSettingBySportSector(
        'sport_sector_points',
        sportSector,
      );
      this.setState({
        points,
      });
    } catch (e: any) {
      //
    }
    if (this.props.id === 'new') {
      this.props.dispatch(
        change(
          FORM_NAME,
          'settings',
          getCompetitionPartRulesInitialValues({
            sport_sector: sportSector,
          }),
        ),
      );
    }
  };

  onSubmitParticipant = async (values: any) => {
    const { appspace, id: competitionPartId, competitionId } = this.props;
    if (competitionPartId) {
      try {
        await api.adminSignupCompetitionPartTeam(
          appspace,
          competitionId,
          competitionPartId,
          undefined,
          {
            teamId: values.teamId,
          },
        );
        await this.props.dispatch(
          getCompetitionPartById.action({
            appspace,
            competitionId,
            competitionPartId,
          }),
        );
        this.setState({
          participantFormOpened: false,
        });
        window.location.reload();
      } catch (e: any) {
        if (e.details.name === 'TEAM_ALREADY_SIGNED_UP') {
          alert(__('Družstvo je už do časti súťaže prihlásené.'));
        } else {
          console.error(e.details);
          alert(__('Vyskytla sa neočakávaná chyba.'));
        }
      }
    }
  };

  renderConfiguration = (sportSector: { value: string }, readOnly: boolean) => {
    let content = null;

    const sportSectorValue = sportSector.value || sportSector;
    switch (sportSectorValue) {
      case 'water-polo':
        content = (
          <WaterPoloCompetitionPartRulesForm
            // readOnly={readOnly}
            phases={this.state.phases}
          />
        );
        break;
      case 'futbal':
        content = (
          <FutbalCompetitionPartRulesForm
            // readOnly={readOnly}
            phases={this.state.phases}
          />
        );
        break;
      case 'futsal':
        content = (
          <FutsalCompetitionPartRulesForm
            // readOnly={readOnly}
            phases={this.state.phases}
          />
        );
        break;
      case 'beachfutbal':
        content = (
          <BeachFootballCompetitionPartRulesForm
            // readOnly={readOnly}
            phases={this.state.phases}
          />
        );
        break;
      case 'minifootball':
        content = (
          <MinifootballCompetitionPartRulesForm
            // readOnly={readOnly}
            phases={this.state.phases}
          />
        );
        break;
      case 'swimming':
        content = (
          <SwimmingCompetitionPartRulesForm
            // readOnly={readOnly}
            points={this.state.points}
          />
        );
        break;
      case 'cestna-cyklistika':
        content = (
          <CyclingCompetitionPartRulesForm
            // readOnly={readOnly}
            points={this.state.points}
          />
        );
        break;
      case 'formula-1':
        content = (
          <FormulaOneCompetitionPartRulesForm
            // readOnly={readOnly}
            points={this.state.points}
          />
        );
        break;
      default:
        content = null;
        break;
    }
    if (content) {
      return (
        <Box
          title={__('Konfigurácia časti súťaže')}
          collapsible
          collapsed={readOnly}
        >
          {content}
        </Box>
      );
    }
    return (
      <BoxNote>
        {__(
          'Pre dané športové odvetvie momentálne neexistuje možnosť konfigurácie.',
        )}
      </BoxNote>
    );
  };

  onSubmitClubInvitation = async (values: {
    email: string;
    club: { value: string };
  }) => {
    const { appspace, competitionId, id: competitionPartId } = this.props;
    try {
      await api.adminInviteCompetitionPartClub(
        appspace,
        competitionId,
        competitionPartId,
        {},
        { email: values.email, club: values.club.value },
      );
      this.setState({
        clubInvitationFormOpened: false,
      });
      await this.props.dispatch(
        getCompetitionPartById.action({
          appspace,
          competitionId,
          competitionPartId,
        }),
      );
    } catch (e: any) {
      alert(__('Klub sa nepodarilo pozvať.'));
    }
  };

  render() {
    const {
      appspace,
      data,
      competition,
      id: competitionPartId,
      competitionId,
      rules,
      isSubmittingParticipant,
      isSubmittingClubInvitation,
    } = this.props;

    const { ageCategories, isFetchingAgeCategories } = this.state;

    let anyTeamWithoutAthletes = false;
    if (competitionPartId !== 'new') {
      anyTeamWithoutAthletes = getProp(data || {}, ['teams'], []).find(
        (team: any) => !getProp(team, ['squad', 'athletes'], []).length,
      );
    }

    const { selectedPart } = this.state;

    const sportSectors = getProp(
      this.props,
      ['profileSportValues', 'sport_sector'],
      [],
    );

    const resultTableReadOnly = getProp(data || {}, ['matches_count'], 0) > 0;
    const resultTableProps = {
      style: {
        ...(resultTableReadOnly && {
          background: 'transparent',
        }),
      },
    };

    const collectiveTableProps = {
      parse: (i: string) => (i || i === '0' ? Number(i) : i),
    };

    if (sportSectors.length === 0) {
      return null;
    }

    return (
      <Form onSubmit={this.props.handleSubmit}>
        {anyTeamWithoutAthletes && (
          <Message danger title={__('Prázdna súpiska')}>
            {__(
              'Niektorý z tímov má prázdnu súpisku a nebude sa teda môcť zúčastniť žiadneho stretnutia.',
            )}
          </Message>
        )}
        {competitionPartId !== 'new' &&
          !Object.keys(getProp(this.props, ['resultsTable', 'results'], {}))
            .length && (
            <Message danger title={__('Žiadni účastníci časti súťaže')}>
              {__('Pre riadne pokračovanie chýbajú prihlásené družstvá.')}
            </Message>
          )}
        <Box
          title={__('Základné informácie')}
          collapsible
          collapsed={competitionPartId !== 'new'}
        >
          <Row>
            <Col xs={12} m={3}>
              <Field
                name="name"
                validate={[required]}
                component={FormField}
                label={__('Názov časti súťaže')}
                required
              />
            </Col>
            <Col xs={12} m={3}>
              <Field
                name="dateFrom"
                type="date"
                format={null}
                validate={[required]}
                component={FormField}
                label={__('Začiatok časti súťaže')}
                required
              />
            </Col>
            <Col xs={12} m={3}>
              <Field
                name="dateTo"
                type="date"
                format={null}
                validate={[required]}
                component={FormField}
                label={__('Koniec časti súťaže')}
                required
              />
            </Col>
          </Row>
        </Box>
        {!Object.keys(getProp(this.props, ['competition', 'rules'], {}))
          .length ? (
          <>
            <Box
              title={__('Podmienky účasti v časti súťaže')}
              collapsible
              collapsed={competitionPartId !== 'new'}
            >
              <Row>
                <React.Fragment>
                  <Col xs={12}>
                    <Field
                      component={FormField}
                      label={__('Prihlasovanie športovcov bez príslušnosti')}
                      type="checkbox"
                      name="rules.loosePlayerSignup"
                      disabled={competitionPartId !== 'new'}
                    />
                  </Col>
                  <Col xs={12} m={3}>
                    <Field
                      component={FormField}
                      label={__('Športové odvetvie')}
                      type="theselect"
                      name="rules.sport_sector"
                      loading={this.state.isFetchingDisciplines}
                      validate={[required]}
                      required
                      options={sportSectors}
                      format={(
                        i: string | { value: string; label: string },
                      ) => {
                        if (typeof i === 'string') {
                          const option = sportSectors.find(
                            (s: any) => s && String(s.value) === i,
                          );
                          if (option) {
                            return {
                              value: option.value || '',
                              label: option.label,
                            };
                          }
                        } else if (i) {
                          return i;
                        }
                        return '';
                      }}
                      disabled={competitionPartId !== 'new'}
                    />
                  </Col>
                  {this.props.sportSectorType === 'collective' && (
                    <Col xs={12} m={3}>
                      <Field
                        name="format"
                        type="theselect"
                        validate={[required]}
                        component={FormField}
                        label={__('Formát časti súťaže')}
                        disabled={competitionPartId !== 'new'}
                        required
                        options={COMPETITION_PART_FORMATS}
                        format={(
                          i: string | { value: string; label: string },
                        ) => {
                          if (typeof i === 'string') {
                            const option = COMPETITION_PART_FORMATS.find(
                              (f) => f && f.value === i,
                            );
                            if (option) {
                              return option;
                            }
                          } else if (i) {
                            return i;
                          }
                          return '';
                        }}
                      />
                    </Col>
                  )}
                  {/* {this.state.disciplines.length > 0 && (
                    <Col xs={12} m={4}>
                      <Field
                        component={FormField}
                        label={__('Športová disciplína')}
                        type="theselect"
                        name="rules.discipline"
                        validate={[required]}
                        required
                        options={this.state.disciplines}
                        format={(
                          i: string | { value: string; label: string },
                        ) => {
                          if (typeof i === 'string') {
                            const option = this.state.disciplines.find(
                              (s: any) => s && String(s.value) === i,
                            );
                            if (option) {
                              return {
                                value: option.value || '',
                                label: option.label,
                              };
                            }
                          } else if (i) {
                            return i;
                          }
                          return '';
                        }}
                        disabled={competitionPartId !== 'new'}
                      />
                    </Col>
                  )} */}
                  <Col xs={12} m={3}>
                    <Field
                      name="rules.gender"
                      component={FormField}
                      label={__('Pohlavie')}
                      type="select"
                      disabled={competitionPartId !== 'new'}
                    >
                      <option value="">{__('Bez rozlíšenia')}</option>
                      <option value="M">{__('Muži')}</option>
                      <option value="F">{__('Ženy')}</option>
                    </Field>
                  </Col>
                  <Col xs={12} m={3}>
                    <Field
                      name="rules.category"
                      component={FormField}
                      label={__('Veková kategória')}
                      type="theselectsimple"
                      options={ageCategories}
                      loading={isFetchingAgeCategories}
                      disabled={competitionPartId !== 'new'}
                    />
                  </Col>
                  {/* <Col xs={12} m={6}>
                    <Field
                      name="rules.bornDateFrom"
                      component={FormField}
                      label={__('Dátum narodenia od')}
                      type="date"
                      disabled={competitionPartId !== 'new'}
                    />
                  </Col>
                  <Col xs={12} m={6}>
                    <Field
                      name="rules.bornDateTo"
                      component={FormField}
                      label={__('Dátum narodenia do')}
                      type="date"
                      disabled={competitionPartId !== 'new'}
                    />
                  </Col> */}
                </React.Fragment>
              </Row>
            </Box>
            {!!rules &&
              !!rules.sport_sector &&
              this.renderConfiguration(
                rules.sport_sector,
                competitionPartId !== 'new',
              )}
          </>
        ) : (
          <div />
        )}
        {competitionPartId !== 'new' && (
          <>
            {this.state.participantFormOpened && (
              <Modal
                isOpen={this.state.participantFormOpened}
                handleClose={() => {
                  this.setState({
                    participantFormOpened: false,
                  });
                }}
              >
                <ModalContent>
                  <Header size="xs" withSeparator>
                    {__('Pridať družstvo')}
                  </Header>
                  <ParticipantForm
                    appspace={appspace}
                    competitionId={competitionId}
                    competitionPartId={competitionPartId}
                    onSubmit={this.onSubmitParticipant}
                  />
                </ModalContent>
                <ModalActions>
                  <div>&nbsp;</div>
                  <div>
                    <Button
                      secondary
                      onClick={() => {
                        this.setState({
                          participantFormOpened: false,
                        });
                      }}
                    >
                      {__('Zrušiť')}
                    </Button>
                    <Button
                      primary
                      loading={isSubmittingParticipant}
                      onClick={async () => {
                        await this.props.dispatch(
                          submit(PARTICIPANT_FORM_NAME),
                        );
                      }}
                    >
                      {__('Prihlásiť účastníka')}
                    </Button>
                  </div>
                </ModalActions>
              </Modal>
            )}
            {this.state.clubInvitationFormOpened && (
              <Modal
                isOpen={this.state.clubInvitationFormOpened}
                handleClose={() => {
                  this.setState({
                    clubInvitationFormOpened: false,
                  });
                }}
              >
                <ModalContent>
                  <Header size="xs" withSeparator>
                    {__('Pozvať klub')}
                  </Header>
                  <ClubInvitationForm
                    suitableClubs={this.props.suitableClubs}
                    form={CLUB_INVITATION_FORM_NAME}
                    onSubmit={this.onSubmitClubInvitation}
                  />
                </ModalContent>
                <ModalActions>
                  <div>&nbsp;</div>
                  <div>
                    <Button
                      secondary
                      onClick={() => {
                        this.setState({
                          clubInvitationFormOpened: false,
                        });
                      }}
                    >
                      {__('Zrušiť')}
                    </Button>
                    <Button
                      primary
                      loading={isSubmittingClubInvitation}
                      onClick={async () => {
                        await this.props.dispatch(
                          submit(CLUB_INVITATION_FORM_NAME),
                        );
                      }}
                    >
                      {__('Pozvať klub')}
                    </Button>
                  </div>
                </ModalActions>
              </Modal>
            )}
          </>
        )}
        {competitionPartId !== 'new' &&
          this.props.sportSectorType === 'collective' && (
            <Box
              title={__('Počiatočný stav časti súťaže')}
              subtitle={
                !resultTableReadOnly && (
                  <>
                    {getProp(competition || {}, ['parts'], []).filter(
                      (part: CompetitionPart) => part._id !== competitionPartId,
                    ).length ? (
                      <>
                        {' '}
                        <Button
                          type="button"
                          primary
                          onClick={() => {
                            this.setState({
                              selectPart: true,
                            });
                          }}
                        >
                          {selectedPart
                            ? selectedPart.name
                            : __('Načítať z predchádzajúcej časti suťaže')}
                        </Button>
                        &nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )
              }
            >
              {!!Object.keys(
                getProp(this.props, ['resultsTable', 'results'], {}),
              ).length && (
                <Col xs={12}>
                  <BasicTable
                    columns={[
                      {
                        header: __('Družstvo'),
                      },
                      {
                        header: __('OZ'),
                        width: 70,
                      },
                      {
                        header: __('V'),
                        width: 70,
                      },
                      {
                        header: __('Vn'),
                        width: 70,
                      },
                      {
                        header: __('Vp'),
                        width: 70,
                      },
                      {
                        header: __('Vpen'),
                        width: 70,
                      },
                      {
                        header: __('R'),
                        width: 70,
                      },
                      {
                        header: __('P'),
                        width: 70,
                      },
                      {
                        header: __('Pn'),
                        width: 70,
                      },
                      {
                        header: __('Pp'),
                        width: 70,
                      },
                      {
                        header: __('Ppen'),
                        width: 70,
                      },
                      {
                        header: __('SG'),
                        width: 70,
                      },
                      {
                        header: __('OG'),
                        width: 70,
                      },
                      {
                        header: __('B'),
                        width: 70,
                      },
                      {
                        width: 100,
                        header: '',
                      },
                    ]}
                    rows={Object.keys(
                      getProp(this.props.resultsTable || {}, ['results'], {}),
                    ).reduce(
                      (acc: any[], k: any) => [
                        ...acc,
                        {
                          ...this.props.resultsTable.results[k],
                          rowKey: this.props.resultsTable.results[k].team._id,
                        },
                      ],
                      [],
                    )}
                    renderRow={(result: Result) => {
                      const matchesCount = data
                        ? getProp(
                            data,
                            ['matchesCountByTeamId', result.team._id],
                            0,
                          )
                        : 0;
                      return [
                        result.team.name,
                        <Field
                          component={Input}
                          key={`${result.team._id}_oz`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.played`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_won`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.won`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_wonNT`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.wonNT`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_wonET`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.wonET`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_wonP`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.wonP`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_draw`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.draw`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_lost`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.lost`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_lostNT`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.lostNT`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_lostET`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.lostET`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_lostP`}
                          name={`resultsTableObject.results.${result.team._id}.stats.matches.lostP`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_sg`}
                          name={`resultsTableObject.results.${result.team._id}.stats.goals.given`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_og`}
                          name={`resultsTableObject.results.${result.team._id}.stats.goals.received`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        <Field
                          component={Input}
                          key={`${result.team._id}_points`}
                          name={`resultsTableObject.results.${result.team._id}.stats.points`}
                          validate={[isInteger]}
                          {...resultTableProps}
                          {...collectiveTableProps}
                        />,
                        matchesCount === 0 ? (
                          <Button
                            key={result.team._id}
                            danger
                            type="button"
                            loading={
                              this.state.removingParticipantId ===
                              result.team._id
                            }
                            disabled={
                              this.state.removingParticipantId &&
                              this.state.removingParticipantId !==
                                result.team._id
                            }
                            onClick={async () => {
                              this.setState({
                                removingParticipantId: result.team._id,
                              });
                              await api.adminRemoveCompetitionPartSignedUpTeam(
                                appspace,
                                competitionId,
                                competitionPartId,
                                result.team._id,
                              );
                              await this.props.dispatch(
                                getCompetitionPartById.action({
                                  appspace,
                                  competitionId,
                                  competitionPartId,
                                }),
                              );
                              window.location.reload();
                              this.setState({
                                removingParticipantId: null,
                              });
                            }}
                          >
                            {__('Odstrániť')}
                          </Button>
                        ) : (
                          <></>
                        ),
                      ];
                    }}
                    rowKey="rowKey"
                  />
                </Col>
              )}
              <Modal
                isOpen={
                  this.state.selectPart ||
                  this.props.isSubmittingInitialResultsTable
                }
                handleClose={() => {
                  this.setState({
                    selectPart: false,
                  });
                }}
              >
                <ModalContent>
                  <Header withSeparator size="xs">
                    {__('Počiatočný stav časti súťaže')}
                  </Header>
                  <Message warning>
                    {__(
                      'Pred vytvorením počiatočného stavu súťaže sa uistite, že sú pridané všetky družstvá, ktoré sa novej časti súťaže budú účastniť.',
                    )}
                  </Message>
                  {this.props.competition && (
                    <CompetitionPartInitialStatusForm
                      appSpace={appspace}
                      competitionId={competitionId}
                      partId={competitionPartId}
                      resultsTable={this.props.resultsTable || {}}
                      parts={getProp(competition || {}, ['parts'], []).filter(
                        (part: CompetitionPart) =>
                          part._id !== competitionPartId,
                      )}
                      onSubmit={this.onSubmitInitialResultsTableForm}
                    />
                  )}
                </ModalContent>
                <ModalActions>
                  <div>&nbsp;</div>
                  <div>
                    <Button
                      onClick={() => {
                        this.setState({
                          selectPart: false,
                        });
                      }}
                      type="button"
                    >
                      {__('Zavrieť')}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.dispatch(
                          submit(COMPETITION_PART_INITIAL_STATUS_FORM_NAME),
                        );
                      }}
                      primary
                      type="button"
                      loading={this.props.isSubmittingInitialResultsTable}
                    >
                      {__('Vybrať')}
                    </Button>
                  </div>
                </ModalActions>
              </Modal>
              <Legend>
                <LegendItem>
                  <LegendItemLabel>{__('OZ')}</LegendItemLabel>
                  <div>{__('Odohrané zápasy')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('V')}</LegendItemLabel>
                  <div>{__('Výhry')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('Vn')}</LegendItemLabel>
                  <div>{__('Výhry v riadnom hracom čase')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('Vp')}</LegendItemLabel>
                  <div>{__('Výhry po predĺžení')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('Vpen')}</LegendItemLabel>
                  <div>{__('Výhry po penáltách')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('R')}</LegendItemLabel>
                  <div>{__('Remízy')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('P')}</LegendItemLabel>
                  <div>{__('Prehry')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('Pn')}</LegendItemLabel>
                  <div>{__('Prehry v riadnom hracom čase')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('Pp')}</LegendItemLabel>
                  <div>{__('Prehry po predĺžení')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('Ppen')}</LegendItemLabel>
                  <div>{__('Prehry po penáltách')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('SG')}</LegendItemLabel>
                  <div>{__('Strelené góly')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('OG')}</LegendItemLabel>
                  <div>{__('Obdržané góly')}</div>
                </LegendItem>
                <LegendItem>
                  <LegendItemLabel>{__('B')}</LegendItemLabel>
                  <div>{__('Body')}</div>
                </LegendItem>
              </Legend>
            </Box>
          )}

        {competitionPartId !== 'new' && this.props.sportSectorType === 'race' && (
          <Box title={__('Počiatočný stav časti súťaže')}>
            {!!Object.keys(getProp(this.props, ['resultsTable', 'results'], {}))
              .length && (
              <Col xs={12}>
                <BasicTable
                  columns={[
                    {
                      header: __('Meno a priezvisko'),
                    },
                    ...getProp(
                      this.props,
                      ['data', 'settings', 'roundPoints'],
                      [],
                    ).map((roundPoint: string) => {
                      const point = this.state.points.find(
                        (i) => i._id === roundPoint,
                      );
                      return {
                        header: point ? point.label : roundPoint,
                        width: point && point.format === 'time' ? 150 : 70,
                      };
                    }),
                  ]}
                  rows={Object.keys(
                    getProp(this.props.resultsTable || {}, ['results'], {}),
                  )
                    .reduce(
                      (acc: any[], k: any) => [
                        ...acc,
                        {
                          ...this.props.resultsTable.results[k],
                          rowKey: this.props.resultsTable.results[k].players
                            .map((p: any) => p._id)
                            .join(','),
                        },
                      ],
                      [],
                    )
                    .sort((a: any, b: any) => {
                      const aName = a.players.map((p: any) => p.name).join(',');
                      const bName = b.players.map((p: any) => p.name).join(',');
                      if (aName > bName) {
                        return 1;
                      } else if (aName < bName) {
                        return -1;
                      }
                      return 0;
                    })}
                  renderRow={(result: RaceResult, idx: number) => [
                    result.players.map((player) => player.name).join(', '),
                    ...getProp(
                      this.props,
                      ['data', 'settings', 'roundPoints'],
                      [],
                    ).map((roundPoint: string) => {
                      const point = this.state.points.find(
                        (p) => p._id === roundPoint,
                      );
                      const integerProps = {
                        validate: [isInteger],
                      };
                      const timeProps = {
                        format: (i: { value: number }) => {
                          if (i && typeof i.value === 'number') {
                            return formatSecondsToString(i.value);
                          }
                          if (typeof i === 'number') {
                            return formatSecondsToString(i);
                          }
                          return i ? i.value : null;
                        },
                        validate: [isNumberFromObject],
                        normalize: (i: string) => {
                          const output: IPoint = {
                            name: roundPoint,
                            value: '',
                          };
                          const split = i.split(':');
                          const secondsSplit =
                            split[split.length - 1].split('.');
                          if (
                            (split.length === 2 &&
                              split[0].length >= 2 &&
                              ((Number(split[1]) <= 59 &&
                                split[1].length === 2) ||
                                (secondsSplit &&
                                  secondsSplit[1] &&
                                  secondsSplit[1].length >= 2))) ||
                            (split.length === 3 &&
                              split[0].length >= 2 &&
                              split[1].length >= 2 &&
                              ((Number(split[2]) <= 59 &&
                                split[2].length === 2) ||
                                (secondsSplit &&
                                  secondsSplit[1] &&
                                  secondsSplit[1].length >= 2)))
                          ) {
                            output.value = formatStringToSeconds(i);
                          } else {
                            output.value = i;
                          }
                          return output;
                        },
                      };
                      return (
                        <Field
                          component={Input}
                          key={`${result.team._id}_${roundPoint}`}
                          name={`resultsTableObject.results.${result.players
                            .map((i) => i._id)
                            .join('|')}.totalPoints.${roundPoint}`}
                          {...resultTableProps}
                          {...(point && point.format === 'time'
                            ? timeProps
                            : integerProps)}
                        />
                      );
                    }),
                  ]}
                  rowKey="rowKey"
                />
              </Col>
            )}
            {!!Object.keys(
              getProp(this.props, ['resultsTable', 'teamResults'], {}),
            ).length && (
              <Col xs={12}>
                <BasicTable
                  columns={[
                    {
                      header: __('Tím'),
                    },
                    ...getProp(
                      this.props,
                      ['data', 'settings', 'roundPoints'],
                      [],
                    ).map((roundPoint: string) => {
                      const point = this.state.points.find(
                        (i) => i._id === roundPoint,
                      );
                      return {
                        header: point ? point.label : roundPoint,
                        width: point && point.format === 'time' ? 150 : 70,
                      };
                    }),
                  ]}
                  rows={Object.keys(
                    getProp(this.props.resultsTable || {}, ['teamResults'], {}),
                  ).reduce(
                    (acc: any[], k: any) => [
                      ...acc,
                      {
                        ...this.props.resultsTable.teamResults[k],
                        rowKey: this.props.resultsTable.teamResults[k].team._id,
                      },
                    ],
                    [],
                  )}
                  renderRow={(result: TeamRaceResult, idx: number) => [
                    result.team.name,
                    ...getProp(
                      this.props,
                      ['data', 'settings', 'roundPoints'],
                      [],
                    ).map((roundPoint: string) => {
                      const point = this.state.points.find(
                        (p) => p._id === roundPoint,
                      );
                      const integerProps = {
                        validate: [isInteger],
                      };
                      const timeProps = {
                        format: (i: { value: number }) => {
                          if (i && typeof i.value === 'number') {
                            return formatSecondsToString(i.value);
                          }
                          if (typeof i === 'number') {
                            return formatSecondsToString(i);
                          }
                          return i ? i.value : null;
                        },
                        validate: [isNumberFromObject],
                        normalize: (i: string) => {
                          const output: IPoint = {
                            name: roundPoint,
                            value: '',
                          };
                          const split = i.split(':');
                          const secondsSplit =
                            split[split.length - 1].split('.');
                          if (
                            (split.length === 2 &&
                              split[0].length >= 2 &&
                              ((Number(split[1]) <= 59 &&
                                split[1].length === 2) ||
                                (secondsSplit &&
                                  secondsSplit[1] &&
                                  secondsSplit[1].length >= 2))) ||
                            (split.length === 3 &&
                              split[0].length >= 2 &&
                              split[1].length >= 2 &&
                              ((Number(split[2]) <= 59 &&
                                split[2].length === 2) ||
                                (secondsSplit &&
                                  secondsSplit[1] &&
                                  secondsSplit[1].length >= 2)))
                          ) {
                            output.value = formatStringToSeconds(i);
                          } else {
                            output.value = i;
                          }
                          return output;
                        },
                      };
                      return (
                        <Field
                          component={Input}
                          key={`${result.team._id}_${roundPoint}`}
                          name={`resultsTableObject.teamResults.${result.team._id}.totalPoints.${roundPoint}`}
                          {...resultTableProps}
                          {...(point && point.format === 'time'
                            ? timeProps
                            : integerProps)}
                        />
                      );
                    }),
                  ]}
                  rowKey="rowKey"
                />
              </Col>
            )}
            <Modal
              isOpen={this.state.selectPart}
              handleClose={() => {
                this.setState({
                  selectPart: false,
                });
              }}
            >
              <ModalContent>
                <Header withSeparator size="xs">
                  {__('Počiatočný stav časti súťaže')}
                </Header>
                {this.props.competition && (
                  <CompetitionPartInitialStatusForm
                    appSpace={appspace}
                    competitionId={competitionId}
                    partId={competitionPartId}
                    resultsTable={this.props.resultsTable || {}}
                    parts={getProp(competition || {}, ['parts'], []).filter(
                      (part: CompetitionPart) => part._id !== competitionPartId,
                    )}
                    onSubmit={this.onSubmitInitialResultsTableForm}
                  />
                )}
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button
                    onClick={() => {
                      this.setState({
                        selectPart: false,
                      });
                    }}
                    type="button"
                  >
                    {__('Zavrieť')}
                  </Button>
                  <Button
                    onClick={async () => {
                      await this.props.dispatch(
                        submit(COMPETITION_PART_INITIAL_STATUS_FORM_NAME),
                      );
                      this.setState({
                        selectPart: false,
                      });
                      // this.props.dispatch(submit(FORM_NAME));
                    }}
                    primary
                    type="button"
                  >
                    {__('Vybrať')}
                  </Button>
                </div>
              </ModalActions>
            </Modal>
          </Box>
        )}

        {!!getProp(data || {}, ['teams'], []).length && (
          <Box title={__('Súpisky družstiev')}>
            <BasicTable
              columns={[
                {
                  header: __('Názov družstva'),
                },
              ]}
              rows={getProp(data || {}, ['teams'], [])}
              rowKey="_id"
              onClickRow={(team) =>
                this.props.history.push(
                  `/admin/${appspace}/competitions/${competitionId}/parts/${competitionPartId}/squads/${team._id}`,
                )
              }
              renderRow={(team) => [team.name]}
            />
          </Box>
        )}
        {competitionPartId !== 'new' && (
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Čakajúce pozvánky')}
              </SegmentHeader>
            }
            loading={this.state.isFetchingInvitations}
          >
            <Button
              primary
              type="button"
              onClick={() => {
                this.setState({
                  clubInvitationFormOpened: true,
                });
              }}
            >
              {__('Pozvať klub')}
            </Button>
            &nbsp;
            <Button
              primary
              type="button"
              onClick={() => {
                this.setState({
                  participantFormOpened: true,
                });
              }}
            >
              {__('Pridať družstvo')}
            </Button>
            <br />
            <br />
            <BasicTable
              columns={[
                {
                  header: __('Názov klubu'),
                },
              ]}
              rows={this.state.invitations}
              renderRow={(i) => [i.club.name]}
            />
          </Segment>
        )}
      </Form>
    );
  }
}

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompetitionPartForm);

export default connect(mapStateToProps)(withRouter(connected));
