import React from 'react';
import { Team } from '../definitions';
import styled from 'styled-components';
import { Members, MembersDetail } from './definitions';
import Message from '@sportnet/ui/Message';
import Loader from '@sportnet/ui/Loader';
import Button from '@sportnet/ui/Button';
import { __ } from 'src/utilities';
import rem from 'polished/lib/helpers/rem';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'src/rootReducer';
import { membersByTeamIdSelector } from './selectors';
import { getMembersList } from './actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import TeamMembersDetail from './detail';

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  team: Team;
};

const TeamMembers: React.FC<Props> = ({ team }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const membersListSelector = useSelector((state: State) =>
    membersByTeamIdSelector(team._id)(state),
  );

  const [membersList, setMembersList] = React.useState(membersListSelector);
  const [isFetchingMembersList, setIsFetchingMembersList] =
    React.useState(false);

  const fetchMembersList = React.useCallback(async () => {
    setIsFetchingMembersList(true);
    try {
      const normalizedMembersSchema = await dispatch(
        getMembersList.action({ appspace: team.appSpace, teamId: team._id }),
      );
      setMembersList(
        Object.values(
          normalizedMembersSchema.entities.membersByTeamId[team._id],
        ),
      );
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsFetchingMembersList(false);
    }
  }, [team.appSpace, dispatch, team._id]);

  React.useEffect(() => {
    fetchMembersList();
  }, [fetchMembersList]);

  const onCreateMembersCopy = async (members: MembersDetail) => {
    const { _id, ...restProps } = members;
    restProps.title = `${restProps.title} (${__('kópia')})`;
    setMembersList([restProps, ...membersList]);
    const element = document.getElementById('0-teamMembersDetail');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (isFetchingMembersList) {
    return <Loader />;
  }

  return (
    <>
      <Message primary>
        <MessageWrapper>
          <div>
            <h3 style={{ margin: 0, marginBottom: rem(4) }}>
              {__('Manuálne osoby v tíme')}
            </h3>
            <span>
              {__('Pre každé družstvo sa osoby v tíme vytvárajú manuálne.')}
            </span>
          </div>
          <Button
            basic
            primary
            onClick={() => {
              setMembersList([
                {
                  title: __('Nové osoby v tíme'),
                  validFrom: new Date().toISOString(),
                  athletes: [],
                  crew: [],
                } as Omit<MembersDetail, '_id'>,
                ...membersList,
              ]);
            }}
          >
            {__('Vytvoriť osoby v tíme')}
          </Button>
        </MessageWrapper>
      </Message>
      {membersList?.map((l: Members, idx: number) => (
        <div id={idx + '-teamMembersDetail'} style={{ scrollMargin: rem(80) }}>
          <TeamMembersDetail
            appspace={team.appSpace}
            onCreateMembersCopy={onCreateMembersCopy}
            members={l}
            membersList={membersList}
            setMembers={setMembersList}
            team={team}
            keyValue={l._id || '0'}
            key={l._id || 0}
          />
        </div>
      ))}
    </>
  );
};

export default TeamMembers;
