import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required, __ } from '../../utilities';

export const CLUB_INVITATION_FORM_NAME = 'CLUB_INVITATION_FORM';

interface IOwnProps {
  suitableClubs: Array<{ value: string; label: string }>;
}

const ClubInvitationForm: React.FC<IOwnProps> = ({ suitableClubs }) => {
  return (
    <>
      <Field
        label={__('Emailová adresa')}
        name="email"
        component={FormField}
        required
        validate={[required]}
      />
      <Field
        label={__('Klub')}
        name="club"
        component={FormField}
        type="theselect"
        format={(i: string | { value: string; label: string }) => {
          if (typeof i === 'string') {
            const option = suitableClubs.find((c) => String(c.value) === i);
            if (option) {
              return option;
            }
          } else if (i) {
            return i;
          }
          return '';
        }}
        options={suitableClubs}
        required
        validate={[required]}
      />
    </>
  );
};

export default compose<IOwnProps>(reduxForm({}))(ClubInvitationForm) as any;
