import styled from 'styled-components';
import TeamSquadDetail from './detail';
import { Squad, SquadDetail } from './definitions';
import Message from '@sportnet/ui/Message';
import React from 'react';
import Loader from '@sportnet/ui/Loader';
import Button from '@sportnet/ui/Button';
import { __ } from 'src/utilities';
import rem from 'polished/lib/helpers/rem';
import { Team } from '../definitions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'src/rootReducer';
import { squadsByTeamIdSelector } from './selectors';
import { getSquads } from './actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Api from '../../../api';

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  team: Team;
};

const TeamSquads: React.FC<Props> = ({ team }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const squadsSelector = useSelector((state: State) =>
    squadsByTeamIdSelector(team._id)(state),
  );
  const [squads, setSquads] = React.useState(squadsSelector);
  const [isFetchingSquads, setIsFetchingSquads] = React.useState(false);
  const [isFetchingLatestSquad, setIsFetchingLatestSquad] =
    React.useState(false);

  const fetchSquads = React.useCallback(async () => {
    setIsFetchingSquads(true);
    try {
      const normalizedSquadSchema = await dispatch(
        getSquads.action({ appspace: team.appSpace, teamId: team._id }),
      );
      setSquads(
        Object.values(normalizedSquadSchema.entities.squadsByTeamId[team._id]),
      );
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsFetchingSquads(false);
    }
  }, [team._id, dispatch, team.appSpace]);

  const onCreateSquadCopy = async (squad: SquadDetail) => {
    const { _id, ...restProps } = squad;
    restProps.title = `${restProps.title} (${__('kópia')})`;
    setSquads([restProps, ...squads]);
    const element = document.getElementById('0-teamSquadDetail');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const getLatestSquad = async () => {
    setIsFetchingLatestSquad(true);
    let res: { athletes?: any[]; crew?: any[] } = {};
    try {
      res = await Api.publicGetTeamSquad(team.appSpace, team._id);
    } catch (err) {
      console.error(err);
      window.alert('Nepodarilo sa načítať dáta z automatickej súpisky');
    } finally {
      setIsFetchingLatestSquad(false);
    }

    return {
      title: __('Nová súpiska'),
      validFrom: new Date().toISOString(),
      athletes:
        res?.athletes?.map((a) => ({
          sportnetUser: {
            _id: a?.sportnetUser?._id,
            name: a?.sportnetUser?.name,
          },
          additionalData: {
            captain: a?.additionalData?.captain,
            position: a?.additionalData?.position,
            nr: a?.additionalData?.nr,
          },
        })) || [],
      crew:
        res?.crew?.map((a) => ({
          sportnetUser: {
            _id: a?.sportnetUser?._id,
            name: a?.sportnetUser?.name,
          },
          position: a?.position,
        })) || [],
    } as Omit<SquadDetail, '_id'>;
  };

  React.useEffect(() => {
    fetchSquads();
  }, [fetchSquads]);

  if (isFetchingSquads) {
    return <Loader />;
  }

  return squads?.length > 0 ? (
    <>
      {!!squads[0]._id && (
        <Message primary>
          <MessageWrapper>
            <div>
              <h3 style={{ margin: 0, marginBottom: rem(4) }}>
                {__('Manuálne súpisky')}
              </h3>
              <span>
                {__(
                  'Pre toto družstvo sú súpisky manuálne, teda pri novej zostave je potrebné vytvoriť novú súpisku s novým dátumom platnosti.',
                )}
              </span>
            </div>
            <Button
              basic
              primary
              onClick={() => {
                setSquads([
                  {
                    title: __('Nová súpiska'),
                    validFrom: new Date().toISOString(),
                    athletes: [],
                    crew: [],
                  } as Omit<SquadDetail, '_id'>,
                  ...squads,
                ]);
              }}
            >
              {__('Vytvoriť súpisku')}
            </Button>
          </MessageWrapper>
        </Message>
      )}
      {squads?.map((s: Squad, idx: number) => (
        <div
          id={idx + '-teamSquadDetail'}
          key={idx + '-teamSquadDetail'}
          style={{ scrollMargin: rem(80) }}
        >
          <TeamSquadDetail
            appspace={team.appSpace}
            onCreateSquadCopy={onCreateSquadCopy}
            squad={s}
            squads={squads}
            setSquads={setSquads}
            team={team}
            keyValue={s._id || '0'}
            key={s._id || 0}
          />
        </div>
      ))}
    </>
  ) : (
    <Message primary>
      <MessageWrapper>
        <div>
          <h3 style={{ margin: 0, marginBottom: rem(4) }}>
            {__('Automatické súpisky')}
          </h3>
          <span>
            {__(
              'Toto družstvo nemá vytvorené žiadne súpisky a preto sú generované automaticky podľa zápasov. Vytvorením prvej súpisky sa tieto zmenia na manuálne.',
            )}
          </span>
        </div>
        <Button
          basic
          primary
          loading={isFetchingLatestSquad}
          onClick={async () => {
            setSquads([await getLatestSquad(), ...squads]);
          }}
        >
          {__('Vytvoriť súpisku')}
        </Button>
      </MessageWrapper>
    </Message>
  );
};

export default TeamSquads;
