import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import Box from '../../components/Box';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { getProp, __ } from '../../utilities';
import { codelistSelector } from '../App/selectors';
import { getSportObjectById, postSportObject, putSportObject } from './actions';
import { Organization, SportGround, SportObject } from './definitions';
import Form, { FORM_NAME } from './form';
import {
  sportObjectByIdIsFetchingSelector,
  sportObjectByIdSelector,
} from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterProps = RouteComponentProps<{
  id: string;
  appspace: string;
}>;

const mapStateToProps = (state: State, props: RouterProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return {
    sportObject: sportObjectByIdSelector(id)(state),
    isFetching: sportObjectByIdIsFetchingSelector(id)(state),
    sportsGroundTypeCodelist: codelistSelector('sports-ground-type')(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouterProps & OwnProps & IMapStateToProps;

type CompState = {
  organization: Organization | null;
};

class SportObjectDetail extends React.Component<Props, CompState> {
  state = {
    organization: null,
  };

  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace, id },
      },
    } = this.props;
    if (id !== 'new') {
      try {
        await dispatch(getSportObjectById.action({ appspace, id }));
      } catch (e: any) {
        console.error(e);
      }
    }
  }

  onSubmit = async (data: SportObject) => {
    const {
      dispatch,
      history: { push },
      match: {
        params: { appspace, id },
      },
    } = this.props;
    const { sportGrounds, appSpace, sport, ...requestData } = data;
    try {
      if (id === 'new') {
        const sportObject = await dispatch(
          postSportObject.action({
            appspace,
            sportObject: { ...requestData, sport },
          }),
        );
        push(`/admin/${appspace}/sport-objects/${sportObject._id}`);
      } else {
        delete requestData._id;
        await dispatch(
          putSportObject.action({
            appspace,
            sportObjectId: id,
            sportObject: requestData,
          }),
        );
      }
    } catch (e: any) {
      if (getProp(e, ['details', 'name'], '') === 'DUPLICATED_SPORTOBJECT') {
        alert(
          __('Športový objekt s týmto názvom alebo adresou už máte vytvorený.'),
        );
      } else {
        alert(__('Ľutujeme, vyskytla sa neočkávaná chyba.'));
      }
    }
  };

  getSportGroundLabel = (codelistId?: string) => {
    const codelistItem = this.props.sportsGroundTypeCodelist.find(
      (item) => item.value === codelistId,
    );
    if (!codelistItem) {
      return '';
    }
    return codelistItem.label;
  };

  render() {
    const {
      sportObject,
      isFetching,
      match: {
        params: { id, appspace },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/sport-objects`}
            />
            <HeaderBar.Header>
              {!!sportObject
                ? sportObject.name
                : __('Nové športové zariadenie')}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                disabled={isFetching}
                loading={isFetching}
                onClick={() => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
                primary
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext
          title={
            !!sportObject ? sportObject.name : __('Nové športové zariadenie')
          }
          breadcrumbs={[
            {
              name: __('Zoznam športových objektov'),
              url: `/admin/${appspace}/sport-objects`,
            },
            {
              name: __('Detail športového objektu'),
            },
          ]}
        /> */}
        <Segment>
          {id === 'new' && (
            <Message warning title={__('Rozpracovaný športový objekt')}>
              {__(
                'Pre pridanie športoviska je potrebné najprv uložiť športový objekt.',
              )}
            </Message>
          )}
          <Box title={__('Základné informácie')}>
            <Form
              data={sportObject}
              id={id}
              onSubmit={this.onSubmit}
              loading={isFetching}
            />
          </Box>
          {id !== 'new' && (
            <Box
              subtitle={
                <Button
                  primary
                  onClick={() => {
                    this.props.history.push(
                      `/admin/${appspace}/sport-objects/${id}/sport-grounds/new`,
                    );
                  }}
                >
                  {__('Pridať športovisko')}
                </Button>
              }
              title={__('Zoznam športovísk')}
            >
              <BasicTable
                columns={[
                  {
                    header: __('Označenie športoviska'),
                  },
                  {
                    header: __('Typ športoviska'),
                  },
                ]}
                rowKey="_id"
                rows={(sportObject && sportObject.sportGrounds) || []}
                renderRow={(i: SportGround) => [
                  i.name,
                  this.getSportGroundLabel(i.sportGroundType),
                ]}
                onClickRow={(i: SportGround) => {
                  this.props.history.push(
                    `/admin/${appspace}/sport-objects/${id}/sport-grounds/${i._id}`,
                  );
                }}
              />
            </Box>
          )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(SportObjectDetail));
