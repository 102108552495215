import Header from '@sportnet/ui/Header';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { endOfDay, format, startOfDay } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import api from '../../api';
import { __ } from '../../utilities';
import styled from 'styled-components';
import { Match } from '../Matches/definitions';
import ContextBar, { ContextBarItem } from '@sportnet/ui/ContextBar';
import Paginator from '@sportnet/ui/Paginator';
import Filter from '@sportnet/ui/Filter';

const CompetitionName = styled.div`
  * {
    margin-bottom: 0;
  }
`;

const LIST_LIMIT = 20;

const closedOptions = [
  {
    value: '1',
    label: __('Uzavreté'),
  },
  {
    value: '0',
    label: __('Neuzavreté'),
  },
];

const MatchManagerDashboard: React.FC<RouteComponentProps<{}>> = ({
  history: { push },
}) => {
  const [offset, setOffset] = React.useState(0);
  const [matches, setMatches] = React.useState<Match[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  const [query, setQuery] = React.useState<{ [key: string]: any }>({});

  const getData = React.useCallback(async () => {
    setIsFetching(true);
    try {
      const f: { [key: string]: any } = { offset, limit: LIST_LIMIT };

      if (query.dateFrom) {
        f['dateFrom'] = format(startOfDay(new Date(query.dateFrom)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      }
      if (query.dateTo) {
        f['dateTo'] = format(endOfDay(new Date(query.dateTo)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      }
      if ((query.closed || []).length === 1) {
        f['closed'] = !!Number(query.closed[0])
      }
      const res = await api.matchManagerGetMatches(f);
      setMatches(res.matches);
      setTotal(res.total);
    } catch (e: any) {
      alert(__('Nepodarilo sa načítať údaje'));
    } finally {
      setIsFetching(false);
    }
  }, [offset, query]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const transformedQuery = Object.keys(query).reduce((acc, k) => {
    if (k === 'closed') {
      const val: any = [];
      (query[k] || ([] as any)).forEach((value: string) => {
        const opt = value && closedOptions.find((i) => i.value === value);
        if (opt) {
          val.push(opt);
        }
      });
      return { ...acc, [k]: val };
    } else if (k === 'dateFrom' || k === 'dateTo') {
      return {
        ...acc,
        [k]: query[k] ? new Date(query[k] as string) : undefined,
      };
    }
    return acc;
  }, {});

  return (
    <ScrollLayout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__('Zoznam stretnutí')}</HeaderBar.Header>
          </HeaderBar>
          <Filter
            onChange={(values: any) => {
              const parameters: { [key: string]: any } = {};
              Object.keys(values).forEach((k) => {
                if (k === 'dateFrom' || k === 'dateTo') {
                  parameters[k] = values[k]
                    ? format(new Date(values[k]), 'yyyy-MM-dd HH:mm:ss')
                    : undefined;
                } else if (values[k] && values[k].length) {
                  parameters[k] = values[k].map((i: any) => i.value);
                } else {
                  parameters[k] = [];
                }
              });

              setQuery(parameters);
            }}
            value={transformedQuery}
            filters={[
              {
                type: 'select',
                name: 'closed',
                label: 'Stav',
                multiple: true,
                options: closedOptions,
              },
              {
                type: 'date',
                name: 'dateFrom',
                label: 'Dátum od',
                showYearDropdown: true,
              },
              {
                type: 'date',
                name: 'dateTo',
                label: 'Dátum do',
                showYearDropdown: true,
              },
            ]}
          />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            {total && (
              <Paginator
                limit={LIST_LIMIT}
                total={total}
                offset={offset as number}
                onChangeOffset={setOffset}
              />
            )}
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment loading={isFetching}>
        {matches.length > 0 ? (
          <Segment raised>
            <Table>
              <Thead>
                <Tr>
                  <Th width={200}>{__('Dátum a miesto konania')}</Th>
                  <Th>{__('Súťaž')}</Th>
                  <Th>{__('Účastníci')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {matches.map((match) => {
                  return (
                    <Tr
                      key={`${match._id}`}
                      onClick={() => {
                        push(
                          `/match-manager/competitions/${match.competition._id}/parts/${match.competitionPart._id}/matches/${match._id}`,
                        );
                      }}
                    >
                      <Td>
                        <CompetitionName>
                          <Header
                            size="xs"
                            {...(match.sportGround && {
                              subtitle: match.sportGround.name,
                            })}
                          >
                            {format(
                              new Date(match.startDate),
                              'dd.MM.yyyy HH:mm',
                            )}
                          </Header>
                        </CompetitionName>
                      </Td>
                      <Td>
                        <CompetitionName>
                          <Header
                            size="xs"
                            subtitle={match.competitionPart.name}
                          >
                            {match.competition.name}
                          </Header>
                        </CompetitionName>
                      </Td>
                      <Td>
                        {(match.teams || []).map((t) => (
                          <>
                            {t.displayName}
                            <br />
                          </>
                        ))}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        ) : (
          <NotFound
            icon="error"
            title={__('Žiadne najbližšie udalosti')}
          />
        )}
      </Segment>
    </ScrollLayout>
  );
};

export default MatchManagerDashboard;
