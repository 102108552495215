import * as React from 'react';
import { getProp } from '../../utilities';
import { NominationItem, User } from '.';
import { Athlete } from '../Teams/definitions';
import BeachFootballNomination from './BeachFootball';
import FutbalNomination from './Futbal';
import FutsalNomination from './Futsal';
import MinifootballNomination from './Minifootball';
import WaterPoloNomination from './WaterPolo';

const FORM_NAME = 'NOMINATION_FORM';

interface OwnProps {
  // dispatch: ThunkDispatch<any, any, AnyAction>;
  data: NominationItem;
  experts: User[];
  settings: any;
  competitionId: string | null;
  partId: string | null;
  handleSubmit: (formData: any) => Promise<void>;
  rules?: null | {
    sport_sector?: string;
  };
  isClubManager?: boolean;
  isMatchManager?: boolean;
}

class NominationForm extends React.PureComponent<OwnProps> {
  renderNomination = (sportSector: string) => {
    const {
      experts,
      settings,
      data: { closed },
      competitionId,
      partId,
      isClubManager,
      isMatchManager,
    } = this.props;
    const nominationProps = {
      initialValues: {
        ...(this.props.data || {}),
        athletes: getProp(this.props, ['data', 'athletes'], []).reduce(
          (acc: { [key: string]: Athlete }, athlete: Athlete) => {
            return { ...acc, [athlete.sportnetUser._id]: athlete };
          },
          {},
        ),
      },
      handleSubmit: this.props.handleSubmit,
      closed: closed || false,
      experts,
      competitionId,
      partId,
      settings,
      sportSector,
      form: FORM_NAME,
      isClubManager,
      isMatchManager,
    };
    switch (sportSector) {
      case 'water-polo':
        return <WaterPoloNomination {...nominationProps} />;
      case 'futbal':
        return <FutbalNomination {...nominationProps} />;
      case 'futsal':
        return <FutsalNomination {...nominationProps} />;
      case 'beachfutbal':
        return <BeachFootballNomination {...nominationProps} />;
      case 'minifootball':
        return <MinifootballNomination {...nominationProps} />;
      default:
        return null;
    }
  };
  render() {
    const { rules } = this.props;
    if (rules && rules.sport_sector) {
      return this.renderNomination(rules.sport_sector);
    }
    return null;
  }
}
export default NominationForm;
