import Loader from '@sportnet/ui/Loader';
import TheLayout from '@sportnet/ui/TheLayout';
import {
  INavigationItem,
  INavigationSubItem,
} from '@sportnet/ui/TheLayout/Navigation';
import { IApp, IAppSpace } from '@sportnet/ui/TheLayout/types';
import CmsConnector from '@sportnet/cms-connector';
import Cookies from 'js-cookie';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sportnetApi from '../../sportnetApi';
import styled, { withTheme } from 'styled-components';
import {
  activeAppspaceNameSelector,
  activeAppspaceSelector,
  applicationInfoSelector,
  appspacesSelector,
  appsSelector,
  authUserSelector,
  breadcrumbsSelector,
} from '../../containers/App/selectors';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface OwnProps {
  children: React.ReactNode;
  topFixed?: JSX.Element;
  bottomFixed?: JSX.Element;
  isFetching?: boolean | null;
  withoutMenu?: boolean;
}

const mapStateToProps = (state: State) => ({
  appspace: activeAppspaceSelector(state),
  appspaces: appspacesSelector(state),
  organizationName: activeAppspaceNameSelector(state),
  application: applicationInfoSelector(state),
  breadcrumbs: breadcrumbsSelector(state),
  user: authUserSelector(state),
  apps: appsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{}> &
  OwnProps &
  IMapStateToProps & { theme: any } & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  };

class Layout extends React.PureComponent<
  Props,
  {
    sidebarVisible: boolean;
    menuItems: INavigationItem[];
    cmsConnectorOpened: boolean;
  }
> {
  static contextTypes = {
    logout: PropTypes.func,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      cmsConnectorOpened: false,
      menuItems: [],
    };
  }

  componentDidMount() {
    this.setMenuItems();
  }

  setMenuItems = async () => {
    const menuItems: any[] = [
      {
        url: this.getMenuItemUrl('sport-objects'),
        label: __('Športové objekty'),
        subitems: [],
      },
      {
        url: this.getMenuItemUrl('teams'),
        label: __('Družstvá'),
        subitems: [],
      },
      {
        url: this.getMenuItemUrl('competitions'),
        label: __('Súťaže'),
        subitems: [],
      },
      {
        url: this.getMenuItemUrl('matches'),
        label: __('Stretnutia'),
        subitems: [],
      },
      {
        url: this.getMenuItemUrl('codelists/seasons'),
        label: __('Nastavenia'),
        subitems: [
          {
            url: this.getMenuItemUrl('domain'),
            label: __('Nastavenia domény'),
          },
          {
            url: this.getMenuItemUrl('public-settings'),
            label: __('Nastavenia verejnej časti'),
          },
          {
            url: this.getMenuItemUrl('codelists/seasons'),
            label: __('Sezóny'),
          },
          {
            url: this.getMenuItemUrl('codelists/age-categories'),
            label: __('Vekové kategórie'),
          },
          {
            url: this.getMenuItemUrl('records'),
            label: __('Kniha rekordov'),
          },
        ],
      },
    ];
    try {
      if (this.props.appspace) {
        const res = await sportnetApi.appgrantGetRoles(
          'sutaze',
          this.props.appspace.app_space,
        );
        const userAppGrants = (res.users || []).find(
          (u) => u.user_id === this.props.user!._id,
        );
        if (userAppGrants) {
          const cmsAppGrant = (userAppGrants.child_apps_role || []).find(
            (a) => a.app_id === 'cms',
          );
          if (cmsAppGrant && cmsAppGrant.role) {
            menuItems.push({
              onClick: () => {
                this.setState({
                  cmsConnectorOpened: true,
                });
              },
              label: __('Správa obsahu'),
              subitems: [],
            });
          }
        }
      }
    } catch (e: any) {
      //
    } finally {
      this.setState({ menuItems });
    }
  };

  getMenuItemUrl = (menuItemUrl: string) => {
    const { appspace } = this.props;
    if (appspace) {
      return `/admin/${appspace.app_space}/${menuItemUrl}`;
    }
    return menuItemUrl;
  };

  render() {
    const {
      bottomFixed,
      children,
      organizationName,
      isFetching,
      appspace,
      application,
      topFixed,
      user,
      withoutMenu,
      appspaces,
    } = this.props as Props;

    const accessToken =
      Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';

    const settings = {
      ...(!withoutMenu && { navigation: this.state.menuItems }),
      renderNavigationLink: (renderProps: {
        item: INavigationItem | INavigationSubItem;
        linkProps: {
          className: string;
          onClick?: () => void;
          children?: React.ReactNode;
        };
      }) => {
        const {
          item: { url },
          linkProps,
        } = renderProps;

        return (
          <Link to={url || ''} {...linkProps}>
            {linkProps.children}
          </Link>
        );
      },
      bottom: <div />,
      accessToken,
      top: <div />,
      name: appspace && appspace.display_name,
      app: application ? { ...application, appSpaceIds: [] } : undefined,
      apps: (this.props.apps || []) as IApp[],
      onLogout: (this.context as any).logout,
      appSpace: (appspace as IAppSpace) || void 0,
      appSpaces: (appspaces || []) as IAppSpace[],
      userOrganization: organizationName || '',
      userOrganizationProfile: appspace ? appspace.org_profile.name : '',
      userName: user ? `${user.name} ${user.surname}` : 'Používateľ aplikácie',
      userPictureSrc:
        user && user.photo && user.photo.public_url
          ? user.photo.public_url
          : undefined,
      notificationCount: user ? user.messages : 0,
      // crumbs: [...basicCrumbs, ...breadcrumbs]
    };
    if (topFixed) {
      settings.top = topFixed;
    }
    if (bottomFixed) {
      settings.bottom = bottomFixed;
    }

    let cmsConnector;

    if (appspace) {
      cmsConnector = (
        <CmsConnector
          opened={this.state.cmsConnectorOpened}
          appSpace={appspace.app_space}
          appId={'sutaze'}
          accessToken={accessToken}
          onClose={() => {
            this.setState({
              cmsConnectorOpened: false,
            });
          }}
        />
      );
    }

    let content = children;
    if (this.state.cmsConnectorOpened) {
      content = cmsConnector;
    } else if (this.state.cmsConnectorOpened || isFetching) {
      content = (
        <LoaderWrapper>
          <Loader theme={this.props.theme} size="xl" />
        </LoaderWrapper>
      );
    }

    return <TheLayout {...settings}>{content}</TheLayout>;
  }
}

export default connect(mapStateToProps)(withRouter(withTheme(Layout)));
