import { format } from 'date-fns';
import skLocale from 'date-fns/locale/sk';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from '@sportnet/utilities';
import styled, { css } from 'styled-components';
import TeamComponent from './Team';
import { Match as IMatch } from 'src/containers/Matches/definitions';
import { ISportSectorPhase } from 'src/containers/Protocols/timeline';
import {
  cleanRoundName,
  getTeam,
  TeamSide,
} from '@sportnet/content/view/widgets/MatchesList/utilities';
import { __ } from 'src/utilities';

interface IOwnProps {
  match: IMatch;
  matchSize?: number;
  lastIndex: boolean;
  theme: any;
  sportSectorsPhases: ISportSectorPhase[];
  fullWidth: boolean;
  compact?: boolean;
  verbose?: boolean;
  settings: IMatch['settings'];
  backgroundImage?: string;
}

interface IMatchWrapperProps {
  lastIndex: boolean;
  matchSize?: number;
  fullWidth: boolean;
  compact: boolean;
  verbose: boolean;
  backgroundImage?: string;
}

export const MatchWrapper = styled.div<IMatchWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ matchSize }) => {
    if (matchSize) {
      return css`
        width: ${rem(matchSize)};
      `;
    }
    return css`
      width: 100%;
    `;
  }};
  height: 100%;
  ${({ lastIndex, fullWidth, compact, theme }) => {
    if (compact) {
      return css`
        width: 100%;
      `;
    }
    if (!lastIndex && !fullWidth) {
      return css`
        border-right: ${rem(1)} solid ${theme.separatorColor};
      `;
    }
    return css``;
  }}
  ${({ verbose, theme }) => {
    if (verbose) {
      return css`
        position: relative;
        padding: ${rem(20)};
        margin: auto;
        .content-widget-matchOverview-teamLogo {
          max-height: ${rem(150)};
          max-width: 100%;
        }
        .content-widget-matchOverview-teamName {
          font-size: ${rem(14)};
          padding-top: ${rem(10)};
        }
        ${LiveScore} {
          font-size: ${rem(24)};
        }
        ${MatchStatusIndicator} {
          font-size: ${rem(10)};
        }
        ${GoalsByPhases} {
          font-size: ${rem(11)};
        }
        ${MatchDate} {
          display: block;
        }
        ${MatchTime} {
          display: block;
        }
      `;
    }
    return css``;
  }}
`;

export const LiveScore = styled.div<{ compact: boolean }>`
  font-size: ${rem(16)};
  padding: ${rem(5)} ${rem(10)};
  width: 40%;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ compact }) => {
    if (compact) {
      return css`
        width: 20%;
      `;
    }
    return css``;
  }}
`;
export const MatchStatusIndicator = styled.div<{ color: string }>`
  font-size: ${rem(9)};
  color: ${({ color }) => color};
  text-transform: uppercase;
  padding-top: ${rem(5)};
`;
export const LiveTime = styled.div`
  font-size: ${rem(10)};
  color: ${({ theme }) => theme.color.danger};
  font-weight: normal;
`;
export const GoalsByPhases = styled.div`
  font-size: ${rem(10)};
  font-weight: normal;
`;
const MatchDate = styled.div`
  font-weight: 300;
  font-size: ${rem(14)};
  display: block;
`;
const MatchTime = styled.div`
  font-size: ${rem(12)};
  font-weight: 400;
  display: block;
`;
const MatchSportGround = styled.div`
  font-size: ${rem(12)};
  font-weight: 300;
`;

export type Props = IOwnProps;

const MatchWrapperLink = styled.a<{ isLive: boolean }>`
  width: 100%;
  display: flex;
  text-decoration: none;
  color: ${({ isLive, theme }) => (isLive ? theme.color.danger : '#333')};
`;

const MatchWrapperDiv = styled.div<{ isLive: boolean }>`
  width: 100%;
  display: flex;
  color: ${({ isLive, theme }) => (isLive ? theme.color.danger : '#333')};
`;

const MatchDetailLink = ({
  href,
  isLive,
  children,
}: {
  href: string;
  isLive?: boolean;
  children: any;
}) => {
  if (href) {
    return (
      <MatchWrapperLink
        href={href}
        isLive={!!isLive}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </MatchWrapperLink>
    );
  }
  return <MatchWrapperDiv isLive={!!isLive}>{children}</MatchWrapperDiv>;
};

class Match<T extends Props> extends React.PureComponent<T> {
  getTeamsIds = () => {
    const { match } = this.props;
    const homeTeam = getTeam(match as any, TeamSide.HOME);
    const homeTeamId = homeTeam ? homeTeam._id : '';
    const awayTeam = getTeam(match as any, TeamSide.AWAY);
    const awayTeamId = awayTeam ? awayTeam._id : '';

    return { homeTeamId, awayTeamId };
  };

  getShootoutGoals = () => {
    const { match } = this.props;
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (match.protocol) {
      homeTeamGoals = (match.protocol.events || []).reduce(
        (acc: any, e: any) => {
          if (
            e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals,
      );
      awayTeamGoals = (match.protocol.events || []).reduce(
        (acc: any, e: any) => {
          if (
            e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals,
      );
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  getGoalsByTeams = () => {
    const { match } = this.props;
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (match.contumationScore && match.contumationScore.length) {
      homeTeamGoals = match.contumationScore[0];
      awayTeamGoals = match.contumationScore[1];
    } else if (match.score && match.score.length) {
      homeTeamGoals = match.score[0];
      awayTeamGoals = match.score[1];
    } else if (match.protocol) {
      homeTeamGoals = (match.protocol.events || []).reduce(
        (acc: any, e: any) => {
          if (
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.phase !== 'shootout' &&
              e.type !== 'dropped') ||
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals,
      );
      awayTeamGoals = (match.protocol.events || []).reduce(
        (acc: any, e: any) => {
          if (
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.phase !== 'shootout' &&
              e.type !== 'dropped') ||
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals,
      );
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  getGoalsByMatchPart = (events: any[]) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();

    return events.reduce(
      (
        acc: any,
        event: {
          eventType: string;
          type: string;
          team: string;
          phase: string;
        },
      ) => {
        if (event.eventType === 'goal') {
          const otherTeamId =
            event.team === homeTeamId ? awayTeamId : homeTeamId;
          if (event.type === 'dropped') {
            return {
              ...acc,
              [event.phase]: {
                ...acc[event.phase],
                [otherTeamId]:
                  (acc[event.phase] ? acc[event.phase][otherTeamId] || 0 : 0) +
                  1,
              },
            };
          }
          return {
            ...acc,
            [event.phase]: {
              ...acc[event.phase],
              [event.team]:
                (acc[event.phase] ? acc[event.phase][event.team] || 0 : 0) + 1,
            },
          };
        }
        return acc;
      },
      this.props.sportSectorsPhases.reduce((acc, phase) => {
        if (getProp(this.props, ['match', 'timer', phase._id])) {
          return {
            ...acc,
            [phase._id]: {
              [homeTeamId]: 0,
              [awayTeamId]: 0,
            },
          };
        }
        return acc;
      }, {}),
    );
  };

  renderAdditionalScoreInfo = () => {
    const { match } = this.props;
    if (!match.scoreByPhases) {
      return null;
    }

    // ak sa zapas v riadnom hracom case skoncil 0:0, nezobrazujeme polcasovy vysledok
    const HTsGoalsCount =
      getProp(match.scoreByPhases, [0, 0], 0) +
      getProp(match.scoreByPhases, [0, 1], 0) +
      getProp(match.scoreByPhases, [1, 0], 0) +
      getProp(match.scoreByPhases, [1, 1], 0);

    const ETsGoalsCount =
      getProp(match.scoreByPhases, [2, 0], 0) +
      getProp(match.scoreByPhases, [2, 1], 0) +
      getProp(match.scoreByPhases, [3, 0], 0) +
      getProp(match.scoreByPhases, [3, 1], 0);

    // ak sa zapas skoncil v riadnom hracom case a zapas skoncil 0:0, nezobrazujeme polcasovy vysledok
    if (!HTsGoalsCount && match.scoreByPhases.length === 2) {
      return null;
    }

    // ak sa zapas skoncil v riadnom hracom case a zapas neskoncil 0:0, zobrazime vysledok po prvom polcase
    if (HTsGoalsCount && match.scoreByPhases.length === 2) {
      return (
        <>
          <GoalsByPhases>({match.scoreByPhases[0].join(':')})</GoalsByPhases>
        </>
      );
    }

    // ak sa zapas skoncil po predlzeni alebo penaltach, zobrazime vysledok po fulltime a po predlzeni
    if (HTsGoalsCount + ETsGoalsCount > 0 && match.scoreByPhases.length === 4) {
      const firstTeamHTsScore =
        match.scoreByPhases[0][0] + match.scoreByPhases[1][0];
      const secondTeamHTsScore =
        match.scoreByPhases[0][1] + match.scoreByPhases[1][1];

      const firstTeamETsScore =
        match.scoreByPhases[2][0] + match.scoreByPhases[3][0];
      const secondTeamETsScore =
        match.scoreByPhases[2][1] + match.scoreByPhases[3][1];

      return (
        <>
          <GoalsByPhases>
            ({firstTeamHTsScore}:{secondTeamHTsScore}, {firstTeamETsScore}:
            {secondTeamETsScore})
          </GoalsByPhases>
        </>
      );
    }

    // ak sa zapas skoncil penaltami a 1HT, 2ET aj ET bez golov, nezobrazime nic
    return null;
  };

  renderContumatedMatchScore = (match: IMatch) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    if (match.contumation && match.contumation.advantage) {
      return (
        <div>
          {match.contumation.advantage === homeTeamId
            ? match.settings!.contumation.score
            : 0}{' '}
          -{' '}
          {match.contumation.advantage === awayTeamId
            ? match.settings!.contumation.score
            : 0}
        </div>
      );
    }
    return null;
  };

  renderContumatedMatch = (match: IMatch) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();

    const goalsByMatchPart = this.getGoalsByMatchPart(
      (match.protocol || { events: [] }).events,
    );
    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const penaltyPhase = this.props.sportSectorsPhases.find(
      (i) => i._id === 'shootout',
    );

    return (
      <>
        {match.contumation && match.contumation.keepScore ? (
          <>
            <div>{`${homeTeamGoals} - ${awayTeamGoals}`}</div>
            <GoalsByPhases>
              (
              {Object.keys(goalsByMatchPart)
                .map((phase) => {
                  return `${goalsByMatchPart[phase][homeTeamId] || 0}:${
                    goalsByMatchPart[phase][awayTeamId] || 0
                  }`;
                })
                .join(', ')}
              )
            </GoalsByPhases>
            {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
              <GoalsByPhases>
                {!!penaltyPhase && penaltyPhase.label}
                <br />
                {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
              </GoalsByPhases>
            )}
          </>
        ) : (
          this.renderContumatedMatchScore(match)
        )}
        <MatchStatusIndicator color={this.props.theme.color.warning}>
          Kontumované
        </MatchStatusIndicator>
      </>
    );
  };

  renderClosedMatch = (match: IMatch) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();
    const goalsByMatchPart = this.getGoalsByMatchPart(
      (match.protocol || { events: [] }).events,
    );

    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const penaltyPhase = this.props.sportSectorsPhases.find(
      (i) => i._id === 'shootout',
    );

    return (
      <>
        <div>{`${homeTeamGoals} - ${awayTeamGoals}${
          getProp(match, ['rules', 'sport_sector']) === 'futbal' &&
          getProp(match, ['scoreByPhases'], []).length === 4 &&
          !homeTeamPenaltyGoals &&
          !awayTeamPenaltyGoals
            ? ' pp'
            : ''
        }`}</div>
        {getProp(match, ['rules', 'sport_sector']) === 'futbal' ? (
          this.renderAdditionalScoreInfo()
        ) : (
          <>
            <GoalsByPhases>
              (
              {Object.keys(goalsByMatchPart)
                .map((phase) => {
                  return `${goalsByMatchPart[phase][homeTeamId] || 0}:${
                    goalsByMatchPart[phase][awayTeamId] || 0
                  }`;
                })
                .join(', ')}
              )
            </GoalsByPhases>
            {match.timer &&
              (match.timer.OT || (match.timer['1ET'] && match.timer['2ET'])) &&
              !match.timer.shootout && (
                <GoalsByPhases>{__('Po predĺžení')}</GoalsByPhases>
              )}
          </>
        )}
        {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
          <GoalsByPhases>
            {!!penaltyPhase && penaltyPhase.label}
            <br />
            {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
          </GoalsByPhases>
        )}
        <MatchStatusIndicator color={this.props.theme.color.success}>
          Ukončené
        </MatchStatusIndicator>
        {match.protocol && match.protocol.audience && (
          <GoalsByPhases>{`${match.protocol.audience} ${__(
            'divákov',
          )}`}</GoalsByPhases>
        )}
      </>
    );
  };

  renderFutureMatch = (match: IMatch) => {
    return (
      <>
        <div>{format(new Date(match.startDate), 'HH:mm')}</div>
        {!this.props.verbose && (
          <MatchStatusIndicator color={this.props.theme.inactiveColor}>
            {format(new Date(match.startDate), 'HH:mm')}
          </MatchStatusIndicator>
        )}
      </>
    );
  };

  getMatchState = (match: IMatch) => {
    let content = null;
    let isFutureMatch = false;
    if (match.contumation && match.contumation.isContumated) {
      content = this.renderContumatedMatch(match);
    } else if (match.closed) {
      content = this.renderClosedMatch(match);
    } else if (!match.closed) {
      content = this.renderFutureMatch(match);
      isFutureMatch = true;
    }
    if (this.props.verbose) {
      return (
        <LiveScore compact={!!this.props.compact}>
          <MatchDate>
            {format(new Date(match.startDate), 'dd.MM.yyyy', {
              locale: skLocale,
            })}
          </MatchDate>
          {!isFutureMatch && (
            <MatchTime>{format(new Date(match.startDate), 'HH:mm')}</MatchTime>
          )}
          {match.round && match.round.name && (
            <MatchTime>{cleanRoundName(match.round.name)}</MatchTime>
          )}
          {!!match.sportGround && (
            <MatchSportGround>
              {match.sportGround.sportObjectName}
            </MatchSportGround>
          )}
          {content}
        </LiveScore>
      );
    }
    return <LiveScore compact={!!this.props.compact}>{content}</LiveScore>;
  };

  render() {
    const { match, lastIndex, fullWidth, compact, verbose, backgroundImage } =
      this.props;

    const homeTeam = getTeam(match as any, TeamSide.HOME);
    const awayTeam = getTeam(match as any, TeamSide.AWAY);

    const content = (
      <MatchWrapper
        fullWidth={fullWidth}
        lastIndex={lastIndex}
        matchSize={this.props.matchSize}
        compact={!!compact}
        verbose={!!verbose}
        backgroundImage={backgroundImage}
      >
        <MatchDetailLink href={''}>
          <TeamComponent
            matchAppSpace={match.appSpace}
            align="left"
            team={homeTeam as any}
            compact={!!compact}
          />
          {this.getMatchState(match)}
          <TeamComponent
            matchAppSpace={match.appSpace}
            align="right"
            team={awayTeam as any}
            compact={!!compact}
          />
        </MatchDetailLink>
        {/* {verbose && this.renderShootersOverview()} */}
      </MatchWrapper>
    );
    return content;
  }
}

export default Match;
