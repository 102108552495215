import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, compose } from 'redux';
import { submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';
import HomepageSectionIdForm from './form';

const FORM_NAME = 'SETTINGS_FORM';

const mapStateToProps = (state: State) => ({
  appSpace: activeAppspaceSelector(state),
});

const PublicSettings: React.FC<
  ReturnType<typeof mapStateToProps> & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  }
> = ({ dispatch, appSpace }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<{
    homepageSectionIdOrUniqId?: string;
  }>({});

  const loadSettings = React.useCallback(async () => {
    setIsFetching(true);
    try {
      const settings = await api.adminGetSettings(
        appSpace ? appSpace.app_space : '',
      );
      setInitialValues({
        homepageSectionIdOrUniqId: String(
          settings.homepageSectionIdOrUniqId || '',
        ),
      });
    } catch (e: any) {
      alert(__('Nepodarilo sa získať nastavenia'));
    } finally {
      setIsFetching(false);
    }
  }, [appSpace]);

  React.useEffect(() => {
    loadSettings();
  }, [appSpace, loadSettings]);

  const submitForm = async (values: {
    homepageSectionIdOrUniqId?: { value: string };
  }) => {
    setIsSubmitting(true);
    try {
      await api.adminEditSettings(
        appSpace ? appSpace.app_space : '',
        {},
        {
          homepageSectionIdOrUniqId: values.homepageSectionIdOrUniqId
            ? values.homepageSectionIdOrUniqId.value
            : '',
        },
      );
    } catch (e: any) {
      alert(__('Nastavenia sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Nastavenia verejnej časti')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() => {
                dispatch(submit(FORM_NAME));
              }}
              primary
              loading={isSubmitting}
            >
              {__('Uložiť')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment>
        <Segment raised>
          <HomepageSectionIdForm
            initialValues={initialValues}
            onSubmit={submitForm}
            form={FORM_NAME}
          />
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(mapStateToProps))(PublicSettings);
