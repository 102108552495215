import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Icon from '@sportnet/ui/Icon';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { reset, submit, SubmissionError } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import Box from '../../components/Box';
import { activeAppspaceSelector } from '../App/selectors';
import { BoxNote } from '..//Matches/form';
import { State } from '../../rootReducer';
import { getProp, __ } from '../../utilities';
import LoaderWrapper from '../../components/LoaderWrapper';
import {
  competitionByIdSelector,
  competitionPartByIdSelector,
} from '../Competitions/selectors';
import PlayerInvitationForm from '../Competitions/Squads/invitationForm';
import BeachFootballSquadPlayerForm from './Forms/BeachFootball/form';
import CyclingSquadPlayForm from './Forms/Cycling';
import FormulaOneSquadPlayForm from './Forms/FormulaOne';
import FutbalSquadPlayerForm, { FOOTBALL_POSITIONS } from './Forms/Futbal/form';
import FutsalSquadPlayerForm from './Forms/Futsal/form';
import MinifootballSquadPlayForm from './Forms/Minifootball/form';
import SwimmingSquadPlayForm from './Forms/Swimming';
import WaterPoloSquadPlayerForm from './Forms/WaterPolo/form';
import SmarttagsForm from './smarttagsForm';
import CrewSelectionForm from './squadCrewForm';

const SQUAD_PLAYER_FORM_NAME = 'SQUAD_PLAYER_FORM';
const SQUAD_PLAYER_INVITATION_FORM_NAME = 'SQUAD_PLAYER_INVITATION_FORM';
const CREW_USER_SELECTION_FORM_NAME = 'CREW_USER_SELECTION_FORM';

type Props = RouteComponentProps<{
  competitionId: string;
  teamId: string;
  partId: string;
  clubId: string;
}> & { dispatch: ThunkDispatch<any, any, AnyAction> };

const mapStateToProps = (state: State, props: Props) => {
  return {
    competition: competitionByIdSelector(props.match.params.competitionId)(
      state,
    ),
    competitionPart: competitionPartByIdSelector(
      props.match.params.competitionId,
      props.match.params.partId,
    )(state),
    activeAppSpace: activeAppspaceSelector(state),
  };
};

const SQUAD_SMARTTAGS_FORM_NAME = 'SQUAD_SMARTTAGS_FORM';

class CompetitionSquadDetail extends React.PureComponent<
  Props & ReturnType<typeof mapStateToProps>
> {
  state = {
    smarttags: [],
    isSubmittingSmarttags: false,
    crewUserCreationModalVisible: false,
    crewUserSelectionModalVisible: false,
    isSubmittingCrewUserCreation: false,
    isSubmittingCrewSelection: false,
    sport_sector: '',
    isRemovingCrewMember: null,
    squad: {
      athletes: [],
      crew: {},
    },
    rules: {},
    crewCodelist: [],
    isFetching: false,
    modalOpened: false,
    invitationModalOpened: false,
    userCreationModalOpened: false,
    isSubmittingInvitation: false,
    isSubmittingUserCreation: false,
    isSubmitting: false,
    isRemoving: null,
    activePlayer: null,
    activeCrewMember: null,
    teamCompetitions: [],
    squadCopyModalOpened: false,
    copySquadIsSubmitting: false,
  };
  componentDidMount() {
    this.getSquad();
    this.loadCrewCodelist();
  }

  loadCrewCodelist = async () => {
    const {
      match: {
        params: { competitionId, partId },
      },
    } = this.props;
    const part = await api.clubManagerGetCompetitionPartById(
      competitionId,
      partId,
    );
    const { items } = await api.getSettingBySportSector(
      'sport_sector_crew',
      part.rules ? part.rules.sport_sector : '',
    );
    this.setState({
      crewCodelist: items.map((i) => ({
        _id: i._id,
        label: i.label,
        competenceTypes: getProp(i, ['payload', 'acceptedCompetenceTypes'], []),
      })),
      rules: part.rules || {},
    });
  };

  getSquad = async (props = this.props) => {
    const {
      match: {
        params: { clubId, competitionId, partId, teamId },
      },
    } = props;
    this.setState({
      isFetching: true,
    });
    try {
      const { smarttagsByAppSpace = [] } = await api.clubManagerGetCompetition(
        competitionId,
      );
      const team = await api.clubManagerGetCompetitionPartTeamSquad(
        competitionId,
        partId,
        teamId,
      );
      const smarttags = smarttagsByAppSpace.find((i) => i.appSpace === clubId);

      this.setState({
        sport_sector: team.sport_sector,
        squad: team.squad,
        smarttags: smarttags ? smarttags.smarttags : [],
      });
    } catch (e: any) {
      //
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      modalOpened: !this.state.modalOpened,
    });
  };

  toggleCrewSelectionModal = () => {
    this.setState({
      crewUserSelectionModalVisible: !this.state.crewUserSelectionModalVisible,
    });
  };

  toggleInvitationModal = () => {
    this.setState({
      invitationModalOpened: !this.state.invitationModalOpened,
    });
  };

  toggleSquadCopyModal = () => {
    this.setState({
      squadCopyModalOpened: !this.state.squadCopyModalOpened,
    });
  };

  submitSquadForm = async (values: {
    sportnetId: { value: string };
    additionalData: {
      [key: string]: any;
    };
  }) => {
    const {
      match: {
        params: { clubId, competitionId, partId, teamId },
      },
      dispatch,
    } = this.props;
    if (!values.sportnetId) {
      throw new SubmissionError({ sportnetId: 'Pole je povinné' });
    }
    this.setState({
      isSubmitting: true,
    });
    try {
      if (this.state.activePlayer) {
        const team = await api.clubManagerEditPlayerInCompetitionPartTeamSquad(
          clubId,
          competitionId,
          partId,
          teamId,
          getProp(this.state.activePlayer || {}, ['sportnetId'], ''),
          undefined,
          {
            additionalData: values.additionalData,
          },
        );
        this.setState({
          squad: team.squad,
        });
      } else {
        const team = await api.clubManagerAddPlayerToCompetitionPartTeamSquad(
          competitionId,
          partId,
          teamId,
          undefined,
          {
            ...values,
            sportnetId: (values.sportnetId.value || values.sportnetId) as any,
          },
        );
        this.setState({
          squad: team.squad,
        });
      }
      dispatch(reset(SQUAD_PLAYER_FORM_NAME));
      this.toggleModal();
    } catch (e: any) {
      console.error(e);
      if (
        getProp(e, ['details', 'name'], '') === 'PLAYER_ALREADY_IN_TEAM_SQUAD'
      ) {
        alert(__('Hráč sa už na súpiske nachádza'));
      } else {
        alert(__('Vyskytla sa neočakávaná chyba'));
      }
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  submitCrewForm = async (values: {
    sportnetId: { value: string };
    position: string;
  }) => {
    const {
      match: {
        params: { competitionId, partId, teamId },
      },
      dispatch,
    } = this.props;
    if (!values.sportnetId) {
      throw new SubmissionError({ sportnetId: __('Pole je povinné') });
    }
    this.setState({
      isSubmittingCrewSelection: true,
    });
    try {
      if (this.state.activeCrewMember) {
        const team =
          await api.clubManagerEditCrewMemberInCompetitionPartTeamSquad(
            competitionId,
            partId,
            teamId,
            getProp(this.state.activeCrewMember || {}, ['sportnetId'], ''),
            undefined,
            {
              position: values.position,
            },
          );
        this.setState({
          squad: team.squad,
        });
      } else {
        const team =
          await api.clubManagerAddCrewMemberToCompetitionPartTeamSquad(
            competitionId,
            partId,
            teamId,
            undefined,
            {
              ...values,
              sportnetId: (values.sportnetId.value || values.sportnetId) as any,
            },
          );
        this.setState({
          squad: team.squad,
        });
      }
      dispatch(reset(CREW_USER_SELECTION_FORM_NAME));
      this.toggleCrewSelectionModal();
    } catch (e: any) {
      console.error(e);
      alert(__('Vyskytla sa neočakávaná chyba'));
    } finally {
      this.setState({
        isSubmittingCrewSelection: false,
      });
    }
  };

  renderForm = () => {
    const props = {
      form: SQUAD_PLAYER_FORM_NAME,
      onSubmit: this.submitSquadForm,
      initialValues: this.state.activePlayer || {},
      update: !!this.state.activePlayer,
    };
    switch (this.state.sport_sector) {
      case 'water-polo':
        return <WaterPoloSquadPlayerForm {...props} />;
      case 'futbal':
        return <FutbalSquadPlayerForm {...props} />;
      case 'futsal':
        return <FutsalSquadPlayerForm {...props} />;
      case 'beachfutbal':
        return <BeachFootballSquadPlayerForm {...props} />;
      case 'minifootball':
        return <MinifootballSquadPlayForm {...props} />;
      case 'swimming':
        return <SwimmingSquadPlayForm {...props} />;
      case 'cestna-cyklistika':
        return <CyclingSquadPlayForm {...props} />;
      case 'formula-1':
        return <FormulaOneSquadPlayForm {...props} />;
      default:
        return (
          <BoxNote>
            {__(
              'Pre dané športové odvetvie momentálne neexistuje možnosť nominácie.',
            )}
          </BoxNote>
        );
    }
  };

  renderTable = () => {
    const {
      match: {
        params: { clubId, competitionId, partId, teamId },
      },
    } = this.props;
    const commonProps = {
      rows: this.state.squad.athletes,
      onClickRow: (i: any) => {
        this.setState(
          {
            activePlayer: {
              sportnetId: i.sportnetUser._id,
              displayName: i.sportnetUser.name,
              additionalData: i.additionalData || {},
            },
          },
          () => {
            this.toggleModal();
          },
        );
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string) => (
      <Button
        loading={this.state.isRemoving === sportnetId}
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          if (
            window.confirm(__('Skutočne si želáte odstrániť hráča zo súpisky?'))
          ) {
            this.setState({
              isRemoving: sportnetId,
            });
            const team =
              await api.clubManagerRemovePlayerFromCompetitionPartTeamSquad(
                clubId,
                competitionId,
                partId,
                teamId,
                sportnetId,
                undefined,
              );
            this.setState({
              squad: team.squad,
              isRemoving: null,
            });
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );
    const getFootballPositions = (positionId: string) => {
      const p = FOOTBALL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    switch (this.state.sport_sector) {
      case 'water-polo':
      case 'swimming':
      case 'cestna-cyklistika':
      case 'formula-1':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              RemoveButton(member.sportnetUser._id),
            ]}
          />
        );
      case 'futbal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFootballPositions(member.additionalData.position),
              member.additionalData.nr,
              RemoveButton(member.sportnetUser._id),
            ]}
          />
        );
      case 'futsal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFootballPositions(member.additionalData.position),
              member.additionalData.nr,
              RemoveButton(member.sportnetUser._id),
            ]}
          />
        );
      case 'beachfutbal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              {
                header: __('Meno na drese'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFootballPositions(member.additionalData.position),
              member.additionalData.nr,
              member.additionalData.shirtname,
              RemoveButton(member.sportnetUser._id),
            ]}
          />
        );
      case 'minifootball':
        // const getMinifootballPosition = (positionId: string) => {
        //   const p = FOOTBALL_POSITIONS.find(
        //     position => position.value === positionId,
        //   );
        //   if (p) {
        //     return p.label;
        //   }
        //   return '';
        // };
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              // {
              //   header: __('Kapitán'),
              // },
              // {
              //   header: __('Pozícia'),
              // },
              // {
              //   header: __('Číslo dresu'),
              // },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser.name,
              // !!member.additionalData.captain && <Icon name="check" />,
              // getMinifootballPosition(member.additionalData.position),
              // member.additionalData.nr,
              RemoveButton(member.sportnetUser._id),
            ]}
          />
        );
      default:
        return null;
    }
  };

  renderCrewTable = () => {
    const {
      match: {
        params: { competitionId, partId, teamId },
      },
    } = this.props;
    const commonProps = {
      rows: Array.isArray(this.state.squad.crew || [])
        ? ((this.state.squad.crew || []) as any).map((crewMember: any) => {
            const codelistItem = (
              this.state.crewCodelist as Array<{
                _id: string;
                label: string;
              }>
            ).find((i) => i._id === crewMember.position);
            return {
              ...crewMember,
              positionLabel: codelistItem ? codelistItem.label : '',
            };
          })
        : Object.keys(this.state.squad.crew || {}).map((position) => {
            const codelistItem = (
              this.state.crewCodelist as Array<{
                _id: string;
                label: string;
              }>
            ).find((i) => i._id === position);
            return {
              ...this.state.squad.crew[position],
              position,
              positionLabel: codelistItem ? codelistItem.label : '',
            };
          }),
      onClickRow: (i: any) => {
        this.setState(
          {
            activeCrewMember: {
              sportnetId: i.sportnetUser._id,
              displayName: i.sportnetUser.name,
              position: i.position,
            },
          },
          () => {
            this.toggleCrewSelectionModal();
          },
        );
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string) => (
      <Button
        loading={this.state.isRemovingCrewMember === sportnetId}
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({
            isRemovingCrewMember: sportnetId,
          });
          if (
            window.confirm(__('Skutočne si želáte odstrániť osobu zo súpisky?'))
          ) {
            const team =
              await api.clubManagerRemoveCrewMemberFromCompetitionPartTeamSquad(
                competitionId,
                partId,
                teamId,
                sportnetId,
                undefined,
              );
            this.setState({
              squad: team.squad,
              isRemovingCrewMember: null,
            });
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );

    return (
      <BasicTable
        {...commonProps}
        columns={[
          {
            header: __('Pozícia'),
          },
          {
            header: __('Meno a priezvisko'),
          },
          { header: '', width: 100 },
        ]}
        renderRow={(member) => [
          member.positionLabel,
          member.sportnetUser.name,
          RemoveButton(member.sportnetUser._id),
        ]}
      />
    );
  };

  submitInvitationForm = async (values: { email: string }) => {
    const {
      match: {
        params: { clubId, competitionId, partId, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isSubmittingInvitation: true,
      });
      await api.clubManagerInvitePlayerToCompetitionPartTeamSquad(
        clubId,
        competitionId,
        partId,
        teamId,
        undefined,
        { email: values.email },
      );
      this.setState({
        invitationModalOpened: false,
      });
    } catch (e: any) {
      alert(__('Pozvánku sa nepodarilo odoslať'));
    } finally {
      this.setState({
        isSubmittingInvitation: false,
      });
    }
  };

  renderInvitationForm = () => (
    <PlayerInvitationForm
      form={SQUAD_PLAYER_INVITATION_FORM_NAME}
      onSubmit={this.submitInvitationForm}
    />
  );

  toggleCrewUserCreationForm = () =>
    this.setState({
      crewUserCreationModalVisible: !this.state.crewUserCreationModalVisible,
    });

  submitSmarttags = async (data: any) => {
    const {
      match: {
        params: { clubId, competitionId, partId },
      },
    } = this.props;
    this.setState({
      isSubmittingSmarttags: true,
    });
    try {
      const { smarttagsByAppSpace = [] } =
        await api.clubManagerUpdateCompetitionSmarttags(
          competitionId,
          partId,
          clubId,
          {},
          data,
        );
      const smarttags = smarttagsByAppSpace.find((i) => i.appSpace === clubId);
      this.setState({
        smarttags: smarttags ? smarttags.smarttags : [],
      });
    } catch (e: any) {
      alert(__('Nepodarilo sa uložiť smarttagy'));
    } finally {
      this.setState({
        isSubmittingSmarttags: false,
      });
    }
  };

  render() {
    const { isFetching, crewCodelist, isSubmittingSmarttags } = this.state;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>
              {__('Súpiska družstva v súťaži')}
            </HeaderBar.Header>
          </HeaderBar>
        }
      >
        <Segment>
          <Box title={__('Smarttagy')}>
            <SmarttagsForm
              onSubmit={this.submitSmarttags}
              appSpace={this.props.match.params.clubId}
              form={SQUAD_SMARTTAGS_FORM_NAME}
              initialValues={{ smarttags: this.state.smarttags }}
            />
            <Button
              primary
              loading={isSubmittingSmarttags}
              onClick={() =>
                this.props.dispatch(submit(SQUAD_SMARTTAGS_FORM_NAME))
              }
            >
              {__('Uložiť')}
            </Button>
          </Box>
          <Box title={__('Súpiska družstva v súťaži')}>
            <Button
              onClick={() => {
                this.setState(
                  {
                    activePlayer: null,
                  },
                  () => {
                    this.toggleModal();
                  },
                );
              }}
              primary
            >
              {__('Pridať hráča')}
            </Button>
            &nbsp;
            {getProp(this.state.rules || {}, ['loosePlayerSignup'], false) && (
              <>
                <Button
                  onClick={() => {
                    this.toggleInvitationModal();
                  }}
                  primary
                >
                  {__('Pozvať hráča')}
                </Button>
              </>
            )}
            <br />
            <br />
            {this.renderTable()}
          </Box>
          <Box title={__('Realizačný tým')}>
            <Button
              onClick={() => {
                this.setState(
                  {
                    activeCrewMember: null,
                  },
                  () => {
                    this.toggleCrewSelectionModal();
                  },
                );
              }}
              primary
            >
              {__('Pridať osobu')}
            </Button>
            <br />
            <br />
            {this.renderCrewTable()}
          </Box>
        </Segment>
        <Modal
          isOpen={this.state.invitationModalOpened}
          handleClose={this.toggleInvitationModal}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Pozvánka hráča do družstva')}
            </Header>
            {this.renderInvitationForm()}
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleInvitationModal}>
                {__('Zavrieť')}
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(
                    submit(SQUAD_PLAYER_INVITATION_FORM_NAME),
                  );
                }}
                primary
                loading={this.state.isSubmittingInvitation}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal isOpen={this.state.modalOpened} handleClose={this.toggleModal}>
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Pridanie hráča do družstva')}
            </Header>
            {this.state.modalOpened && this.renderForm()}
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleModal}>{__('Zavrieť')}</Button>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(SQUAD_PLAYER_FORM_NAME));
                }}
                primary
                loading={this.state.isSubmitting}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Modal
          isOpen={this.state.crewUserSelectionModalVisible}
          handleClose={this.toggleCrewSelectionModal}
        >
          <ModalContent>
            <Header size="s" withSeparator>
              {__('Pridanie člena realizačného tímu')}
            </Header>
            <CrewSelectionForm
              crewCodelist={crewCodelist}
              initialValues={this.state.activeCrewMember}
              onSubmit={this.submitCrewForm}
              update={this.state.activeCrewMember}
              form={CREW_USER_SELECTION_FORM_NAME}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={this.toggleCrewSelectionModal}>
                {__('Zavrieť')}
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(CREW_USER_SELECTION_FORM_NAME));
                }}
                primary
                loading={this.state.isSubmittingCrewSelection}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(CompetitionSquadDetail));
