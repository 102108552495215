import Avatar from '@sportnet/ui/AvatarForm/Avatar';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Cookies from 'js-cookie';
import MediaManagerConnector from '@sportnet/media-manager-connector';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import {
  change,
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import CmsApi, { Section } from '../../CmsApi';
import styled from 'styled-components';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import Box from '../../components/Box';
import { State } from '../../rootReducer';
import { getProp, required, __ } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';
import { Season } from '../Codelists/Seasons/definitions';
import { Competition } from './definitions';

export const FORM_NAME = 'COMPETITION_FORM';

const CenteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  & > div {
    justify-content: center;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const Aside = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  flex-basis: 180px;
`;

const Label = styled.strong`
  display: inline-block;
  margin-bottom: 1rem;
`;

const DeleteButton = styled(Button)`
  margin-top: 1rem;
`;

interface CustomFormData {
  name: string;
}

interface OwnProps {
  id: string;
  data: Competition | null;
  appspace: string;
  seasons: Season[];
  admins?: Administrator[];
  profileSportValues: {
    sport: any;
    sport_sector: any;
  } | null;
  suitableTeams: Array<{
    _id: string;
    name: string;
  }>;
}

interface Administrator {
  displayName: string;
  sportnetId: string;
}

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  const sport = getProp(props, ['profileSportValues', 'sport', 'value']);
  return {
    publication: selector(state, 'publication'),
    appSpace: activeAppspaceSelector(state),
    initialValues: {
      ...(!props.data ? { publication: { published: true } } : {}),
      ...props.data,
      sport,
      signup: false,
      season: props.data && props.data.season && props.data.season._id,
    },
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = InjectedFormProps<CustomFormData> &
  IMapStateToProps &
  OwnProps & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  };

class CompetitionForm extends React.PureComponent<Props> {
  state: {
    participantFormOpened: boolean;
    mediaManagerOpened: boolean;
    sections: Section[];
  } = {
      participantFormOpened: false,
      mediaManagerOpened: false,
      sections: [],
    };

  componentDidMount() {
    this.loadSections();
  }

  loadSections = async () => {
    const { tree = [] } = await CmsApi.getAdminSectionsTree(
      'sutaze',
      this.props.appSpace ? this.props.appSpace.app_space : '',
      `competitionId|${this.props.id}`,
    );
    this.setState({ sections: tree });
  };
  render() {
    const accessToken =
      Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';
    const sport = getProp(this.props, ['profileSportValues', 'sport']);
    // const sportSectors = getProp(
    //   this.props,
    //   ['profileSportValues', 'sport_sector'],
    //   [],
    // );
    // let isAnyPartSignable = false;
    // getProp(data || {}, ['parts'], []).forEach((part: CompetitionPart) => {
    //   if (part.signup) {
    //     isAnyPartSignable = true;
    //   }
    // });

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Box title={__('Základné informácie')}>
          <Row>
            <Col xs={12} s={3}>
              <Field
                name="name"
                validate={[required]}
                component={FormField}
                required
                label={__('Názov súťaže')}
              />
            </Col>
            <Col xs={12} s={3}>
              <Field
                name="identifier"
                component={FormField}
                label={__('Identifikátor súťaže')}
              />
            </Col>
            <Col xs={12} s={3}>
              <Field
                name="season"
                validate={[required]}
                component={FormField}
                type="select"
                required
                label={__('Sezóna')}
              >
                <option hidden />
                {this.props.seasons.map((item: Season) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Field>
            </Col>
            <Col xs={12} s={3}>
              <Field
                name="sport"
                component={FormField}
                disabled
                label={__('Šport')}
                required
                format={() => {
                  return getProp(sport, ['label'], '');
                }}
              />
            </Col>
            <Col xs={3}>
              <Field
                name="aggregatedPlayerStats"
                component={FormField}
                type="checkbox"
                label={__('Povoliť agregovanú štatistiku hráčov')}
              />
            </Col>
            <Col xs={3}>
              <Field
                name="interstate"
                component={FormField}
                type="checkbox"
                info={__('Reprezentačné súťaže by mali mať nastavenú hodnotu `true`.')}
                label={__('Medzištátna súťaž')}
              />
            </Col>
            <Col xs={12}>
              <Field
                name="note"
                component={FormField}
                type="textarea"
                label={__('Poznámky')}
              />
            </Col>
            <Col xs={12}>
              <Field
                name="homepageSectionIdOrUniqId"
                component={FormField}
                label={__('Sekcia pre hlavnú stránku')}
                type="theselect"
                options={this.state.sections.map((s) => ({
                  label: s.name || '',
                  value: s._id || '',
                }))}
                format={(i: string | { _id: string; name: string }) => {
                  if (typeof i === 'string' || typeof i === 'number') {
                    const option = this.state.sections.find(
                      (s) => s && String(s._id) === String(i),
                    );
                    if (option) {
                      return {
                        value: option._id || '',
                        label: option.name,
                      };
                    }
                  } else if (i) {
                    return i;
                  }
                  return '';
                }}
              />
            </Col>
          </Row>
        </Box>
        {this.props.id !== 'new' && (
          <Box title={__('Publikácia')}>
            {/* <DomainForm
              domain={getProp(this.props, ['data', 'domain'], '')}
              onSubmitForm={this.onSubmitForm}
              payload={{
                competitionId: getProp(this.props, ['data', '_id'], ''),
              }}
            /> */}
            <Row>
              <CenteredCol xs={12}>
                <Wrapper>
                  <Avatar
                    src={getProp(
                      this.props.publication,
                      ['logo', 'public_url'],
                      '',
                    )}
                  />
                  <Aside>
                    <Label>{__('Logo súťaže')}</Label>
                    <Button
                      basic
                      type="button"
                      onClick={() => {
                        this.setState({
                          mediaManagerOpened: true,
                        });
                      }}
                    >
                      {__('Vybrať logo')}
                    </Button>
                    {getProp(
                      this.props.publication,
                      ['logo', 'public_url'],
                      '',
                    ) && (
                        <DeleteButton
                          danger
                          type="button"
                          onClick={() => {
                            this.props.dispatch(
                              change(FORM_NAME, 'publication.logo', {}),
                            );
                          }}
                        >
                          {__('Odstrániť')}
                        </DeleteButton>
                      )}
                  </Aside>
                </Wrapper>
                <Field
                  name="publication.published"
                  component={FormField}
                  label={__('Publikovať')}
                  type="checkbox"
                />
              </CenteredCol>
            </Row>
            <MediaManagerConnector
              opened={this.state.mediaManagerOpened}
              appSpace={this.props.appspace}
              appId={process.env.REACT_APP_APP_ID as string}
              accessToken={accessToken}
              onClose={() => {
                this.setState({
                  mediaManagerOpened: false,
                });
              }}
              onError={(e: any) => {
                this.setState({
                  mediaManagerOpened: false,
                });
                throw e;
              }}
              onUnauthorized={() => {
                this.setState({
                  mediaManagerOpened: false,
                });
                throw Error('Media manager unauthorized');
              }}
              onSelectFiles={(e: any) => {
                this.props.dispatch(
                  change(FORM_NAME, 'publication.logo', {
                    filepath: e[0].filepath,
                    media_url: e[0].media_url,
                    public_url: e[0].public_url,
                  }),
                );
                this.setState({
                  mediaManagerOpened: false,
                });
              }}
            />
            {this.props.appSpace && (
              <TagmanagerConnector
                appspace={this.props.appSpace.app_space}
                appId={process.env.REACT_APP_APP_ID || ''}
                accessToken={accessToken}
              >
                {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
                  <Field
                    getSmarttagsValues={getSmarttagsValues}
                    getSmarttagsKeys={getSmarttagsKeys}
                    getSmarttagsByFts={getSmarttagsByFts}
                    name="smarttags"
                    type="smarttag"
                    label={__('Smarttagy')}
                    component={FormField}
                    placeholder={__('Zadajte Smart:tag a stlačte Enter')}
                  />
                )}
              </TagmanagerConnector>
            )}
          </Box>
        )}
      </Form>
    );
  }
}

const connected = reduxForm<CustomFormData, any>({
  form: FORM_NAME,
  enableReinitialize: true,
})(CompetitionForm);

export default connect(mapStateToProps)(connected);
