import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { activeAppspaceSelector } from '../../App/selectors';
import { State } from '../../../rootReducer';
import { __ } from '../../../utilities';

const mapStateToProps = (state: State) => ({
  appSpace: activeAppspaceSelector(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const TeamUpdateForm: React.FC<Props> = ({ appSpace, ...props }) => {
  const accessToken =
    Cookies.get(String(process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME)) || '';

  if (!appSpace) {
    return null;
  }
  return (
    <>
      <Field
        label={__('Logo družstva')}
        auth={{
          appId: process.env.REACT_APP_APP_ID,
          appSpace: appSpace.app_space,
          token: accessToken,
        }}
        component={FormFieldRedux}
        name="logo"
        type="image"
      />
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm({}),
)(TeamUpdateForm) as any;
