import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { getProp } from '../../utilities';
import { State } from '../../rootReducer';

const recordsDomainSelector = (state: State) => {
  return state.entities.records;
};

export const recordsListSelector = createSelector(
  recordsDomainSelector,
  (records) =>
    Object.keys(records).reduce((acc: any[], k) => [...acc, records[k]], []),
);

export const recordByIdSelector = (recordId: string) =>
  createSelector(recordsDomainSelector, (records) =>
    getProp(records, [recordId], {}),
  );

export const recordsSelector = (listName: string) =>
  createSelector(
    getListResults(listName),
    recordsDomainSelector,
    (results, objects) => {
      if (results) {
        const res = results.map((id: string) => {
          return objects[id];
        });
        return res;
      }
      return [];
    },
  );
