import Image from '@sportnet/ui/Image';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  FALLBACK_LOGO_IMAGE,
  getTeamLogoSrc,
} from '@sportnet/content/view/widgets/MatchesList/utilities';
import { Team } from 'src/containers/Teams/definitions';

interface ITeamProps {
  align: string;
  compact: boolean;
}

const TeamLogo = styled(Image)`` as any;

export const TeamName = styled.div`
  font-size: ${rem(14)};
  font-weight: bold;
  color: ${({ theme }) => (theme as any).app.textColor};
  text-transform: uppercase;
  padding: ${rem(10)};
  text-align: center;
`;

const TeamWrapper = styled.div`
  display: flex;
  width: 30%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

export const TeamItem = styled.div<ITeamProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ align, compact }) => {
    if (compact && align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: right;
        width: 40%;
        ${TeamName} {
          text-align: right !important;
        }
      `;
    } else if (compact && align === 'right') {
      return css`
        flex-direction: row;
        width: 40%;
        justify-content: left;
        ${TeamName} {
          text-align: left !important;
        }
      `;
    }
    return css``;
  }}
`;

const TeamLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: ${rem(80)};
  height: ${rem(80)};
  background: white;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  img {
    padding: ${rem(8)};
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TeamDetailLink = styled.a<ITeamProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ align, compact }) => {
    if (compact && align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: right;
        width: 40%;
        ${TeamName} {
          text-align: right !important;
        }
      `;
    } else if (compact && align === 'right') {
      return css`
        flex-direction: row;
        width: 40%;
        justify-content: left;
        ${TeamName} {
          text-align: left !important;
        }
      `;
    }
    return css``;
  }}
`;

interface IOwnProps {
  matchAppSpace: string;
  align: 'left' | 'right';
  team: Team | null;
  compact: boolean;
}

const TeamComponent: React.FC<IOwnProps> = ({
  matchAppSpace,
  align,
  team,
  compact,
}) => {
  const teamLogoSrc = getTeamLogoSrc(matchAppSpace, team as any);

  const renderTeam = () => (
    <>
      <TeamLogoWrapper>
        <TeamLogo
          className="content-widget-matchOverview-teamLogo"
          title={(team as any)?.organization.name}
          alt={(team as any)?.organization.name}
          src={teamLogoSrc}
          fallbackSrc={FALLBACK_LOGO_IMAGE}
        />
      </TeamLogoWrapper>
      <TeamName className="content-widget-matchOverview-teamName">
        {team?.name}
      </TeamName>
    </>
  );

  return (
    <TeamWrapper>
      {(team as any)?.detailUrl ? (
        <TeamDetailLink
          href={(team as any)?.detailUrl}
          align={align}
          compact={!!compact}
        >
          {renderTeam()}
        </TeamDetailLink>
      ) : (
        <TeamItem align={align} compact={!!compact}>
          {renderTeam()}
        </TeamItem>
      )}
    </TeamWrapper>
  );
};

export default React.memo(TeamComponent);
