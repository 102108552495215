import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { Field } from 'redux-form';
import {
  formatSecondsToString,
  formatStringToSeconds,
  isInteger,
  isNumber,
  required,
  __,
} from '../../../../utilities';

interface OwnProps {
  readOnly?: boolean;
  phases: Array<{
    _id: string;
    label: string;
  }>;
}

type Props = OwnProps;

class MinifootballCompetitionPartRulesForm extends React.PureComponent<Props> {
  render() {
    const { readOnly, phases } = this.props;
    return (
      <>
        <Segment
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Časomiera')}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12}>
              <Field
                component={FormField}
                name="settings.reverseTime"
                type="checkbox"
                label={__('Odpočítavanie času')}
                disabled={readOnly}
              />
            </Col>
            <Col xs={12}>
              <Field
                component={FormField}
                name="settings.overlapTime"
                type="checkbox"
                label={__(
                  'Pretekanie času (časomiera môže plynúť aj po uplynutí času)',
                )}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Segment>
        <Segment
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Fázy stretnutia')}
            </SegmentHeader>
          }
        >
          <Row>
            {phases.map((phase) => (
              <React.Fragment key={phase._id}>
                <Col xs={12} s={3}>
                  <Field
                    component={FormField}
                    name={`settings.phases.${phase._id}.startTime`}
                    label={`${phase.label} - ${__('Počiatočný čas')}`}
                    type="text"
                    required
                    validate={[isNumber]}
                    format={(i: number) => {
                      if (typeof i === 'number') {
                        return formatSecondsToString(i);
                      }
                      return i;
                    }}
                    normalize={(i: string) => {
                      const split = i.split(':');
                      if (
                        (split.length === 2 || split.length === 3) &&
                        split[0].length >= 2 &&
                        Number(split[1]) <= 59 &&
                        split[1].length === 2
                      ) {
                        return formatStringToSeconds(i);
                      }
                      return i;
                    }}
                    disabled={readOnly}
                  />
                </Col>
                <Col xs={12} s={3}>
                  <Field
                    component={FormField}
                    name={`settings.phases.${phase._id}.endTime`}
                    label={`${phase.label} - ${__('Konečný čas')}`}
                    type="text"
                    required
                    validate={[isNumber]}
                    format={(i: number) => {
                      if (typeof i === 'number') {
                        return formatSecondsToString(i);
                      }
                      return i;
                    }}
                    normalize={(i: string) => {
                      const split = i.split(':');
                      if (
                        (split.length === 2 || split.length === 3) &&
                        split[0].length >= 2 &&
                        Number(split[1]) <= 59 &&
                        split[1].length === 2
                      ) {
                        return formatStringToSeconds(i);
                      }
                      return i;
                    }}
                    disabled={readOnly}
                  />
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Segment>
        <Segment
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Nominácia')}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12} s={4}>
              <Field
                component={FormField}
                name="settings.players.field.min"
                label={__('Minimálny počet hráčov')}
                type="number"
                required
                validate={[required, isInteger]}
                disabled={readOnly}
              />
            </Col>
            <Col xs={12} s={4}>
              <Field
                component={FormField}
                name="settings.players.field.max"
                label={__('Maximálny počet hráčov')}
                type="number"
                required
                validate={[required, isInteger]}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Segment>
        <Segment
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Kontumácia')}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12}>
              <Field
                component={FormField}
                name="settings.contumation.score"
                type="number"
                label={__('Priznaný počet gólov pri kontumácii')}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Segment>
      </>
    );
  }
}

export default MinifootballCompetitionPartRulesForm;
