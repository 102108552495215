import * as React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import SwimmingSportGroundsForm from './FormsBySport/Swimming';

export const SPORT_GROUNDS_FORM_NAME = 'SPORT_GROUNDS_FORM';

interface IFormData {
  name: string;
}

type Props = InjectedFormProps<IFormData, {}>;

class SportGroundsForm extends React.PureComponent<Props> {
  render() {
    return <SwimmingSportGroundsForm />;
  }
}

export default reduxForm<IFormData, {}>({
  form: SPORT_GROUNDS_FORM_NAME,
  enableReinitialize: true,
})(SportGroundsForm);
