import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { State } from '../../rootReducer';
import { getProp } from '../../utilities';
import { Competition, LIST_NAME } from './definitions';

const competitionsDomainSelector = (state: State) => {
  return state.entities.competitions;
};

const competitionDetailSelector = (state: State) => {
  return state.detail.competitions;
};

export const competitionsResultsSelector = createSelector(
  competitionsDomainSelector,
  (substate) =>
    Object.keys(substate || {}).reduce((acc: Competition[], id) => {
      return [...acc, substate[id]];
    }, []),
);

const competitionPartsDomainSelector = (state: State) => {
  return state.entities.competitionPartsByCompetitionId;
};

export const competitionsSelector = createSelector(
  getListResults(LIST_NAME),
  competitionsDomainSelector,
  (results, objects) => {
    if (results) {
      const res = results.map((id: string) => {
        return objects[id];
      });
      return res;
    }
    return [];
  },
);

export const competitionByIdSelector = (id: string) =>
  createSelector(competitionsDomainSelector, (objects) => {
    if (objects && objects[id]) {
      return objects[id];
    }
    return null;
  });

export const competitionByIdIsFetchingSelector = (id: string) =>
  createSelector(competitionDetailSelector, (substate) =>
    getProp(substate, [id, 'isFetching'], false),
  );

export const competitionPartsByCompetitionIdSelector = (
  competitionId: string,
) =>
  createSelector(competitionPartsDomainSelector, (substate) => {
    const items = getProp(substate, [competitionId], {});
    return Object.keys(items).reduce((acc: any[], nextId: any) => {
      return [...acc, items[nextId]];
    }, []);
  });

export const competitionPartByIdSelector = (
  competitionId: string,
  competitionPartId: string,
) =>
  createSelector(competitionPartsDomainSelector, (substate) => {
    const items = getProp(substate, [competitionId], {});
    return items[competitionPartId] || null;
  });
