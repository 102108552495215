import * as React from 'react';

interface OwnProps {
  name: string;
  size: number;
  color?: string;
}

type Props = OwnProps;

class Icons extends React.PureComponent<Props> {
  render() {
    const { name, size, color } = this.props;
    switch (name) {
      case 'goal':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width={size || 20}
            height={size || 20}
          >
            <path
              fill={color}
              d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 2.5c.7 0 1.4.1 2.2.2L16 4.2l-2.2-1.5c.7-.2 1.5-.2 2.2-.2zm-5.2 1l4.4 3.2.8.6.7-.5 4.4-3.2c2 .8 3.7 2.1 5.1 3.7l-1.7 5.3-.3.8.7.5 4.5 3.3c-.2 2.2-.8 4.2-1.9 6h-6.4l-.3.8-1.7 5.3c-1 .2-2 .3-3.1.3s-2.2-.1-3.2-.4l-1.7-5.3-.3-.9H4.4c-1.1-1.8-1.7-3.8-1.9-6L7 13.8l.7-.5-.3-.8-1.7-5.3c1.4-1.6 3.1-2.9 5.1-3.7zM16 8.7l-.7.5-5.6 4.1-.7.6.3.8 2.2 6.6.3.8H20.5l.3-.8 2.2-6.6.3-.8-.7-.5L17 9.3l-1-.6zm12 1c.7 1.3 1.1 2.7 1.3 4.2l-2.2-1.6.9-2.6zM4 9.8l.8 2.5-2.2 1.6c.3-1.4.7-2.8 1.4-4.1zm12 2l4.2 3-1.6 4.9h-5.2l-1.6-4.9 4.2-3zm6.9 13.7h2.8c-1 1.1-2.3 2-3.6 2.7l.8-2.7zm-16.6 0H9l.8 2.6c-1.2-.7-2.4-1.6-3.5-2.6z"
            />
          </svg>
        );
      case 'failed_goal':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 20}
            height={size || 20}
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              fill={color}
              d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
            />
          </svg>
        );
      case 'yellow_card':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            id="card"
            width={size || 20}
            height={size || 20}
          >
            <path
              fill="#ffc600"
              d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
            />
          </svg>
        );
      case 'red_card':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            id="card"
            width={size || 20}
            height={size || 20}
          >
            <path
              fill="#FF0C00"
              d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
            />
          </svg>
        );
      case 'second_yellow_card':
        return (
          <svg
            version="1.1"
            viewBox="0 0 512 512"
            width={size || 20}
            height={size || 20}
          >
            <g>
              <path
                fill="#FF0C00"
                d="M367.6,512c37.6,0,67.8-30.5,67.8-68.2V68.4c0-16.3-5.7-31.5-15.2-43.3L92.3,487.5   c12.5,15.1,31.1,24.5,52.2,24.5H367.6z"
              />
              <path
                fill="#FFC700"
                d="M76.7,68.4v375.4c0,16.6,5.9,31.8,15.6,43.7L420.1,25.2C407.7,9.9,388.9,0,367.6,0H144.4   C106.8,0,76.7,30.8,76.7,68.4z"
              />
            </g>
          </svg>
        );
      case 'substitution':
        return (
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width={size || 20}
            height={size || 20}
          >
            <path
              fill="#FF0C00"
              d="M303.1,133.3L151,379.2L0,132.8h303.4L303.1,133.3z"
            />
            <path
              fill="#63C509"
              d="M208.9,378.7l152.1-246l151,246.5H208.6L208.9,378.7z"
            />
          </svg>
        );
      case 'exclusion':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 20}
            height={size || 20}
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              fill={color}
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"
            />
          </svg>
        );
      case 'timeout':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 20}
            height={size || 20}
            viewBox="0 0 24 24"
          >
            <path
              fill={color}
              d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
            <path fill={color} d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
          </svg>
        );
    }
    return '';
  }
}

export default Icons;
