import Button from '@sportnet/ui/Button';
import Modal, { ModalContent, ModalActions } from '@sportnet/ui/Modal';
import { __ } from '@sportnet/ui/utilities';
import { format } from 'date-fns';
import { reset, submit } from 'redux-form';
import Box from 'src/components/Box';
import { Athlete, Squad, SquadDetail } from './definitions';
import SquadForm from './form';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import BasicTable from '@sportnet/ui/BasicTable';
import Icon from '@sportnet/ui/Icon';
import { FOOTBALL_POSITIONS } from '../../Competitions/Squads/Forms/Cycling';
import { FUTSAL_POSITIONS } from '../../Competitions/Squads/Forms/Futsal/form';
import React from 'react';
import Header from '@sportnet/ui/Header';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { State } from '../../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { squadByIdSelector } from './selectors';
import { deleteSquad, getSquadById, postSquad, putSquad } from './actions';
import { CrewMember, Team } from '../definitions';
import Message from '@sportnet/ui/Message';
import CrewMemberForm from '../crewMemberForm';
import api from 'src/api';
import PlayerForm from '../PlayerForm';

const RowWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: ${rem(8)};
`;

type PersonInfo = {
  sportnetUser: {
    _id: string;
    name: string;
  };
  additionalData: any;
} | null;

type Props = {
  appspace: string;
  squad: Squad;
  keyValue: string;
  squads: Squad[];
  setSquads: any;
  team: Team;
  onCreateSquadCopy: (squad: SquadDetail) => Promise<void>;
};

const TeamSquadDetail: React.FC<Props> = ({
  appspace,
  squad,
  keyValue,
  squads,
  setSquads,
  team,
  onCreateSquadCopy,
}) => {
  const FORM_NAME = React.useMemo(() => 'SQUAD_FORM_' + keyValue, [keyValue]);
  const PLAYER_FORM = React.useMemo(
    () => 'PLAYER_FORM_' + keyValue,
    [keyValue],
  );
  const CREW_MEMBER_FORM = React.useMemo(
    () => 'CREW_MEMBER_FORM_' + keyValue,
    [keyValue],
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const squadDetailSelector = useSelector((state: State) =>
    squadByIdSelector(team._id, squad._id)(state),
  ) as SquadDetail;
  const [squadDetail, setSquadDetail] = React.useState<SquadDetail>();
  const [formErrors, setFormErrors] = React.useState<any>();
  const [isRemovingPlayer, setIsRemovingPlayer] = React.useState<string | null>(
    null,
  );
  const [isSubmittingPlayer, setIsSubmittingPlayer] = React.useState(false);

  const [activePlayer, setActivePlayer] = React.useState<PersonInfo>(null);
  const [activeCrewMember, setActiveCrewMember] =
    React.useState<PersonInfo>(null);
  const [team_sport_sector] = React.useState(team.sport_sector);

  const [isCrewMemberModalOpened, setIsCrewMemberModalOpened] =
    React.useState(false);
  const [isPlayerModalOpened, setIsPlayerModalOpened] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const [isSubmittingSquad, setIsSubmittingSquad] = React.useState(false);
  const [isRemovingSquad, setIsRemovingSquad] = React.useState(false);
  const [isSubmittingCrewMember, setIsSubmittingCrewMember] =
    React.useState(false);
  const [isFetchingSquadDetail, setIsFetchingSquadDetail] =
    React.useState(false);

  const [hasChanged, setHasChanged] = React.useState(false);

  const [validFrom, setValidFrom] = React.useState(
    new Date(squad.validFrom || new Date()),
  );
  const [title, setTitle] = React.useState(squad.title || '');

  const [captain, setCaptain] = React.useState(false);
  const [playerPosition, setPlayerPosition] = React.useState<string>('');
  const [nr, setNr] = React.useState('');
  const [player, setPlayer] = React.useState<{
    value: string;
    label: string;
  }>();

  const [crewMember, setCrewMember] = React.useState<{
    value: string;
    label: string;
  }>();
  const [crewMemberPosition, setCrewMemberPosition] = React.useState('');
  const [crewCodelists, setCrewCodelists] = React.useState<
    {
      _id: string;
      label: string;
    }[]
  >([]);

  React.useEffect(() => {
    setFormErrors({});
  }, [player]);

  React.useEffect(() => {
    setCaptain(activePlayer?.additionalData?.captain || false);
    setPlayerPosition(activePlayer?.additionalData?.position || '');
    setNr(activePlayer?.additionalData?.nr || '');
    setPlayer(
      activePlayer?.sportnetUser
        ? {
            value: activePlayer?.sportnetUser?._id,
            label: activePlayer?.sportnetUser?.name,
          }
        : undefined,
    );
  }, [activePlayer]);

  const getCrewCodelists = React.useCallback(async () => {
    await api
      .getSettingBySportSector('sport_sector_crew', team_sport_sector)
      .then((res) => {
        setCrewCodelists(res.items);
      });
  }, [team_sport_sector]);

  React.useEffect(() => {
    getCrewCodelists();
  }, [getCrewCodelists]);

  const getSquadDetail = React.useCallback(async () => {
    if (!!squad._id) {
      try {
        setIsFetchingSquadDetail(true);
        await dispatch(
          getSquadById.action({
            appspace,
            teamId: team._id,
            squads: squads,
            squadId: squad._id,
          }),
        ).finally(() => {
          setIsFetchingSquadDetail(false);
        });
      } catch (e: any) {
        console.error(e);
      }
    }
  }, [appspace, dispatch, squad._id, team._id, squads]);

  React.useEffect(() => {
    if (!!!isCollapsed) {
      setTitle(squad.title);
      setValidFrom(new Date(squad.validFrom));
      getSquadDetail();
    }
  }, [getSquadDetail, isCollapsed, squad.title, squad.validFrom]);

  React.useEffect(() => {
    if (!!!squad?._id) {
      setSquadDetail(squad as SquadDetail);
    } else {
      setSquadDetail(squadDetailSelector);
    }
  }, [squadDetailSelector, squad]);

  const submitCrewMemberForm = async (values: Athlete) => {
    setIsSubmittingCrewMember(true);
    if (!!squadDetail) {
      if (activeCrewMember) {
        values.sportnetUser = activeCrewMember.sportnetUser;
        const index = squadDetail.crew.findIndex(
          (a: Athlete) => a.sportnetUser._id === values.sportnetUser._id,
        );
        setHasChanged(
          JSON.stringify(squadDetail.crew[index]) !== JSON.stringify(values),
        );
        squadDetail.crew[index] = values;
      } else {
        setHasChanged(true);
        squadDetail.crew.push(values);
      }
      setIsSubmittingCrewMember(false);
      setIsCrewMemberModalOpened(false);
    }
  };

  const onSubmitSquad = async () => {
    if (!!squadDetail) {
      setIsSubmittingSquad(true);
      squadDetail.title = title;
      squadDetail.validFrom = validFrom.toISOString();

      const { _id, ...squad } = squadDetail;

      if (!!squadDetail?._id) {
        await dispatch(
          putSquad.action({
            appspace,
            teamId: team._id,
            squadId: _id,
            squad: squad,
          }),
        )
          .then((res) => {
            setHasChanged(false);
            const index = squads.findIndex((s) => s._id === _id);
            const updatedSquads = [...squads];
            updatedSquads[index] = res;
            setSquads(updatedSquads);
            dispatch(reset(FORM_NAME));
          })
          .catch((err) => {
            console.error(err);
            window.alert('Súpisku sa nepodarilo uložiť');
          })
          .finally(() => {
            setIsSubmittingSquad(false);
          });
      } else {
        await dispatch(
          postSquad.action({
            appspace,
            teamId: team._id,
            squad: squad,
          }),
        )
          .then((res) => {
            setSquadDetail(res);
            squads[0] = res;
            setSquads(squads);
            setHasChanged(false);
            dispatch(reset(FORM_NAME));
          })
          .catch((err) => {
            console.error(err);
            window.alert('Súpisku sa nepodarilo vytvoriť');
          })
          .finally(() => {
            setIsSubmittingSquad(false);
          });
      }
    }
  };

  const onDeleteSquad = async () => {
    if (
      !!squadDetail?._id &&
      window.confirm('Naozaj si prajete odstrániť túto súpisku?')
    ) {
      setIsRemovingSquad(true);

      await dispatch(
        deleteSquad.action({
          appspace,
          teamId: team._id,
          squadId: squadDetail._id,
        }),
      )
        .catch((err) => {
          console.error(err);
          window.alert('Súpisku sa nepodarilo odstrániť');
        })
        .finally(() => {
          setIsRemovingSquad(false);
          const index = squads.findIndex((s) => s._id === squadDetail._id);
          if (index >= 0) {
            squads.splice(index, 1);
          }
        });
    }
  };

  const renderPlayersTable = () => {
    const commonProps = {
      rows: squadDetail?.athletes || [],
      onClickRow: (i: any) => {
        setActivePlayer(i);
        setIsPlayerModalOpened(true);
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string, disabled?: boolean) => (
      <Button
        disabled={disabled}
        loading={isRemovingPlayer === sportnetId}
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();

          if (
            window.confirm(__('Skutočne si želáte odstrániť hráča zo súpisky?'))
          ) {
            setIsRemovingPlayer(sportnetId);
            const index = (squadDetail?.athletes as Athlete[]).findIndex(
              (a) => a.sportnetUser._id === sportnetId,
            );
            const reducedAthletes = [...(squadDetail?.athletes || [])];

            if (index >= 0) {
              reducedAthletes.splice(index, 1);
              setSquadDetail({
                ...(squadDetail as any),
                athletes: reducedAthletes,
              });
              setHasChanged(true);
            }
            setIsRemovingPlayer(null);
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );
    const getFootballPositions = (positionId: string) => {
      const p = FOOTBALL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    const getFutsalPositions = (positionId: string) => {
      const p = FUTSAL_POSITIONS.find(
        (position) => position.value === positionId,
      );
      if (p) {
        return p.label;
      }
      return '';
    };
    switch (team_sport_sector) {
      case 'water-polo':
      case 'swimming':
      case 'cestna-cyklistika':
      case 'formula-1':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      case 'futbal':
        return (
          <BasicTable
            rows={squadDetail?.athletes || []}
            onClickRow={(i: any) => {
              setActivePlayer(i);
              setIsPlayerModalOpened(true);
            }}
            rowKey={'_id'}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => {
              return [
                member.sportnetUser?.name,
                !!member.additionalData.captain && <Icon name="check" />,
                getFootballPositions(member.additionalData.position),
                member.additionalData.nr,
                RemoveButton(
                  member.sportnetUser?._id,
                  member.removable === false,
                ),
              ];
            }}
          />
        );
      case 'futsal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFutsalPositions(member.additionalData.position),
              member.additionalData.nr,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      case 'beachfutbal':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              {
                header: __('Kapitán'),
              },
              {
                header: __('Pozícia'),
              },
              {
                header: __('Číslo dresu'),
              },
              {
                header: __('Meno na drese'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              !!member.additionalData.captain && <Icon name="check" />,
              getFootballPositions(member.additionalData.position),
              member.additionalData.nr,
              member.additionalData.shirtname,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      case 'minifootball':
        return (
          <BasicTable
            {...commonProps}
            columns={[
              {
                header: __('Meno a priezvisko'),
              },
              { header: '', width: 100 },
            ]}
            renderRow={(member) => [
              member.sportnetUser?.name,
              RemoveButton(
                member.sportnetUser?._id,
                member.removable === false,
              ),
            ]}
          />
        );
      default:
        return null;
    }
  };

  const renderCrewTable = () => {
    const commonProps = {
      rows: Array.isArray(squadDetail?.crew || [])
        ? ((squadDetail?.crew || []) as any).map((crewMember: any) => {
            return {
              ...crewMember,
            };
          })
        : Object.keys(squadDetail?.crew || {}).map((position) => {
            return {
              ...squadDetail?.crew[position],
              position,
            };
          }),
      onClickRow: (i: any) => {
        setActiveCrewMember(i);
        setCrewMemberPosition(i.position);
        setIsCrewMemberModalOpened(true);
      },
      rowKey: '_id',
    };
    const RemoveButton = (sportnetId: string) => (
      <Button
        danger
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          if (
            window.confirm(
              __(
                'Skutočne si želáte odstrániť člena realizačného tímu zo súpisky?',
              ),
            )
          ) {
            const index = (squadDetail?.crew as CrewMember[]).findIndex(
              (a) => a.sportnetUser._id === sportnetId,
            );
            const reducedCrew = [...(squadDetail?.crew || [])];

            if (index >= 0) {
              reducedCrew.splice(index, 1);
              setSquadDetail({
                ...(squadDetail as any),
                crew: reducedCrew,
              });
              setHasChanged(true);
            }
          }
        }}
      >
        {__('Odstrániť')}
      </Button>
    );

    return (
      <BasicTable
        {...commonProps}
        columns={[
          {
            header: __('Meno a priezvisko'),
          },
          {
            header: __('Pozícia'),
          },
          { header: '', width: 100 },
        ]}
        renderRow={(member) => [
          member.sportnetUser?.name,
          crewCodelists.find((c) => c._id === member.position)?.label,
          RemoveButton(member.sportnetUser?._id),
        ]}
      />
    );
  };

  const submitPlayerForm = async (values: Athlete) => {
    values.additionalData = values.additionalData || {};
    values.additionalData.position =
      values.additionalData.position || 'goalkeeper';
    values.additionalData.nr = values.additionalData.nr || '1';

    if (!values?.sportnetUser?._id) {
      setFormErrors({ player: __('Toto pole je povinné') });
      return;
    }

    setFormErrors({});
    setIsSubmittingPlayer(true);

    if (!!squadDetail) {
      if (activePlayer && !!squadDetail) {
        values.sportnetUser = activePlayer.sportnetUser;
        const index = squadDetail.athletes?.findIndex(
          (a: Athlete) => a.sportnetUser._id === values.sportnetUser._id,
        );
        setHasChanged(
          JSON.stringify(squadDetail.athletes[index]) !==
            JSON.stringify(values),
        );
        squadDetail.athletes[index] = values;
      } else {
        setHasChanged(true);
        squadDetail.athletes.push(values);
      }
      setIsSubmittingPlayer(false);
      setIsPlayerModalOpened(false);
    }
  };

  return (
    <>
      <Box
        title={`Súpiska: ${squad.title}`}
        subtitle={`${format(new Date(squad.validFrom), 'dd.MM.yyyy')}`}
        collapsible={true}
        collapsed={!!squad._id ? isCollapsed : false}
        toggleCollapse={!!squad._id ? setIsCollapsed : undefined}
      >
        {!!squadDetail?._id &&
          (!!hasChanged ||
            title !== squad.title ||
            validFrom.toISOString() !== squad.validFrom) && (
            <Message warning>
              {__('Zmeny sa zaznamenajú až po stlačení tlačidla')}
              <b>{__(' Uložiť súpisku')}</b>
            </Message>
          )}
        {!!!squadDetail?._id && (
          <Message warning>
            {__('Súpiska bude vytvorená až po stlačení tlačidla')}
            <b>{__(' Vytvoriť súpisku')}</b>
          </Message>
        )}
        <SquadForm
          dispatch={dispatch}
          form={FORM_NAME}
          initialValues={{
            validFrom: new Date(squad.validFrom),
            title: squad.title,
          }}
          onSubmit={onSubmitSquad}
          title={title}
          setTitle={setTitle}
          validFrom={validFrom}
          setValidFrom={setValidFrom}
        />
        <Box title="Hráči">
          <Button
            onClick={() => {
              setActivePlayer(null);
              setPlayer(undefined);
              setNr('');
              setPlayerPosition('');
              setIsPlayerModalOpened(true);
            }}
            primary
          >
            {__('Pridať hráča')}
          </Button>
          <br />
          <br />
          {renderPlayersTable()}
        </Box>
        <br />
        <Box title="Realizačný tím">
          <Button
            onClick={() => {
              setActiveCrewMember(null);
              setCrewMember(undefined);
              setCrewMemberPosition('');
              setIsCrewMemberModalOpened(true);
            }}
            primary
          >
            {__('Pridať osobu')}
          </Button>
          <br />
          <br />
          {renderCrewTable()}
        </Box>
        <br />
        <RowWrapper>
          {!!squadDetail?._id && (
            <>
              <Button danger onClick={onDeleteSquad} loading={isRemovingSquad}>
                {__('Zmazať')}
              </Button>
              <Button
                basic
                primary
                onClick={() => {
                  if (!!squadDetail) {
                    onCreateSquadCopy(squadDetail);
                  }
                }}
              >
                {__('Vytvoriť kópiu')}
              </Button>
              {(!!hasChanged ||
                title !== squad.title ||
                validFrom.toISOString() !== squad.validFrom) && (
                <Button
                  basic
                  danger
                  loading={isFetchingSquadDetail}
                  onClick={() => {
                    setTitle(squad.title);
                    setValidFrom(new Date(squad.validFrom));
                    getSquadDetail().then(() => {
                      setSquadDetail(squadDetailSelector);
                    });
                    dispatch(reset(FORM_NAME));
                    setHasChanged(false);
                  }}
                >
                  {__('Zahodiť zmeny')}
                </Button>
              )}
            </>
          )}
          {!!!squadDetail?._id && (
            <Button
              basic
              danger
              onClick={() => {
                const reducedSquads = [...squads];
                reducedSquads.shift();
                setSquads(reducedSquads);
                setIsCollapsed(true);
              }}
            >
              {__('Zahodiť')}
            </Button>
          )}
          <Button
            primary
            onClick={() => {
              dispatch(submit(FORM_NAME));
            }}
            disabled={
              !!squadDetail?._id
                ? !(
                    !!hasChanged ||
                    title !== squad.title ||
                    validFrom.toISOString() !== squad.validFrom
                  )
                : false
            }
            loading={isSubmittingSquad}
          >
            {__(!!squadDetail?._id ? 'Uložiť súpisku' : 'Vytvoriť súpisku')}
          </Button>
        </RowWrapper>
      </Box>
      <Modal
        handleClose={() => setIsCrewMemberModalOpened(false)}
        isOpen={isCrewMemberModalOpened}
      >
        <ModalContent>
          <Header size="s" withSeparator>
            {!!activeCrewMember
              ? __('Úprava člena realizačného tímu v družstve')
              : __('Pridanie člena realizačného tímu do družstva')}
          </Header>
          <CrewMemberForm
            form={CREW_MEMBER_FORM}
            onSubmit={submitCrewMemberForm}
            codelists={crewCodelists}
            initialValues={activeCrewMember || {}}
            editing={!!activeCrewMember}
            dispatch={dispatch}
            appspace={appspace}
            team={team}
            crewMember={crewMember}
            setCrewMember={setCrewMember}
            position={crewMemberPosition}
            setPosition={setCrewMemberPosition}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                setActiveCrewMember(null);
                setIsCrewMemberModalOpened(false);
              }}
            >
              {__('Zavrieť')}
            </Button>
            &nbsp;
            <Button
              primary
              loading={isSubmittingCrewMember}
              onClick={() => dispatch(submit(CREW_MEMBER_FORM))}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      <Modal
        isOpen={isPlayerModalOpened}
        handleClose={() => setIsPlayerModalOpened(false)}
      >
        <ModalContent>
          <Header size="s" withSeparator>
            {!!activePlayer
              ? __('Úprava hráča v družstve')
              : __('Pridanie hráča do družstva')}
          </Header>
          <PlayerForm
            form={PLAYER_FORM}
            onSubmit={submitPlayerForm}
            errors={formErrors}
            initialValues={activePlayer || {}}
            editing={!!activePlayer}
            dispatch={dispatch}
            appspace={appspace}
            team={team}
            player={player}
            setPlayer={setPlayer}
            captain={captain}
            setCaptain={setCaptain}
            position={playerPosition}
            setPosition={setPlayerPosition}
            nr={nr}
            setNr={setNr}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                setActivePlayer(null);
                setIsPlayerModalOpened(false);
                setFormErrors({});
              }}
            >
              {__('Zavrieť')}
            </Button>
            <Button
              onClick={() => {
                dispatch(submit(PLAYER_FORM));
              }}
              primary
              loading={isSubmittingPlayer}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

export default TeamSquadDetail;
