import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import rootReducer, { State } from './rootReducer';

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

export type CustomThunkAction<R> = (
  dispatch: ThunkDispatch<State, {}, AnyAction>,
  getState: () => State,
) => R;

export type CustomThunkDispatch = ThunkDispatch<State, {}, AnyAction>;

export interface ReduxConnectProps {
  dispatch: CustomThunkDispatch;
}

export const history = createBrowserHistory({}) as any;

const thunk: ThunkMiddleware<{}, AnyAction, {}> =
  thunkMiddleware.withExtraArgument(
    {},
    // { Api },
  );

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), thunk),
);

export default function configureStore(initialState?: object) {
  return createStore(
    connectRouter(history)(rootReducer) as any,
    initialState as any,
    enhancer,
  );
}
