import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderTop from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import Box from '../../../components/Box';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { State } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { setBreadcrumbs } from '../../App/actions';
import { activeAppspaceProfileSelector } from '../../App/selectors';
import { getSeasonById, postSeason, putSeason } from './actions';
import Form, { FORM_NAME } from './form';
import { seasonByIdIsFetchingSelector, seasonByIdSelector } from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterProps = RouteComponentProps<{
  id: string;
  appspace: string;
}>;

const mapStateToProps = (state: State, props: RouterProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return {
    ppo: activeAppspaceProfileSelector(state),
    season: seasonByIdSelector(id)(state),
    isFetching: seasonByIdIsFetchingSelector(id)(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouterProps & OwnProps & IMapStateToProps;

class SeasonDetail extends React.PureComponent<Props> {
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace, id },
      },
    } = this.props;
    dispatch(
      setBreadcrumbs([
        <Link key={0} to={`/admin/${appspace}/codelists/seasons`}>
          {__('Číselník sezón')}
        </Link>,
        id === 'new' ? __('Nová sezóna') : __('Úprava sezóny'),
      ]),
    );
    if (id !== 'new') {
      try {
        await dispatch(getSeasonById.action({ appspace, id }));
      } catch (e: any) {
        console.error(e);
      }
    }
  }
  onSubmit = async (data: any) => {
    const {
      dispatch,
      history: { push },
      match: {
        params: { appspace, id },
      },
      ppo,
    } = this.props;
    const requestData = { ...data, sport: ppo ? ppo.sport : '' };
    if (id === 'new') {
      await dispatch(
        postSeason.action({
          appspace,
          season: requestData,
        }),
      );
    } else {
      const { _id, appSpace, ...updateData } = requestData;
      await dispatch(
        putSeason.action({ appspace, seasonId: id, season: updateData }),
      );
    }
    push(`/admin/${appspace}/codelists/seasons`);
  };
  render() {
    const {
      season,
      isFetching,
      match: {
        params: { appspace, id },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderTop>
            <HeaderTop.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/codelists/seasons`}
            />
            <HeaderTop.Header>
              {!!season
                ? `${__('Detail sezóny')} | ${season.name}`
                : __('Nová sezóna')}
            </HeaderTop.Header>
          </HeaderTop>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={() => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
                primary
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <Segment>
          {/* <AppContext
            title=
            breadcrumbs={[
              {
                name: __('Zoznam sezón'),
                url: `/admin/${appspace}/codelists/seasons`,
              },
            ]}
          /> */}
          <Box title={__('Základné informácie')}>
            <Form data={season} id={id} onSubmit={this.onSubmit} />
          </Box>
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(SeasonDetail));
