import { createSelector } from 'reselect';
import { State } from '../../../rootReducer';
import { getProp } from '../../../utilities';

const squadsDomainSelector = (state: State) => {
  return state.entities.squadsByTeamId;
};
export const squadsByTeamIdSelector = (teamId: string) =>
  createSelector(squadsDomainSelector, (substate) => {
    const items = getProp(substate, [teamId], {});
    return Object.keys(items).reduce((acc: any[], nextId: any) => {
      return [...acc, items[nextId]];
    }, []);
  });

export const squadByIdSelector = (teamId: string, squadId: string) =>
  createSelector(squadsDomainSelector, (substate) => {
    const items = getProp(substate, [teamId], {});
    return items[squadId] || null;
  });
