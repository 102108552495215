import * as React from 'react';
import { Match as IMatch } from 'src/containers/Matches/definitions';
import { ISportSectorPhase } from 'src/containers/Protocols/timeline';
import { withTheme } from 'styled-components';
import Match from './Match';

interface IOwnProps {
  theme: any;
  match: IMatch;
  sportSectorPhases: ISportSectorPhase[];
}

const C: React.FC<IOwnProps> = ({ theme, match, sportSectorPhases }) => {
  if (match) {
    return (
      <Match
        theme={theme}
        match={match}
        lastIndex
        sportSectorsPhases={sportSectorPhases}
        fullWidth
        settings={match.settings}
        verbose={true}
      />
    );
  }
  return null;
};

export default withTheme(C);
