import React from 'react';
import BasicTable from '@sportnet/ui/BasicTable';
import Loader from '@sportnet/ui/Loader';
import { Team } from '../definitions';
import Api from 'src/api';
import { __ } from 'src/utilities';
import { useHistory } from 'react-router-dom';

type Props = {
  team: Team;
};

const TeamStats: React.FC<Props> = ({ team }) => {
  const history = useHistory();

  const [athletes, setAthletes] = React.useState([]);

  const [athletesIsFetching, setAthletesIsFetching] = React.useState(false);

  const fetchTeamAthletes = React.useCallback(async () => {
    setAthletesIsFetching(true);
    Api.adminGetTeamAthletes(team.appSpace, team._id)
      .then((response: any) => {
        setAthletes(response.athletes);
      })
      .finally(() => setAthletesIsFetching(false));
  }, [team.appSpace, team._id]);

  React.useEffect(() => {
    fetchTeamAthletes();
  }, [fetchTeamAthletes]);

  if (athletesIsFetching) {
    return <Loader />;
  }

  return (
    <BasicTable
      columns={[
        { header: __('Sportnet ID') },
        { header: __('Meno a priezvisko') },
      ]}
      onClickRow={(athlete) => {
        history.push(
          `/admin/${team.appSpace}/teams/${team._id}/statistics/${athlete.sportnetUser._id}`,
        );
      }}
      rowKey="sportnetUser._id"
      rows={((athletes || []) as any[]).sort((a, b) => {
        if (
          a.sportnetUser &&
          b.sportnetUser &&
          a.sportnetUser.name! > b.sportnetUser.name!
        ) {
          return 1;
        } else if (
          a.sportnetUser &&
          b.sportnetUser &&
          a.sportnetUser.name! < b.sportnetUser.name!
        ) {
          return -1;
        }
        return 0;
      })}
      renderRow={(athlete) => [
        athlete.sportnetUser._id,
        athlete.sportnetUser.name,
      ]}
    />
  );
};

export default TeamStats;
