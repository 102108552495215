import { Theme } from '@sportnet/ui/Themes/styled-components';
import * as React from 'react';
import { AnyAction } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import { Situation } from '../../../components/TableForm';
import { Match } from '../../Matches/definitions';
import { State } from '../../../rootReducer';
import { getProp } from '../../../utilities';
import { Athlete, CrewMember, Team } from '../../Teams/definitions';
import { format } from 'date-fns';

const FORM_NAME = 'MATCH_FORM';

interface OwnProps {
  teams: Team[];
  nominations: Array<{
    athletes: Athlete[];
    crew: CrewMember[];
    teamId: string;
  }>;
  closed: boolean;
  data?: Match | null;
}

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  return {
    events: selector(state, 'protocol.events'),
    delegates: selector(state, 'protocol.delegates'),
    toClose: selector(state, 'toClose') || false,
    matchTimer: selector(state, 'timer') || {},
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & {
  dispatch: ThunkDispatch<any, any, AnyAction>;
} & Theme &
  IMapStateToProps;

interface ITimer {
  ongoing: boolean;
  seconds: number;
  phase: string;
  toggleDate: string;
}

class CommonProtocol<T extends Props> extends React.Component<T> {
  currentTimerValue = 0;
  currentTimerPhase = '';
  state: {
    situationTeamId: string;
    isEventsFormOpened: boolean;
    activeSituation?: {
      idx?: number;
      situation: Situation;
      data?: any;
    };
    timer: ITimer;
    codelists: {
      [key: string]: any[];
    };
  } = {
    situationTeamId: '',
    isEventsFormOpened: false,
    activeSituation: undefined,
    timer: {
      ongoing: false,
      seconds: 0,
      phase: '',
      toggleDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    },
    codelists: {
      phases: [],
      events: [],
      delegates: [],
    },
  };

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.getSettings(data!);
    }
    if (getProp(data! || {}, ['liveState'])) {
      this.setState({
        timer: getProp(data! || {}, ['liveState']),
      });
    }
  }

  componentDidUpdate(prevProps: T) {
    if (this.props.data && !prevProps.data) {
      if (getProp(this.props.data || {}, ['liveState'])) {
        this.setState({
          timer: getProp(this.props.data || {}, ['liveState']),
        });
      }
    }
  }

  getSettings = async (data: Match) => {
    const competitionPart = await api.getCompetitionPartById(
      data.appSpace,
      data.competition._id,
      data.competitionPart._id,
    );
    if (data && data.rules && competitionPart && competitionPart.settings) {
      const phases = (await api.getSettingBySportSector(
        'sport_sector_phases',
        data.rules.sport_sector || '',
      )) as any;
      const events = (await api.getSettingBySportSector(
        'sport_sector_events',
        data.rules.sport_sector || '',
      )) as any;
      const delegates = (await api.getSettingBySportSector(
        'sport_sector_delegates',
        data.rules.sport_sector || '',
      )) as any;
      const activePhase = phases.items[0];
      const { startTime } = (competitionPart.settings as any).phases[
        activePhase._id
      ];
      this.setState({
        codelists: {
          phases: phases.items.map((i: any) => ({
            ...i,
            value: i._id,
          })),
          events: events.items.map((i: any) => ({
            ...i,
            value: i._id,
          })),
          delegates: delegates.items.map((i: any) => ({
            ...i,
            value: i._id,
          })),
        },
        timer: {
          ...this.state.timer,
          seconds: data.liveState ? data.liveState.seconds : startTime,
          phase: data.liveState ? data.liveState.phase : activePhase._id,
        },
      });
    }
  };

  getNominatedTeams = () =>
    this.props.teams
      .sort((t) => {
        if (getProp(t, ['additionalProperties', 'homeaway'], '') === 'home') {
          return -1;
        } else if (
          getProp(t, ['additionalProperties', 'homeaway'], '') === 'away'
        ) {
          return 1;
        }
        return 0;
      })
      .reduce((acc: Team[], t) => {
        const nomination = this.props.nominations.find(
          (i) => i.teamId === t._id,
        );
        if (nomination) {
          return [
            ...acc,
            {
              ...t,
              label: t.name || '',
              value: t._id,
            },
          ];
        }
        return acc;
      }, []);

  getNominatedPlayers = () =>
    this.props.teams.reduce((acc, t) => {
      const nomination = this.props.nominations.find((i) => i.teamId === t._id);

      if (nomination) {
        return {
          ...acc,
          [t._id]: nomination.athletes.map((a) => ({
            label: a.sportnetUser.name,
            value: a.sportnetUser._id,
            additionalData: a.additionalData,
          })),
        };
      }
      return acc;
    }, {});

  getNominatedCrewMembers = () =>
    this.props.teams.reduce((acc, t) => {
      const nomination = this.props.nominations.find((i) => i.teamId === t._id);

      if (nomination) {
        return {
          ...acc,
          [t._id]: nomination.crew.map((a) => ({
            label: a.sportnetUser.name,
            value: a.sportnetUser._id,
            additionalData: {
              position: a.position,
            },
          })),
        };
      }
      return acc;
    }, {});

  getSortedEvents = () =>
    getProp(this.props, ['events'], []).sort((ee: any, ef: any) => {
      const firstEventPhaseIndex = this.state.codelists.phases.findIndex(
        (i) => i.value === ee.phase,
      );
      const secondEventPhaseIndex = this.state.codelists.phases.findIndex(
        (i) => i.value === ef.phase,
      );
      if (firstEventPhaseIndex > secondEventPhaseIndex) {
        return 1;
      } else if (firstEventPhaseIndex < secondEventPhaseIndex) {
        return -1;
      }
      if (
        (ee.eventTime > ef.eventTime &&
          this.props.data &&
          !this.props.data.settings.reverseTime) ||
        (ee.eventTime < ef.eventTime &&
          this.props.data &&
          this.props.data.settings.reverseTime)
      ) {
        return 1;
      } else if (
        (ee.eventTime > ef.eventTime &&
          this.props.data &&
          this.props.data.settings.reverseTime) ||
        (ee.eventTime < ef.eventTime &&
          this.props.data &&
          !this.props.data.settings.reverseTime)
      ) {
        return -1;
      }
      return 0;
    });

  setActiveSituation = (
    situation: {
      type: string;
      name: string;
      label: string;
    },
    team: { value: string; label: string },
    person?: { value: string; label: string; isCrewMember?: boolean },
    data?: any,
    idx?: number,
    eventTime?: number,
    phase?: string,
  ) => {
    const activeSituation = {
      idx,
      situation,
      data: {
        ...(data || {}),
        eventType: situation.type,
        eventTime: eventTime || this.currentTimerValue,
        phase: phase || this.currentTimerPhase,
        player:
          person && !person.isCrewMember
            ? {
                _id: person.value,
                name: person.label,
              }
            : undefined,
        crewMember:
          person && person.isCrewMember
            ? {
                _id: person.value,
                name: person.label,
              }
            : undefined,
        team: team.value,
      },
    };
    if (!activeSituation.data.player) {
      delete activeSituation.data.player;
    }
    if (!activeSituation.data.crewMember) {
      delete activeSituation.data.crewMember;
    }
    if (typeof idx === 'undefined') {
      delete activeSituation.idx;
    }
    this.setState({
      activeSituation,
      situationTeamId: team.value,
      isEventsFormOpened: true,
    });
  };

  onToggleTimer = (
    state: boolean,
    value: number,
    phase: string,
    forceUpdate?: boolean,
  ) => {
    const { data } = this.props;

    const liveState = {
      ongoing: state,
      seconds: value,
      phase,
    };
    // const timer = this.props.matchTimer;

    // if (!this.props.matchTimer[phase] && state) {
    //   timer[phase] = {
    //     ...timer[phase],
    //     start: {
    //       date: moment().format(),
    //       seconds: value,
    //     },
    //   };
    //   // this.props.dispatch(
    //   //   change(FORM_NAME, `timer.${phase}.start`, timer[phase].start),
    //   // );
    // } else if (
    //   this.props.matchTimer[this.state.timer.phase] &&
    //   phase !== this.state.timer.phase &&
    //   !state
    // ) {
    //   timer[this.state.timer.phase] = {
    //     ...timer[this.state.timer.phase],
    //     end: {
    //       date: moment().format(),
    //       seconds: this.state.timer.seconds,
    //     },
    //   };
    //   // this.props.dispatch(
    //   //   change(
    //   //     FORM_NAME,
    //   //     `timer.${this.state.timer.phase}.end`,
    //   //     timer[this.state.timer.phase].end,
    //   //   ),
    //   // );
    // }

    let toggleDate = this.state.timer.toggleDate;

    if (
      state !== this.state.timer.ongoing ||
      phase !== this.state.timer.phase ||
      (value !== this.state.timer.seconds && forceUpdate)
    ) {
      toggleDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      api.adminUpdateMatchLiveState(
        data?.appSpace || '',
        data?._id || '',
        undefined,
        {
          liveState: { ...liveState, toggleDate },
        },
      );
    }
    this.props.dispatch(change(FORM_NAME, 'liveState', liveState));
    this.setState({
      timer: {
        ...this.state.timer,
        ...liveState,
        toggleDate,
      },
    });
  };
}

export default CommonProtocol;
