import { MatchManagerRole } from '../../api';
import { Season } from '../Codelists/Seasons/definitions';
import { SportGround } from '../SportObjects/definitions';
import { Team } from '../Teams/definitions';

export const matchManagerRoleName = (role: MatchManagerRole): string => {
  const roleNames: { [key in MatchManagerRole]: string } = {
    NOMINATIONS: 'Správa nominácií',
    PROTOCOL: 'Vytváranie protokolu',
    VAR: 'Videoasistent rozhodcu',
    DATACOLLECTOR: 'Data collector',
  };
  return roleNames[role] || role;
};

export const matchManagerRoles: Array<MatchManagerRole> = [
  'NOMINATIONS',
  'VAR',
  'PROTOCOL',
  'DATACOLLECTOR',
];
export interface Result {
  team: {
    _id: string;
    name: string;
  };
  stats: {
    matches: {
      played: number;
      won: number;
      draw: number;
      lost: number;
    };
    goals: {
      given: number;
      received: number;
    };
    points: number;
  };
}

export interface TeamRaceResult {
  team: {
    _id: string;
    name: string;
  };
  totalPoints: Array<{ name: string; value: string }>;
}

export interface RaceResult {
  team: {
    _id: string;
    name: string;
  };
  players: Array<{
    _id: string;
    name: string;
    citizenship?: string;
    additionalData: { [key: string]: any };
  }>;
  totalPoints: Array<{ name: string; value: string }>;
}

export interface Player {
  _id: string;
  teamId: string;
  name: string;
  stats: {
    match_starts?: number;
    match_appearances?: number;
    goals?: number;
    assists?: number;
    yellow_cards?: number;
    red_cards?: number;
  };
}

export interface Match {
  _id: string;
  score?: number[];
  contumationScore?: number[];
  penaltiesScore?: number[];
  scoreByPhases?: number[][];
  scoreIsComputed?: boolean;
  appSpace: string;
  drawId?: number;
  competition: {
    _id: string;
    name: string;
    identifier?: string;
  };
  competitionPart: {
    _id: string;
    name: string;
  };
  season: Season;
  sportGround?: SportGround;
  startDate: string;
  protocol?: any;
  round?: {
    _id?: string;
    name: string;
  };
  rules?: {
    gender?: string;
    sport_sector?: string;
  };
  liveState?: {
    toggleDate: string;
    ongoing: boolean;
    phase: string;
    seconds: number;
  };
  teams?: Team[];
  timer?: any;
  nominations?: Array<{
    teamId?: string;
    athletes: Array<{
      sportnetUser: {
        _id: string;
        name: string;
      };
      additionalData?: { [key: string]: string };
    }>;
    crew?: Array<{
      sportnetUser: {
        _id: string | null;
        name: string | null;
      };
      position?: string;
    }>;
    status?: 'OPENED' | 'CLOSED';
  }>;
  resultsTable?: {
    results: Result[];
    players?: Player[];
  };
  contumation?: {
    isContumated: boolean;
    advantage?: string | null;
    keepScore?: boolean;
  };
  closed?: boolean;
  settings?: any;
  managers?: Array<{
    user: {
      _id: string;
      name: string;
    };
    type: {
      label: string;
      value: string;
    };
    roles?: Array<MatchManagerRole>;
  }>;
}

export interface MatchCreate {
  _id: string;
  appSpace?: string;
  competitionId?: string;
  partId?: string;
  sportGround?: SportGround;
  startDate?: string;
  round?: {
    _id: string;
    name: string;
  };
  rules?: {
    gender?: string;
    sport_sector?: string;
  };
  teams?: Team[];
  nominations?: Array<{
    teamId: string;
    athletes: Array<{
      sportnetUser: {
        _id: string;
      };
      additionalData?: {};
    }>;
    crew: {};
    status?: 'OPENED' | 'CLOSED';
  }>;
}

export interface MatchUpdate {
  _id: string;
  appSpace?: string;
  competitionId?: string;
  partId?: string;
  sportGround?: SportGround;
  startDate?: string;
  round?: {
    _id: string;
    name: string;
  };
  rules?: {
    gender?: string;
    sport_sector?: string;
  };
  teams?: Team[];
  nominations?: Array<{
    teamId: string;
    athletes: Array<{
      sportnetUser: {
        _id: string;
      };
      additionalData?: {};
    }>;
    crew: {};
    status?: 'OPENED' | 'CLOSED';
  }>;
}
