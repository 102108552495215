import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { commit, initialize, isCommiting } from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { State } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { setBreadcrumbs } from '../../App/actions';
import { getSeasons } from './actions';
import { LIST_NAME, Season } from './definitions';
import { seasonsCurrentListSelector } from './selectors';
import { format } from 'date-fns';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterParameters = RouteComponentProps<{ appspace: string }>;

const mapStateToProps = (state: State, props: RouterParameters) => ({
  seasons: seasonsCurrentListSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouterParameters & OwnProps & IMapStateToProps;

class SeasonsList extends React.PureComponent<Props> {
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    dispatch(setBreadcrumbs([__('Číselník sezón')]));
    try {
      await dispatch(
        commit.action({
          listName: LIST_NAME,
          load: async () => {
            const {
              entities: { seasons },
            } = await dispatch(getSeasons.action({ appspace }));
            const items = Object.keys(seasons).map((key) => {
              return seasons[key];
            });
            return {
              total: items.length,
              results: items.map((season) => {
                return season._id;
              }),
              nextOffset: null,
            };
          },
        }),
      );
    } catch (e: any) {
      console.error(e);
    }
  }
  render() {
    const {
      seasons,
      isFetching,
      match: {
        params: { appspace },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Header>{__('Zoznam sezón')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Link to={`/admin/${appspace}/codelists/seasons/new`}>
                <Button primary>{__('Pridať sezónu')}</Button>
              </Link>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <>
          {!isFetching && seasons.length === 0 ? (
            <NotFound
              icon="search"
              title={__('Momentálne neexistuje žiadna sezóna')}
            />
          ) : (
            <Segment>
              <Segment raised>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__('Označenie')}</Th>
                      <Th>{__('Trvanie od')}</Th>
                      <Th>{__('Trvanie do')}</Th>
                      {/* <Th>{__('Aktuálna')}</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {seasons.map((season: Season) => {
                      return (
                        <Tr
                          onClick={() => {
                            this.props.history.push(
                              `/admin/${appspace}/codelists/seasons/${season._id}`,
                            );
                          }}
                          key={season._id}
                        >
                          <Td>{season.name}</Td>
                          <Td>
                            {format(new Date(season.dateFrom), 'dd.MM.yyyy')}
                          </Td>
                          <Td>
                            {format(new Date(season.dateTo), 'dd.MM.yyyy')}
                          </Td>
                          {/* <Td>{season.actual && '✓'}</Td> */}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Segment>
            </Segment>
          )}
        </>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(SeasonsList));
