import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { change, isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../../api';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { Competition, CompetitionPart } from '../../Competitions/definitions';
import { SportGround } from '../../SportObjects/definitions';
import { Team } from '../../Teams/definitions';
import { State } from '../../../rootReducer';
import { getProp, __ } from '../../../utilities';
import { updateMatchManagerMatch } from '../actions';
import { FORM_NAME, MatchNomination } from '../form';
import MatchManagerMatchForm from './form';
import { format } from 'date-fns';

const mapStateToProps = (state: State) => ({
  isFormSubmitting: isSubmitting(FORM_NAME)(state),
});

const MatchManagerMatchDetail: React.FC<
  ReturnType<typeof mapStateToProps> & {
    dispatch: ThunkDispatch<any, any, AnyAction>;
  } & RouteComponentProps<{
      competitionId: string;
      partId: string;
      matchId: string;
    }>
> = ({
  match: {
    params: { competitionId, partId, matchId },
  },
  location: { hash },
  dispatch,
  isFormSubmitting,
}) => {
  const [competition, setCompetition] = React.useState<Competition | null>(
    null,
  );
  const [part, setPart] = React.useState<CompetitionPart | null>(null);
  const [teams, setTeams] = React.useState<Team[]>([]);

  const [isFetching, setIsFetching] = React.useState(false);

  const QueryClient = useQueryClient();

  const {
    data: match,
    isFetching: isFetchingMatch,
    isFetched: isFetchedMatch,
  } = useQuery(['match', { competitionId, partId, matchId }], () =>
    api.matchManagerGetMatchById(competitionId, partId, matchId),
  );

  const getData = React.useCallback(async () => {
    setIsFetching(true);
    try {
      const competitionRes = await api.matchManagerGetCompetition(
        competitionId,
      );
      const partRes = await api.matchManagerGetCompetitionPartById(
        competitionId,
        partId,
      );
      const teamsRes = await api.matchManagerGetMatchSuitableTeams(matchId);
      setCompetition(competitionRes);
      setPart(partRes);
      setTeams(teamsRes.teams);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať detail udalosti'));
    } finally {
      setIsFetching(false);
    }
  }, [competitionId, partId, matchId]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const getNomination = () => {
    return (match ? match.teams || [] : [])
      .map((i: Team) => {
        const nomination = getProp(match || {}, ['nominations'], []).find(
          (n: { teamId: string }) => n.teamId === i._id,
        );
        return {
          team: i,
          nomination,
        };
      })
      .filter((a: any) => a);
  };

  const isMatchNominated = () => {
    const nominations = getNomination();
    let matchNominated = nominations.length > 0;

    nominations.forEach((nomination: any) => {
      if (!getProp(nomination, ['nomination', 'closed'], false)) {
        matchNominated = false;
      }
    });
    return matchNominated;
  };

  const onSubmit = async (data: any) => {
    const submitData: {
      closed?: boolean;
      teams?: any;
      sportGround?: SportGround;
      competitionId: string;
      partId: string;
      drawId?: number;
      startDate?: string;
      protocol?: any;
      rules?: any;
      nominations?: MatchNomination[];
      roundId?: string;
      timer?: {
        [key: string]: {
          start: {
            date: string;
            seconds: string;
          };
          end?: {
            date: string;
            seconds: string;
          };
        };
      };
    } = {
      competitionId,
      partId,
    };

    submitData.teams = data.teams;

    if (Object.keys(data.protocol || {}).length) {
      submitData.protocol = data.protocol;
    }
    if (typeof data.toClose !== 'undefined') {
      submitData.closed = data.toClose;
    }
    if (data.timer) {
      submitData.timer = data.timer;
    }
    submitData.startDate = format(
      new Date(data.startDate),
      'yyyy-MM-dd HH:mm:ss',
    );

    await dispatch(
      updateMatchManagerMatch.action({
        competitionId,
        partId,
        matchId,
        match: submitData,
      }),
    );

    await getData();

    QueryClient.invalidateQueries('match');

    // if (data.liveState) {
    //   api.adminUpdateMatchLiveState(appspace, id, undefined, {
    //     liveState: data.liveState,
    //     timer: data.timer || {},
    //   });
    // }
    // this.setMatchData();
    // dispatch(
    //   getCompetitionPartById.action({
    //     appspace,
    //     competitionId,
    //     competitionPartId: partId,
    //   }),
    // );
  };

  if (isFetching || (isFetchingMatch && !isFetchedMatch)) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  if (match && part) {
    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            {!!match && (
              <HeaderBar.Action icon="back" as={Link} to={'/match-manager'} />
            )}
            <HeaderBar.Header>{__('Detail udalosti')}</HeaderBar.Header>
          </HeaderBar>
        }
        {...(hash === '#PROTOCOL'
          ? {
              bottomFixed: (
                <ContextBar>
                  <ContextBarSpacer />
                  <ContextBarItem>
                    {!isFetchingMatch && !match.closed && isMatchNominated() && (
                      <React.Fragment>
                        <Button
                          primary
                          onClick={async () => {
                            await dispatch(change(FORM_NAME, 'toClose', false));
                            dispatch(submit(FORM_NAME));
                          }}
                          loading={isFormSubmitting}
                        >
                          {__('Uložiť')}
                        </Button>
                        &nbsp;
                        {
                          <Button
                            success
                            onClick={async () => {
                              await dispatch(
                                change(FORM_NAME, 'toClose', true),
                              );
                              dispatch(submit(FORM_NAME));
                            }}
                            loading={isFormSubmitting}
                          >
                            {__('Uložiť a uzavrieť')}
                          </Button>
                        }
                      </React.Fragment>
                    )}
                  </ContextBarItem>
                </ContextBar>
              ),
            }
          : {})}
      >
        <MatchManagerMatchForm
          isMatchNominated={isMatchNominated()}
          data={match as any}
          id={matchId}
          competition={competition}
          competitionPart={part}
          closed={!!match.closed}
          teams={teams}
          onSubmit={onSubmit}
        />
      </ScrollLayout>
    );
  }
  return null;
};

export default compose(connect(mapStateToProps))(MatchManagerMatchDetail);
