import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { Theme } from '@sportnet/ui/Themes/styled-components';
import { addSeconds, format } from 'date-fns';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import {
  arrayRemove,
  change,
  Field,
  formValueSelector,
  submit,
} from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import Icons from '../../../components/Icons';
import { competitionPartByIdSelector } from '../../Competitions/selectors';
import { Match, Result } from '../../Matches/definitions';
import styled, { withTheme } from 'styled-components';
import Box from '../../../components/Box';
import { State } from '../../../rootReducer';
import {
  getProp,
  isTime,
  parseToTimeString,
  required,
  __,
} from '../../../utilities';
import { Athlete, Team } from '../../Teams/definitions';
import CommonProtocol from '../Common';
import EventForm, { EVENT_FORM_NAME } from '../eventForm';
import Timeline from '../timeline';
import ProtocolTimer from '../timer';

const FORM_NAME = 'MATCH_FORM';

interface User {
  _id: string;
  name: string;
}

interface Event {
  eventTime: string;
  eventType: string;
  type: string;
  assist: User;
  replacement: User;
  player: User;
  reason: string;
  team: string;
  score: string;
  phase: string;
}

interface OwnProps {
  teams: Team[];
  nominations: Array<{
    athletes: Athlete[];
    crew: any;
    teamId: string;
  }>;
  closed: boolean;
  data?: Match | null;
}

const selector = formValueSelector(FORM_NAME);
const eventFormSelector = formValueSelector(EVENT_FORM_NAME);
const mapStateToProps = (state: State, props: OwnProps) => {
  let competitionPart = null;
  let settings = null;
  if (props.data) {
    competitionPart = competitionPartByIdSelector(
      props.data.competition._id,
      props.data.competitionPart._id,
    )(state);
    settings = props.data.settings;
  }
  return {
    competitionPart,
    settings,
    protocol: selector(state, 'protocol'),
    timer: selector(state, 'timer'),
    startDate: selector(state, 'startDate'),
    events: selector(state, 'protocol.events'),
    delegates: selector(state, 'protocol.delegates'),
    toClose: selector(state, 'toClose') || false,
    matchTimer: selector(state, 'timer') || {},
    eventFormValues: {
      team: eventFormSelector(state, 'team'),
      player: eventFormSelector(state, 'player'),
      replacement: eventFormSelector(state, 'replacement'),
      assist: eventFormSelector(state, 'assist'),
    },
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<{
  appspace: string;
  id: string;
}> &
  OwnProps & { dispatch: ThunkDispatch<any, any, AnyAction> } & Theme &
  IMapStateToProps;

// VALIDACIA

const PlayerInfo = styled('div')`
  display: flex;
  & > div {
    padding: 0 5px;
  }
`;

const Player = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  font-size: 14px;
`;

const Situations = styled('div')`
  display: flex;
`;

const SituationItem = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${rem(2)};
`;

class FutsalProtocol extends CommonProtocol<Props> {
  getSituationFields = (name: string, idx: number) => {
    const nominatedTeams = this.getNominatedTeams() as any;
    let nominatedPlayers = this.getNominatedPlayers();

    const { player, assist, replacement, team } = this.props.eventFormValues;

    if (team) {
      nominatedPlayers = nominatedPlayers[team];
    } else {
      nominatedPlayers = Object.keys(nominatedPlayers).reduce(
        (acc: any[], k) => [...acc, ...nominatedPlayers[k]],
        [],
      );
    }

    const situations = {
      goal: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Polčas')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Typ gólu')}
            name={`type`}
            required
            validate={[required]}
            options={this.state.codelists.events.filter(
              (e) => e.eventType === 'goal',
            )}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Strelec gólu')}
            name={`player`}
            required
            validate={[required]}
            options={(nominatedPlayers as any).filter(
              (p: any) => !assist || p.value !== assist._id,
            )}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Asistencia')}
            name={`assist`}
            options={(nominatedPlayers as any).filter(
              (p: any) => !player || p.value !== player._id,
            )}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
        </>
      ),
      substitution: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Polčas')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Striedaný hráč')}
            name={`player`}
            required
            validate={[required]}
            options={(nominatedPlayers as any).filter(
              (p: any) => !replacement || p.value !== replacement._id,
            )}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Striedajúci hráč')}
            name={`replacement`}
            options={(nominatedPlayers as any).filter(
              (p: any) => !player || p.value !== player._id,
            )}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
        </>
      ),
      yellow_card: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Polčas')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Potrestaný hráč')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            label={__('Dôvod')}
            name={`reason`}
            required
            validate={[required]}
          />
        </>
      ),
      second_yellow_card: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Polčas')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Potrestaný hráč')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            label={__('Dôvod')}
            name={`reason`}
            required
            validate={[required]}
          />
        </>
      ),
      red_card: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Polčas')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Potrestaný hráč')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
          <Field
            component={FormField}
            label={__('Dôvod')}
            name={`reason`}
            required
            validate={[required]}
          />
        </>
      ),
      failed_goal: (
        <>
          <Field
            component={FormField}
            label={__('Čas')}
            name={`eventTime`}
            required
            validate={[isTime]}
            format={(i: any) => {
              if (typeof i === 'number') {
                let minutes = `${Math.ceil(i / 60)}`;
                minutes = `${Math.floor(i / 60)}`;
                const seconds = String(i % 60).padStart(2, '0');
                return `${minutes}:${seconds}`;
              }
              return i;
            }}
            onBlur={(e: any) => {
              let {
                target: { value },
              } = e;
              if (!value.includes(':') && !isNaN(value)) {
                e.preventDefault();
                value = `${value}:00`;
                this.props.dispatch(
                  change(EVENT_FORM_NAME, 'eventTime', value),
                );
              }
            }}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Družstvo')}
            name={`team`}
            required
            validate={[required]}
            options={nominatedTeams}
            disabled
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Polčas')}
            name={`phase`}
            required
            validate={[required]}
            options={this.state.codelists.phases}
          />
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Typ gólu')}
            name={`type`}
            required
            validate={[required]}
            options={this.state.codelists.events.filter(
              (e) => e.eventType === 'failed_goal',
            )}
          />
          <Field
            component={FormField}
            type="theselect"
            label={__('Strelec')}
            name={`player`}
            required
            validate={[required]}
            options={nominatedPlayers as any}
            parse={(i: any) => (i ? { _id: i.value, name: i.label } : i)}
            format={(i: any) =>
              i && i._id ? { value: i._id, label: i.name } : i
            }
          />
        </>
      ),
    };
    return situations[name];
  };

  getSituations = (
    nominatedTeams: Array<{ label: string; value: string }>,
    players: Array<{ label: string; value: string }>,
  ) => [
    {
      label: __('Gól'),
      name: __('Gól'),
      type: 'goal',
    },
    {
      label: __('Striedanie'),
      name: __('Striedanie'),
      type: 'substitution',
    },
    {
      label: __('Žltá karta'),
      name: __('Žltá karta'),
      type: 'yellow_card',
    },
    {
      label: __('Druhá žltá karta'),
      name: __('Druhá žltá karta'),
      type: 'second_yellow_card',
    },
    {
      label: __('Červená karta'),
      name: __('Červená karta'),
      type: 'red_card',
    },
    {
      label: __('Nepremenený gól'),
      name: __('Nepremenený gól'),
      type: 'failed_goal',
    },
  ];

  renderEventRow = (event: Event) => {
    const team = this.props.teams.find((t) => t._id === event.team);
    const goalType = this.state.codelists.events
      .filter((e) => e.eventType === 'goal')
      .find((i) => i.value === event.type);
    const failedGoalType = this.state.codelists.events
      .filter((e) => e.eventType === 'failed_goal')
      .find((i) => i.value === event.type);
    switch (event.eventType) {
      case 'goal':
        return (
          <div>
            <div>
              <b>{goalType ? goalType.label : ''}</b>
            </div>
            {team && (
              <div>
                {__('Družstvo')}: {team.name}
              </div>
            )}
            {event.player && (
              <div>
                {__('Strelec')}: {event.player.name}
              </div>
            )}
            {event.assist && (
              <div>
                {__('Asistencia')}: {event.assist.name}
              </div>
            )}
          </div>
        );
      case 'failed_goal':
        return (
          <div>
            <div>
              <b>{failedGoalType ? failedGoalType.label : ''}</b>
            </div>
            {team && (
              <div>
                {__('Družstvo')}: {team.name}
              </div>
            )}
            {event.player && (
              <div>
                {__('Strelec')}: {event.player.name}
              </div>
            )}
          </div>
        );
      case 'substitution':
        return (
          <div>
            <div>
              <b>{__('Striedanie')}</b>
            </div>
            <div>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Striedaný hráč')}: {event.player.name}
              </div>
              {event.replacement && (
                <div>
                  {__('Striedajúci hráč')}: {event.replacement.name}
                </div>
              )}
            </div>
          </div>
        );
      case 'yellow_card':
        return (
          <div>
            <div>
              <b>{__('Žltá karta')}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Potrestaný hráč')}: {event.player.name}
              </div>
              <div>
                {__('Dôvod')}: {event.reason}
              </div>
            </div>
          </div>
        );
      case 'second_yellow_card':
        return (
          <div>
            <div>
              <b>{__('Druhá žltá karta')}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Potrestaný hráč')}: {event.player.name}
              </div>
              <div>
                {__('Dôvod')}: {event.reason}
              </div>
            </div>
          </div>
        );
      case 'red_card':
        return (
          <div>
            <div>
              <b>{__('Červená karta')}</b>
              {team && (
                <div>
                  {__('Družstvo')}: {team.name}
                </div>
              )}
              <div>
                {__('Potrestaný hráč')}: {event.player.name}
              </div>
              <div>
                {__('Dôvod')}: {event.reason}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { closed, data } = this.props;

    const events = getProp(this.props, ['events'], []);
    // const delegates = getProp(this.props, ['delegates'], []);

    const nominatedPlayers = this.getNominatedPlayers();
    const nominatedTeams = this.getNominatedTeams();

    const situations = this.getSituations(
      nominatedTeams as any,
      Object.keys(nominatedPlayers).reduce(
        (acc: any[], k) => [...acc, ...nominatedPlayers[k]],
        [],
      ),
    );

    return (
      <Segment>
        {data && data.resultsTable && (
          <Box title={__('Výsledková tabuľka po stretnutí')}>
            <BasicTable
              columns={[
                {
                  header: __('Družstvo'),
                },
                {
                  header: __('OZ'),
                  width: 40,
                },
                {
                  header: __('V'),
                  width: 40,
                },
                {
                  header: __('R'),
                  width: 40,
                },
                {
                  header: __('P'),
                  width: 40,
                },
                {
                  header: __('G'),
                  width: 80,
                },
                {
                  header: __('B'),
                  width: 40,
                },
              ]}
              rows={data.resultsTable.results.map((r, idx) => ({
                ...r,
                rowId: `${r.team._id}_${idx}`,
              }))}
              renderRow={(result: Result) => [
                result.team.name,
                result.stats.matches.played,
                result.stats.matches.won,
                result.stats.matches.draw,
                result.stats.matches.lost,
                `${result.stats.goals.given}:${result.stats.goals.received}`,
                result.stats.points,
              ]}
              rowKey={'rowId'}
            />
          </Box>
        )}
        {/* <Box title={__('Zodpovedné osoby')}>
          <TableForm
            disabled={closed}
            buttonLabel={__('Pridať osobu')}
            headers={[{ label: __('Typ') }, { label: __('Meno a priezvisko') }]}
            items={delegates}
            list={delegates.map(
              (i: { type: string; name: string; grant: boolean }) => {
                return {
                  type: i.type,
                  name: i.name,
                };
              },
            )}
            fields={[
              {
                type: 'select',
                label: __('Typ'),
                items: this.state.codelists.delegates,
                name: 'type',
                required: true,
              },
              {
                label: __('Meno a priezvisko'),
                type: 'input',
                name: 'name',
                required: true,
              },
            ]}
            onChange={e => {
              this.props.dispatch(change(FORM_NAME, 'protocol.delegates', e));
            }}
          />
        </Box> */}
        <Box title={__('Priebeh stretnutia')}>
          <Message warning>
            {__(
              'V prípade, že nevyplníte čas začiatku a konca zápasu, na verejnej časti nebude možné zobraziť priebeh stretnutia.',
            )}
          </Message>
          <Box title={__('Začiatok a koniec')}>
            <Row>
              {this.state.codelists.phases
                .filter((phase) => phase.playPhase)
                .map((phase, phaseIdx) => {
                  let startTime;
                  let endTime;
                  if (phase.basePhase) {
                    const baseTime = new Date(this.props.startDate);
                    startTime = addSeconds(baseTime, phase.startTime);
                    endTime = addSeconds(baseTime, phase.endTime);
                    if (phaseIdx > 0) {
                      startTime = addSeconds(startTime, 900);
                    }
                    if (
                      !getProp(this.props.timer || {}, [
                        phase._id,
                        'start',
                        'date',
                      ])
                    ) {
                      this.props.dispatch(
                        change(
                          FORM_NAME,
                          `timer.${phase._id}.start.date`,
                          format(startTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                        ),
                      );
                      this.props.dispatch(
                        change(
                          FORM_NAME,
                          `timer.${phase._id}.end.date`,
                          format(endTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                        ),
                      );
                    }
                  }

                  return (
                    <>
                      <Col xs={12} s={6} m={3}>
                        <Field
                          name={`timer.${phase._id}.start.date`}
                          label={`${phase.label} - ${__('začiatok')}`}
                          component={FormField}
                          type="time"
                          timeIntervals={1}
                          disabled={closed}
                        />
                      </Col>
                      <Col xs={12} s={6} m={3}>
                        <Field
                          name={`timer.${phase._id}.end.date`}
                          label={`${phase.label} - ${__('koniec')}`}
                          component={FormField}
                          type="time"
                          timeIntervals={1}
                          disabled={closed}
                        />
                      </Col>
                    </>
                  );
                })}
            </Row>
          </Box>

          {!closed && this.props.competitionPart && (
            <Box title={__('Live stretnutie')}>
              <ProtocolTimer
                matchId={!!this.props.data ? this.props.data._id : undefined}
                phases={this.state.codelists.phases}
                onToggle={this.onToggleTimer}
                initialData={this.state.timer}
                settings={this.props.competitionPart.settings}
                getCurrentTime={(diff: number) => {
                  this.currentTimerValue = diff;
                }}
                getCurrentPhase={(phase: string) => {
                  this.currentTimerPhase = phase;
                }}
              />
            </Box>
          )}
          <Row>
            {nominatedTeams.map((team: any) => (
              <Col key={team.value} xs={12} m={6}>
                <div>
                  <Segment
                    raised
                    header={
                      <SegmentHeader withSeparator size="xs">
                        {team.label}
                      </SegmentHeader>
                    }
                  >
                    {nominatedPlayers[team.value].map(
                      (player: {
                        label: string;
                        value: string;
                        additionalData: { nr: string };
                      }) => (
                        <Player key={player.value}>
                          <PlayerInfo>
                            <div>{player.additionalData.nr}</div>
                            <div>{player.label}</div>
                          </PlayerInfo>
                          {!closed && (
                            <Situations>
                              {situations.map((situation) => (
                                <SituationItem
                                  key={situation.name}
                                  onClick={() => {
                                    this.setActiveSituation(
                                      situation,
                                      team,
                                      player,
                                    );
                                  }}
                                >
                                  {situation.type === 'goal' && (
                                    <Icons
                                      name="goal"
                                      size={15}
                                      color={this.props.theme.color.primary}
                                    />
                                  )}
                                  {situation.type === 'failed_goal' && (
                                    <Icons
                                      name="failed_goal"
                                      size={15}
                                      color={this.props.theme.color.warning}
                                    />
                                  )}
                                  {situation.type === 'yellow_card' && (
                                    <Icons name="yellow_card" size={15} />
                                  )}
                                  {situation.type === 'second_yellow_card' && (
                                    <Icons
                                      name="second_yellow_card"
                                      size={15}
                                    />
                                  )}
                                  {situation.type === 'red_card' && (
                                    <Icons name="red_card" size={15} />
                                  )}
                                  {situation.type === 'substitution' && (
                                    <Icons name="substitution" size={17.5} />
                                  )}
                                </SituationItem>
                              ))}
                            </Situations>
                          )}
                        </Player>
                      ),
                    )}
                  </Segment>
                </div>
              </Col>
            ))}
          </Row>
          {this.props.settings && (
            <Timeline
              protocol={this.props.protocol}
              phases={this.state.codelists.phases}
              eventTypes={this.state.codelists.events}
              teams={this.props.teams}
              settings={this.props.settings}
              timer={this.props.data && this.props.data.timer}
              onClickEvent={(event, idx) => {
                if (!closed) {
                  const situation = situations.find(
                    (s) => s.type === event.eventType,
                  );
                  const team = this.props.teams.find(
                    (t) => t._id === event.team,
                  );
                  if (situation && team) {
                    this.setActiveSituation(
                      situation,
                      { label: team.name, value: team._id },
                      { label: event.player.name, value: event.player._id },
                      event,
                      idx > -1 ? idx : undefined,
                      event.eventTime,
                      event.phase,
                    );
                  }
                }
              }}
            />
          )}
          {this.state.isEventsFormOpened && this.state.activeSituation && (
            <Modal
              isOpen
              handleClose={() =>
                this.setState({
                  situationTeamId: '',
                  isEventsFormOpened: false,
                  activeSituation: null,
                })
              }
            >
              <ModalContent>
                <EventForm
                  initialValues={this.state.activeSituation.data}
                  onSubmit={(values: any) => {
                    const eventTime =
                      typeof values.eventTime === 'number'
                        ? parseToTimeString(values.eventTime)
                        : values.eventTime;

                    if (
                      this.state.activeSituation &&
                      typeof this.state.activeSituation.idx !== 'undefined'
                    ) {
                      this.props.dispatch(
                        change(
                          FORM_NAME,
                          `protocol.events.${this.state.activeSituation.idx}`,
                          {
                            ...values,
                            eventTime,
                          },
                        ),
                      );
                    } else {
                      this.props.dispatch(
                        change(FORM_NAME, 'protocol.events', [
                          ...events,
                          {
                            ...values,
                            eventTime,
                          },
                        ]),
                      );
                    }
                    this.setState(
                      {
                        situationTeamId: '',
                        isEventsFormOpened: false,
                        activeSituation: null,
                      },
                      () => {
                        this.props.dispatch(submit(FORM_NAME));
                      },
                    );
                  }}
                >
                  {this.getSituationFields(
                    this.state.activeSituation.situation.type,
                    this.state.activeSituation.idx || 0,
                  )}
                </EventForm>
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button
                    secondary
                    onClick={() => {
                      this.setState({
                        situationTeamId: '',
                        isEventsFormOpened: false,
                      });
                    }}
                  >
                    {__('Zavrieť')}
                  </Button>
                  &nbsp;
                  {!!this.state.activeSituation &&
                    typeof this.state.activeSituation.idx !== 'undefined' && (
                      <>
                        <Button
                          danger
                          onClick={() => {
                            if (this.state.activeSituation) {
                              this.props.dispatch(
                                arrayRemove(
                                  FORM_NAME,
                                  'protocol.events',
                                  this.state.activeSituation.idx || 0,
                                ),
                              );
                              this.setState(
                                {
                                  situationTeamId: '',
                                  isEventsFormOpened: false,
                                  activeSituation: null,
                                },
                                () => {
                                  this.props.dispatch(submit(FORM_NAME));
                                },
                              );
                            }
                          }}
                        >
                          {__('Odstrániť')}
                        </Button>
                        &nbsp;
                      </>
                    )}
                  <Button
                    primary
                    onClick={() => {
                      this.props.dispatch(submit(EVENT_FORM_NAME));
                    }}
                  >
                    {this.state.activeSituation &&
                    typeof this.state.activeSituation.idx !== 'undefined'
                      ? __('Upraviť')
                      : __('Pridať')}
                  </Button>
                </div>
              </ModalActions>
            </Modal>
          )}
          {/* <TableForm
            key={
              this.state.activeSituation &&
              this.state.activeSituation.situation.type
            }
            isOpen={this.state.isEventsFormOpened}
            disabled={closed}
            {...(this.state.activeSituation && {
              activeSituation: this.state.activeSituation,
            })}
            timer={this.state.timer}
            headers={[
              { label: __('Čas') },
              { label: __('Polčas') },
              { label: __('Udalosť') },
            ]}
            list={this.getSortedEvents().map((event: Event) => {
              const phase = this.state.codelists.phases.find(
                i => i.value === event.phase,
              );
              return {
                eventTime: event.eventTime,
                phase: phase && phase.label,
                event: this.renderEventRow(event),
              };
            })}
            items={events}
            onClose={() => {
              this.setState({
                situationTeamId: '',
                isEventsFormOpened: false,
              });
            }}
            fields={[
              {
                name: 'events',
                type: 'events',
                situations: this.getSituations(
                  nominatedTeams as any,
                  Object.keys(nominatedPlayers).reduce((acc: any[], k) => {
                    if (
                      this.state.situationTeamId &&
                      k === this.state.situationTeamId
                    ) {
                      return [...acc, ...nominatedPlayers[k]];
                    }
                    return acc;
                  }, []),
                ),
              },
            ]}
            onChange={async e => {
              this.setState({
                situationTeamId: '',
                isEventsFormOpened: false,
              });
              this.props.dispatch(
                change(FORM_NAME, 'liveState', this.state.timer),
              );
              this.props.dispatch(change(FORM_NAME, 'protocol.events', e));
              setTimeout(() => {
                this.props.dispatch(submit(FORM_NAME));
              }, 500);
            }}
          /> */}
        </Box>
      </Segment>
    );
  }
}

export default connect(mapStateToProps)(withRouter(withTheme(FutsalProtocol)));
