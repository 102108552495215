import Input from '@sportnet/ui/Input';
import { addSeconds, differenceInSeconds, format, subSeconds } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';
// import { getProp } from '../../utilities';
// import socketConnect from 'socket.io-client';

const Panel = styled.div``;

interface OwnProps {
  getCurrentTime?: (diff: number) => void;
  getCurrentPhase?: (phase: string) => void;
  onToggle: (
    state: boolean,
    value: number,
    phase: string,
    forceUpdate?: boolean,
  ) => void;
  phases: Array<{
    label: string;
    value: string;
    playPhase?: boolean;
    basePhase?: boolean;
    endTime?: number;
  }>;
  matchId?: string;
  initialData: {
    phase: string;
    ongoing: boolean;
    toggleDate: string;
    seconds: number;
  };
  settings: {
    reverseTime?: boolean;
    overlapTime?: boolean;
    phases: {
      [key: string]: {
        startTime: number;
        endTime: number;
      };
    };
  };
}

type Props = OwnProps;

class ProtocolTimer extends React.PureComponent<Props> {
  state = {
    toggleDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    temporaryDate: '',
    activePhase: '',
  };

  timerEvent: any = null;
  socket: SocketIOClient.Socket | null = null;

  componentDidMount() {
    this.timerEvent = setInterval(() => {
      const { diff } = this.getCurrentTimerValue();

      const phaseData = this.props.phases.find(
        (p) => p.value === this.state.activePhase,
      );

      if (phaseData && !this.props.settings.overlapTime) {
        if (
          this.props.settings.reverseTime &&
          typeof phaseData.endTime !== 'undefined' &&
          diff <= phaseData.endTime
        ) {
          this.pauseTimer();
        } else if (
          !this.props.settings.reverseTime &&
          typeof phaseData.endTime !== 'undefined' &&
          diff >= phaseData.endTime
        ) {
          this.pauseTimer();
        }
      }
      this.forceUpdate();
    }, 1000);
    this.setTimer();
    if (this.props.matchId) {
      this.registerLiveScore(this.props.matchId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.getCurrentTime) {
      const { diff } = this.getCurrentTimerValue();
      this.props.getCurrentTime(diff);
    }
    if (this.props.getCurrentPhase) {
      this.props.getCurrentPhase(this.state.activePhase);
    }
    if (
      this.props.initialData.phase !== prevProps.initialData.phase ||
      this.props.initialData.ongoing !== prevProps.initialData.ongoing ||
      this.props.initialData.seconds !== prevProps.initialData.seconds ||
      this.props.initialData.toggleDate !== prevProps.initialData.toggleDate
    ) {
      this.setTimer(this.props);
    }
  }

  registerLiveScore = (matchId: string) => {
    // this.socket = socketConnect(process.env.REACT_APP_SOCKET_BASE_URL || '', {
    //   path: '/api/v1/socket.io',
    // });
    // this.socket.on(`match_livestate_${matchId}`, (response: any) => {
    //   this.setState(
    //     {
    //       startDate: response.liveState.startDate,
    //       phase: response.liveState.phase,
    //     },
    //     () => {
    //       if (response.liveState.ongoing && !this.timerEvent) {
    //         this.startTimer();
    //       } else if (!response.liveState.ongoing && this.timerEvent) {
    //         this.pauseTimer();
    //       }
    //     },
    //   );
    // });
  };

  componentWillUnmount() {
    clearInterval(this.timerEvent);
    this.timerEvent = null;
    if (this.socket) {
      this.socket.removeAllListeners();
    }
  }

  getCurrentTimerValue = () => {
    const nowDiff = differenceInSeconds(
      new Date(),
      new Date(this.state.toggleDate),
    );
    let currentTime = addSeconds(
      new Date(this.state.toggleDate),
      this.props.initialData.seconds,
    );
    if (this.props.initialData.ongoing) {
      if (this.props.settings.reverseTime) {
        currentTime = subSeconds(new Date(currentTime), nowDiff);
      } else {
        currentTime = addSeconds(new Date(currentTime), nowDiff);
      }
    }
    const diff = differenceInSeconds(
      new Date(currentTime),
      new Date(this.state.toggleDate),
    );
    const minutes = String(Math.floor(diff / 60)).padStart(2, '0');
    const seconds = String(diff % 60).padStart(2, '0');
    return { diff, minutes, seconds };
  };

  getActivePhase = () =>
    this.props.phases.find((phase) => phase.value === this.state.activePhase);

  setTimer = (props = this.props) => {
    const {
      initialData: { phase, toggleDate, ongoing },
    } = props;
    this.setState({
      activePhase: phase,
      toggleDate,
    });
    if (ongoing) {
      this.startTimer();
    }
  };

  startTimer = (toggle = false) => {
    this.forceUpdate();
    const { diff: secondsToSet } = this.getCurrentTimerValue();
    if (toggle) {
      this.props.onToggle(true, secondsToSet, this.state.activePhase);
    }
  };

  pauseTimer = () => {
    const { diff: secondsToSet } = this.getCurrentTimerValue();
    this.props.onToggle(false, secondsToSet, this.state.activePhase);
    this.forceUpdate();
  };

  onChangeTime = (e: any) => {
    this.setState({
      temporaryDate: e.target.value,
    });
  };

  onChangePhase = (e: any) => {
    const phaseData = this.props.settings.phases[e.target.value];
    let { diff: secondsToSet } = this.getCurrentTimerValue();
    if (phaseData) {
      secondsToSet = phaseData.startTime;
    }
    this.setState(
      {
        activePhase: e.target.value,
      },
      () => {
        this.props.onToggle(false, secondsToSet, this.state.activePhase);
      },
    );
  };

  onBlurInput = () => {
    if (this.state.temporaryDate) {
      const split = this.state.temporaryDate.split(':');
      if (split && split.length === 2) {
        const seconds = Number(split[0]) * 60 + Number(split[1]);
        if (Number.isNaN(seconds)) {
          this.fallBackTime();
        } else {
          this.setState({
            secondsToAdd: seconds,
            temporaryDate: '',
          });
          this.props.onToggle(false, seconds, this.state.activePhase, true);
        }
      } else {
        this.fallBackTime();
      }
    } else {
      this.fallBackTime();
    }
  };

  fallBackTime = () => {
    this.setState({
      temporaryDate: '',
    });
  };

  render() {
    const { minutes, seconds } = this.getCurrentTimerValue();

    const activePhase = this.getActivePhase();
    return (
      <Panel>
        <Input
          value={
            this.state.temporaryDate
              ? this.state.temporaryDate
              : `${minutes}:${seconds}`
          }
          disabled={this.props.initialData.ongoing}
          onBlur={this.onBlurInput}
          onFocus={(event: any) => {
            event.target.select();
          }}
          onChange={this.onChangeTime}
        >
          <Input.Select
            onChange={this.onChangePhase}
            value={this.state.activePhase}
            disabled={this.props.initialData.ongoing}
          >
            {this.props.phases.map((phase) => (
              <option key={phase.value} value={phase.value}>
                {phase.label}
              </option>
            ))}
          </Input.Select>
          <input
            onBlur={this.onBlurInput}
            onFocus={(event: any) => {
              event.target.select();
            }}
            onChange={this.onChangeTime}
          />
          {activePhase &&
            !!activePhase.playPhase &&
            (this.props.initialData.ongoing ? (
              <Input.Button
                type="button"
                danger
                icon="pause-circle"
                onClick={this.pauseTimer}
              />
            ) : (
              <Input.Button
                type="button"
                primary
                icon="play-circle"
                onClick={() => {
                  this.startTimer(true);
                }}
              />
            ))}
        </Input>
      </Panel>
    );
  }
}

export default ProtocolTimer;
