import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { State } from '../../../rootReducer';
import { getProp } from '../../../utilities';
import { LIST_NAME } from './definitions';

const ageCategoriesDomainSelector = (state: State) => {
  return state.entities.ageCategories;
};

export const ageCategoriesSelector = createSelector(
  ageCategoriesDomainSelector,
  (substate) =>
    substate ? Object.keys(substate).map((key) => substate[key]) : [],
);

export const ageCategoriesCurrentListSelector = createSelector(
  getListResults(LIST_NAME),
  ageCategoriesDomainSelector,
  (results, ageCategories) => {
    if (results) {
      const res = results.map((id: string) => {
        return ageCategories[id];
      });
      return res;
    }
    return [];
  },
);

export const ageCategoryByIdSelector = (id: string) =>
  createSelector(ageCategoriesDomainSelector, (ageCategories) => {
    if (ageCategories && ageCategories[id]) {
      return ageCategories[id];
    }
    return null;
  });

const ageCategoryDetailSelector = (state: State) => {
  return state.detail.ageCategories;
};

export const ageCategoryByIdIsFetchingSelector = (id: string) =>
  createSelector(ageCategoryDetailSelector, (substate) =>
    getProp(substate, [id, 'isFetching'], false),
  );
