import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  subtitle?: any;
  size?: string;
  withSeparator?: boolean;
  toggleCollapse?: React.Dispatch<React.SetStateAction<boolean>>;
  collapsible?: boolean;
  collapsed?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
}

const Wrapper = styled('div')`
  padding: ${({ theme }) => `${rem(theme.grid.gutterWidth)} 0`};
`;

class Box extends React.PureComponent<Props> {
  render() {
    const {
      subtitle,
      title,
      children,
      size = 's',
      withSeparator = true,
      toggleCollapse,
      loading,
    } = this.props;
    return (
      <Segment
        raised
        loading={loading}
        header={
          <SegmentHeader
            size={size as any}
            withSeparator={withSeparator}
            {...(subtitle && { subtitle })}
            collapsible={this.props.collapsible}
            toggleCollapse={!!toggleCollapse ? toggleCollapse : undefined}
            isCollapsed={this.props.collapsed}
          >
            {title}
          </SegmentHeader>
        }
      >
        <Wrapper>{children}</Wrapper>
      </Segment>
    );
  }
}

export default Box;
