import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import FormField from '@sportnet/ui/FormField';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { change, isSubmitting, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import Form from './form';

export const FORM_NAME = 'NOMINATION_FORM';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

export interface NominationItem {
  sportnetUser?: {
    _id?: string;
    name?: string;
  };
  additionalData?: any;
  teamId?: string;
  closed?: boolean;
}

export interface User {
  sportnetId: string;
  displayName: string;
}

const mapStateToProps = (state: State) => ({
  formIsSubmitting: isSubmitting(FORM_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouteComponentProps<{
  id: string;
  appspace: string;
  teamId: string;
}> &
  OwnProps &
  IMapStateToProps;

class Nomination extends React.PureComponent<
  Props,
  {
    experts: User[];
    nomination: any;
    isFetching: boolean;
    settings: {};
    competitionId: null | string;
    partId: null | string;
    rules?: null | {
      sport_sector?: string;
    };
    isDownloadingLineupPDF: boolean;
    isSendingLineupPDF: boolean;
    sendLineupModalOpened: boolean;
    email: string;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      experts: [],
      settings: {},
      nomination: null,
      isFetching: false,
      competitionId: null,
      partId: null,
      rules: null,
      isDownloadingLineupPDF: false,
      isSendingLineupPDF: false,
      sendLineupModalOpened: false,
      email: '',
    };
  }
  async componentDidMount() {
    const {
      match: {
        params: { appspace, id, teamId },
      },
    } = this.props;
    try {
      this.setState({
        isFetching: true,
      });
      const { competition, competitionPart, rules } =
        await api.adminGetMatchById(appspace, id);
      const { settings } = await api.adminGetCompetitionPartById(
        appspace,
        competition._id,
        competitionPart._id,
      );
      const nomination = await api.adminGetMatchNominations(
        appspace,
        id,
        teamId,
      );

      this.setState({
        nomination,
        competitionId: competition._id,
        partId: competitionPart._id,
        isFetching: false,
        settings: settings || {},
        rules,
      });
    } catch (e: any) {
      this.setState({
        isFetching: false,
      });
      alert(__('Nepodarilo sa získať zoznam športovcov'));
    }
  }
  submit = async (formData: any) => {
    const {
      match: {
        params: { appspace, id, teamId },
      },
    } = this.props;
    try {
      await api.adminPutMatchNominations(
        appspace,
        id,
        teamId,
        {},
        { ...formData, crew: formData.crew || [] },
      );
      const nomination = await api.adminGetMatchNominations(
        appspace,
        id,
        teamId,
      );
      this.setState({
        nomination,
      });
    } catch (e: any) {
      alert(__('Nepodarilo sa uložiť nomináciu'));
      console.error(e);
    } finally {
      if (formData.closed) {
        this.props.history.push(`/admin/${appspace}/matches/${id}`);
      }
    }
  };

  downloadLineupPDF = async () => {
    const {
      match: {
        params: { appspace, id },
      },
    } = this.props;
    this.setState({ isDownloadingLineupPDF: true });
    try {
      const response = await api.getMatchProtocolPDF(appspace, id);
      window.location.href = (response as any).url;
    } catch (e) {
      console.error(e);
      window.alert(__('Vygenerovanie zostáv sa nepodarilo'));
    } finally {
      this.setState({ isDownloadingLineupPDF: false });
    }
  };

  sendLineupPDF = async () => {
    const {
      match: {
        params: { appspace, id },
      },
    } = this.props;
    this.setState({ isSendingLineupPDF: true });
    try {
      await api.sendMatchProtocolPDF(appspace, id, {
        email: this.state.email,
      });
    } catch (e) {
      console.error(e);
      window.alert(__('Vygenerovanie zostáv sa nepodarilo'));
    } finally {
      this.setState({ isSendingLineupPDF: false, email: '' });
    }
  };

  toggleSendLineupModal = () => {
    this.setState((prev) => ({
      sendLineupModalOpened: !prev.sendLineupModalOpened,
    }));
  };

  render() {
    const {
      experts,
      nomination,
      isFetching,
      settings,
      competitionId,
      partId,
      rules,
    } = this.state;
    const {
      formIsSubmitting,
      match: {
        params: { appspace, id },
      },
    } = this.props;

    if (isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/matches/${id}`}
            />
            <HeaderBar.Header>{__('Nominácia do stretnutia')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          nomination && !nomination.closed ? (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  primary
                  loading={formIsSubmitting}
                  onClick={async () => {
                    await this.props.dispatch(
                      change(FORM_NAME, 'closed', false),
                    );
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                >
                  {__('Uložiť')}
                </Button>
                &nbsp;
                <Button
                  success
                  loading={formIsSubmitting}
                  onClick={async () => {
                    await this.props.dispatch(
                      change(FORM_NAME, 'closed', true),
                    );
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                >
                  {__('Uložiť a uzavrieť')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          ) : (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  basic
                  onClick={this.downloadLineupPDF}
                  loading={this.state.isDownloadingLineupPDF}
                >
                  {__('Stiahnuť zostavy')}
                </Button>
                &nbsp;
                <Button basic onClick={this.toggleSendLineupModal}>
                  {__('Poslať zostavy e-mailom')}
                </Button>
                &nbsp;
                <Button
                  primary
                  loading={formIsSubmitting}
                  onClick={async () => {
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                >
                  {__('Uložiť')}
                </Button>
                &nbsp;
                {nomination && nomination.closed && (
                  <Button
                    success
                    loading={formIsSubmitting}
                    onClick={async () => {
                      await this.props.dispatch(
                        change(FORM_NAME, 'closed', false),
                      );
                      this.props.dispatch(submit(FORM_NAME));
                    }}
                  >
                    {__('Otvoriť nomináciu')}
                  </Button>
                )}
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        {/* <AppContext
          title={__('Nominácia do stretnutia')}
          breadcrumbs={[
            {
              name: __('Detail stretnutia'),
              url: `/admin/${appspace}/matches/${id}`,
            },
          ]}
        /> */}
        <Modal
          isOpen={this.state.sendLineupModalOpened}
          handleClose={this.toggleSendLineupModal}
        >
          <ModalContent>
            <FormField
              value={this.state.email}
              label={__('Emailová adresa')}
              onChange={(e: any) => this.setState({ email: e.target.value })}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button basic onClick={this.toggleSendLineupModal}>
                {__('Zavrieť')}
              </Button>
              &nbsp;
              <Button
                primary
                onClick={this.sendLineupPDF}
                loading={this.state.isSendingLineupPDF}
              >
                {__('Poslať zostavy e-mailom')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Segment>
          {nomination && (
            <Form
              handleSubmit={this.submit}
              experts={experts}
              data={nomination}
              settings={settings}
              competitionId={competitionId}
              partId={partId}
              rules={rules}
            />
          )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Nomination));
