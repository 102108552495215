import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Icon from '@sportnet/ui/Icon';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Loader from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import connectQueryHoc, {
  QueryHocInterface,
  QueryHocTypes,
} from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  commit,
  getListNextOffset,
  getListParameters,
  getListParametersSerialized,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { ThunkDispatch } from 'redux-thunk';
import LoaderWrapper from '../../components/LoaderWrapper';
import { State } from '../../rootReducer';
import { __ } from '../../utilities';
import { setBreadcrumbs } from '../App/actions';
import { getSeasons } from '../Codelists/Seasons/actions';
import { seasonsSelector } from '../Codelists/Seasons/selectors';
import { getCompetitions } from './actions';
// import { getTeams } from './actions';
import { Competition, LIST_LIMIT, LIST_NAME } from './definitions';
import { competitionsSelector } from './selectors';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

type RouterParameters = RouteComponentProps<{ appspace: string }>;

const mapStateToProps = (state: State, props: RouterParameters) => ({
  competitions: competitionsSelector(state),
  seasons: seasonsSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
  nextOffset: getListNextOffset(LIST_NAME)(state),
  parameters: getListParameters(LIST_NAME)(state),
  serializedParameters: getListParametersSerialized(LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouterParameters & OwnProps & IMapStateToProps & QueryHocInterface;

class CompetitionsList extends React.PureComponent<Props> {
  state = {
    visibleRowId: null,
  };

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(setBreadcrumbs([__('Zoznam súťaží')]));
    dispatch(getSeasons.action({ appspace }));
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
          ...this.props.query,
        },
      }),
    );
    this.setParameters(this.props.query);
    this.fetchTeamsByParameters(this.props);
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.serializedQuery !== prevProps.serializedQuery) {
      this.setParameters(this.props.query);
    }
    if (this.props.serializedParameters !== prevProps.serializedParameters) {
      this.fetchTeamsByParameters(this.props);
    }
  }
  setParameters = (parameters: any): void => {
    this.props.dispatch(
      setParams({
        listName: LIST_NAME,
        parameters,
      }),
    );
  };
  fetchTeamsByParameters = async (props: Props = this.props) => {
    const {
      match: {
        params: { appspace },
      },
      dispatch,
      parameters,
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const {
            entities: { competitions: newCompetitions },
            total,
            nextOffset,
          } = await dispatch(
            getCompetitions.action({
              appspace,
              limit: LIST_LIMIT,
              offset: parameters.offset,
              q: parameters.q,
              seasonIds:
                (parameters.seasonIds || []).length > 0 && parameters.seasonIds,
            }),
          );
          const items = Object.keys(newCompetitions).map((id) => {
            return newCompetitions[id];
          });
          return {
            total: total || items.length,
            results: items.map((object: Competition) => {
              return object._id;
            }),
            nextOffset: nextOffset || null,
          };
        },
      }),
    );
  };
  render() {
    const {
      competitions,
      isFetching,
      // seasons,
      parameters: { offset },
      total,
      match: {
        params: { appspace },
      },
    } = this.props;

    const filteredCompetitions: Competition[] = [];
    competitions.forEach((comp: Competition) => {
      const hasCompetitionWithSameGroup = filteredCompetitions.find(
        (c) => c.competitionGroupId === comp.competitionGroupId,
      );
      if (!hasCompetitionWithSameGroup) {
        filteredCompetitions.push(comp);
      }
    });

    return (
      <ScrollLayout
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              {total && (
                <Paginator
                  limit={LIST_LIMIT}
                  total={total}
                  offset={offset}
                  onChangeOffset={async (newOffset: number) => {
                    this.props.setParameter({
                      offset: newOffset,
                    });
                  }}
                />
              )}
            </ContextBarItem>
            <ContextBarSpacer />
            <ContextBarItem>
              <Link to={`/admin/${appspace}/competitions/new`}>
                <Button primary>{__('Pridať súťaž')}</Button>
              </Link>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {isFetching ? (
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        ) : (
          <>
            {!isFetching && competitions.length === 0 ? (
              <NotFound
                icon="search"
                title={__('Pre dané parametre sa nenašli žiadne súťaže')}
              />
            ) : (
              <Segment>
                <Segment raised>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th width={50} />
                        <Th>{__('Názov súťaže')}</Th>
                        <Th width={150}>{__('Identifikátor')}</Th>
                        <Th width={150}>{__('Sezóna')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredCompetitions.map((competition: Competition) => {
                        const hasMultipleCompetitions =
                          competitions.filter(
                            (c: Competition) =>
                              competition.competitionGroupId &&
                              c.competitionGroupId ===
                                competition.competitionGroupId,
                          ).length > 1;
                        return (
                          <React.Fragment key={competition._id}>
                            <Tr
                              onClick={() => {
                                this.props.history.push(
                                  `/admin/${appspace}/competitions/${competition._id}`,
                                );
                              }}
                            >
                              <Td
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  this.setState({
                                    visibleRowId:
                                      this.state.visibleRowId ===
                                      competition.competitionGroupId
                                        ? null
                                        : competition.competitionGroupId,
                                  });
                                }}
                              >
                                {hasMultipleCompetitions && (
                                  <div>
                                    <Icon
                                      name={
                                        this.state.visibleRowId ===
                                        competition.competitionGroupId
                                          ? 'arrow-down'
                                          : 'arrow-top'
                                      }
                                    />
                                  </div>
                                )}
                              </Td>
                              <Td>{competition.name}</Td>
                              <Td>{competition.identifier}</Td>
                              <Td>
                                {!!competition.season &&
                                  competition.season.name}
                              </Td>
                            </Tr>
                            {hasMultipleCompetitions &&
                              this.state.visibleRowId ===
                                competition.competitionGroupId &&
                              competitions
                                .filter(
                                  (c: Competition) =>
                                    c.competitionGroupId ===
                                      this.state.visibleRowId &&
                                    c._id !== competition._id,
                                )
                                .map((item: Competition) => (
                                  <Tr
                                    onClick={() => {
                                      this.props.history.push(
                                        `/admin/${appspace}/competitions/${item._id}`,
                                      );
                                    }}
                                    key={item._id}
                                  >
                                    <Td />
                                    <Td>{item.name}</Td>
                                    <Td>{item.identifier}</Td>
                                    <Td>{!!item.season && item.season.name}</Td>
                                  </Tr>
                                ))}
                          </React.Fragment>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Segment>
              </Segment>
            )}
          </>
        )}
      </ScrollLayout>
    );
  }
}

const connectedToQuery = connectQueryHoc<any>({
  parameters: {
    seasonIds: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
    q: {
      type: QueryHocTypes.String,
      defaultValue: '',
    },
  },
})(CompetitionsList);

export default connect(mapStateToProps)(withRouter(connectedToQuery));
