import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import { __ } from '@sportnet/ui/utilities';
import { change, reduxForm } from 'redux-form';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import React from 'react';
import { compose } from 'redux';
import UserSelectionInput from './userSelectionInput';

const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

type Props = {
  dispatch: any;
  form: any;
  appspace: string;
  editing: boolean;
  codelists: any[];
  onSubmit: any;
  initialValues: any;
  crewMember: any;
  setCrewMember: any;
  position: any;
  setPosition: any;
};

const CrewMemberForm: React.FC<Props> = ({
  dispatch,
  form,
  appspace,
  editing,
  crewMember,
  codelists,
  setCrewMember,
  position,
  setPosition,
}) => {
  return (
    <Row>
      {!!!editing && (
        <Col xs={12}>
          <FormGroup>
            <Label>{__('Výber hráča (min. 4 znaky)')}</Label>
            <RelativeWrapper>
              <UserSelectionInput
                value={crewMember}
                appSpace={appspace}
                onChange={(i) => {
                  dispatch(change(form, 'sportnetUser', i));
                  setCrewMember({ value: i?._id, label: i?.name });
                }}
              />
            </RelativeWrapper>
          </FormGroup>
        </Col>
      )}
      <Col xs={12}>
        <FormField
          type="select"
          onChange={(e: any) => {
            dispatch(
              change(form, 'position', e.target.value || ''),
              setPosition(e.target.value || ''),
            );
          }}
          value={position}
          label={__('Pozícia')}
        >
          <option hidden />
          {codelists.map((position) => (
            <option key={position._id} value={position._id}>
              {position.label}
            </option>
          ))}
        </FormField>
      </Col>
    </Row>
  );
};
export default compose(reduxForm<any, any>({ enableReinitialize: true }))(
  CrewMemberForm,
) as any;
